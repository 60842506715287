import React, { FunctionComponent } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Logo from "assets/new-logo.png";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
}));

type Props = {
  padding?: string;
  alignment?: "left" | "center" | "right";
  direction?: "row" | "column";
  height?: string;
};

const TransparentLogo: FunctionComponent<Props> = ({
  padding = "0px",
  alignment = "center",
  direction = "row",
  height = "120px",
}: Props) => {
  return (
    <>
      <Container
        style={{
          padding: padding,
          display: "flex",
          flexDirection: direction,
          justifyContent: alignment,
          height: height,
        }}
      >
        <img src={Logo} alt="logo" />
      </Container>
    </>
  );
};

export default TransparentLogo;
