import React, { FunctionComponent, useCallback, useState } from "react";
import { Text, Button, Card, Logo, Input, Loader } from "shared";
import { Colors } from "theme/color";
import { Box, Grid } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Image from "assets/new-password.png";
import useAppDispatch from "hooks/useAppDispatch";
import { UserSelectors } from "reducers/user";
import swal from "sweetalert";
import { userResetPassword } from "reducers/user";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ResetSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(40, "Password must not exceed 40 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Confirm Password does not match"),
});

type Props = Record<string, never>;

const ResetPassword: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const { loading } = UserSelectors();

  const pathname = location.pathname;
  const parts = pathname.split("/");
  const verificationCode = parts[2];
  const verificationEmail = parts[3];

  const memberToken = `${verificationCode}/${verificationEmail}`;

  const handleResetPassword = useCallback(
    async (values: { password: string }) => {
      try {
        setIsLoading(true);
        setIsDisabled(true);
        // attempt a login, and if successful get the user profile
        await dispatch(
          userResetPassword({
            memberToken: memberToken,
            password: values.password,
          })
        ).unwrap();
        // go to the main page
        // alert("Wellcome To Aspire GRC login");
        swal({
          title: "Reset Password",
          text: "Password updated successfully!",
          icon: "success",
        });
        navigate("/");
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Reset Password",
            text: "Bad Request",
            icon: "error",
          });
        } else {
          swal({
            title: "Reset Password",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setIsLoading(false);
        setIsDisabled(false);
      }
    },
    [dispatch]
  );

  if (loading) {
    return (
      <p>
        {" "}
        <Loader isLoading={loading} />
      </p>
    );
  }
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            display={{ xs: "none", lg: "block" }}
            alignItems="center"
          >
            <Box>
              <Box pl={10} pr={10} pt={4}>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    display={{ xs: "none", lg: "block" }}
                    alignItems="center"
                  >
                    <Box ml={6}>
                      {" "}
                      <img src={Image} alt="new-password-img" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={{ xs: 2, sm: 2, md: 6, lg: 8 }}>
              <Card>
                <Box p={{ xs: 2, sm: 2, md: 6, lg: 12 }}>
                  <Logo />
                  <Box mt={4} display={{ xs: "none", lg: "block" }}>
                    <Text
                      color={Colors.text.black}
                      fontSize="14"
                      variant="h4"
                      align="center"
                      fontWeight="900"
                    >
                      Set new password
                    </Text>
                  </Box>
                  <Box pt={4}>
                    <Formik
                      initialValues={{
                        password: "",
                        confirmPassword: "",
                      }}
                      validationSchema={ResetSchema}
                      onSubmit={handleResetPassword}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Box>
                          <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange("password")}
                            handleShow={toggleShowPassword}
                            Icon={
                              showPassword ? <Visibility /> : <VisibilityOff />
                            }
                          />
                          {errors.password && touched.password ? (
                            <div className="error-message">
                              {errors.password}
                            </div>
                          ) : null}
                          <Input
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            value={values.confirmPassword}
                            onChange={handleChange("confirmPassword")}
                            handleShow={toggleShowConfirmPassword}
                            Icon={
                              showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )
                            }
                          />
                          {errors.confirmPassword && touched.confirmPassword ? (
                            <div className="error-message">
                              {errors.confirmPassword}
                            </div>
                          ) : null}
                          <Box pt={5}>
                            <Button
                              title={
                                isLoading ? "Loading..." : "Set New Password"
                              }
                              align="center"
                              width="80%"
                              onClick={handleSubmit}
                              disabled={isDisabled}
                            />
                          </Box>
                        </Box>
                      )}
                    </Formik>

                    <Box mt={3}>
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Text
                          color={Colors.text.blue}
                          fontSize="14"
                          variant="subtitle1"
                          align="center"
                        >
                          Go back to Login{" "}
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ResetPassword;
