import { AutoLinkNode, LinkNode } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import * as React from "react";

// Transform links to open in new tab until
// https://github.com/facebook/lexical/pull/3209
export default function LinkModifierPlugin() {
  const [editor] = useLexicalComposerContext();
  React.useEffect(() => {
    const removeTransform = editor.registerNodeTransform(
      LinkNode,
      (linkNode) => {
        if (!linkNode.__target) {
          linkNode.setTarget("_blank");
        }
      }
    );

    return () => removeTransform();
  }, [editor]);

  React.useEffect(() => {
    const removeTransform = editor.registerNodeTransform(
      AutoLinkNode,
      (linkNode) => {
        if (!linkNode.__target) {
          linkNode.setTarget("_blank");
        }
      }
    );

    return () => removeTransform();
  }, [editor]);

  return null;
}
