import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const HeaderTitle = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  padding: theme.spacing(2),
  border: "1px solid grey",
  borderRadius: "10px",
}));

export const Header = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  color: "white",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "#fff",
    color: "#000 !important",
  },
}));

export const NotFound = styled(Box)({
  backgroundColor: "#fff",
  borderRadius: "10px",
  filter: "drop-shadow(0px 0px 4px grey)",
  padding: "20px",
  marginBottom: "20px",
});
