import React, { FunctionComponent, useState } from "react";
import { Button, Loader } from "shared";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import useAppDispatch from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import {
  getUserInvite,
  acceptInvite,
  rejectInvite,
} from "reducers/collaburation";
import { RootState } from "store";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import UserAccept from "assets/accept-invite.png";

type Props = Record<string, never>;

const Inviteuser: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const verificationCode = parts[2];
  const verificationEmail = parts[3];

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const emailString = `${verificationCode}/${verificationEmail}`;

  React.useEffect(() => {
    setLoading(true);

    let userInviteResponse: any;

    if (emailString) {
      dispatch(
        getUserInvite({
          emailString: emailString,
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.success === false) {
            swal({
              title: "Accept Users Invite",
              text: `${result?.message && "Invitation Accepted Already!"}`,
              icon: "error",
            });
            if (userInfo?.email !== undefined) {
              navigate("/404");
            }
          }
          userInviteResponse = result?.data?.email;
          if (userInfo?.email === undefined) {
            const email = userInviteResponse;
            navigate(`/sign/up`, { state: { email, emailString } });
          } else if (
            userInviteResponse !== userInfo?.email &&
            result?.success === true
          ) {
            swal({
              title: "Accept Users Invite",
              text: "Please login to invite user account to accept this offer",
              icon: "warning",
            });
            navigate("/404");
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err?.status === 401) {
            const email = userInviteResponse;
            navigate(`/sign/up`, { state: { email, emailString } });
          }
          setLoading(false);
        });
    }
  }, [dispatch, emailString, userInfo]);

  const handleAccept = () => {
    if (emailString) {
      dispatch(
        acceptInvite({
          userString: emailString,
        })
      )
        .unwrap()
        .then(() => {
          setLoading(false);
          swal({
            title: "User Invitation",
            text: "Thank you for accepting invite !",
            icon: "success",
          });
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  const handleReject = () => {
    if (emailString) {
      dispatch(
        rejectInvite({
          userString: emailString,
        })
      )
        .unwrap()
        .then(() => {
          setLoading(false);
          swal({
            title: "User Invitation",
            text: "Invitation Rejected Successfully !",
            icon: "success",
          });
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Box display="flex" justifyContent="center" width="100%" mt={2}>
            <img src={UserAccept} alt="invite" className="invite-img-size " />
          </Box>
          <Box display="flex" justifyContent="center" mt={5}>
            <Button
              title="Accept Invite"
              align="center"
              width="180px"
              onClick={handleAccept}
            />
            <Button
              title="Reject Invite"
              align="center"
              width="180px"
              margin="0px 0px 0px 10px"
              onClick={handleReject}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Inviteuser;
