import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Colors } from "theme/color";

export const TaskCard = styled(Box)({
  background: Colors.text.white,
  padding: "8px 8px 15px 8px",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  cursor: "pointer",
});
