import React, { FunctionComponent } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import SSPTableBody from "../sspSharedTbody";
import { assessmentTypeNISTCSFQSG } from "utils/constant";
import SSPNISTQGTbody from "../sspTableBodyQSG";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderTop: "1px solid grey  !important",
    borderBottom: "1px solid grey !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 400,
    border: "1px solid grey  !important",
    borderBottom: "1px solid grey !important",
    cursor: "pointer",
  },
}));

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
}));

type Props = {
  assessmentObjective?: string;
  notInterested?: any;
  met?: any;
  notMet?: any;
  data?: any;
  disabled?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  notMerSwitchColor?: any;
  objectiveWeitgh?: any;
  objectTooltip?: boolean;
  showSubCategoryComment?: any;
  placeholderNist?: any;
  hideControlRequirement?: any;
  assessmentTypeCurrent?: any;
};

const SSPTable: FunctionComponent<Props> = ({
  assessmentObjective = "Assessment Objectives  ",
  notInterested = "N/A",
  met = "MET",
  notMet = "NOT MET",
  data,
  disabled = false,
  placeholder = "Description of how objective is met or why not applicable:",
  placeholderNist = "Description of how objective is yes or why not applicable:",
  notMerSwitchColor = "red",
  objectTooltip = false,
  showSubCategoryComment = false,
  hideControlRequirement = false,
  assessmentTypeCurrent,
}: Props) => {
  if (!data) {
    return <Box>No data found</Box>;
  }

  return (
    <>
      <Container>
        <TableContainer>
          <Table sx={{ minWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="objective" style={{ width: "60%" }}>
                  {hideControlRequirement ? null : assessmentObjective}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "10%" }}>
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <NotInterestedIcon />
                    </Box>
                    <Box className="headerBold">{notInterested}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className="headerBold"
                  style={{ width: "10%" }}
                >
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <CheckCircleIcon />
                    </Box>
                    <Box className="headerBold">{met}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className="headerBold"
                  style={{ width: "10%" }}
                >
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <CancelIcon />
                    </Box>
                    <Box className="headerBold">{notMet}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className="headerBold notMet"
                  style={{ width: "10%" }}
                >
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <VscWorkspaceTrusted size={20} />
                    </Box>
                    <Box className="bold">Evidence</Box>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {assessmentTypeCurrent === assessmentTypeNISTCSFQSG ? (
              <SSPNISTQGTbody
                data={data}
                disabled={disabled}
                notMerSwitchColor={notMerSwitchColor}
                objectTooltip={objectTooltip}
                showSubCategoryComment={showSubCategoryComment}
                placeholderNist={placeholderNist}
                placeholder={placeholder}
              />
            ) : (
              <SSPTableBody
                data={data}
                disabled={disabled}
                notMerSwitchColor={notMerSwitchColor}
                objectTooltip={objectTooltip}
                showSubCategoryComment={showSubCategoryComment}
                placeholderNist={placeholderNist}
                placeholder={placeholder}
              />
            )}
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default SSPTable;
