import React, { FunctionComponent, useState } from "react";
import {
  SSPAccordian,
  Text,
  DashboardWrapper,
  SspChildAccordion,
  Loader,
  Button,
} from "shared";
import { FiDownload } from "react-icons/fi";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import useAppDispatch from "hooks/useAppDispatch";
import {
  getSSpAssessmentSSpList,
  getEvidenceImagesReport,
} from "reducers/assessment";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  ListItemIcon,
  Zoom,
} from "@mui/material";
import { Colors } from "theme/color";
import * as XLSX from "xlsx";
import JSZip from "jszip";
import swal from "sweetalert";
import { FlexBox } from "./style";
import { assessmentTypeNISTCSFQSG, FrontlineDefender } from "utils/constant";

interface QuestionStatus {
  notMet: boolean;
  met: boolean;
  notInterested: boolean;
}

type Props = Record<string, never>;

const SspCreater: FunctionComponent<Props> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const [assessmentLevel, setAssessmentLevel] = useState();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const assessmentId = parts[3];
  const sspList = useSelector((state: RootState) => state.assessment.sspList);
  const evidenceImagesList = useSelector(
    (state: RootState) => state.assessment.evidenveListImages
  );

  const { state } = location;

  const assessmentTypeCurrent = state && state.assessmentTypes;

  const listOfImages = Array.isArray(evidenceImagesList)
    ? evidenceImagesList.map((item: any) => {
        const assessment = {
          images: item.image,
        };
        return assessment;
      })
    : [];

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    dispatch(getEvidenceImagesReport({ assessmentId }))
      .unwrap()
      .then(() => {})
      .catch((err: any) => {
        console.log(err);
      });
  }, [dispatch, assessmentId]);

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getSSpAssessmentSSpList({ assessmentId }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [dispatch, assessmentId]);

  const accordion = React.useMemo(() => {
    return sspList?.map((item) => {
      const securityRequirements = item.security_requirements.map((sr: any) => {
        setAssessmentLevel(sr.assessment_level);
        const objectives = sr.objectives
          .filter(
            (objective: any) =>
              assessmentTypeCurrent !== assessmentTypeNISTCSFQSG ||
              objective.is_nist_csf_2_0_quick_start_objective
          )
          .map((objective: any) => {
            let status: QuestionStatus = {
              notMet: false,
              met: false,
              notInterested: false,
            };
            if (objective.user_answer === "not_met") {
              status.notMet = true;
            } else if (objective.user_answer === "met") {
              status.met = true;
            } else if (objective.user_answer === "na") {
              status.notInterested = true;
            }
            const nistExamples = objective.examples?.split("\\n").join("\n");
            const question = {
              id: objective.pk,
              assessmentSecurityRequirementId:
                objective.assessment_security_requirement,
              questionNumber: objective.code,
              title: objective.description,
              status,
              isEvidenceProvided: objective.is_evidence_provided,
              examples: nistExamples,
              objectiveComment: objective.comment,
            };
            return question;
          });
        const controlDescription =
          sr.assessment_level === null
            ? sr?.user_help_text?.html
            : sr.control_explanation?.split("\\n").join("\n");
        return {
          controlNumber:
            assessmentLevel === null
              ? sr.control_number + ":"
              : sr.control_number,
          controlDescription: controlDescription,
          controlRequirement: sr.control_requirement,
          objectives: objectives,
          userComment: sr.user_comment,
          userResponse: sr.user_response,
          familyTitle: sr.assessment_family,
        };
      });

      const accordion = {
        title: item.assessment_family,
        completedQueries: item.total_family_responses,
        totalQueries: item.total_family_objectives,
        securityRequirements: securityRequirements,
        userComment: item.user_comment + "12",
      };
      return accordion;
    });
  }, [sspList]);

  // excell sheet download

  const formatDataForExcel = () => {
    const data: any = [];

    const headers =
      assessmentLevel === null
        ? [
            "Function",
            "Category",
            "Sub_Category",
            "Status",
            "Description of how yes, no or N/A",
            "Evidence",
          ]
        : [
            "Control Number",
            "Control Requirement",
            "Question Title",
            "Status",
            "Description of how user met, not met or N/A",
            "Evidence",
          ];

    const seenControlNumbers = new Set(); // To track seen control numbers

    accordion.forEach((item) => {
      item.securityRequirements.forEach((sr: any) => {
        let controlNumberAdded = false; // Flag to check if control number is added

        sr.objectives.forEach((objective: any) => {
          const pattern = objective.questionNumber.match(/(\[[^\]]+\])$/);
          const lastBracketValue = pattern
            ? pattern[1]
            : objective.questionNumber;
          const rowData = [
            assessmentLevel === null
              ? controlNumberAdded
                ? ""
                : sr.familyTitle?.name
              : controlNumberAdded
              ? ""
              : sr.controlNumber,
            // controlNumberAdded ? "" : sr.controlNumber,
            controlNumberAdded ? "" : sr.controlRequirement, // Add control requirement only once
            lastBracketValue + " " + objective.title,
            `${objective.status.notMet ? "Not Met" : ""}${
              objective.status.met ? "Met" : ""
            }${objective.status.notInterested ? "Not Applicable" : ""}`,
            assessmentLevel === null
              ? objective.objectiveComment
              : sr.userComment,
            objective.isEvidenceProvided,
          ];

          data.push(rowData);

          // Set the flag once the control number is added
          if (!controlNumberAdded) {
            seenControlNumbers.add(sr.controlNumber);
            controlNumberAdded = true;
          }
        });
      });
    });

    return [headers, ...data];
  };

  const addWidthAndBackgroundColor = (ws: any) => {
    // Define column widths
    const columnConfigs = [
      { width: 12 },
      { width: 100 },
      { width: 100 },
      { width: 15 },
      { width: 50 },
      { width: 15 },
    ];

    // Define background color for header row
    const headerBackgroundColor = { bgColor: { rgb: "000000FF" } }; // Blue background color

    // Apply column widths
    columnConfigs.forEach((config, columnIndex) => {
      ws["!cols"] = ws["!cols"] || [];
      ws["!cols"][columnIndex] = config;
    });

    // Apply background color to the header row
    const headerRow = 1; // Assuming the header row is the first row
    for (
      let columnIndex = 0;
      columnIndex < columnConfigs.length;
      columnIndex++
    ) {
      const cellAddress = XLSX.utils.encode_col(columnIndex) + (headerRow + 1); // Add 1 because Excel uses 1-based indexing
      ws[cellAddress] = {
        ...ws[cellAddress],
        s: { fill: headerBackgroundColor }, // Set the 'fill' property for the cell
      };
    }
  };

  const exportToExcel = () => {
    const data = formatDataForExcel();
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Add column width and background color
    addWidthAndBackgroundColor(ws);

    XLSX.writeFile(wb, "Ssp_Creator.xlsx");
  };

  const downloadPDF = async () => {
    setAllExpanded(true); // set all expanded before generating PDF
    setLoading(true);
    setIsDisabled(true);
    const input = document.getElementById("pdf-content");

    if (input) {
      setTimeout(() => {
        // add a delay to ensure all content is expanded
        html2canvas(input, { scale: 0.7 }).then(async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = await PDFDocument.create();
          const img = await pdf.embedPng(imgData);
          const { width, height } = img.scale(1);

          // add some padding to the page
          const padding = 50;
          const pageWidth = width + padding * 2;
          const pageHeight = height + padding * 2;
          const page = pdf.addPage([pageWidth, pageHeight]);
          page.drawImage(img, { x: padding, y: padding, width, height });

          const pdfBytes = await pdf.save();
          const compressedPdfBytes = await (
            await PDFDocument.load(pdfBytes)
          ).save({
            useObjectStreams: false,
          });
          const blob = new Blob([compressedPdfBytes], {
            type: "application/pdf",
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "download.pdf";
          document.body.appendChild(a);
          a.click();
          setLoading(false);
          setIsDisabled(false);
          setAllExpanded(false);
        });
      }, 1000); // adjust the delay time as needed
    } else {
      alert("Element with ID 'pdf-content' not found in DOM");
    }
  };

  const [loadingImg, setLoadingImg] = useState(false);

  const downloadEvidenceImagesLinks = async () => {
    if (listOfImages.length === 0) {
      swal({
        title: "Evidence",
        text: "This assessment has no Evidence Image",
        icon: "error",
      });
      return;
    }

    const zip = new JSZip();
    setLoadingImg(true);

    try {
      // Use Promise.all to wait for all fetch operations to complete
      await Promise.all(
        listOfImages.map(async (item, index) => {
          try {
            const response = await fetch(item.images);
            const blob = await response.blob();
            // Use a unique name for each image file in the zip
            zip.file(`item${index + 1}.jpg`, blob);
          } catch (error) {
            console.error(`Error fetching image: ${item.images}`, error);
          }
        })
      );

      // Generate the zip file
      const content = await zip.generateAsync({ type: "blob" });

      // Create a download link
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(content);

      downloadLink.href = url;
      downloadLink.download = "Evidence.zip";

      // Append the link to the document and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the document
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error creating zip file:", error);
      swal({
        title: "Evidence",
        text: "Oops, something went wrong. Please try again later.",
        icon: "error",
      });
    } finally {
      setLoadingImg(false);
    }
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Box>
            <Box mt={3} mb={2}>
              <Text fontSize="32px" fontWeight="700" className="text-mobile">
                SSP creator
              </Text>
            </Box>

            <FlexBox mb={2}>
              <Box>
                <IconButton
                  onClick={downloadEvidenceImagesLinks}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  disabled={currentPackage === FrontlineDefender ? true : false}
                >
                  <Button
                    title={loadingImg ? "Loading..." : "Download All Evidences"}
                    borderRadius="50px"
                    width="260px"
                    fontWeight="600"
                    fontSize="22"
                    Icon={<FiDownload />}
                    disabled={
                      currentPackage === FrontlineDefender
                        ? true
                        : false && loadingImg
                    }
                  />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "end",
                }}
              >
                <Tooltip
                  title="Click to download CSV or Pdf"
                  placement="bottom"
                  arrow
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Button
                      title={loading ? "Loading..." : "Download SSP"}
                      borderRadius="50px"
                      width="200px"
                      fontWeight="600"
                      fontSize="22"
                      Icon={<FiDownload />}
                      disabled={isDisabled}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={downloadPDF}
                  sx={{
                    backgroundColor: Colors.button.primary_bg,
                    "&:hover": {
                      backgroundColor: Colors.button.border_gary_03,
                      color: Colors.text.white,
                    },
                  }}
                >
                  <ListItemIcon>
                    <FiDownload />{" "}
                  </ListItemIcon>
                  {loading ? "Loading..." : "Download PDF"}
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor: Colors.text.blue,
                    color: Colors.text.white,
                    marginTop: "6px",
                    "&:hover": {
                      backgroundColor: Colors.button.border_gary_03,
                      color: Colors.text.white,
                    },
                  }}
                  onClick={exportToExcel}
                >
                  <ListItemIcon>
                    <FiDownload />{" "}
                  </ListItemIcon>
                  Download Excel
                </MenuItem>
              </Menu>
            </FlexBox>
            <div id="pdf-content">
              {accordion?.map((item: any, index: any) => (
                // eslint-disable-next-line react/jsx-key
                <SSPAccordian
                  key={index}
                  data={item}
                  index={index}
                  expandedAll={allExpanded}
                >
                  {assessmentLevel === null ? (
                    <SspChildAccordion
                      data={item}
                      index={index}
                      accordions={accordion}
                      assessmentId={assessmentId}
                      notMet="No"
                      met="Yes"
                      notMerSwitchColor={Colors.button.primary_bg}
                      objectTooltip={true}
                      showSubCategoryComment={true}
                      hideControlRequirement={true}
                      assessmentTypeCurrent={assessmentTypeCurrent}
                    />
                  ) : (
                    <SspChildAccordion
                      data={item}
                      index={index}
                      accordions={accordion}
                      assessmentId={assessmentId}
                    />
                  )}
                </SSPAccordian>
              ))}
            </div>
          </Box>
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default SspCreater;
