import axios, { AxiosError } from "axios";

import {
  AUTHORIZATION_HEADER_NAME,
  getAuthorizationHeaderValue,
  setAuthToken,
} from "utils/auth";
import { COMMON } from "utils/common";

const API_END_POINT = {
  LOGIN: "user/login",
  SIGN_UP: "user/signup",
  FORGET_PASSWORD: "user/request/password-change",
  LOGOUT: "user/logout",
  SUPPORT: "user/support",
  RESEND_VERIFY: "user/email/resend-verify",
  VERIFY_EMAIL: "user/email/verify",
  RESET_PASSWORD: "user/change/password",
  GENERATE_OTP: "user/otp/generate",
  VERIFY_OTP: "user/otp/verify",
  VALIDATE_OTP: "user/otp/validate",
  DISABLED_OTP: "user/otp/disable",
  UPDATE_PROFILE: "profile/update",
  ADD_ASSESSMENT: "assessment/create",
  ASSESSMENT_LIST: "assessment/list",
  ASSESSMENT_LEVELS: "assessment/levels",
  ASSESSMENT_QA: "assessment/level-qa",
  SSP_CREATOR: "assessment/ssp-creator",
  ASSESSMENT_REPORT: "assessment/report",
  ASSESSMENT_STATS: "assessment/stats",
  DELETE_ASSESSMENT: "assessment/delete",
  ADD_EVIDENCE: "assessment/create-evidence",
  PUT_EVIDENCE_IMAGE: "assessment/put-evidence-media",
  EVIDENCE_REPORT: "assessment/assessment-evidence",
  EVIDENCE_IMAGE: "assessment/add-evidence-media",
  DELETE_EVIDENCE: "assessment/delete-evidence-media",
  EVIDENCE_REPORT_IMAGES: "assessment/get-all-assessment-evidence-media",
  EVIDENCE_LIST: "assessment/list-evidence",
  STORE_QA_RESPONSE: "assessment/store-qa-response",
  UNDO_QA_RESPONSE: "assessment/undo-qa-response",
  ASSET_LIST: "asset_inventory/inventory/",
  ADD_ASSET: "asset_inventory/inventory/",
  DELETE_ASSET: "/asset_inventory/inventory",
  CATEGORY_LIST: "asset_inventory/categories/",
  UPDATE_ASSET: "asset_inventory/inventory",
  POAM: "/poam",
  ADD_POAM: "poam/create/",
  POAM_LIST: "poam/list",
  BUFFER_LIST: "buffer-zone/",
  BUFFER_QUESTION_LIST: "buffer-zone",
  RESPONSE_LIST_CHECKBOX: "buffer-zone/user-buffer-zone-option",
  BUFFER_QUESTION_MUTIONS: "buffer-zone",
  RESIURCES: "resources",

  PACKAGE_LIST: "subscription/plans/",
  USER_PACKAGE: "subscription/user/",

  CREATE_THREAD: "assistant/",
  ADD_MESSAGE: "assistant/add-message-to-thread/",
  RUN_THREAD: "assistant/run-assistant-on-thread/",
  RUN_THREAD_ID: "assistant/get-user-run-status",
  GET_MESSAGE: "assistant/get-user-messages/",
  INVITE_USERS: "collaburation/invite-users/",
  GET_USER_INVITE: "collaburation/validate-invitation",
  ACCEPT_INVITE: "collaburation/accept-invite",
  SIGN_UP_INVITE: "collaburation/signup",
  INVITE_USERS_LIST: "collaburation/invites/",
  RESEND_INVITE: "collaburation/invite/resend/",
  INVITE_USER_DEL: "collaburation/invite/delete",
  REJECT_INVITE: "collaburation/reject-invite",
  INVITE_USERS_LIST_ACCEPTED: "collaburation/invites/",
  ASSIGN_PERMISSION: "collaburation/assign-permission/",
  GET_USER_PERMISSION_LIST: "collaburation/get-user-permissions/",
  CHANGE_PERMISSION: "collaburation/change-permission/",
  DELETE_PERMISSION: "collaburation/remove-user-permission/",
  GET_LIST_TASK_BOARD: "collaburation/get-object-permission-task-board-new/",
  OBJECT_CHANGE_HISTORY: "collaburation/get-object-change-history/",
  LOGIN_EMAL_VERIFY: "user/check/email/status",
  ADD_NIST_SUBCATEGORY_COMMENT: "assessment/store-qa-comment",
  MFA_OTP_STATUS: "user/check/otp/status",
  ADD_FEATURE_REQUEST: "user/request_feature",
  DELETE_USER_ALL_PER: "collaburation/remove-user/",
  UPDATE_TOOL_TIP_HTML: "assessment/put-user-help-text",
  SUBSCRIBE_FRONTLINE_DEFENDER_PACKAGE: "subscription/subscribe/free-package/",
};

type API_METHOD = "GET" | "POST" | "PUT" | "DELETE";

export const login = async (email: string, password: string) => {
  return _baseRequest(API_END_POINT.LOGIN, "POST", { email, password }).then(
    (loginResponse) => {
      const authToken = loginResponse.auth_token;
      setAuthToken(authToken);
      return loginResponse;
    }
  );
};

export const loginVerifyEmail = async (email: any) => {
  return _baseRequest(API_END_POINT.LOGIN_EMAL_VERIFY, "POST", {
    email,
  }).then((loginResponseEmailVerify) => {
    return loginResponseEmailVerify;
  });
};

export const signUp = (
  name: string,
  email: string,
  password: string,
  companyName: string,
  toc_agree: boolean
) => {
  return _baseRequest(API_END_POINT.SIGN_UP, "POST", {
    name: name,
    email: email,
    password: password,
    company_name: companyName,
    toc_agree: toc_agree,
  }).then((signUpResponse) => {
    const authToken = signUpResponse.auth_token;
    setAuthToken(authToken);
    return signUpResponse;
  });
};

export const mfaOtpStatus = async () => {
  return _authorizedRequest(API_END_POINT.MFA_OTP_STATUS, "POST", {}).then(
    (loginResponseEmailOtp) => {
      return loginResponseEmailOtp;
    }
  );
};

export const forgetPassword = (email: string) => {
  return _baseRequest(API_END_POINT.FORGET_PASSWORD, "POST", {
    email: email,
  });
};

export const resetPassword = (memberToken: string, password: string) => {
  return _baseRequest(API_END_POINT.RESET_PASSWORD, "POST", {
    token: memberToken,
    password: password,
  });
};

export const supportConact = (
  email: string,
  fname: string,
  lname: string,
  massage: string
) => {
  return _baseRequest(API_END_POINT.SUPPORT, "POST", {
    email: email,
    first_name: fname,
    last_name: lname,
    massage: massage,
  });
};

export const resendVerify = () => {
  return _authorizedRequest(API_END_POINT.RESEND_VERIFY, "POST");
};

export const verifyEmail = async (email: string) => {
  const verfiyResponse = await _authorizedRequest(
    API_END_POINT.VERIFY_EMAIL,
    "POST",
    {
      remember_token: email,
    }
  );
  const authToken = verfiyResponse.auth_token;
  setAuthToken(authToken);
  return verfiyResponse;
};

export const logout = () => {
  return _authorizedRequest(API_END_POINT.LOGOUT, "POST");
};

export const generateOtp = () => {
  return _authorizedRequest(API_END_POINT.GENERATE_OTP, "POST", {}).then(
    (response) => {
      return response;
    }
  );
};

export const verifyOtp = (twoFactorOtp: string) => {
  return _authorizedRequest(API_END_POINT.VERIFY_OTP, "POST", {
    token: twoFactorOtp,
  }).then((response) => {
    return response;
  });
};

export const validateOtp = (validOtp: string) => {
  return _authorizedRequest(API_END_POINT.VALIDATE_OTP, "POST", {
    token: validOtp,
  });
};

export const disabledOtp = () => {
  return _authorizedRequest(API_END_POINT.DISABLED_OTP, "POST", {}).then(
    (response) => {
      return response;
    }
  );
};

export const updateProfilePage = (
  name: string,
  jobTitle: string,
  password: string,
  company_name: string,
  image?: File
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("job_title", jobTitle);
  formData.append("password", password);
  formData.append("company_name", company_name);
  if (image) {
    formData.append("image", image);
  }

  return _authorizedRequest(
    API_END_POINT.UPDATE_PROFILE,
    "POST",
    formData
  ).then((response) => {
    return response;
  });
};

export const addAssessment = (
  assessment: string,
  companyName: string,
  assessmentType: string,
  assessmentLevel: any,
  target_assessment_level?: any
) => {
  return _authorizedRequest(API_END_POINT.ADD_ASSESSMENT, "POST", {
    name: assessment,
    company_name: companyName,
    assessment_type: assessmentType,
    assessment_level: assessmentLevel,
    target_assessment_level: target_assessment_level,
  });
};

export const fetchAssessmentList = async (
  page: number,
  limit: number,
  levelId: string,
  search: any
) => {
  const sanitizedSearch = search ? search.replace(/^\s+/g, "undefined") : "";
  const url = `${API_END_POINT.ASSESSMENT_LIST}?page=${page}&limit=${limit}&level_id=${levelId}&search=${sanitizedSearch}`;
  const response = await _authorizedRequest(url, "GET");
  return response;
};

export const deleteAssessment = (id: number) => {
  const url = `${API_END_POINT.DELETE_ASSESSMENT}/${id}/`;
  return _authorizedRequest(url, "DELETE");
};

export const fetchAssessmentStats = (
  inprogressPage: number,
  completedPage: number,
  perPage: number
) => {
  const url = `${API_END_POINT.ASSESSMENT_STATS}?inprogress_page=${inprogressPage}&completed_page=${completedPage}&per_page=${perPage}`;
  return _authorizedRequest(url, "GET").then((response) => {
    return response;
  });
};

export const assessmentLevels = async () => {
  const response = await _authorizedRequest(
    API_END_POINT.ASSESSMENT_LEVELS,
    "GET",
    {}
  );

  return response;
};

export const getAssessmentQa = async (
  assessmentId: any,
  levelId: any,
  page?: number
) => {
  return await _authorizedRequestResponse(
    `${API_END_POINT.ASSESSMENT_QA}/${assessmentId}/${levelId}?page=${page}`,
    "GET"
  );
};

export const getSSpAssessment = (assessmentId: any) => {
  return _authorizedRequest(
    `${API_END_POINT.SSP_CREATOR}/${assessmentId}`,
    "GET"
  );
};

export const getAssessmentReport = (assessmentId: any) => {
  return _authorizedRequest(
    `${API_END_POINT.ASSESSMENT_REPORT}/${assessmentId}`,
    "GET"
  );
};

export const addEvidence = (
  pk: string,
  assessmentId: number | null,
  assessmentObjective: number | null,
  name: string,
  additionalNotes: string,
  evidenceDate: string
) => {
  return _authorizedRequest(API_END_POINT.ADD_EVIDENCE, "POST", {
    pk: pk,
    assessment: assessmentId,
    assessment_objective: assessmentObjective,
    name: name,
    additional_notes: additionalNotes,
    evidence_date: evidenceDate,
  });
};

export const getEvidence = (
  assessmentId: number | null,
  objectiveId: number | null
) => {
  const url = `${API_END_POINT.EVIDENCE_REPORT}/${assessmentId}/${objectiveId}/`;
  return _authorizedRequest(url, "GET").then((response) => {
    return response;
  });
};

export const evidenceImage = (
  assessment_evidence: number | null,
  image?: File | null
) => {
  const formData = new FormData();
  formData.append("assessment_evidence", String(assessment_evidence));
  if (image) {
    formData.append("image", image);
  }

  return _authorizedRequest(
    API_END_POINT.EVIDENCE_IMAGE,
    "POST",
    formData
  ).then((response) => {
    return response;
  });
};

export const putEvidenceImage = (
  evidence_image_id: string | null,
  assessment_evidence: number | null,
  image?: File | null
) => {
  const formData = new FormData();
  formData.append("assessment_evidence", String(assessment_evidence));
  if (image) {
    formData.append("image", image);
  }

  return _authorizedRequest(
    `${API_END_POINT.PUT_EVIDENCE_IMAGE}/${evidence_image_id}/`,
    "PUT",
    formData
  ).then((response) => {
    return response;
  });
};

export const deleteEvidence = (pk: number) => {
  const url = `${API_END_POINT.DELETE_EVIDENCE}/${pk}/`;
  return _authorizedRequest(url, "DELETE");
};

export const getEvidenceImagesList = (assessmentId: any) => {
  const url = `${API_END_POINT.EVIDENCE_REPORT_IMAGES}/${assessmentId}/`;
  return _authorizedRequest(url, "GET").then((response) => {
    return response;
  });
};

export const fetchEvidenceList = async (
  page: number,
  limit: number,
  search: string
) => {
  const sanitizedSearch = search ? search.replace(/^\s+/g, "undefined") : "";
  const url = `${API_END_POINT.EVIDENCE_LIST}?page=${page}&limit=${limit}&search=${sanitizedSearch}`;
  return await _authorizedRequest(url, "GET").then((response) => {
    return response;
  });
};

export const storeQaResponse = (
  answer: string,
  assessment: string,
  assessment_objective: any,
  task: boolean
) => {
  return _authorizedRequestResponse(API_END_POINT.STORE_QA_RESPONSE, "POST", {
    answer: answer,
    assessment: assessment,
    assessment_objective: assessment_objective,
    task: task,
  });
};

export const undoQaResponse = (
  answer: string,
  assessment: string,
  assessment_objective: string,
  task: boolean
) => {
  return _authorizedRequestResponse(API_END_POINT.UNDO_QA_RESPONSE, "POST", {
    answer: answer,
    assessment: assessment,
    assessment_objective: assessment_objective,
    task: task,
  });
};

export const addAsset = (
  category: string,
  asset: string,
  quantity: string,
  assetType: string,
  assetDescription: string,
  comment: string,
  controlledBy?: string,
  poc?: string,
  cuiCategory?: string,
  distributionControl?: string
) => {
  return _authorizedRequest(API_END_POINT.ADD_ASSET, "POST", {
    asset_category: category,
    asset: asset,
    quantity: quantity,
    asset_type: assetType,
    asset_description: assetDescription,
    comment: comment,
    controlled_by: controlledBy,
    poc: poc,
    cui_category: cuiCategory,
    distribution_control: distributionControl,
  });
};

export const fetchAssetList = (page: number, limit: number, search: any) => {
  const sanitizedSearch = search ? search.replace(/^\s+/g, "undefined") : "";
  const url = `${API_END_POINT.ASSET_LIST}?page=${page}&limit=${limit}&search=${sanitizedSearch}`;
  const response = _authorizedRequest(url, "GET");
  return response;
};

export const deleteAsset = (id: number) => {
  const url = `${API_END_POINT.DELETE_ASSET}/${id}/`;
  return _authorizedRequest(url, "DELETE");
};

export const fetchCategory = () => {
  const url = `${API_END_POINT.CATEGORY_LIST}`;
  const response = _authorizedRequest(url, "GET");
  return response;
};

export const updateAsset = (
  id: number | undefined,
  category: string,
  asset: string,
  quantity: string,
  assetType: string,
  assetDescription: string,
  comment: string,
  controlledBy?: string,
  poc?: string,
  cuiCategory?: string,
  distributionControl?: string
) => {
  const url = `${API_END_POINT.UPDATE_ASSET}/${id}/`;
  return _authorizedRequest(url, "PUT", {
    asset_category: category,
    asset: asset,
    quantity: quantity,
    asset_type: assetType,
    asset_description: assetDescription,
    comment: comment,
    controlled_by: controlledBy,
    poc: poc,
    cui_category: cuiCategory,
    distribution_control: distributionControl,
  });
};

export const getPoam = (assessmentId: any) => {
  return _authorizedRequest(`${API_END_POINT.POAM}/${assessmentId}/`, "GET");
};

export const addPoam = async (
  poamId: string,
  weaknessName: string,
  severity: string,
  assessment: any,
  point_of_contact_email: string,
  scheduled_completion_date: any,
  comments: string,
  source_of_weakness: string,
  weakness_description: string,
  actual_completion_date: any,
  milestones: { milestone: string; is_completed: boolean }[]
) => {
  return await _authorizedRequest(API_END_POINT.ADD_POAM, "POST", {
    poam_id: poamId,
    weakness_name: weaknessName,
    severity: severity,
    assessment: assessment,
    milestones: milestones,
    point_of_contact_email: point_of_contact_email,
    scheduled_completion_date: scheduled_completion_date,
    comments: comments,
    source_of_weakness: source_of_weakness,
    actual_completion_date: actual_completion_date,
    weakness_description: weakness_description,
  });
};

export const getPoamList = (assessmentId: any) => {
  return _authorizedRequest(
    `${API_END_POINT.POAM_LIST}/${assessmentId}/`,
    "GET"
  );
};

export const putPoam = async (
  pk: number,
  poamId: string,
  weaknessName: string,
  severity: string,
  assessment: any,
  point_of_contact_email: string,
  scheduled_completion_date: any,
  comments: string,
  source_of_weakness: string,
  weakness_description: string,
  actual_completion_date: any,
  milestones: { milestone: string; is_completed: boolean }[]
) => {
  return await _authorizedRequest(API_END_POINT.ADD_POAM, "PUT", {
    pk: pk,
    poam_id: poamId,
    weakness_name: weaknessName,
    severity: severity,
    assessment: assessment,
    milestones: milestones,
    point_of_contact_email: point_of_contact_email,
    scheduled_completion_date: scheduled_completion_date,
    comments: comments,
    source_of_weakness: source_of_weakness,
    actual_completion_date: actual_completion_date,
    weakness_description: weakness_description,
  });
};

export const getBufferList = () => {
  return _authorizedRequest(API_END_POINT.BUFFER_LIST, "GET");
};

export const getBufferZoneQuestion = (id: number) => {
  return _authorizedRequest(
    `${API_END_POINT.BUFFER_QUESTION_LIST}/${id}/`,
    "GET"
  );
};

export const getBufferZoneQuestionWithowner = (id: number, ownerPk?: any) => {
  return _authorizedRequest(
    `${API_END_POINT.BUFFER_QUESTION_LIST}/${id}/?owner=${ownerPk}`,
    "GET"
  );
};

export const bufferZoneQuestionResponse = (
  index: number,
  is_completed: boolean,
  task?: boolean
) => {
  const url = `${API_END_POINT.RESPONSE_LIST_CHECKBOX}/${index}/`;
  return _authorizedRequestResponse(url, "POST", {
    is_completed: is_completed,
    task: task,
  });
};

export const getBufferZoneQuestionMutions = (id: number) => {
  return _authorizedRequest(
    `${API_END_POINT.BUFFER_QUESTION_MUTIONS}/${id}/resources/`,
    "GET"
  );
};

export const getPackageList = () => {
  return _authorizedRequest(`${API_END_POINT.PACKAGE_LIST}`, "GET");
};

export const userPackage = () => {
  return _authorizedRequest(`${API_END_POINT.USER_PACKAGE}`, "GET");
};

export const createThreadId = () => {
  return _authorizedRequest(`${API_END_POINT.CREATE_THREAD}`, "POST");
};

export const addMessage = (role: string, content: string) => {
  return _authorizedRequest(API_END_POINT.ADD_MESSAGE, "POST", {
    role: role,
    content: content,
  });
};

export const runThread = () => {
  return _authorizedRequest(`${API_END_POINT.RUN_THREAD}`, "POST");
};

export const runThreadID = (id: string) => {
  return _authorizedRequest(`${API_END_POINT.RUN_THREAD_ID}/${id}/`, "POST");
};

export const getMessageList = () => {
  return _authorizedRequest(`${API_END_POINT.GET_MESSAGE}`, "GET");
};

export const inviteUsers = (email_addresses: string[]) => {
  return _authorizedRequest(API_END_POINT.INVITE_USERS, "POST", {
    email_addresses: email_addresses,
  });
};

export const getUserInvite = (emailString: string) => {
  return _baseRequestResponse(
    `${API_END_POINT.GET_USER_INVITE}/${emailString}/`,
    "GET"
  );
};

export const acceptInvite = (userString: string) => {
  return _authorizedRequest(
    `${API_END_POINT.ACCEPT_INVITE}/${userString}/`,
    "POST"
  );
};

export const signUpInviteUser = (
  name: string,
  email: string,
  password: string,
  companyName: string,
  inviteString: string,
  toc_agree: any
) => {
  return _baseRequest(
    `${API_END_POINT.SIGN_UP_INVITE}/${inviteString}/`,
    "POST",
    {
      name: name,
      email: email,
      password: password,
      company_name: companyName,
      toc_agree: toc_agree,
    }
  ).then((signUpResponse) => {
    const authToken = signUpResponse.auth_token;
    setAuthToken(authToken);
    return signUpResponse;
  });
};

export const fetchInviteUsers = async (
  page: number,
  limit: number,
  search: string
) => {
  const sanitizedSearch = search ? search.replace(/^\s+/g, "undefined") : "";
  const url = `${API_END_POINT.INVITE_USERS_LIST}?page=${page}&limit=${limit}&search=${sanitizedSearch}`;
  return await _authorizedRequest(url, "GET").then((response) => {
    return response;
  });
};

export const resendUserInvite = (pk: number) => {
  return _authorizedRequest(API_END_POINT.RESEND_INVITE, "POST", {
    pk: pk,
  });
};

export const delInviteUser = (pk: number) => {
  return _authorizedRequest(
    `${API_END_POINT.INVITE_USER_DEL}/${pk}/`,
    "DELETE",
    {
      pk: pk,
    }
  );
};

export const rejectInvite = (userString: string) => {
  return _authorizedRequest(
    `${API_END_POINT.REJECT_INVITE}/${userString}/`,
    "POST"
  );
};

export const fetchAcceptedInvitedUsers = async (
  page: number,
  limit: number,
  search: string
) => {
  const url = `${API_END_POINT.INVITE_USERS_LIST_ACCEPTED}?accepted=1&page=${page}&limit=${limit}&search=${search}`;
  return await _authorizedRequest(url, "GET").then((response) => {
    return response;
  });
};

export const assignPermission = (
  invited_user_ids: any[],
  object_id: any[],
  content_type: string,
  read_permission: boolean,
  write_permission: boolean
) => {
  return _authorizedRequestResponse(API_END_POINT.ASSIGN_PERMISSION, "POST", {
    user_ids: invited_user_ids,
    object_ids: object_id,
    app_content_type: content_type,
    read_permission: read_permission,
    write_permission: write_permission,
  });
};

export const fetchPermissionUserList = async (
  id: number | undefined,
  content_type: string
) => {
  const url = `${API_END_POINT.GET_USER_PERMISSION_LIST}?object_id=${id}&app_content_type=${content_type}`;
  return await _authorizedRequest(url, "GET").then((response) => {
    return response;
  });
};

export const deletePermission = (
  userId: any,
  content_type: any,
  object_id: any[]
) => {
  return _authorizedRequest(API_END_POINT.DELETE_PERMISSION, "POST", {
    user_ids: userId,
    app_content_type: content_type,
    object_ids: object_id,
  });
};

export const changePermission = (
  invited_user_id: any[],
  object_id: any[],
  perm_codename: string,
  read_permission: boolean,
  write_permission: boolean
) => {
  return _authorizedRequest(API_END_POINT.CHANGE_PERMISSION, "POST", {
    user_ids: invited_user_id,
    object_ids: object_id,
    app_content_type: perm_codename,
    read_permission: read_permission,
    write_permission: write_permission,
  });
};

export const getListTaskBoard = () => {
  return _authorizedRequest(`${API_END_POINT.GET_LIST_TASK_BOARD}`, "GET");
};

export const objectChangeHistory = async (
  content_type: string,
  id: number,
  ownerPk: number
) => {
  const url = `${API_END_POINT.OBJECT_CHANGE_HISTORY}?app_content_type=${content_type}&object_id=${id}&owner=${ownerPk}`;
  const response = await _authorizedRequest(url, "GET");
  return response;
};

export const addCommentNistCsfSubCaytegory = (
  answer: any,
  assessment: string,
  assessment_objective: any,
  comment: any,
  task: boolean
) => {
  return _authorizedRequest(
    API_END_POINT.ADD_NIST_SUBCATEGORY_COMMENT,
    "POST",
    {
      answer: answer,
      assessment: assessment,
      assessment_objective: assessment_objective,
      comment: comment,
      task: task,
    }
  );
};

export const featureRequest = (title: any, description: any) => {
  return _authorizedRequest(API_END_POINT.ADD_FEATURE_REQUEST, "POST", {
    title: title,
    description: description,
  });
};

// delete user all permission
export const deleteAllUserInvite = (pk: number) => {
  return _authorizedRequest(`${API_END_POINT.DELETE_USER_ALL_PER}`, "POST", {
    user_id: pk,
  });
};

// update tooltip data
export const updateTooltipHtml = (
  help_text_id: number | undefined,
  security_requirement: number | undefined,
  assessment: string,
  user_help_text: string
) => {
  const url = `${API_END_POINT.UPDATE_TOOL_TIP_HTML}/${help_text_id}/`;
  return _authorizedRequest(url, "PUT", {
    security_requirement: security_requirement,
    assessment: assessment,
    user_help_text: user_help_text,
  });
};

// api for frontline defender package

export const subscribeFrontlineDefender = (plan_id: number) => {
  return _authorizedRequest(
    API_END_POINT.SUBSCRIBE_FRONTLINE_DEFENDER_PACKAGE,
    "POST",
    {
      plan: plan_id,
    }
  );
};

const _baseRequest = (
  url: string,
  method: API_METHOD = "GET",
  data?: object,
  headers?: { [key: string]: string }
): Promise<any> => {
  return apiClient
    .request({
      method,
      url,
      headers,
      data,
    })
    .then((response) => response?.data?.data)

    .catch((err: AxiosError | Error) =>
      Promise.reject({
        name: err.name,
        message: err.message,
        status: (err as AxiosError).response?.status || -1,
        data: (err as AxiosError).response?.data,
      })
    );
};

const _baseRequestResponse = (
  url: string,
  method: API_METHOD = "GET",
  data?: object,
  headers?: { [key: string]: string }
): Promise<any> => {
  return apiClient
    .request({
      method,
      url,
      headers,
      data,
    })
    .then((response) => response?.data)

    .catch((err: AxiosError | Error) =>
      Promise.reject({
        name: err.name,
        message: err.message,
        status: (err as AxiosError).response?.status || -1,
        data: (err as AxiosError).response?.data,
      })
    );
};

const _authorizedRequest = async (
  url: string,
  method: API_METHOD = "GET",
  data?: object,
  headers?: { [key: string]: string }
): Promise<any> => {
  const authToken = await getAuthorizationHeaderValue();

  if (!headers) {
    headers = {};
  }
  if (authToken !== null) {
    headers[AUTHORIZATION_HEADER_NAME] = authToken;
  }

  return _baseRequest(url, method, data, headers);
};

const _authorizedRequestResponse = async (
  url: string,
  method: API_METHOD = "GET",
  data?: object,
  headers?: { [key: string]: string }
): Promise<any> => {
  const authToken = await getAuthorizationHeaderValue();

  if (!headers) {
    headers = {};
  }
  if (authToken !== null) {
    headers[AUTHORIZATION_HEADER_NAME] = authToken;
  }

  return _baseRequestResponse(url, method, data, headers);
};

const apiClient = axios.create({
  baseURL: COMMON.apiBaseUrl,
  headers: {
    "Content-type": "multipart/form-data",
  },
  transformRequest: [
    function (data, headers) {
      if (data instanceof FormData) {
        // Don't modify the headers for FormData requests
        return data;
      } else {
        // For non-FormData requests, serialize the data as JSON
        headers["Content-type"] = "application/json";
        return JSON.stringify(data);
      }
    },
  ],
});
