import React, { FunctionComponent } from "react";
import { Avatar, Box } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import CertificateImage from "assets/bg-img-certificate.png";
import LogoCertificat from "assets/logo-certificate.png";
import Logo from "assets/new-logo.png";

const Container = styled(Box)({
  width: "100%",
  height: "85vh",
  boxShadow:
    "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
});

const MainCard = styled(Box)({
  backgroundImage: `url(${CertificateImage})`, // Use the imported image
  backgroundSize: "100% 100%", // Adjust this property as needed
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
});

const Content = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

const InnerBox = styled(Box)({
  marginTop: "40px",
});

const Name = styled(Box)({
  fontFamily: "cursive",
  fontSize: "30px",
  fontWeight: 600,
  textDecoration: "underline",
  textAlign: "center",
});

const DateBox = styled(Box)({
  fontFamily: "Gill Sans, sans-seri",
  fontSize: "16px",
  textAlign: "center",
  padding: "0px 60px",
});

const LogoBox = styled(Box)({
  width: "100px",
  height: "100px",
});

const CustomAvatar = styled("img")({
  width: "100%",
  height: "100%",
  borderRadius: "50%",
});

const AssessmentName = styled("span")({
  fontWeight: 600,
  textTransform: "capitalize",
  color: "#1D1F32",
});

const Title = styled(Box)({
  fontSize: "30px",
  lineHeight: "20px",
  fontWeight: 900,
  textAlign: "center",
  fontFamily: "Roca One, Arial, sans-serif",
});

const SubTitle = styled(Box)({
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: 500,
  textAlign: "center",
  fontFamily: "cursive",
  backgroundColor: "#1D1F32",
  color: "#fff",
  width: "fit-content",
  padding: "5px 8px",
});
type Props = {
  name?: string;
  date?: string;
  description?: string;
  level?: string;
  assessmentName?: string;
};

const Certificate: FunctionComponent<Props> = ({
  name = "Your Good Name",
  date = "12-01-2023",
  description = "Certificate of participating in completion of assessment",
  level = "for CMMC Level 1 Self-Assessment on",
  assessmentName,
}: Props) => {
  return (
    <Container>
      <MainCard mt={5}>
        <Box pt={4} display="flex" flexDirection="row" justifyContent="center">
          <Avatar
            src={LogoCertificat}
            alt="ab"
            sx={{ width: 140, height: 120 }}
          />
        </Box>
        <Title>CERTIFICATE</Title>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <SubTitle mt={1}>Of Achievement is Presented To</SubTitle>
        </Box>
        <Content>
          <InnerBox>
            <Name>{name}</Name>
            <DateBox mt={3}>
              {description} <AssessmentName>{assessmentName}</AssessmentName>{" "}
              {level} {date}
            </DateBox>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              mt={10}
            >
              <LogoBox>
                <CustomAvatar alt="Remy Sharp" src={Logo} />
              </LogoBox>
            </Box>
          </InnerBox>
        </Content>
      </MainCard>
    </Container>
  );
};

export default Certificate;
