import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  ImagePicker,
  Text,
  Card,
  CustomeButton,
  Input,
  Switch,
  QrCode,
  DashboardWrapper,
  Loader,
  UserTable,
  UserInvite,
} from "shared";

import { FcCollaboration } from "react-icons/fc";
import { Box, Grid, Modal, Slider, Button } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import { Colors } from "theme/color";
import { logoutAction, resetAuthToken } from "utils/auth";
import {
  userLogout,
  twoFactorOtp,
  verifyOtp,
  disabledOtp,
  updateProfile,
} from "reducers/user";
import { getMailBufferList } from "reducers/assessment";
import {
  fetchInviteUsers,
  inviteUser,
  resendUserInvite,
  delInviteUser,
  deleteUserAllInvites,
} from "reducers/collaburation";
import useAppDispatch from "hooks/useAppDispatch";
import { RootState } from "store";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { COMMON } from "utils/common";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DefaultImg from "assets/member-avatar-01.png";
import AvatarEditor from "react-avatar-editor";
import HoverImageOne from "assets/one-img.svg";
import HoverImageTwo from "assets/two-img.svg";
import HoverImageThree from "assets/three-img.svg";
import HoverImageFour from "assets/four-img.svg";
import HoverImageFive from "assets/five-img.svg";
import HoverImageSix from "assets/six-img.svg";
import Standard from "assets/standard.png";
import Premium from "assets/premium.png";
import Basic from "assets/basic.png";
import {
  PackageAvatar,
  ImageContainer,
  Badges,
  Multifactor,
  style,
  boxStyle,
  modalStyle,
  ModalContent,
} from "./style";
import { FrontlineDefender, Bunker, FortressPackage } from "utils/constant";

const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[A-Za-z ]+$/,
      "Only alphabetic characters and spaces are allowed"
    )
    .required("Name is required")
    .min(1, "Too Short!")
    .max(50, "Too Long!"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(40, "Password must not exceed 40 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirmPassword: Yup.string().when("password", {
    is: (val: any) => val && val.length > 0,
    then: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  }),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const VerifyOtp = Yup.object().shape({
  twoFactorOtp: Yup.string().required("OTP code is required"),
});

type Props = Record<string, never>;

const Profile: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const otpEnabled = useSelector((state: RootState) => state.user.otpEnabled);
  const token = useSelector((state: RootState) => state.user.otpauthUrl);
  const userImge = useSelector(
    (state: RootState) => state.user.userInfo?.users_profile?.image
  );

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0]?.plan?.name : "Null";

  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState<boolean>(otpEnabled);
  const [open, setOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // usertable
  const paginationRowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const selectedObjectIdRef = React.useRef<number | null>(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [deleteInvite, setDeleteInvite] = useState<number | null>(null);
  const [clickedResend, setClickedResend] = useState<number | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchLoading === false) {
      const newSearchText = event.target.value;
      setSearchText(newSearchText);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setTableLoader(true);
    setPage(page);
    dispatch(
      fetchInviteUsers({
        page: page,
        limit: paginationPerPage,
        search: searchText,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setCount(data.count);
          setTableLoader(false);
        }, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowsPerPageChange = (event: any) => {
    setTableLoader(true);
    const value = event?.target?.value;
    if (value) {
      setPaginationPerPage(parseInt(value));
      setPage(1);
    }
  };

  // fetch invite user api in useEffect
  useEffect(() => {
    setSearchLoading(true);
    setPage(1);
    dispatch(
      fetchInviteUsers({
        page: 1,
        limit: paginationPerPage,
        search: searchText,
      })
    )
      .unwrap()
      .then((data) => {
        setCount(data.count);
        setLoading(false);
        setTableLoader(false);
        setSearchLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, paginationPerPage, searchText]);

  // get invite user list from reducers
  const inviteUserList = useSelector(
    (state: RootState) => state.collaboration.inviteUserList
  );

  const inviteUsers = inviteUserList?.map((item: any, index) => {
    const invitedUser = {
      index: index + 1,
      pk: item.pk,
      email: item.email_address,
      permissions: item.accepter,
      accepted: item.accepted,
      invitedToken: item.invitation_token,
    };
    return invitedUser;
  });

  // profile page

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const baseURL = COMMON.apiBaseUrl;

  const trimmedImagePath = userImge?.substring(1);

  const profileImage = `${baseURL}${trimmedImagePath}`;

  const defaultImg = userImge === undefined ? DefaultImg : profileImage;

  const [image, setImage] = useState<string | undefined>(defaultImg);

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [slideValue, setSlideValue] = useState<number>(10);
  const cropRef = React.useRef<AvatarEditor | null>(null);

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  useEffect(() => {
    if (trimmedImagePath) {
      fetch(`${baseURL}${trimmedImagePath}`)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "profile-image", { type: blob.type });
          setSelectedFile(file);
        })
        .catch((error) => console.error(error));
    }
  }, [trimmedImagePath, baseURL]);

  // two factor api call
  const generateQrCode = useCallback(async () => {
    await dispatch(twoFactorOtp());
  }, [dispatch]);
  // disabled two factor
  const disabledQrCode = useCallback(async () => {
    dispatch(disabledOtp());
  }, [dispatch]);
  // handle switch two factor popup
  const handleSwitchChange = async () => {
    try {
      await generateQrCode(); // Wait for generateQrCode to complete
      setOpen(true);
      setChecked(true);
    } catch (error: any) {
      alert("Error: " + error.message);
    }
  };

  // handle unchecked switch
  const handleSwitchDisabled = async () => {
    try {
      disabledQrCode().then(() => {
        setChecked(false);
        swal({
          title: "Disabled Two Factor Authentication",
          text: "Successfully Disabled Two Factor Authentication",
          icon: "success",
        });
      });
    } catch (error: any) {
      alert("Error: " + error.message);
    }
  };

  const handleClose = () => {
    setChecked(false);
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = fileInput.files![0];
    // Check if the file type is allowed
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      swal({
        title: "Evidence",
        text: "Please upload an image in .png, .jpeg, or .jpg format.",
        icon: "error",
      });
      fileInput.value = "";
      return;
    }

    // Check if the file size is larger than 1MB (1,000,000 bytes)
    const maxSize = 1000000; // 1MB in bytes
    if (file.size > maxSize) {
      swal({
        title: "Profile Update",
        text: "Please use image size less than 1MB.",
        icon: "error",
      });
      fileInput.value = "";
      return; // Exit the function to prevent further processing
    }

    setImage(URL.createObjectURL(file));
    setModalOpen(true);
    fileInput.value = "";
  };

  // handle user logout
  const handleLogout = useCallback(async () => {
    setLoading(true);
    setDisabled(true);
    dispatch(userLogout())
      .unwrap()
      .then(() => {
        setTimeout(() => {
          logoutAction();
          resetAuthToken();
        }, 5000); // wait for 5 seconds before executing logoutAction()
        navigate("/signin");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  }, [dispatch]);

  const createFileFromPath = async (path: string): Promise<File> => {
    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], "defaultImage.png", { type: "image/png" });
    return file;
  };
  // profile page update api
  const handleUpdate = useCallback(
    async (values: {
      name: string;
      password: string;
      jobTitle: string;
      companyName: string;
    }) => {
      try {
        setIsLoading(true);
        setIsDisabled(true);
        const newUploadedImg = selectedFile
          ? selectedFile
          : await createFileFromPath(DefaultImg);
        // attempt a login, and if successful get the user profile
        await dispatch(
          updateProfile({
            name: values.name,
            jobTitle: values.jobTitle,
            password: values.password,
            company_name: values.companyName,
            image: newUploadedImg,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Profile Update",
              text: "Successfully Updated Profile",
              icon: "success",
            });
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Profile Update",
            text: "Profile Is Not Updated! something went wrong",
            icon: "error",
          });
        } else if (error?.data.status === 413) {
          swal({
            title: "Profile Update",
            text: "Please use image size less than 1MB.",
            icon: "error",
          });
        } else {
          swal({
            title: "Profile Update",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setIsLoading(false);
        setIsDisabled(false);
      }
    },
    [dispatch, selectedFile]
  );

  // verify otp code of two factor api
  const handleVerify = useCallback(
    async (values: { twoFactorOtp: string }) => {
      try {
        // attempt a login, and if successful get the user profile
        await dispatch(
          verifyOtp({
            twoFactorOtp: values.twoFactorOtp,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Two Factor Authentication",
              text: "Successfully verified two factor authentication",
              icon: "success",
            });
            setOpen(false);
            setChecked(true);
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Two Factor Authentication",
            text: "Invalid Two Factor Authentication Value!",
            icon: "error",
          });
        } else {
          swal({
            title: "Two Factor Authentication",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
          setOpen(false);
          setChecked(true);
        }
      }
    },
    [dispatch]
  );
  // dashboard route link
  const navigateToDashboard = () => {
    setIsClose(true);
    navigate("/dashboard");
    setIsClose(false);
  };

  //handle save
  const handleSave = async () => {
    if (cropRef.current && image) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      // Create a new File object
      const file = new File([blob], "filename.png", {
        type: blob.type,
        lastModified: Date.now(),
      });

      setSelectedFile(file);
      setImage(URL.createObjectURL(blob));
      setModalOpen(false);
    }
  };

  const handleCroperClose = () => {
    setModalOpen(false);
    setImage(defaultImg);
  };
  // call buffer page api in useEffect
  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getMailBufferList())
      .unwrap()
      .then(() => {})
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  // get buffer list from reducers
  const bufferList = useSelector(
    (state: RootState) => state?.assessment?.bufferListData
  );

  const getIconByPhaseName = (phaseName: any) => {
    switch (phaseName) {
      case "Reconnaissance":
        return <img src={HoverImageOne} alt="one" />;
      case "Intelligence Gathering":
        return <img src={HoverImageTwo} alt="two" />;
      case "Fortress Fortification":
        return <img src={HoverImageThree} alt="three" />;
      case "Tactical Communication":
        return <img src={HoverImageFour} alt="four" />;
      case "War Games":
        return <img src={HoverImageFive} alt="five" />;
      case "Battle Ready":
        return <img src={HoverImageSix} alt="six" />;
      default:
        return null;
    }
  };

  // user invite flow
  const [values, setValues] = useState([] as string[]);
  const [currValue, setCurrValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [openUser, setOpenUser] = useState(false);
  const [inviteDisable, setInviteDisable] = useState(false);

  const handleChangeUserInvite = (e: any) => {
    setCurrValue(e.target.value);
  };

  const handleDeleteCollaborationUser = (item: any, index: any) => {
    let arr = [...values];
    arr.splice(index, 1);
    console.log(item);
    setValues(arr);
  };

  const isValidEmail = (email: any) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleKeyUp = (e: any) => {
    if (e.keyCode === 32) {
      const trimmedValue = currValue.trim();
      if (trimmedValue !== "" && isValidEmail(trimmedValue)) {
        setValues((oldState) => [...oldState, trimmedValue]);
        setCurrValue("");
        setEmailError("");
      } else {
        // Set email error when the entered email is invalid
        setEmailError("Please enter valid email!");
      }
    }
  };
  // handle invite users api call
  const handleInviteUsers = useCallback(async () => {
    setInviteDisable(true);
    if (values.length === 0) {
      swal({
        title: "User Invitation",
        text: "Please add at least one email address before sending invitations!",
        icon: "warning",
      });
      setInviteDisable(false);
      return;
    }

    try {
      // Check if userInfo exists and has an email
      const userInfoEmail = userInfo?.email;

      // Filter out values that match userInfo email
      const remainingValues = values.filter(
        (value) => value.toLowerCase() !== userInfoEmail?.toLowerCase()
      );

      // If any value matched userInfo email, show alert
      if (remainingValues.length !== values.length) {
        swal({
          title: "User Invitation",
          text: `${userInfo?.email} \n This email addresses match your own email!  `,
          icon: "warning",
        });
      }

      // Check if there are any remaining values to send invitations
      if (remainingValues.length === 0) {
        // No remaining values, return from here
        setInviteDisable(false);
        return;
      }

      // add new user to share features
      // const lowerCaseValues = values.map((value) => value.toLowerCase());
      await dispatch(
        inviteUser({
          email_addresses: remainingValues,
        })
      )
        .unwrap()
        .then((response: any) => {
          let emails: any = [];
          let errorMessage: any;
          setOpenUser(false);
          response.forEach((item: any) => {
            // Iterating through each item in the array
            for (const email in item) {
              // Accessing the email property dynamically
              const emailArray = item[email].email_address;
              // Assuming there's only one email in the array
              const extractedEmail = emailArray ? emailArray[0] : null;

              if (extractedEmail) {
                errorMessage = extractedEmail;
              }

              emails.push(email);
              setInviteDisable(false);
            }
          });

          if (errorMessage) {
            setInviteDisable(false);
            swal({
              title: "User Invitation",
              text: `${emails.join(", ")} \n ${errorMessage}`,
              icon: "warning",
            });
          } else {
            swal({
              title: "User Invitation",
              text: "Invitation sent successfully!",
              icon: "success",
            });
          }
          dispatch(
            fetchInviteUsers({
              page: 1,
              limit: paginationPerPage,
              search: searchText,
            })
          )
            .unwrap()
            .then((data) => {
              setCount(data.count);
              setLoading(false);
              setTableLoader(false);
              setSearchLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    } catch (error: any) {
      if (error?.status === 400) {
        setInviteDisable(false);
        setOpenUser(false);
        swal({
          title: "User Invitation",
          text: "Oops, something went wrong. Please try again later.",
          icon: "error",
        });
      }
    }
  }, [dispatch, values, inviteDisable]);

  const handleModalOpenUser = () => {
    setEmailError("");
    setValues([]);
    setOpenUser(true);
  };

  const handleCloseUser = () => {
    setEmailError("");
    setValues([]);
    setCurrValue("");
    setOpenUser(false);
  };
  // handle resend invite to user in invite users table

  const handleResendInvite = useCallback(
    async (row: any) => {
      try {
        setClickedResend(row?.pk);
        const response = await dispatch(resendUserInvite({ pk: row?.pk }));

        if (response.meta.requestStatus === "fulfilled") {
          // Successful response
          swal({
            title: "Resend User Invitation",
            text: "Successfully resend user invite!",
            icon: "success",
          });
        } else if (response.meta.requestStatus === "rejected") {
          // Handle 404 error
          swal({
            title: "Resend User Invitation",
            text: "Something went wrong please try again",
            icon: "error",
          });
        }

        setClickedResend(null);
      } catch (error: any) {
        setClickedResend(null);
        // Handle other errors

        swal({
          title: "Resend User Invitation",
          text: "Oops, something went wrong. Please try again later.",
          icon: "error",
        });
      }
    },
    [dispatch, clickedResend]
  );

  // handle delete api call for invite users
  const handleDeleteInvite = useCallback(
    async (row: any) => {
      try {
        setDeleteInvite(row?.pk);
        const response = await dispatch(delInviteUser({ pk: row?.pk }));

        if (response.meta.requestStatus === "fulfilled") {
          dispatch(
            fetchInviteUsers({
              page: page,
              limit: paginationPerPage,
              search: searchText,
            })
          )
            .unwrap()
            .then((data) => {
              setCount(data.count);
              setLoading(false);
              setTableLoader(false);
              setSearchLoading(false);
            })
            .catch((err) => {
              if (err.status === 404) {
                setPage(page - 1);
                dispatch(
                  fetchInviteUsers({
                    page: page - 1,
                    limit: paginationPerPage,
                    search: searchText,
                  })
                )
                  .unwrap()
                  .then((data) => {
                    setCount(data.count);
                    setTableLoader(false);
                  });
              }
              console.log(err);
            });
          // Successful response
          swal({
            title: "Delete User Invitation",
            text: "Successfully deleted user invite!",
            icon: "success",
          });
        } else if (response.meta.requestStatus === "rejected") {
          // Handle 404 error
          swal({
            title: "Delete User Invitation",
            text: "Something went wrong please try again",
            icon: "error",
          });
        }

        setDeleteInvite(null);
      } catch (error: any) {
        // Handle other errors
        setDeleteInvite(null);
        swal({
          title: "Delete User Invitation",
          text: "Something went wrong please try again",
          icon: "error",
        });
      }
    },
    [dispatch, deleteInvite, page]
  );

  // handle delete user all permisison

  const handleRemoveUserAllPer = useCallback(
    async (row: any) => {
      try {
        setDeleteInvite(row?.pk);
        const response = await dispatch(
          deleteUserAllInvites({ pk: row?.permissions })
        );

        if (response.meta.requestStatus === "fulfilled") {
          dispatch(
            fetchInviteUsers({
              page: page,
              limit: paginationPerPage,
              search: searchText,
            })
          )
            .unwrap()
            .then((data) => {
              setCount(data.count);
              setLoading(false);
              setTableLoader(false);
              setSearchLoading(false);
            })
            .catch((err) => {
              if (err.status === 404) {
                setPage(page - 1);
                dispatch(
                  fetchInviteUsers({
                    page: page - 1,
                    limit: paginationPerPage,
                    search: searchText,
                  })
                )
                  .unwrap()
                  .then((data) => {
                    setCount(data.count);
                    setTableLoader(false);
                  });
              }
              console.log(err);
            });
          // Successful response
          swal({
            title: "Delete User Invitation",
            text: "Successfully deleted user invite!",
            icon: "success",
          });
        } else if (response.meta.requestStatus === "rejected") {
          // Handle 404 error
          swal({
            title: "Delete User Invitation",
            text: "Something went wrong please try again",
            icon: "error",
          });
        }

        setDeleteInvite(null);
      } catch (error: any) {
        // Handle other errors
        setDeleteInvite(null);
        swal({
          title: "Delete User Invitation",
          text: "Something went wrong please try again",
          icon: "error",
        });
      }
    },
    [dispatch, deleteInvite, page]
  );

  const handleMessage = () => {
    swal({
      title: "Invite Users",
      text: "Sorry you cann't invite users",
      icon: "warning",
    });
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Box ml={3} mt={3}>
              <ImageContainer>
                <ImagePicker
                  onChange={handleChange}
                  file={image}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                />
                <Box
                  sx={{ zIndex: 100, marginLeft: "-50px", marginTop: "60px" }}
                >
                  {currentPackage === FrontlineDefender && (
                    <PackageAvatar src={Basic} alt="package" />
                  )}
                  {currentPackage === Bunker && (
                    <PackageAvatar src={Standard} alt="package" />
                  )}
                  {currentPackage === FortressPackage && (
                    <PackageAvatar src={Premium} alt="package" />
                  )}
                </Box>

                <Badges ml={currentPackage === "Null" ? 7 : 0}>
                  {Array.isArray(bufferList) &&
                    bufferList?.map((zone, index) => (
                      <React.Fragment key={index}>
                        {zone.completed_options === zone.total_options && (
                          <Box pl={{ lg: 2, md: 1, sm: 0, xs: 0 }}>
                            {getIconByPhaseName(zone.phase_name)}
                          </Box>
                        )}
                      </React.Fragment>
                    ))}
                </Badges>
              </ImageContainer>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              m={3}
              width="100%"
            >
              <Box>
                <Text fontSize="36px" fontWeight="600">
                  My Profile{" "}
                </Text>
                <Text fontSize="18px" fontWeight="400">
                  Add your profile picture and other details.
                </Text>
              </Box>
              <Box mr={4} mt={4}>
                {currentPackage === "Null" ? (
                  <Box>
                    <Button
                      aria-label="collaboration"
                      sx={{
                        background: Colors.text.navy_orange,
                        textTransform: "capitalize",
                        color: Colors.text.black,
                      }}
                      // disabled={true}
                      variant="contained"
                      endIcon={<FcCollaboration />}
                      onClick={handleMessage}
                    >
                      Invite User
                    </Button>
                  </Box>
                ) : (
                  <UserInvite
                    values={values}
                    currValue={currValue}
                    emailError={emailError}
                    handleChange={handleChangeUserInvite}
                    handleDelete={handleDeleteCollaborationUser}
                    handleKeyUp={handleKeyUp}
                    onSubmit={handleInviteUsers}
                    handleOpen={handleModalOpenUser}
                    handleClose={handleCloseUser}
                    open={openUser}
                    inviteDisable={inviteDisable}
                  />
                )}
              </Box>
            </Box>
            <Box sx={{ lg: { m: 3 }, md: { m: 1 } }}>
              <Box>
                <Formik
                  initialValues={{
                    name: userInfo?.name || "",
                    password: "",
                    confirmPassword: "",
                    email: userInfo?.email || "",
                    jobTitle: userInfo?.users_profile?.job_title || "",
                    companyName: userInfo?.company_name || "",
                  }}
                  validationSchema={ProfileSchema}
                  onSubmit={handleUpdate}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <Box>
                      <Card>
                        <Box p={{ sx: 1, md: 3, lg: 4 }}>
                          <Grid container item spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box p={2}>
                                <Input
                                  name="name"
                                  type="text"
                                  label="Name"
                                  value={values.name}
                                  onChange={handleChange("name")}
                                />
                                {errors.name && touched.name ? (
                                  <div className="error-message">
                                    {errors.name}
                                  </div>
                                ) : null}
                                <Input
                                  name="email"
                                  type="email"
                                  label="Email"
                                  value={values?.email}
                                  readonly={true}
                                  onChange={handleChange("email")}
                                />
                                {errors?.email && touched?.email ? (
                                  <div className="error-message">
                                    {errors.email}
                                  </div>
                                ) : null}
                                <Input
                                  name="jobTitle"
                                  type="text"
                                  label="Job Title"
                                  value={values.jobTitle}
                                  onChange={handleChange("jobTitle")}
                                />
                                {errors.jobTitle && touched.jobTitle ? (
                                  <div className="error-message">
                                    {errors.jobTitle}
                                  </div>
                                ) : null}
                                <Input
                                  name="companyName"
                                  type="text"
                                  label="Company Name"
                                  value={values.companyName}
                                  onChange={handleChange("companyName")}
                                  readonly={true}
                                />
                                {errors.companyName && touched.companyName ? (
                                  <div className="error-message">
                                    {errors.companyName}
                                  </div>
                                ) : null}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box p={2}>
                                <Input
                                  name="password"
                                  label="Change Password"
                                  type={showPassword ? "text" : "password"}
                                  value={values.password}
                                  onChange={handleChange("password")}
                                  handleShow={toggleShowPassword}
                                  Icon={
                                    showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )
                                  }
                                />
                                {errors.password && touched.password ? (
                                  <div className="error-message">
                                    {errors.password}
                                  </div>
                                ) : null}
                                <Input
                                  name="confirmPassword"
                                  label="Confirm Password"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  value={values.confirmPassword}
                                  onChange={handleChange("confirmPassword")}
                                  handleShow={toggleShowConfirmPassword}
                                  Icon={
                                    showConfirmPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )
                                  }
                                />
                                {errors.confirmPassword &&
                                touched.confirmPassword ? (
                                  <div className="error-message">
                                    {errors.confirmPassword}
                                  </div>
                                ) : null}
                                <Multifactor
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                >
                                  <Text fontSize="14px" fontWeight="600">
                                    Multi-Factor Authentications
                                  </Text>
                                  <Box>
                                    {otpEnabled ? (
                                      <Switch
                                        color={Colors.button.primary_bg}
                                        checked={checked}
                                        onChange={handleSwitchDisabled}
                                      />
                                    ) : (
                                      <Switch
                                        color={Colors.button.primary_bg}
                                        checked={checked}
                                        onChange={handleSwitchChange}
                                      />
                                    )}
                                  </Box>
                                </Multifactor>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                      <Box
                        pt={6}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <CustomeButton
                          title={loading ? "Loading..." : "Log Out"}
                          align="center"
                          width="130px"
                          onClick={handleLogout}
                          disabled={disabled}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <CustomeButton
                            title={isLoading ? "Loading..." : "Update"}
                            align="center"
                            onClick={handleSubmit}
                            width="110px"
                            disabled={isDisabled}
                          />

                          <Box ml={{ xs: 0, md: 4 }} mt={{ xs: 2, md: 0 }}>
                            <CustomeButton
                              title="Close"
                              align="center"
                              onClick={navigateToDashboard}
                              width="110px"
                              disabled={isClose}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            {tableLoader ? (
              <Loader isLoading={tableLoader} />
            ) : (
              <UserTable
                user={inviteUsers}
                totalRows={count}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                pages={page}
                paginationPerPages={paginationPerPage}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                setPage={setPage}
                handleSearch={handleInputChange}
                search={searchText}
                selectedObjectIdRef={selectedObjectIdRef}
                loading={tableLoader}
                searchLoading={searchLoading}
                handleResendInvite={handleResendInvite}
                handleDeleteInvite={handleDeleteInvite}
                deleteInvite={deleteInvite}
                clickedResend={clickedResend}
                handleDeleteUser={handleRemoveUserAllPer}
              />
            )}
          </Box>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalContent sx={style}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text fontSize="18px" fontWeight="600">
                  Two-Factor Authentication
                </Text>
                <AiFillCloseCircle
                  onClick={handleClose}
                  className="custom-icon"
                />
              </Box>
              <Text fontSize="16px" fontWeight="400">
                Connect an authenticator app (e.g. Google Authenticator,
                Microsoft Authenticator) to generate a verification code. Use
                the code to verify your identity.
              </Text>
              <ul>
                <li>
                  Download and install an authenticator app on your mobile
                  device.
                </li>
                <li>Use the app to scan this QR code.</li>
              </ul>
              <Box mt={4}>
                <QrCode otpauth_url={token} />
              </Box>

              <Box>
                <Formik
                  initialValues={{
                    twoFactorOtp: "",
                  }}
                  validationSchema={VerifyOtp}
                  onSubmit={handleVerify}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <Box>
                      <Box p={2}>
                        <Input
                          name="twoFactorOtp"
                          type="text"
                          placeholder="Enter authenticator code"
                          value={values.twoFactorOtp}
                          onChange={handleChange("twoFactorOtp")}
                        />
                        {errors.twoFactorOtp && touched.twoFactorOtp ? (
                          <div className="error-message">
                            {errors.twoFactorOtp}
                          </div>
                        ) : null}
                      </Box>

                      <Box pt={6}>
                        <CustomeButton
                          title="Verify"
                          align="center"
                          onClick={handleSubmit}
                          width="130px"
                        />
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Box>
            </ModalContent>
          </Modal>

          <Modal sx={modalStyle} open={modalOpen}>
            <Box sx={boxStyle}>
              <AvatarEditor
                ref={(ref) => (cropRef.current = ref)}
                image={image || ""}
                style={{ width: "100%", height: "100%" }}
                border={50}
                borderRadius={150}
                color={[0, 0, 0, 0.72]}
                scale={slideValue / 10}
                rotate={0}
              />

              {/* MUI Slider */}
              <Slider
                min={10}
                max={50}
                sx={{
                  margin: "0 auto",
                  width: "80%",
                  color: "cyan",
                }}
                size="medium"
                defaultValue={slideValue}
                value={slideValue}
                onChange={(e, value) => setSlideValue(value as number)}
              />
              <Box
                sx={{
                  display: "flex",
                  padding: "10px",
                  border: "3px solid white",
                  background: "black",
                }}
              >
                <Button
                  size="small"
                  sx={{
                    marginRight: "10px",
                    color: "white",
                    borderColor: "white",
                  }}
                  variant="outlined"
                  onClick={handleCroperClose}
                >
                  cancel
                </Button>
                <Button
                  sx={{ background: "#5596e6" }}
                  size="small"
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </DashboardWrapper>
  );
};

export default Profile;
