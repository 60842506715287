import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Box, TextField } from "@mui/material";

interface LightTooltipProps extends TooltipProps {
  className?: string;
}

export const DarkTooltip = styled(
  ({ className, ...props }: LightTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "#fff",
    boxShadow: theme.shadows[3],
    fontSize: 13,
    letterSpacing: "0.2px",
    padding: 10,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000 !important", // Set the arrow color to white
  },
}));

export const LightTooltip = styled(
  ({ className, ...props }: LightTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: theme.shadows[3],
    fontSize: 13,
    letterSpacing: "0.2px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff !important", // Set the arrow color to white
  },
}));

export const modalResource = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  maxHeight: "100vh",
  padding: "20px",
  width: 600,
  borderRadius: "10px",
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for smaller screens
  },
};

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const CardVerification = styled(Box)(({ theme }) => ({
  filter: "drop-shadow(0px 0px 5px #FED74F)",
  background: "#fff",
  [theme.breakpoints.down("md")]: {
    filter: "none",
    background: "#fff",
  },
}));

export const LargeModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  overflowY: "auto",
  maxHeight: "87vh",
  paddingBottom: "15px",
  width: "60%",
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for smaller screens
  },
};

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "800px",
    margin: "0px auto",
    backgroundColor: "#000",
    padding: "20px",
  },
});

export const ObjectiveTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "800px",
    margin: "0px 5% !important",
    backgroundColor: "#000",
    padding: "20px",
  },
});

export const ControlExplanation = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "90%",
    minWidth: "fit-content !important",
    margin: "0px 5% !important",
    backgroundColor: "#000",
    padding: "20px",
  },
});

export const InputSerch = styled(TextField)`
  background: white;
  width: 100%;
  & .MuiInputBase-root {
    padding: 0px 25px; /* add padding inside the input */
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #fed74f !important;
    border: 2px solid #fed74f;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #fed74f !important;
      border: 2px solid #fed74f;
      border-radius: 50px;
    }
    &:hover fieldset {
      border-color: #fed74f !important;
      border-radius: 50px;
      border: 2px solid #fed74f;
    }
    &.Mui-focused fieldset {
      border-color: #fed74f !important;
      border-radius: 50px;
      border: 2px solid #fed74f;
    }
  }
`;

export const evidenceModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  height: "70vh",
  minHeight: "70vh",
  maxHeight: "70vh",
  padding: "30px",
  width: 500,
  borderRadius: 5,
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for smaller screens
  },
};

export const commentModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  minHeight: "35vh",
  maxHeight: "45vh",
  padding: "30px",
  width: 500,
  borderRadius: 5,
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for smaller screens
  },
};

export const modalPreview = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "100vh",
  padding: "20px",
  width: 600,
  borderRadius: "10px",
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for smaller screens
  },
};
