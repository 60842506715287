import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import React from "react";

export const PackageAvatar = styled("img")({
  width: "50px",
  height: "50px",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  borderRadius: "50%",
});

export const ImageContainer = styled(Box)(({ theme }) => ({
  backgroundImage: "linear-gradient(to bottom, white 50%, #000 50%)",
  padding: "10px",
  borderBottomLeftRadius: "20px",
  display: "flex",
  flexDirection: "row",
  height: "250px",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: "linear-gradient(to bottom, white 50%, #000 50%)",
    padding: "10px",
    borderBottomLeftRadius: "20px",
    display: "flex",
    flexDirection: "row",
    height: "auto",
  },
}));

export const Badges = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "170px",
  [theme.breakpoints.down("md")]: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridTemplateTows: "auto auto",
    gridGap: "10px",
    paddingTop: "10px",
  },
}));

export const Multifactor = styled(Box)({
  border: "2px solid #FED74F",
  padding: "20px 20px",
  borderRadius: "50px",
  marginTop: "28px",
});

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  maxHeight: "100vh",
  padding: "30px",
};
export const boxStyle: React.CSSProperties = {
  width: "300px",
  height: "300px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const modalStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));
