import React, { FunctionComponent, ChangeEventHandler } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { FaCommentDots } from "react-icons/fa";
import { Colors } from "theme/color";

const Input = styled("input")(({ theme }) => ({
  padding: "2px 2px",
  border: "none !important",
  minHeight: "18px",
  height: "40px",
  width: "100%",
  fontSize: "18px",
  [theme.breakpoints.down("md")]: {
    padding: "2px 2px",
    border: "none !important",
    minHeight: "18px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "2px 2px",
    border: "none !important",
    minHeight: "18px",
    width: "100%",
  },
  "&:hover": {
    outline: "none", // Hide outline on hover
    boxShadow: "none", // Hide box shadow on hover
  },
  "&:focus": {
    outline: "none", // Hide outline on focus
    boxShadow: "none", // Hide box shadow on focus
  },
}));

type Props = {
  placeholder?: string;
  type?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value?: string;
  id?: string;
  readOnly?: boolean;
  onBlur?: any;
};

const CommentInput: FunctionComponent<Props> = ({
  placeholder = "comment",
  type = "text",
  name = "name",
  onChange,
  value,
  id,
  readOnly = false,
  onBlur,
}: Props) => {
  return (
    <Box
      sx={{ width: "100%", overflowX: "hidden" }}
      display="flex"
      alignItems="center"
    >
      <Box mr={3}>
        <FaCommentDots
          style={{ fontSize: "30px", color: Colors.text.orange }}
        />
      </Box>
      <Input
        id={id}
        onChange={onChange}
        value={value || ""}
        type={type}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        onBlur={onBlur}
      />
    </Box>
  );
};

export default CommentInput;
