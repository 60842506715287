import React, { FunctionComponent } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { AppBar, Box, Stack, IconButton } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Colors } from "../../theme/color";

const LargeScreenHeader = styled(AppBar)({
  backgroundColor: "#000 !important",
  height: 70,
});

const MobileScreenHeader = styled(AppBar)({
  backgroundColor: "#FED74F !important",
  height: 70,
  borderBottomLeftRadius: 30,
  borderBottomRightRadius: 30,
  color: Colors.text.black,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: 20,
});

type Props = {
  title?: string;
  width?: string;
  margin?: string;
  direction?: "row" | "column";
  align?: "left" | "center" | "right";
  fontSize?: string;
  Icon?: React.ElementType;
  fontWeight?: string;
};

const HeaderComponent: FunctionComponent<Props> = ({
  title = "title",
  Icon = LockOpenIcon,
  fontSize = "24px",
  fontWeight = "700",
}: Props) => {
  return (
    <>
      <Box display={{ xs: "none", lg: "block" }}>
        <LargeScreenHeader position="static"></LargeScreenHeader>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
        <MobileScreenHeader position="static">
          <Stack direction="row" spacing={2}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <Icon />
            </IconButton>
            <Box pt={1} style={{ fontSize: fontSize, fontWeight: fontWeight }}>
              {" "}
              {title}
            </Box>
          </Stack>
        </MobileScreenHeader>
      </Box>
    </>
  );
};

export default HeaderComponent;
