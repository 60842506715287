import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

interface QontoConnectorProps {
  theme?: any;
}

const QontoConnector = styled(StepConnector)(
  ({ theme }: QontoConnectorProps) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50%)",
      right: "calc(50%)",
      [theme.breakpoints.down("sm")]: {
        top: 10,
        left: "calc(-50%)",
        right: "calc(50%)",
      },
    },

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#07A82F",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#07A82F",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#ACACAC",
      borderTopWidth: 4,
      borderRadius: 1,
    },
  })
);

const QontoStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ACACAC",
  display: "flex",
  height: 24,
  alignItems: "center",
  border: "3px solid currentColor",
  borderRadius: "50%",
  padding: "0px 8px",
  marginTop: "-3px",
  background: "white",
  zIndex: 100,
  ...(ownerState.active && {
    color: "#07A82F",
    border: "3px solid currentColor",
    borderRadius: "50%",
    padding: "0px 8px",
    marginTop: "-3px",
    background: "white",
  }),
  ...(ownerState.completed && {
    color: "#FFF",
    zIndex: 1,
    fontSize: 18,
    fontWeight: 700,
    border: "0px solid currentColor",
    background: "#07A82F",
    borderRadius: "50%",
    padding: "3px 3px",
  }),

  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const Stacked = styled(Stack)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    overflowX: "auto",
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? <Check /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

const steps = [
  "Reconnaissance",
  "Intelligence Gathering",
  "Fortress Fortification",
  "Tactical Communication",
  "War Games",
  "Battle Ready",
];

interface CustomizedSteppersProps {
  activeStep?: number;
}

const CustomizedSteppers: React.FC<CustomizedSteppersProps> = ({
  activeStep = 0,
}) => {
  return (
    <Stacked spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{ wordBreak: "break-all" }}
            >
              {label}
            </StepLabel>
            <QontoConnector />
          </Step>
        ))}
      </Stepper>
    </Stacked>
  );
};

export default CustomizedSteppers;
