import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  filter: "drop-shadow(0px 0px 5px grey)",
  backgroundColor: "#fff",
  borderRadius: "20px",
  [theme.breakpoints.down("sm")]: {
    filter: "none !important",
    border: "unset !important",
    backgroundColor: "unset !important",
  },
}));
