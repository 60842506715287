import React, { FunctionComponent } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { Button } from "shared";
import { useNavigate } from "react-router-dom";
import Not from "assets/notFOund.png";
import { Bubbles, Card } from "./style";

type Props = Record<string, never>;

const NotFound: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const handleClicked = () => {
    navigate("/dashboard");
  };
  return (
    <Bubbles>
      <Card>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <img src={Not} alt="404" style={{ width: "100%" }} />

              <Box>
                <Typography component="p">
                  The page your are looking for may be temporarily
                  unavailable...
                </Typography>
                <Box mt={4}>
                  <Button
                    title="Go To Home"
                    width="280px"
                    borderRadius="50px"
                    fontWeight="500"
                    onClick={handleClicked}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Bubbles>
  );
};

export default NotFound;
