import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import JSZip from "jszip";
interface Option {
  resource_url: string | null;
  comment: string;
}
interface BasicTableProps {
  rows?: Option[];
}

const BasicTable: React.FC<BasicTableProps> = ({ rows }) => {
  const downloadZipFile = (content: string) => {
    const zip = new JSZip();
    zip.file("file", content);

    zip.generateAsync({ type: "blob" }).then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: index % 2 === 0 ? "#fdf4da" : "#fffcf1",
              }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell sx={{ width: "60%" }}>{row.comment}</TableCell>
              <TableCell>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "capitalize",
                    color: "#003D96",
                  }}
                  onClick={() => {
                    if (row.resource_url) {
                      window.open(row.resource_url, "_blank");
                    }
                  }}
                >
                  Open
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "capitalize",
                    color: "#003D96",
                  }}
                  onClick={() => {
                    if (row.resource_url) {
                      downloadZipFile(row.resource_url);
                    }
                  }}
                >
                  Download
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
