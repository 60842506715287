import React, { FunctionComponent, useState, useCallback } from "react";
import { Text, Loader } from "shared";

import { Colors } from "theme/color";
import { Box, Grid, Button } from "@mui/material";
import VerifyEmailImage from "assets/email.png";
import { resendVerify } from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { UserSelectors } from "reducers/user";
import { CardVerification } from "utils/sharedStyled";
import { SuccessMessage, ImageContainer } from "./style";

type Props = Record<string, never>;

const EmailVerification: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { loading } = UserSelectors();

  const handleResend = useCallback(async () => {
    dispatch(resendVerify())
      .unwrap()
      .then(() => {
        // If the API request is successful, set `isVisible` to `true`
        setIsVisible(true);
      })
      .catch((error) => {
        // If the API request fails, handle the error here
        console.error(error);
      });
  }, [dispatch]);

  if (loading) {
    return (
      <p>
        {" "}
        <Loader isLoading={loading} />
      </p>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="container-bg">
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <Box
              p={{ xs: 2, sm: 2, md: 6, lg: 0 }}
              mt={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              ml={{ xs: 0, sm: 0, md: 0, lg: 12 }}
              mr={{ xs: 0, sm: 0, md: 0, lg: 12 }}
            >
              <CardVerification>
                <Box p={{ xs: 2, sm: 2, md: 2, lg: 4 }}>
                  <Box display={{ xs: "none", md: "block", lg: "block" }}>
                    <Text
                      fontSize="40px"
                      fontWeight="700"
                      align="center"
                      variant="h2"
                    >
                      Email Verification{" "}
                    </Text>
                  </Box>
                  <ImageContainer
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    {" "}
                    <img
                      src={VerifyEmailImage}
                      alt="img"
                      className="verify-img"
                    />
                  </ImageContainer>
                  <Box style={{ display: isVisible ? "block" : "none" }}>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={7} lg={7}>
                        <SuccessMessage>
                          <Text
                            fontSize="18px"
                            fontWeight="400"
                            align="center"
                            variant="subtitle1"
                          >
                            A fresh verification link has been sent to your
                            email address.
                          </Text>
                        </SuccessMessage>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={7} lg={7}>
                        <Box>
                          <Text
                            fontSize="18px"
                            fontWeight="400"
                            align="center"
                            variant="subtitle1"
                          >
                            Before proceeding, please check your email for a
                            verification link. If you did not receive the email
                          </Text>
                        </Box>
                        <Box mt={2} display="flex" justifyContent="center">
                          <Button onClick={handleResend}>
                            <Text
                              fontSize="20px"
                              fontWeight="600"
                              align="center"
                              variant="h5"
                              color={Colors.text.blue}
                              textDecoration="underline"
                            >
                              Click here to request another{" "}
                            </Text>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardVerification>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EmailVerification;
