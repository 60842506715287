import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  Text,
  DashboardWrapper,
  Button,
  DataTableComponent,
  Loader,
} from "shared";
import { BiPlusCircle } from "react-icons/bi";

import useAppDispatch from "hooks/useAppDispatch";
import swal from "sweetalert";
import {
  addAssessment,
  fetchAssessments,
  deleteAssessment,
} from "reducers/assessment";
import { RootState } from "store";

import { Box, SelectChangeEvent, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useSelector } from "react-redux";
import { FaAccusoft } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { MdOutlineLock } from "react-icons/md";
import { LightTooltip } from "utils/sharedStyled";
import { FlexBox, BoxSm } from "./style";
import {
  assessmentTypeNISTCSF,
  assessmentTypeNISTCSFQSG,
  assessmentNistSp,
  FrontlineDefender,
  FortressPackage,
} from "utils/constant";
import CreateAssessmentModal from "pages/addAssessmentModal";

type Props = Record<string, never>;

const AssessmentLevelTwo: FunctionComponent<Props> = () => {
  const paginationRowsPerPageOptionsLevelTwo = [5, 10, 15, 20, 25, 50];
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [assessmentType, setAssessmentType] = useState("NIST SP 800-171");
  const [assessmentLevel, setAssessmentLevel] = useState("1");
  const [targeAssessmentLevel, setTargetAssessmentLevel] = useState("Partial");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(5);
  const [searchText, setSearchText] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);

  const selectedObjectIdRef = useRef<number | null>(null);
  const [tabValue, setTabValue] = React.useState("2");
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (tabValue === "1") {
      navigate("/assessment/nist-sp-800-171/level/1");
    } else if (tabValue === "2") {
      navigate("/assessment/nist-sp-800-171/level/2");
    } else {
      navigate("/assessment/nist-csf");
      // navigate("/assessment/nist-csf-quick-start-guide");
    }
  }, [tabValue]);

  const theme = useTheme();
  const navigate = useNavigate();

  // fetch assessments records from redux
  const assessmentList = useSelector(
    (state: RootState) => state.assessment.assessmentsListData
  );

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  //tabs handle change
  const handleTabChange = async (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTableLoader(true);
    setTabValue(newValue);
  };

  // select assessment level handle change
  const handleSelectLevel = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    setAssessmentLevel(selectedValue);
  };

  const handleSelectTargetLevel = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    setTargetAssessmentLevel(selectedValue);
  };

  // select assessment type handle
  const handleSelect = (event: SelectChangeEvent) => {
    setAssessmentType(event.target.value);
  };
  // assessment delete handle
  const handleDelete = useCallback(() => {
    const selectedObjectId = selectedObjectIdRef.current;
    if (selectedObjectId !== null) {
      const objectId = selectedObjectId;
      if (objectId) {
        setTableLoader(true);
        dispatch(deleteAssessment({ id: objectId }))
          .unwrap()
          .then(() => {
            // Handle successful deletion
            swal({
              title: "Assessment",
              text: `Assessment Deleted Successfully!`,
              icon: "success",
            });

            dispatch(
              fetchAssessments({
                page: page,
                limit: paginationPerPage,
                levelId: tabValue,
                search: "",
              })
            )
              .unwrap()
              .then((data) => {
                setCount(data.count);
                setTableLoader(false);
              })
              .catch((err) => {
                if (err.status === 404) {
                  setPage(page - 1);
                  dispatch(
                    fetchAssessments({
                      page: page - 1,
                      limit: paginationPerPage,
                      levelId: tabValue,
                      search: "",
                    })
                  )
                    .unwrap()
                    .then((data) => {
                      setCount(data.count);
                      setTableLoader(false);
                    });
                }
                console.log(err);
              });
          })
          .catch((error) => {
            // Handle error - 404 status code in this case
            if (error?.data.status === 500) {
              setTableLoader(false);
              swal({
                title: "Error",
                text: "Internal server error occurred while deleting the assessment.",
                icon: "error",
              });
            } else if (error?.data.status === 404) {
              setTableLoader(false);
              swal({
                title: "Assessment",
                text: `Assessment not found or already deleted.`,
                icon: "error",
              });
            } else {
              // Handle other errors
              swal({
                title: "Error",
                text: "An error occurred while deleting the assessment.",
                icon: "error",
              });
            }
          });
      }
    }
  }, [dispatch, page, paginationPerPage, tabValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchLoading === false) {
      const newSearchText = event.target.value;
      setSearchText(newSearchText);
    }
  };

  // add assessment handle
  const handleAssessment = useCallback(
    async (values: { assessment: string; companyName: string }) => {
      try {
        if (!values.assessment.trim()) {
          swal({
            title: "Assessment",
            text: "Enter valid assessment name",
            icon: "warning",
          });
          return; // Exit function if any field is empty
        }
        if (
          assessmentType === assessmentNistSp &&
          assessmentLevel === "1" &&
          currentPackage === FrontlineDefender
        ) {
          swal({
            title: "Assessment",
            text: "Subscribe Bunker $499 Per Year To Unlock Assessment Level one",
            icon: "warning",
          });
          navigate("/plan");
          return;
        }
        if (
          assessmentType === assessmentNistSp &&
          assessmentLevel === "2" &&
          currentPackage !== FortressPackage
        ) {
          swal({
            title: "Assessment",
            text: "Subscribe Fortress (Price $197/M Billed Annually) To Unlock Level 2",
            icon: "warning",
          });
          navigate("/plan");
          return;
        }
        if (
          assessmentType === assessmentTypeNISTCSF &&
          currentPackage !== FortressPackage
        ) {
          swal({
            title: "Assessment",
            text: "Subscribe Fortress (Price $197/M Billed Annually) To Unlock NIST CSF 2.0",
            icon: "warning",
          });
          navigate("/plan");
          return;
        }
        setIsLoading(true);
        setIsDisabled(true);
        setTableLoader(true);

        const currentLevel =
          assessmentType === assessmentTypeNISTCSF
            ? "0"
            : assessmentType === assessmentTypeNISTCSFQSG
            ? "0"
            : assessmentLevel;
        setTabValue(currentLevel);
        await dispatch(
          addAssessment({
            assessment: values.assessment,
            companyName: values.companyName,
            assessmentType: assessmentType,
            assessmentLevel:
              assessmentType === assessmentTypeNISTCSF
                ? null
                : assessmentType === assessmentTypeNISTCSFQSG
                ? null
                : assessmentLevel,
            target_assessment_level: targeAssessmentLevel,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Assessment",
              text: "Assessment Added Successfully !",
              icon: "success",
            });
            setOpen(false);
            setAssessmentLevel("1");
            const getListLevelId =
              assessmentType === assessmentTypeNISTCSF
                ? "0"
                : assessmentType === assessmentTypeNISTCSFQSG
                ? "0"
                : assessmentLevel;
            dispatch(
              fetchAssessments({
                page: page,
                limit: paginationPerPage,
                levelId: getListLevelId,
                search: "",
              })
            )
              .unwrap()
              .then((data) => {
                setCount(data.count);
                setTableLoader(false);
              })
              .catch((err) => {
                console.log(err);
                setTableLoader(false);
              });
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          setTableLoader(false);
          swal({
            title: "Assessment",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Assessment",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setIsLoading(false);
        setIsDisabled(false);
      }
    },
    [
      dispatch,
      page,
      paginationPerPage,
      tabValue,
      assessmentType,
      assessmentLevel,
      targeAssessmentLevel,
    ]
  );

  // level two handle pagination
  const handlePageChangeLevelTwo = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setTableLoader(true);
    setPage(page);
    dispatch(
      fetchAssessments({
        page: page,
        limit: paginationPerPage,
        levelId: tabValue,
        search: searchText,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setCount(data.count);
          setTableLoader(false);
        }, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // level two rows change
  const handleRowsPerPageChangeLevelTwo = (event: any) => {
    const value = event?.target?.value;
    if (value) {
      setPaginationPerPage(parseInt(value));
      setPage(1);
    }
  };

  const handleClick = () => {
    setChecked(!checked);
    setOpen(true);
  };

  const handleClose = () => {
    setChecked(false);
    setOpen(false);
    setAssessmentLevel("1");
  };

  useEffect(() => {
    setSearchLoading(true);

    setPage(1);
    dispatch(
      fetchAssessments({
        page: 1,
        limit: paginationPerPage,
        search: searchText,
        levelId: "2",
      })
    )
      .unwrap()
      .then((data) => {
        setCount(data.count);
        setLoading(false);
        setTableLoader(false);
        setSearchLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSearchLoading(true);
      });
  }, [dispatch, paginationPerPage, searchText]);

  // data maping
  const assessmentData = assessmentList?.map((item: any, index) => {
    const date = new Date(item.created_at).toISOString().slice(0, 10);
    let actionValue;

    if (item.status === "not_started") {
      actionValue = "START";
    } else if (item.status === "inprogress") {
      actionValue = "RESUME";
    } else {
      actionValue = "REPORT";
    }

    const assessment = {
      index: index + 1,
      id: item.id,
      assessment: item.name,
      company: item.company_name,
      assessmentType: item.assessment_type,
      modified: date,
      status: item.status,
      score: item.score,
      action: actionValue,
      levels: item.progress,
      assessment_level: item.assessment_level,
    };
    return assessment;
  });

  const handlePlan = () => {
    navigate("/plan");
  };

  return (
    <DashboardWrapper>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Box>
            <Box mt={3} mb={2}>
              <Text fontSize="32px" fontWeight="700" className="text-mobile">
                Assessment
              </Text>
            </Box>
          </Box>
          <TabContext value={tabValue}>
            <FlexBox>
              <Box
                sx={{
                  maxWidth: { xs: "100%", sm: "100%", md: "100%" },
                  mb: { xs: 3, sm: 3, md: 0, lg: 0 },
                  mt: 1,
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons={false}
                  sx={{
                    minHeight: "40px !important",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    overflowX: "auto",
                  }}
                >
                  {currentPackage === FrontlineDefender ? (
                    <LightTooltip
                      arrow
                      placement="top"
                      title="Subscribe Bunker $499 Per Year To Unlock Assessment Level one"
                    >
                      <Tab
                        onClick={handlePlan}
                        icon={<MdOutlineLock />}
                        iconPosition="end"
                        label="NIST SP 800-171 - Level 1"
                        value="1"
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#FED74F",
                            color: "#000",
                            minHeight: "40px !important",
                            borderRadius: "5px",
                          },
                          minHeight: "40px !important",
                          color: "#000",
                          border: "1px solid #FED74F",
                          borderRadius: "5px",
                          fontSize: "12px !important",
                          marginLeft: "10px",
                          [theme.breakpoints.down("sm")]: {
                            minHeight: "40px !important",
                            color: "#FED74F",
                            border: "1px solid #FED74F",
                            borderRadius: "5px",
                            fontSize: "10px !important",
                            width: "150px",
                          },
                        }}
                      />
                    </LightTooltip>
                  ) : (
                    <Tab
                      icon={<FaAccusoft />}
                      iconPosition="end"
                      label="NIST SP 800-171 - Level 1"
                      value="1"
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#FED74F",
                          color: "#000",
                          minHeight: "40px !important",
                          borderRadius: "5px",
                        },
                        minHeight: "40px !important",
                        color: "#000",
                        border: "1px solid #FED74F",
                        borderRadius: "5px",
                        fontSize: "12px !important",
                        [theme.breakpoints.down("sm")]: {
                          minHeight: "40px !important",
                          color: "#FED74F",
                          border: "1px solid #FED74F",
                          borderRadius: "5px",
                          fontSize: "10px !important",
                          width: "150px",
                        },
                      }}
                    />
                  )}
                  {currentPackage !== FortressPackage ? (
                    <LightTooltip
                      arrow
                      placement="top"
                      title="Subscribe Fortress (Price $197/M Billed Annually) To Unlock Assessment Level Two"
                    >
                      <Tab
                        onClick={handlePlan}
                        icon={<MdOutlineLock />}
                        iconPosition="end"
                        label="NIST SP 800-171 - Level 2"
                        value="2"
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#FED74F",
                            color: "#000",
                            minHeight: "40px !important",
                            borderRadius: "5px",
                          },
                          minHeight: "40px !important",
                          color: "#000",
                          border: "1px solid #FED74F",
                          borderRadius: "5px",
                          fontSize: "12px !important",
                          marginLeft: "10px",
                          [theme.breakpoints.down("sm")]: {
                            minHeight: "40px !important",
                            color: "#FED74F",
                            border: "1px solid #FED74F",
                            borderRadius: "5px",
                            fontSize: "10px !important",
                            width: "150px",
                          },
                        }}
                      />
                    </LightTooltip>
                  ) : (
                    <Tab
                      icon={<FaAccusoft />}
                      iconPosition="end"
                      label="NIST SP 800-171 - Level 2"
                      value="2"
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#FED74F",
                          color: "#000",
                          minHeight: "40px !important",
                          borderRadius: "5px",
                        },
                        minHeight: "40px !important",
                        color: "#000",
                        border: "1px solid #FED74F",
                        borderRadius: "5px",
                        fontSize: "12px !important",
                        marginLeft: "10px",
                        [theme.breakpoints.down("sm")]: {
                          minHeight: "40px !important",
                          color: "#FED74F",
                          border: "1px solid #FED74F",
                          borderRadius: "5px",
                          fontSize: "10px !important",
                          width: "150px",
                        },
                      }}
                    />
                  )}

                  <Tab
                    icon={<FaAccusoft />}
                    iconPosition="end"
                    label="NIST CSF 2.0"
                    value="0"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#FED74F",
                        color: "#000",
                        minHeight: "40px !important",
                        borderRadius: "5px",
                      },
                      minHeight: "40px !important",
                      color: "#000",
                      border: "1px solid #FED74F",
                      borderRadius: "5px",
                      fontSize: "12px !important",
                      marginLeft: "10px",
                      [theme.breakpoints.down("sm")]: {
                        minHeight: "40px !important",
                        color: "#FED74F",
                        border: "1px solid #FED74F",
                        borderRadius: "5px",
                        fontSize: "10px !important",
                        width: "150px",
                      },
                    }}
                  />
                </TabList>
              </Box>

              <BoxSm pt={0} mb={4} pr={3}>
                <Button
                  title="Add Assessment"
                  borderRadius="50px"
                  fontWeight="600"
                  width="240px"
                  align="right"
                  fontSize="15px"
                  Icon={<BiPlusCircle />}
                  onClick={handleClick}
                />
              </BoxSm>
            </FlexBox>

            <TabPanel value="2" sx={{ padding: "0px !important" }}>
              {tableLoader ? (
                <Loader isLoading={tableLoader} />
              ) : (
                <DataTableComponent
                  assessments={assessmentData}
                  totalRows={count}
                  paginationRowsPerPageOptions={
                    paginationRowsPerPageOptionsLevelTwo
                  }
                  pages={page}
                  paginationPerPages={paginationPerPage}
                  handlePageChange={handlePageChangeLevelTwo}
                  handleRowsPerPageChange={handleRowsPerPageChangeLevelTwo}
                  setPage={setPage}
                  handleSearch={handleInputChange}
                  searchTexts={searchText}
                  handleDelete={handleDelete}
                  selectedObjectIdRef={selectedObjectIdRef}
                  loading={tableLoader}
                  searchLoading={searchLoading}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      )}

      <CreateAssessmentModal
        isLoading={isLoading}
        isDisabled={isDisabled}
        open={open}
        handleClose={handleClose}
        handleAssessment={handleAssessment}
        assessmentType={assessmentType}
        handleSelect={handleSelect}
        targeAssessmentLevel={targeAssessmentLevel}
        assessmentTypeNISTCSF={assessmentTypeNISTCSF}
        handleSelectTargetLevel={handleSelectTargetLevel}
        assessmentLevel={assessmentLevel}
        handleSelectLevel={handleSelectLevel}
      />
    </DashboardWrapper>
  );
};

export default AssessmentLevelTwo;
