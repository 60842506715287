import React, { useState } from "react";
import AutoLinkPlugin from "./Plugins/LexicalEditorAutoLinkPlugin";
import LinkModifierPlugin from "./Plugins/LexicalLinkModifierPlugin";
import { LexicalEditorTheme } from "./LexicalEditorTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { Box, TextField } from "@mui/material";
import LexicalEditorToolbarPlugin from "./Plugins/LexicalEditorToolbarPlugin";
import { $generateHtmlFromNodes } from "@lexical/html";
import { $getRoot, LexicalEditor } from "lexical";
import { Button } from "shared";
import useAppDispatch from "hooks/useAppDispatch";
import { featureRequest } from "reducers/assessment";
import swal from "sweetalert";

function onError(error: any) {
  console.error(error);
}

function Placeholder() {
  return (
    <Box
      sx={{
        color: "#999",
        overflow: "hidden",
        position: "absolute",
        textOverflow: "ellipsis",
        top: "100px",
        left: "25px",
        fontSize: "15px",
        userSelect: "none",
        display: "inline-block",
        pointerEvents: "none",
      }}
    >
      What new CMMC Proof features would you like to see?
    </Box>
  );
}

interface Props {
  hasNav: boolean;
  closeModal: any;
}

export default function LexicalEditors({ hasNav, closeModal }: Props) {
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [returnHtml, setReturnHtml] = useState<any>();

  const dispatch = useAppDispatch();

  const onChange = (editorState: any, editor: LexicalEditor) => {
    editorState.read(() => {
      const root = $getRoot();
      if (root.getFirstChild() !== null) {
        const htmlString = $generateHtmlFromNodes(editor);
        setReturnHtml(htmlString);
      } else {
        console.log("No content in the editor");
      }
    });
  };

  const defaultHtml = '<p class="lexical-editor-paragraph"><br></p>';

  const handleSubmit = () => {
    if (!title.trim()) {
      swal({
        title: "Feature Request",
        text: "Please enter feature title",
        icon: "warning",
      });
      return; // Exit function if any field is empty
    }
    if (returnHtml.trim() === defaultHtml) {
      swal({
        title: "Feature Request",
        text: "please enter feature description",
        icon: "warning",
      });
      return;
    }
    setLoading(true);
    dispatch(
      featureRequest({
        title: title,
        description: returnHtml,
      })
    )
      .unwrap()
      .then(() => {
        setLoading(false);
        swal({
          title: "Feature Request",
          text: "Saved feature request successfully!",
          icon: "success",
        });
        closeModal();
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        swal({
          title: "Feature Request",
          text: "Something went wrong!",
          icon: "error",
        });
      });
  };

  const initialConfig = {
    namespace: "MyEditor",
    theme: LexicalEditorTheme,
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      {hasNav && (
        <Box
          sx={{
            position: "fixed",
            top: 75,
            left: 13,
            width: "97%",
            zIndex: 1,
            backgroundColor: "#fff",
          }}
        >
          <LexicalEditorToolbarPlugin />
        </Box>
      )}
      <Box
        sx={{
          marginTop: hasNav ? "60px" : "0px",
          height: "calc(64vh - 0px)",
          overflowY: "auto",
          padding: "15px",
        }}
      >
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={handleTitleChange}
          sx={{ marginBottom: "20px" }}
        />
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                boxShadow: "base",
                resize: "none",
                fontSize: "15px",
                caretColor: "rgb(5, 5, 5)",
                position: "relative",
                tabSize: "1",
                outline: "0",
                padding: "15px 10px",
                height: "42vh",
                overflowY: "auto",
                border: "1px solid #c4c4c4",
                borderRadius: "10px",
              }}
            />
          }
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <LinkModifierPlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <OnChangePlugin onChange={onChange} />
        <Box mt={1}>
          <Button
            title={loading ? "Submitting..." : "Submit"}
            width="120px"
            onClick={handleSubmit}
            disabled={loading}
          />
        </Box>
      </Box>
    </LexicalComposer>
  );
}
