import React, { FunctionComponent, ChangeEventHandler } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const TextArea = styled(TextField)`
  background: white;
  margin-top: 10px;
  & .MuiInputBase-root {
    padding: 22px 40px; /* add padding inside the input */
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #fed74f !important;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #fed74f !important;
      border: 2px solid #fed74f;
      border-radius: 50px;
    }
    &:hover fieldset {
      border-color: #fed74f !important;
      border: 2px solid #fed74f;
      border-radius: 50px;
    }
    &.Mui-focused fieldset {
      border-color: #fed74f !important;
      border: 2px solid #fed74f;
      border-radius: 50px;
    }
  }
`;

type Props = {
  placeholder?: string;
  width?: string;
  name?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  type?: any;
  onBlur?: any;
  Icon?: React.ReactNode;

  IconBackground?: string;
  IconsColor?: string;
  handleShow?: () => void;
  onKeyPress?: any;
};

const InputComponent: FunctionComponent<Props> = ({
  placeholder = "Placeholder",
  width = "100%",
  name = "name",
  value,
  onChange,
  type = "text",
  onBlur,
  Icon,
  handleShow,
  IconBackground,
  IconsColor,
  onKeyPress,
}: Props) => {
  return (
    <>
      <TextArea
        type={type}
        multiline
        maxRows={4}
        name={name}
        aria-label="minimum height"
        placeholder={placeholder}
        style={{ width: width }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                tabIndex={-1}
                onClick={handleShow}
                style={{ background: IconBackground, color: IconsColor }}
              >
                {Icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default InputComponent;
