import React, { FunctionComponent, useCallback, useState } from "react";
import { Text, Button, Card, Logo, Input } from "shared";
import { Colors } from "theme/color";
import { Box, Grid, Stack, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import { Formik } from "formik";
import useAppDispatch from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { userForgetPassword } from "reducers/user";
import * as yup from "yup";
import swal from "sweetalert";
import { BottomBorder, Container } from "./style";
import ReCAPTCHA from "react-google-recaptcha";

const SignInSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is Required"),
});

type Props = Record<string, never>;

const ResetPassword: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const siteKey = process.env.REACT_APP_SITE_KEY!;
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const handleResetPassword = useCallback(
    async (values: { email: string }) => {
      try {
        const captchaValue = recaptchaRef.current?.getValue();
        if (!captchaValue) {
          swal({
            title: "Register",
            text: "Please verify the reCAPTCHA!",
            icon: "warning",
          });
          return; // Exit function if any field is empty
        }
        setIsLoading(true);
        setIsDisabled(true);
        // attempt a login, and if successful get the user profile
        await dispatch(
          userForgetPassword({
            email: values.email,
          })
        ).unwrap();
        // go to the main page
        swal({
          title: "Forgot Password",
          text: "Email sent successfully",
          icon: "success",
        });
        navigate("/reset-password-email");
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Forgot Password",
            text: "Email does not exist",
            icon: "error",
          });
        } else {
          alert("Oops, something went wrong. Please try again later.");
        }
      } finally {
        setIsLoading(false);
        setIsDisabled(false);
      }
    },
    [dispatch]
  );

  return (
    <>
      <Container sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            display={{ xs: "none", md: "block", lg: "block" }}
            alignItems="center"
          >
            <Box>
              <Box pl={10}>
                <Text
                  fontSize="52px"
                  fontWeight="900"
                  align="left"
                  variant="h1"
                >
                  Forgot password
                </Text>
              </Box>
              <Box pl={10} pr={10} pt={4}>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    display={{ xs: "none", lg: "block" }}
                    alignItems="center"
                  >
                    <Text fontSize="20px" fontWeight="400" align="justify">
                      Please enter your email address. We will send you an email
                      to reset your password.
                    </Text>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={{ xs: 2, sm: 2, md: 6, lg: 8 }}>
              <Card>
                <Box p={{ xs: 2, sm: 2, md: 4, lg: 5 }}>
                  <Logo />
                  <Box pt={8}>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={SignInSchema}
                      onSubmit={handleResetPassword}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Box>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange("email")}
                          />
                          {errors.email && touched.email ? (
                            <div className="error-message">{errors.email}</div>
                          ) : null}
                          <Box
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} />
                          </Box>
                          <Box pt={5}>
                            <Button
                              title={isLoading ? "Loading..." : "Submit"}
                              align="center"
                              width="60%"
                              onClick={handleSubmit}
                              disabled={isDisabled}
                            />
                          </Box>
                        </Box>
                      )}
                    </Formik>
                    <Box
                      pt={4}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <Stack
                        direction={{ xs: "row", sm: "row" }}
                        spacing={{ xs: 0, sm: 0, md: 0 }}
                      >
                        <Text
                          color={Colors.text.black}
                          fontSize="14px"
                          variant="subtitle1"
                        >
                          Forgot which email you used?
                          <Link
                            to="/contact-support"
                            style={{ textDecoration: "none" }}
                          >
                            <Typography component="span" className="creat-link">
                              {" "}
                              Contact Support
                            </Typography>
                          </Link>
                        </Text>
                      </Stack>
                    </Box>
                    <BottomBorder />
                    <Box>
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Text
                          color={Colors.text.link}
                          fontSize="14"
                          variant="subtitle1"
                          align="center"
                        >
                          Go back to Login{" "}
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ResetPassword;
