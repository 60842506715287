import React, { useState } from "react";
import { DashboardWrapper, TextArea, Text, Loader } from "shared";
import { Box, Grid } from "@mui/material";
import { TiArrowUp } from "react-icons/ti";
import { Colors } from "theme/color";
import { AiOutlineUser } from "react-icons/ai";
import useAppDispatch from "hooks/useAppDispatch";
import {
  runThread,
  addMessage,
  getMessageList,
  runThreadID,
} from "reducers/assessment";
import Typist from "react-typist";
import AceEditor from "react-ace";
import { MdCheck, MdContentPaste } from "react-icons/md";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import { InputContainer, List, BorderBox, MessageBox } from "./style";

interface Message {
  id: string;
  role: string;
  content: {
    type: string;
    text: {
      value: string;
      annotations: any[];
    };
  }[];
}

interface MessageListProps {
  data?: Message[];
}

const OpenAIComponent: React.FC<MessageListProps> = () => {
  const dispatch = useAppDispatch();
  const [inputText, setInputText] = useState("");
  const [question, setQuestion] = useState("");
  const [listMessages, setListMessages] = useState<any[]>([]);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const bottomRef = React.useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
    setQuestion(e.target.value);
  };

  const handleGenerateClick = async () => {
    try {
      if (!inputText.trim()) {
        return;
      }
      await dispatch(
        addMessage({
          role: "user",
          content: inputText,
        })
      )
        .then(() => {
          setInputText("");
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
        });
      const runResponse = await dispatch(runThread());

      let runResponseId;
      let status;

      while (status !== "completed") {
        runResponseId = await dispatch(
          runThreadID({ id: runResponse?.payload.id })
        );

        status = runResponseId.payload.status;

        if (status === "in_progress") {
          // Wait for some time before checking again (you can adjust the delay)
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      await dispatch(getMessageList())
        .then((action) => {
          const data = action.payload.data;
          const sortedMessages = data
            .slice()
            .sort((a: any, b: any) => a.created_at - b.created_at);
          const lastTwoIndices = sortedMessages.slice(-2);
          setListMessages((prevListMessages) => [
            ...prevListMessages,
            ...lastTwoIndices,
          ]);
        })
        .catch((error) => {
          console.log(error);
        });

      setLoader(false);
      setLoading(false);
      // setGenerateClickCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Error generating response:", error);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      dispatch(getMessageList())
        .then((action) => {
          const data = action.payload.data;
          const sortedMessages = data
            .slice()
            .sort((a: any, b: any) => a.created_at - b.created_at);
          setListMessages(sortedMessages);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [listMessages]);

  const loadingRef = React.useRef(null);

  React.useEffect(() => {
    if (loading && bottomRef.current) {
      // Scroll to the box when loading is true
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading]);

  return (
    <DashboardWrapper>
      <Box mt={4}>
        <Box className="Overfolw">
          {loader ? (
            <Loader isLoading={loader} />
          ) : (
            <>
              {listMessages?.length > 0 ? (
                listMessages?.map((message: any, index) => (
                  <div key={index}>
                    <Box display="flex" flexDirection="row" pl={3}>
                      <Box>
                        {message.role === "user" ? (
                          <AiOutlineUser
                            style={{
                              background: "#727272",
                              padding: "8px",
                              borderRadius: "40px",
                              marginRight: "20px",
                              color: "#fff",
                              marginTop: "20px",
                            }}
                          />
                        ) : (
                          <Box
                            style={{
                              background: "#FED74F",
                              padding: "4px 6px",
                              borderRadius: "50%",
                              marginRight: "20px",
                              color: "#000",
                              marginTop: "20px",
                              height: "23px",
                              width: "20px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="15"
                              fill="none"
                            >
                              <circle
                                cx="3.64314"
                                cy="10.3926"
                                r="3.53571"
                                fill="black"
                              />
                              <path
                                d="M13.2866 0.0712891H7.82227L18.4294 21.9284H23.8937L13.2866 0.0712891Z"
                                fill="black"
                              />
                            </svg>
                          </Box>
                        )}
                      </Box>
                      <MessageBox
                        mt={3}
                        className={`${
                          message.role === "user"
                            ? "userQuery"
                            : "assistanAnswer"
                        }`}
                        ref={
                          index === listMessages.length - 1 ? bottomRef : null
                        }
                      >
                        <Box className="cmmc_proof_title">
                          {message.role === "assistant" && "CMMC PROOF:"}
                        </Box>
                        <div>
                          {index === listMessages.length - 1 ? (
                            <Typewriter
                              text={message?.content[0]?.text?.value}
                              delay={4}
                            />
                          ) : (
                            <PreviousResponse
                              text={message.content[0]?.text?.value}
                            />
                          )}
                        </div>
                      </MessageBox>
                    </Box>
                  </div>
                ))
              ) : (
                <Box>
                  <Box ml={4}>
                    <Text
                      color={Colors.text.black}
                      fontSize="24px"
                      fontWeight="600"
                    >
                      Where would you like to go ?
                    </Text>
                    <Text color={Colors.button.border_gary_03} fontSize="18">
                      start typing or upload a file....
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <List>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <BorderBox>
                            <Text
                              color={Colors.text.black}
                              fontSize="16px"
                              fontWeight="400"
                            >
                              Suggest fun activities
                            </Text>
                            <Text
                              color={Colors.button.border_gary_03}
                              fontSize="14px"
                              fontWeight="400"
                            >
                              Lorem ipsum dolor sit amet consectetur.
                            </Text>
                          </BorderBox>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <BorderBox>
                            <Text
                              color={Colors.text.black}
                              fontSize="16px"
                              fontWeight="400"
                            >
                              Tell me a fun fact
                            </Text>
                            <Text
                              color={Colors.button.border_gary_03}
                              fontSize="14px"
                              fontWeight="400"
                            >
                              Lorem ipsum dolor sit amet consectetur.
                            </Text>
                          </BorderBox>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <BorderBox>
                            <Text
                              color={Colors.text.black}
                              fontSize="16px"
                              fontWeight="400"
                            >
                              Recommend a dish
                            </Text>
                            <Text
                              color={Colors.button.border_gary_03}
                              fontSize="14px"
                              fontWeight="400"
                            >
                              Lorem ipsum dolor sit amet consectetur.
                            </Text>
                          </BorderBox>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <BorderBox>
                            <Text
                              color={Colors.text.black}
                              fontSize="16px"
                              fontWeight="400"
                            >
                              Plan an itinerary
                            </Text>
                            <Text
                              color={Colors.button.border_gary_03}
                              fontSize="14px"
                              fontWeight="400"
                            >
                              Lorem ipsum dolor sit amet consectetur.
                            </Text>
                          </BorderBox>
                        </Grid>
                      </Grid>
                    </List>
                  </Box>
                </Box>
              )}
            </>
          )}
          <Box>
            {loading ? (
              <Box ref={loadingRef}>
                <Box ml={3} mb={3} display="flex">
                  <AiOutlineUser
                    style={{
                      background: "#727272",
                      padding: "10px",
                      borderRadius: "40px",
                      marginRight: "20px",
                      color: "#fff",
                      marginTop: "20px",
                    }}
                  />
                  <Box className="userQuery" mt={3}>
                    <Typist avgTypingDelay={200}>{question}</Typist>
                  </Box>
                </Box>
                <Box ml={5} mt={3} mb={3} display="flex">
                  <div className="loader-animation"></div>
                  <div className="loader-animation"></div>
                  <div className="loader-animation"></div>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <InputContainer>
                <TextArea
                  placeholder="search here..."
                  type="text"
                  value={inputText}
                  onChange={handleInputChange}
                  Icon={<TiArrowUp />}
                  handleShow={handleGenerateClick}
                  IconBackground="#FED74F"
                  IconsColor="#000"
                />
              </InputContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DashboardWrapper>
  );
};

export default OpenAIComponent;

interface TypewriterProps {
  text: string;
  delay: number;
}

const Typewriter: React.FC<TypewriterProps> = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  React.useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);
  const extractedCode = extractCodeBlocksFromText(currentText);
  return (
    <span>
      <div>
        {extractedCode.length > 0 ? (
          <>
            {extractedCode?.map((codeBlock, index) => (
              <CodeEditor key={index} codeBlock={codeBlock} />
            ))}
          </>
        ) : (
          <div>{currentText}</div>
        )}
      </div>
    </span>
  );
};

interface PrevwriterProps {
  text: string;
}

const PreviousResponse: React.FC<PrevwriterProps> = ({ text }) => {
  const codeBlocks = extractCodeBlocksFromText(text);
  return (
    <span>
      <div>
        {codeBlocks.length > 0 ? (
          <>
            {codeBlocks?.map((codeBlock, index) => (
              <CodeEditor key={index} codeBlock={codeBlock} />
            ))}
          </>
        ) : (
          <div>{text}</div>
        )}
      </div>
    </span>
  );
};

const extractCodeBlocksFromText = (text: string): CodeBlocks[] => {
  const codeRegex = /```(\w+)?([\s\S]+?)```/g;
  const codeBlocks: CodeBlocks[] = [];
  let lastIndex = 0;
  let match;

  while ((match = codeRegex.exec(text)) !== null) {
    const textBefore = text.substring(lastIndex, match.index ?? 0);
    const code = match[2];

    codeBlocks.push({ text: textBefore, code });
    lastIndex = match.index ? match.index + match[0].length : 0;
  }

  // Include any remaining text after the last code block
  if (lastIndex < text.length) {
    codeBlocks.push({
      text: text.substring(lastIndex),
      code: "",
    });
  }

  return codeBlocks;
};

const makeTextBoldWithinBackticks = (text: any) => {
  const parts = text.split(/(`.*?`|\d+\.\s|-\s|,)/g);
  let isList = false;
  let listTag: string | null = null;

  return parts.map((part: any, index: any) => {
    // Remove commas from each part
    part = part.replace(/,/g, "");

    if (part.startsWith("`")) {
      // Remove the new line from the bold tag
      return (
        <React.Fragment key={index}>
          <span
            className="single-quote-text"
            style={{
              color: "#000",
              fontSize: ".875em",
              whiteSpace: "nowrap",
              fontWeight: 600,
              marginLeft: "3px",
            }}
          >
            {part}
          </span>
        </React.Fragment>
      );
    } else if (/^\d+\.\s/.test(part) || /^-\s/.test(part)) {
      // Detected list item
      isList = true;
      listTag = part.startsWith("-") ? "ul" : "ol";
      return null;
    } else if (isList && part.trim() !== "") {
      // Inside a list, add a new line for each non-empty list item
      return (
        <li key={index} className={`single-quote-text ${listTag}`}>
          {part}
        </li>
      );
    } else {
      return part;
    }
  });
};

interface CodeBlocks {
  text?: string;
  code?: string;
}

const CodeEditor: React.FC<{ codeBlock: CodeBlocks }> = ({ codeBlock }) => {
  const editorRef = React.useRef(null);
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    if (editorRef.current) {
      const editor = (editorRef.current as any).editor;
      const lineHeight = editor.renderer.lineHeight;
      const totalLines = editor.session.getLength();
      const calculatedHeight = `${lineHeight * totalLines}px`;

      // Update the height dynamically
      editor.container.style.height = calculatedHeight;
    }
  }, [codeBlock.code]);

  const handleCopyCode = () => {
    if (editorRef.current) {
      const codeToCopy = (editorRef.current as any).editor.getValue();
      navigator.clipboard
        .writeText(codeToCopy)
        .then(() => {
          setIsCopied(true);

          // Reset the copied message after 1 second
          setTimeout(() => {
            setIsCopied(false);
          }, 1000);
        })
        .catch((err) => {
          console.error("Unable to copy code to clipboard", err);
        });
    }
  };

  return (
    <div>
      <div>{makeTextBoldWithinBackticks(codeBlock.text)}</div>

      {codeBlock.code && (
        <Box mt={3} mb={3}>
          <Box className="code-copy-box">
            <Box display="flex" justifyContent="end">
              <Box onClick={handleCopyCode} className="code-copy-button">
                {isCopied ? (
                  <Box display="flex">
                    <Box>
                      <MdCheck className="check-icon" />
                    </Box>{" "}
                    <Box>Copied!</Box>
                  </Box>
                ) : (
                  <Box display="flex">
                    <Box>
                      <MdContentPaste className="copy-icon" />
                    </Box>{" "}
                    <Box>Copy Code</Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <AceEditor
            ref={editorRef}
            mode="javascript"
            theme="monokai"
            value={codeBlock.code}
            readOnly
            showGutter={false} // Hide line numbers
            highlightActiveLine={false}
            style={{
              width: "100%",
              height: "auto",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              cursor: "none", // Set cursor to default (no hover effect)
            }}
          />
        </Box>
      )}
    </div>
  );
};
