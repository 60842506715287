import React, { FunctionComponent, ReactNode } from "react";
import { Box, Drawer } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { RxCrossCircled } from "react-icons/rx";
import { Colors } from "theme/color";

const Container = styled(Box)(({ theme }) => ({
  zIndex: 1202,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.button.primary_bg,
  minHeight: "100%",
  padding: theme.spacing(2),
  width: "50vw",
}));

type Props = {
  isOpen?: boolean;
  align?: "left" | "center" | "right";
  handleClose: () => void;
  children?: ReactNode | ReactNode[];
};

const Header: FunctionComponent<Props> = ({
  isOpen = false,
  align = "center",
  handleClose,
  children,
}: Props) => {
  return (
    <>
      <Container style={{ textAlign: align }} mt={5}>
        <Drawer open={isOpen} anchor="left">
          <DrawerContent>
            <Box display="flex" justifyContent="flex-end">
              <RxCrossCircled fontSize="24px" onClick={handleClose} />
            </Box>
            {children}
          </DrawerContent>
        </Drawer>
      </Container>
    </>
  );
};

export default Header;
