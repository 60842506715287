import React, { useState } from "react";
import DataTable, { IDataTableProps } from "react-data-table-component";

import {
  InputAdornment,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  Button,
  Popover,
} from "@mui/material";

import { FiSearch } from "react-icons/fi";
import { Colors } from "theme/color";
import { useNavigate } from "react-router-dom";
import { Loader } from "shared";
import { HiDotsVertical } from "react-icons/hi";
import { MdOutlineDeleteOutline, MdRemoveRedEye } from "react-icons/md";
import PoamIcon from "assets/poam.png";
import { IoIosPlay } from "react-icons/io";
import { AiOutlinePause } from "react-icons/ai";
import ReportIcon from "assets/report.png";
import { GrCertificate } from "react-icons/gr";
import { InputSerch } from "utils/sharedStyled";
import swal from "sweetalert";
import { Collaboration } from "shared";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useAppDispatch from "hooks/useAppDispatch";
import {
  fetchAcceptedInvitedUsers,
  fetchPermissionUserList,
  changeUserPermission,
  deleteUserPermission,
  assignPermission,
} from "reducers/collaburation";
interface Assessment {
  id?: number;
  assessment?: string;
  company?: string;
  assessmentType?: string;
  modified?: string;
  score?: number;
  status?: string;
  action?: string;
  ssp?: string;
  levels?: any;
  index?: any;
}

interface Props {
  assessments?: Assessment[];
  totalRows?: number;
  paginationRowsPerPageOptions: number[];
  pages?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  paginationPerPages?: number;
  handlePageChange?: (
    // eslint-disable-next-line no-unused-vars
    event: React.ChangeEvent<unknown>,
    // eslint-disable-next-line no-unused-vars
    page: number
  ) => void | undefined;
  // eslint-disable-next-line no-unused-vars
  handleRowsPerPageChange?: (event: any) => void;
  searchTexts?: string;
  handleSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  handleDelete?: () => void;
  selectedObjectIdRef: React.MutableRefObject<number | null>;
  loading?: boolean;
  searchLoading?: boolean;
}

const DataTableComponent: React.FC<Props> = ({
  assessments,
  totalRows,
  paginationRowsPerPageOptions,
  pages,
  paginationPerPages,
  handlePageChange,
  handleRowsPerPageChange,
  handleSearch,
  searchTexts,
  handleDelete,
  selectedObjectIdRef,
  loading,
  searchLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const page = pages ? pages : 0;
  const paginationPerPage = paginationPerPages ? paginationPerPages : 0;

  const searchText = searchTexts ? searchTexts : null;

  const currentPageRows = assessments?.length ? assessments : [];

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [rowList, setRowList] = React.useState<any>();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    objectId: any
  ) => {
    setAnchorEl(event.currentTarget);
    setRowList(objectId);
    selectedObjectIdRef.current = objectId.id;
  };

  const handleClickAction = () => {
    const name = rowList.assessment;
    const objectId = rowList.id;
    navigate(
      `/start/assessment/${objectId}/level/${rowList.assessment_level}`,
      {
        state: { name },
      }
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSSP = () => {
    if (rowList.action === "REPORT") {
      const objectId = rowList.id;
      navigate(`/assessment/report/${objectId}`);
    }
  };

  const handleClickActionView = () => {
    const objectId = rowList.id;
    const name = rowList.assessment;
    const flage = false;
    navigate(
      `/start/assessment/${objectId}/level/${rowList.assessment_level}`,
      {
        state: { flage, name },
      }
    );
  };
  // certificate route
  const handleCertificate = () => {
    const state = rowList.assessment;
    navigate(`/certificate`, { state });
  };
  const handlePoam = () => {
    const completedDate = rowList.modified;
    if (rowList?.action === "REPORT") {
      const objectId = rowList.id;
      navigate(`/poam/${objectId}`, { state: { completedDate } });
    }
  };

  // collaboration feature
  const [userSearch, setUserSearch] = useState<string>("");
  const [loader, setLoader] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchLoading === false) {
      const newSearchText = event.target.value;
      setUserSearch(newSearchText);
    }
  };

  React.useEffect(() => {
    dispatch(
      fetchAcceptedInvitedUsers({
        page: 1,
        limit: 15,
        search: userSearch,
      })
    )
      .unwrap()
      .then(() => {
        setLoader(false);
      })
      .catch((err: any) => {
        console.log(err);

        setLoader(false);
      });
  }, [dispatch, userSearch]);

  const [viewChecked, setViewChecked] = useState(false);
  const [writeChecked, setWriteChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [selectedUserID, setSelectedUserID] = useState<any[]>([]);

  const [userPermissions, setUserPermissions] = useState<any[]>([]);
  const [listLoader, setListLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const inviteUserList = useSelector(
    (state: RootState) => state.collaboration.inviteAcceptUserList
  );

  const inviteUser = inviteUserList?.map((item: any) => {
    const evidence = {
      id: item.pk,
      accepter: item.accepter,
      userName: item.email_address,
    };
    return evidence;
  });

  const handleModalOpen = () => {
    setWriteChecked(false);
    setViewChecked(false);
    setListLoader(true);
    setSelectedUserID([]);
    setPersonName([]);
    setUserPermissions([]);

    setOpen(true);

    dispatch(
      fetchPermissionUserList({ id: rowList.id, content_type: "assessment" })
    )
      .unwrap()
      .then((response) => {
        setUserPermissions(response.response);
        setListLoader(false);
      })
      .catch((error: any) => {
        console.log(error);
        setListLoader(false);
        swal({
          title: "Assign Task",
          text: "Something went wrong please try again!",
          icon: "error",
        });
      });
  };

  const handleViewChange = () => {
    setViewChecked(true);
    setWriteChecked(false);
  };

  const handleWriteChange = () => {
    setWriteChecked(true);
    setViewChecked(false);
  };

  const handleCloseModalCollaboration = () => {
    setOpen(false);
    setPersonName([]);
    setSelectedUserID([]);
  };

  const handleAutocompleteChange = (event: any, name: any) => {
    // Make API call using the selected names (value) here
    const selectedUserIds = name?.map((username: any) => {
      const user = inviteUser.find((item: any) => item.userName === username);
      return user ? user.accepter : null;
    });
    // Filter out null values (IDs not found for some usernames)
    const validUserIds = selectedUserIds.filter((id: any) => id !== null);
    setSelectedUserID(validUserIds);
    // Update the state with selected names
    setPersonName(name);
  };

  const handleClickToggle = (email: string, buttonType: string) => {
    setUserPermissions((prevPermissions: any[]) => {
      const updatedPermissions = [...prevPermissions];
      const userIndex = updatedPermissions.findIndex(
        (user) => user.user_email === email
      );

      if (userIndex !== -1) {
        const updatedUser = { ...updatedPermissions[userIndex] };

        if (buttonType === "Viewer") {
          updatedUser.can_view = true;
          updatedUser.can_write = false;
        } else if (buttonType === "Editor") {
          updatedUser.can_view = false;
          updatedUser.can_write = true;
        }

        updatedPermissions[userIndex] = updatedUser;

        dispatch(
          changeUserPermission({
            invited_user_id: [updatedUser.accepter_id],
            object_id: [rowList.id],
            perm_codename: "assessment",
            read_permission: updatedUser.can_view,
            write_permission: updatedUser.can_write,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Change Permission",
              text: "Successfully Change Permission",
              icon: "success",
            });
          })
          .catch((err: any) => {
            console.log(err);
            swal({
              title: "Change Permission",
              text: "Something went wrong",
              icon: "error",
            });
          });
      }

      return updatedPermissions;
    });
  };

  const handleDeletePermission = (email: any) => {
    dispatch(
      deleteUserPermission({
        userId: [email.accepter_id],
        content_type: "assessment",
        object_id: [rowList.id],
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          fetchPermissionUserList({
            id: rowList.id,
            content_type: "assessment",
          })
        )
          .unwrap()
          .then((response) => {
            setUserPermissions(response.response);
            setListLoader(false);
          })
          .catch((error: any) => {
            console.log(error);
            setListLoader(false);
          });
        swal({
          title: "Remove Permission",
          text: "Successfully Remove Permission",
          icon: "success",
        });
      })
      .catch((err: any) => {
        console.log(err);
        swal({
          title: "Remove Permission",
          text: "Something went wrong",
          icon: "error",
        });
      });
  };

  const handleAssignAssignment = () => {
    if (selectedUserID.length === 0) {
      swal({
        title: "Assign Task",
        text: "Please Select user!",
        icon: "warning",
      });
      return;
    }
    if (!viewChecked && !writeChecked) {
      swal({
        title: "Assign Task",
        text: "Please Select Permission!",
        icon: "warning",
      });
      return;
    }
    setSubmitLoader(true);
    dispatch(
      assignPermission({
        invited_user_ids: selectedUserID,
        object_id: [rowList.id],
        content_type: "assessment",
        read_permission: viewChecked,
        write_permission: writeChecked,
      })
    )
      .unwrap()
      .then(() => {
        setSubmitLoader(false);
        setOpen(false);
        swal({
          title: "Assign Task",
          text: "Successfully Assign Task !",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoader(false);

        swal({
          title: "Assign Task",
          text: "Something went wrong please try again",
          icon: "error",
        });
      });
  };

  const columns: any[] = [
    {
      name: "ASSESSMENT NAME",
      selector: (row: any) => row.assessment,
      center: true,
      width: "18%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "COMPANY",
      selector: (row: any) => row.company,
      center: true,
      width: "17%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "ASSESSMENT TYPE",
      selector: (row: any) => row.assessmentType,
      center: true,
      width: "17%",
      style: {
        cursor: "default",
      },
    },

    {
      name: "STATUS",
      cell: (row: any) => {
        let buttonValue;
        if (row.status === "not_started") {
          buttonValue = "Not Started";
        } else if (row.status === "inprogress") {
          buttonValue = "In Progress";
        } else {
          buttonValue = "Completed";
        }
        let buttonClass;
        if (row.status === "not_started") {
          buttonClass = "btn-started";
        } else if (row.status === "inprogress") {
          buttonClass = "btn-inprogress";
        } else {
          buttonClass = "btn-completed";
        }
        return (
          <button className={buttonClass} value={buttonValue}>
            {buttonValue}
          </button>
        );
      },
      width: "13%",
      center: true,
      style: {
        cursor: "default",
      },
    },
    {
      name: "MODIFIED",
      selector: (row: any) => row.modified,
      center: true,
      width: "15%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "SCORE",
      selector: (row: any) => row.score,
      center: true,
      width: "11%",
      cell: (row: any) => {
        if (row.status === "completed" && row.assessment_level === 1) {
          return row.score;
        } else {
          return "";
        }
      },
      style: {
        cursor: "default",
      },
    },
    {
      name: "Action",
      // Assuming you have a unique identifier for each row (e.g., row.id)
      selector: (row: any) => (
        <div>
          <Button
            aria-describedby={row.id} // Use a unique identifier for each row
            onClick={(event) => {
              handleClick(event, row);
            }}
            sx={{ color: "black" }}
          >
            <HiDotsVertical />
          </Button>
        </div>
      ),
      center: true,
      width: "7%",
      style: {
        cursor: "default",
      },
    },
  ];

  const tableProps: IDataTableProps<Assessment> = {
    columns,
    data: currentPageRows,
    pointerOnHover: true,
    pagination: true,
    paginationPerPage: paginationPerPage,
    paginationRowsPerPageOptions: paginationRowsPerPageOptions,
    paginationComponent: () => (
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 0px",
        }}
      >
        <Box sx={{ marginTop: "10px", fontSize: "20px !important" }}>
          Showing {(page - 1) * paginationPerPage + 1}-
          {Math.min(page * paginationPerPage)} of {totalRows} entries
        </Box>
        <Pagination
          sx={{
            "& .Mui-selected": {
              backgroundColor: "#FED74F !important",
              color: "#000",
              borderRadius: "5px",
              fontSize: "16px",
            },
          }}
          count={totalRows ? Math.ceil(totalRows / paginationPerPage) : 0}
          page={page}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component={Button}
              sx={{ fontSize: "20px" }}
            />
          )}
        />
      </Box>
    ),
    onChangeRowsPerPage: handleRowsPerPageChange,
    subHeader: true,
    subHeaderComponent: (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex">
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Show
            </Typography>
            <Select
              value={paginationPerPage}
              onChange={handleRowsPerPageChange}
              style={{ marginRight: "20px" }}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent !important", // Hide the root border
                  },
                  "&:hover fieldset, &.Mui-focused fieldset": {
                    borderColor: "transparent !important", // Hide the border on hover and focus
                  },
                },
                "& .MuiSelect-icon": {
                  color: "#FED74F",
                  border: "1px solid #FED74F",
                  borderRadius: "50px",
                },
                "& .MuiSelect-select": {
                  borderColor: "#FED74F !important",
                  border: "2px solid #FED74F !important",
                  borderRadius: "50px !important",
                  padding: "14px 20px",
                  "&:hover, &:focus": {
                    borderColor: "#FED74F !important",
                    border: "2px solid #FED74F !important",
                  },
                },
                width: { xs: "80%", md: "40%" },
              }}
            >
              {paginationRowsPerPageOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: Colors.button.primary_bg,
                    },
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Entries{" "}
            </Typography>
            <Box mt={1.5} ml={4} sx={{ color: "green" }}>
              {searchLoading ? "Please wait.." : ""}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputSerch
            id="input-with-icon-textfield"
            placeholder="Search"
            value={searchText || ""}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    ),
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {loading ? (
            <Loader isLoading={loading} />
          ) : (
            <DataTable
              key={paginationPerPage}
              {...tableProps}
              className="table-style"
              customStyles={customStyles}
              conditionalRowStyles={[
                {
                  when: (row) => row.index % 2 === 0,
                  style: {
                    backgroundColor: "#fffcf1",
                  },
                },
                {
                  when: (row) => row.index % 2 !== 0,
                  style: {
                    backgroundColor: "#fdf4da",
                  },
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
      <Popover
        id={rowList?.id} // Use a unique identifier for each row
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorReference="anchorEl"
        sx={{ width: "200px" }}
      >
        <Box
          sx={{
            background: "#fffcf1 !important",
            padding: "10px",
            width: "150px",
          }}
        >
          <Box>
            <Box
              className="action-hover"
              onClick={
                rowList?.action === "REPORT"
                  ? handleClickSSP
                  : handleClickAction
              }
            >
              {rowList?.action === "START" && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>Start</Box>
                  <Box pt={0.5}>
                    <IoIosPlay />
                  </Box>
                </Box>
              )}

              {rowList?.action === "RESUME" && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>Resume</Box>
                  <Box pt={0.5}>
                    <AiOutlinePause />
                  </Box>
                </Box>
              )}

              {rowList?.action === "REPORT" && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>Report</Box>
                  <Box pt={0.5}>
                    <img
                      src={ReportIcon}
                      alt="poam"
                      style={{ width: "13px", height: "15px" }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            onClick={handleDelete}
            display="flex"
            justifyContent="space-between"
            className="action-hover"
            alignItems="center"
          >
            <Box>Delete</Box>
            <Box pt={0.5}>
              <MdOutlineDeleteOutline
                style={{ fontSize: "20px", marginRight: "-4px" }}
              />
            </Box>
          </Box>
          {rowList?.action?.toUpperCase() === "REPORT" && (
            <>
              <Box
                onClick={handleClickActionView}
                className="action-hover"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>View</Box>
                <Box pt={0.5}>
                  <MdRemoveRedEye
                    style={{ fontSize: "20px", marginRight: "-4px" }}
                  />
                </Box>
              </Box>

              <Box
                onClick={handleCertificate}
                className="action-hover"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>Certificate</Box>
                <Box pt={0.5}>
                  <GrCertificate />
                </Box>
              </Box>
              <Box
                onClick={handlePoam}
                className="action-hover"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>Poam</Box>
                <Box>
                  <img
                    src={PoamIcon}
                    alt="poam"
                    style={{ width: "15px", height: "15px" }}
                  />
                </Box>
              </Box>
            </>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            className="action-hover"
            alignItems="center"
          >
            <Collaboration
              title="Assesment"
              viewChecked={viewChecked}
              writeChecked={writeChecked}
              handleWriteChange={handleWriteChange}
              handleViewChange={handleViewChange}
              personName={personName}
              open={open}
              handleOpen={handleModalOpen}
              handleClose={handleCloseModalCollaboration}
              handleChangeSelect={handleAutocompleteChange}
              handleInputChange={handleInputChange}
              loader={loader}
              viewResource={false}
              handleSubmit={handleAssignAssignment}
              iconOnly={true}
              buttonTitle="Collaboration"
              userPermissions={userPermissions}
              listLoader={listLoader}
              handleClickToggle={handleClickToggle}
              handleDeletePermission={handleDeletePermission}
              loaderButton={submitLoader}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default DataTableComponent;

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#FED74F",
      color: "#000",
      border: "none",
      padding: "5px 10px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
    },
  },
  rows: {
    style: {
      backgroundColor: "white", // change body background color
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
      borderBottom: "none !important",
      // add media query for mobile
      "@media (max-width: 767px)": {
        fontSize: "10px !important",
      },
    },
  },
  cells: {
    style: {
      padding: "15px 5px !important",
    },
  },
};
