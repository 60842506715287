import React, { FunctionComponent } from "react";
import {
  Box,
  Modal,
  Button,
  Chip,
  FormControl,
  Divider,
  Input,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Text, CustomeButton } from "shared";
import { Colors } from "theme/color";
import { RxCrossCircled } from "react-icons/rx";
import { FcCollaboration } from "react-icons/fc";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  overflowY: "auto",
  maxHeight: "87vh",
  paddingBottom: "15px",
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "#fff",
    boxShadow: theme.shadows[3],
    fontSize: 13,
    letterSpacing: "0.2px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000 !important", // Set the arrow color to white
  },
}));

const StyledFormControl = styled(FormControl)`
  gap: 8px;
  width: 95%;
  border: 2px solid #fed74f;
  padding: 10px;
  border-radius: 5px;

  & > div.container {
    gap: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  & > div.container > span {
    background-color: gray;
    padding: 1px 3px;
    border-radius: 4px;
  }

  & > div.MuiInput-root {
    flex: 1;
  }

  & > div.MuiInput-root > input {
    width: 100% !important;
    min-width: 40%;

    /* Hide bottom border on xs screens */
    @media (max-width: 600px) {
      border-bottom: none;
    }
  }
`;

type Props = {
  width?: string;
  currValue: any;
  emailError: any;
  handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  values?: any;
  handleDelete: // eslint-disable-next-line no-unused-vars
  (item: any, index: any) => void;
  handleKeyUp: // eslint-disable-next-line no-unused-vars
  (e: any) => void;
  onSubmit?: () => void;
  open?: any;
  handleClose: () => void;
  handleOpen: () => void;
  inviteDisable?: any;
};

const InviteUser: FunctionComponent<Props> = ({
  width = "100%",
  emailError,
  currValue,
  handleChange,
  handleDelete,
  handleKeyUp,
  values,
  onSubmit,
  open,
  handleClose,
  handleOpen,
  inviteDisable,
}: Props) => {
  return (
    <Box>
      <LightTooltip
        arrow
        placement="bottom"
        title="click here to invite user's"
      >
        <Box
          style={{
            width: width,
          }}
        >
          <Button
            aria-label="collaboration"
            onClick={handleOpen}
            sx={{
              background: Colors.text.navy_orange,
              textTransform: "capitalize",
              color: Colors.text.black,
            }}
            variant="contained"
            endIcon={<FcCollaboration />}
          >
            Invite User
          </Button>
        </Box>
      </LightTooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent sx={style}>
          <Header
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text fontSize="15px" fontWeight="600" color={Colors.text.black}>
              Invite Users to share feature
            </Text>
            <RxCrossCircled
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </Header>
          <Divider />
          <Box p={2}>
            <Box display="flex" justifyContent="center" mb={2}>
              <Text color={Colors.text.green}>
                Enter Space button to add user email
              </Text>
            </Box>
            <StyledFormControl>
              <div className={"container"}>
                {values?.map((item: any, index: any) => (
                  <Chip
                    key={index}
                    size="small"
                    onDelete={
                      handleDelete ? () => handleDelete(item, index) : undefined
                    }
                    label={item}
                  />
                ))}
              </div>
              <Input
                value={currValue}
                onChange={handleChange}
                onKeyDown={handleKeyUp}
              />
            </StyledFormControl>
            {emailError && <div className="error-message">{emailError}</div>}
          </Box>
          <CustomeButton
            title="Submit"
            width="120px"
            fontWeight="500"
            onClick={onSubmit}
            disabled={inviteDisable ? inviteDisable : false}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InviteUser;
