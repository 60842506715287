import React, { FunctionComponent } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { TaskTable } from "shared";
import { ControlExplanation } from "utils/sharedStyled";
import { Colors } from "theme/color";
import { BsInfoSquareFill } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
  borderBottom: "1px solid grey",
}));

const TitleBox = styled(AccordionSummary)(({ theme }) => ({
  background: "#fff",
  margin: theme.spacing(0),
  color: "#000",
  borderLeft: "1px solid grey",
  borderRight: "1px solid grey",
}));

const AccordionBox = styled(Accordion)(({ theme }) => ({
  background: "#fff",
  margin: theme.spacing(0),
  color: "#000",
  boxShadow: "none",
}));

const AccordionTitle = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  paddingRight: theme.spacing(0),
}));

const AssessmentTitle = styled(Box)({
  width: "100% !important",
});

const QuestionTitle = styled(Typography)({
  fontSize: "20px",
  fontWeight: "500",
  paddingLeft: "0px",
});

const StyledSpan = styled("span")({
  fontSize: "22px",
  fontWeight: "700",
  paddingRight: "10px",
});

// Add a new styled component for the icon
const ExpandIcon = styled("span")({
  fontSize: "22px",
  fontWeight: "700",
  cursor: "pointer",
  paddingLeft: "10px",
});

const IconContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
}));

const PenaltyContainer = styled("span")({
  fontSize: "16px",
  fontWeight: "500",
  cursor: "pointer",
  padding: "10px 15px",
  background: `${Colors.text.theme}`,
  color: `${Colors.text.black}`,
  borderRadius: "20px",
  marginLeft: "10px",
  whiteSpace: "nowrap",
});

const Penalty = styled("span")({
  fontSize: "20px",
  fontWeight: "700",
  cursor: "pointer",
  padding: "5px 10px",
  color: `${Colors.text.black}`,
  borderRadius: "20px",
});

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const HeaderModal = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
}));

type Props = {
  data: any;
  index: any;
  accordions?: any;
  assessmentId?: any;
  notMet?: any;
  met?: any;
  notInterested?: any;
  objectiveStatuses?: any;
  levelId?: any;
  updateCompletedQueries: // eslint-disable-next-line no-unused-vars
  (change: number) => void;
  viewFlag?: boolean;
  phaseCardEmail?: any;
  notMerSwitchColor?: any;
  weightFlag?: any;
  hideObjectiveTitle?: any;
  showCommentsColumn?: any;
  assessmentTypeCurrent?: any;
  handleOpen?: // eslint-disable-next-line no-unused-vars
  (data: any, securityRequirementsId: any, htmlID: any) => void;
};

const AssessmentTable: FunctionComponent<Props> = ({
  data: item,
  assessmentId: assessmentId,
  levelId: levelId,
  updateCompletedQueries,
  viewFlag,
  phaseCardEmail,
  notMerSwitchColor,
  met,
  notMet,
  weightFlag,
  hideObjectiveTitle,
  showCommentsColumn,
  assessmentTypeCurrent,
  handleOpen,
}: Props) => {
  if (!item) {
    return <div>No data found</div>;
  }

  const [expanded, setExpanded] = React.useState(
    Array.from(Array(item.securityRequirements.length).keys())
  );

  const handleChange =
    (index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (isExpanded) {
        setExpanded([...expanded, index]); // Add the clicked index to the expanded array
      } else {
        setExpanded(expanded.filter((i) => i !== index)); // Remove the clicked index from the expanded array
      }
    };

  return (
    <>
      {item.securityRequirements?.map((item: any, index: any) => {
        return (
          <Container key={index}>
            <AccordionBox key={index} expanded={expanded.includes(index)}>
              <TitleBox
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                {hideObjectiveTitle ? (
                  <AccordionTitle>
                    <AssessmentTitle>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <QuestionTitle width="95%">
                          {" "}
                          <StyledSpan>{item.controlNumber}</StyledSpan>
                          {item.controlRequirement &&
                          item.controlRequirement.trim().endsWith(".")
                            ? item.controlRequirement // If it ends with a full stop, leave it as is
                            : `${item.controlRequirement.trim()}.`}
                          {weightFlag == true && (
                            <PenaltyContainer>
                              Weight: <Penalty>{item.penalty}</Penalty>
                            </PenaltyContainer>
                          )}
                          <ControlExplanation
                            title={
                              <Box
                                component="span"
                                style={{
                                  lineHeight: "normal",
                                  fontSize: "16px",
                                  whiteSpace: "pre-line",
                                  textAlign: "justify",
                                  letterSpacing: 1,
                                }}
                              >
                                <Box
                                  component="span"
                                  display="flex"
                                  justifyContent="end"
                                  mb={2}
                                >
                                  {item.controlDescription &&
                                    item.controlDescription.length > 0 && (
                                      <IconButton
                                        aria-label="information"
                                        sx={{
                                          backgroundColor: Colors.text.theme,
                                          "&:hover": {
                                            backgroundColor: Colors.text.theme,
                                          },
                                        }}
                                        onClick={() => {
                                          if (handleOpen) {
                                            handleOpen(
                                              item.controlDescription,
                                              item.securityRequirementsId,
                                              item.controlDescriptionID
                                            );
                                          }
                                        }}
                                      >
                                        <FaRegEdit
                                          style={{
                                            color: Colors.text.black,
                                            fontSize: "16px",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                </Box>
                                <Box
                                  component="span"
                                  dangerouslySetInnerHTML={{
                                    __html: item.controlDescription,
                                  }}
                                />
                              </Box>
                            }
                          >
                            <IconButton aria-label="information">
                              <BsInfoSquareFill
                                style={{
                                  color: Colors.text.info,
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>
                          </ControlExplanation>
                        </QuestionTitle>

                        <IconContainer width="4%">
                          <Box display="flex" justifyContent="end" width="100%">
                            <ExpandIcon
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent accordion from expanding/collapsing
                                handleChange(index)(
                                  e as any,
                                  !expanded.includes(index)
                                );
                              }}
                            >
                              {expanded.includes(index) ? (
                                <AiOutlineMinus color="black" />
                              ) : (
                                <AiOutlinePlus color="black" />
                              )}
                            </ExpandIcon>
                          </Box>
                        </IconContainer>
                      </Box>
                    </AssessmentTitle>
                  </AccordionTitle>
                ) : (
                  <AccordionTitle>
                    <AssessmentTitle>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <QuestionTitle width="95%">
                          {" "}
                          <StyledSpan>{item.controlNumber}</StyledSpan>
                          {item.controlRequirement &&
                          item.controlRequirement.trim().endsWith(".")
                            ? item.controlRequirement // If it ends with a full stop, leave it as is
                            : `${item.controlRequirement.trim()}.`}
                          {weightFlag == true && (
                            <PenaltyContainer>
                              Weight: <Penalty>{item.penalty}</Penalty>
                            </PenaltyContainer>
                          )}
                          <ControlExplanation
                            title={
                              <p
                                style={{
                                  lineHeight: "normal",
                                  fontSize: "15px",
                                  whiteSpace: "pre-line",
                                  textAlign: "justify",
                                  letterSpacing: 1,
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.controlDescription,
                                  }}
                                />
                              </p>
                            }
                          >
                            <IconButton aria-label="information">
                              <BsInfoSquareFill
                                style={{
                                  color: Colors.text.info,
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>
                          </ControlExplanation>
                        </QuestionTitle>

                        <IconContainer width="4%">
                          <Box display="flex" justifyContent="end" width="100%">
                            <ExpandIcon
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent accordion from expanding/collapsing
                                handleChange(index)(
                                  e as any,
                                  !expanded.includes(index)
                                );
                              }}
                            >
                              {expanded.includes(index) ? (
                                <AiOutlineMinus color="black" />
                              ) : (
                                <AiOutlinePlus color="black" />
                              )}
                            </ExpandIcon>
                          </Box>
                        </IconContainer>
                      </Box>
                    </AssessmentTitle>
                  </AccordionTitle>
                )}
              </TitleBox>
              <AccordionDetails sx={{ padding: "0px !important" }}>
                <TaskTable
                  data={item}
                  assesmtId={assessmentId}
                  levelId={levelId}
                  updateCompletedQueries={updateCompletedQueries}
                  viewFlag={viewFlag}
                  phaseCardEmail={phaseCardEmail}
                  notMet={notMet}
                  met={met}
                  notMerSwitchColor={notMerSwitchColor}
                  weightFlag={weightFlag}
                  hideObjectiveTitle={hideObjectiveTitle}
                  showCommentsColumn={showCommentsColumn}
                  assessmentTypeCurrent={assessmentTypeCurrent}
                />
              </AccordionDetails>
            </AccordionBox>
          </Container>
        );
      })}
    </>
  );
};

export default AssessmentTable;
