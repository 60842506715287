import React, { FunctionComponent } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  IconButton,
} from "@mui/material";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { SSPTable } from "shared";
import { ControlExplanation } from "utils/sharedStyled";
import { Colors } from "theme/color";
import { BsInfoSquareFill } from "react-icons/bs";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
  borderBottom: "1px solid grey",
}));

const TitleBox = styled(AccordionSummary)(({ theme }) => ({
  background: "#fff",
  margin: theme.spacing(0),
  color: "#000",
  borderLeft: "1px solid grey",
  borderRight: "1px solid grey",
}));

const AccordionBox = styled(Accordion)(({ theme }) => ({
  background: "#fff",
  margin: theme.spacing(0),
  color: "#000",
  boxShadow: "none",
}));

const AccordionTitle = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  paddingRight: theme.spacing(0),
}));

const AssessmentTitle = styled(Box)({
  width: "100% !important",
});

const QuestionTitle = styled(Box)({
  fontSize: "20px",
  fontWeight: "500",
  paddingLeft: "0px",
});

const StyledSpan = styled("span")({
  fontSize: "22px",
  fontWeight: "700",
  paddingRight: "10px",
});

const IconContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
}));

// Add a new styled component for the icon
const ExpandIcon = styled("span")({
  fontSize: "22px",
  fontWeight: "700",
  cursor: "pointer",
  paddingLeft: "10px",
});

type Props = {
  data: any;
  index: any;
  accordions?: any;
  assessmentId?: any;
  notMet?: any;
  met?: any;
  notInterested?: any;
  objectiveStatuses?: any;
  levelId?: any;
  notMerSwitchColor?: any;
  objectTooltip?: boolean;
  showSubCategoryComment?: boolean;
  hideControlRequirement?: boolean;
  assessmentTypeCurrent?: any;
};

const AssessmentTable: FunctionComponent<Props> = ({
  data: item,
  notInterested,
  notMet,
  met,
  notMerSwitchColor,
  objectTooltip,
  showSubCategoryComment,
  hideControlRequirement,
  assessmentTypeCurrent,
}: Props) => {
  if (!item) {
    return <div>No data found</div>;
  }

  const [expanded, setExpanded] = React.useState(
    Array.from(Array(item?.securityRequirements?.length).keys())
  );

  const handleChange =
    (index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (isExpanded) {
        setExpanded([...expanded, index]); // Add the clicked index to the expanded array
      } else {
        setExpanded(expanded.filter((i) => i !== index)); // Remove the clicked index from the expanded array
      }
    };

  return (
    <>
      {item.securityRequirements?.map((item: any, index: any) => {
        return (
          <Container key={index}>
            <AccordionBox key={index} expanded={expanded.includes(index)}>
              <TitleBox
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                {objectTooltip ? (
                  <AccordionTitle>
                    <AssessmentTitle>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <QuestionTitle width="95%">
                          {" "}
                          <StyledSpan>{item.controlNumber}</StyledSpan>
                          {item.controlRequirement}
                          <ControlExplanation
                            title={
                              <p
                                style={{
                                  lineHeight: "normal",
                                  fontSize: "15px",
                                  whiteSpace: "pre-line",
                                  textAlign: "justify",
                                  letterSpacing: 1,
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.controlDescription,
                                  }}
                                />
                              </p>
                            }
                          >
                            <IconButton aria-label="information">
                              <BsInfoSquareFill
                                style={{
                                  color: Colors.text.info,
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>
                          </ControlExplanation>
                        </QuestionTitle>

                        <IconContainer width="4%">
                          <Box display="flex" justifyContent="end" width="100%">
                            <ExpandIcon
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent accordion from expanding/collapsing
                                handleChange(index)(
                                  e as any,
                                  !expanded.includes(index)
                                );
                              }}
                            >
                              {expanded.includes(index) ? (
                                <AiOutlineMinus color="black" />
                              ) : (
                                <AiOutlinePlus color="black" />
                              )}
                            </ExpandIcon>
                          </Box>
                        </IconContainer>
                      </Box>
                    </AssessmentTitle>
                  </AccordionTitle>
                ) : (
                  <AccordionTitle>
                    <AssessmentTitle>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <QuestionTitle width="95%">
                          {" "}
                          <StyledSpan>{item.controlNumber}</StyledSpan>
                          {item.controlRequirement}
                          <ControlExplanation
                            title={
                              <p
                                style={{
                                  lineHeight: "normal",
                                  fontSize: "15px",
                                  whiteSpace: "pre-line",
                                  textAlign: "justify",
                                  letterSpacing: 1,
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.controlDescription,
                                  }}
                                />
                              </p>
                            }
                          >
                            <IconButton aria-label="information">
                              <BsInfoSquareFill
                                style={{
                                  color: Colors.text.info,
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>
                          </ControlExplanation>
                        </QuestionTitle>

                        <IconContainer width="4%">
                          <Box display="flex" justifyContent="end" width="100%">
                            <ExpandIcon
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent accordion from expanding/collapsing
                                handleChange(index)(
                                  e as any,
                                  !expanded.includes(index)
                                );
                              }}
                            >
                              {expanded.includes(index) ? (
                                <AiOutlineMinus color="black" />
                              ) : (
                                <AiOutlinePlus color="black" />
                              )}
                            </ExpandIcon>
                          </Box>
                        </IconContainer>
                      </Box>
                    </AssessmentTitle>
                  </AccordionTitle>
                )}
              </TitleBox>
              <AccordionDetails sx={{ padding: "0px !important" }}>
                <SSPTable
                  data={item}
                  disabled={true}
                  assessmentObjective="Control Questions"
                  readOnly={true}
                  notInterested={notInterested}
                  notMet={notMet}
                  met={met}
                  notMerSwitchColor={notMerSwitchColor}
                  objectTooltip={objectTooltip}
                  showSubCategoryComment={showSubCategoryComment}
                  hideControlRequirement={hideControlRequirement}
                  assessmentTypeCurrent={assessmentTypeCurrent}
                />
              </AccordionDetails>
            </AccordionBox>
          </Container>
        );
      })}
    </>
  );
};

export default AssessmentTable;
