import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "100vh",
}));
