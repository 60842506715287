import * as React from "react";
import {
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";

import { Box, IconButton, Input, Link } from "@mui/material";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { LowPriority, getSelectedNode } from "./LexicalEditorUtils";
import { useCallback, useEffect, useRef, useState } from "react";

function positionEditorElement(editor: HTMLDivElement, rect: DOMRect | null) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

export function LexicalEditorFloatingLinkEditor({
  editor,
}: {
  editor: LexicalEditor;
}) {
  const editorRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState<
    null | RangeSelection | NodeSelection
  >(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection?.isCollapsed &&
      rootElement !== null &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error dom-types
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection?.getRangeAt(0);
      let rect: DOMRect | null;
      if (nativeSelection?.anchorNode === rootElement) {
        let inner: Element = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange?.getBoundingClientRect() ?? null;
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(null);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <Box
      ref={editorRef}
      style={{
        position: "absolute",
        zIndex: 2000,
        top: "-10000px",
        marginLeft: "370px",
        marginTop: "6px",
        width: "50%",
        opacity: "0",
        backgroundColor: " #fff",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)",
        borderRadius: "8px",
        transition: "opacity 0.5s",
      }}
    >
      {isEditMode ? (
        <Input
          ref={inputRef}
          style={{
            display: "block",
            width: "calc(100% - 24px)",
            boxSizing: "border-box",
            margin: "8px 12px",
            padding: "8px 12px",
            borderRadius: "15px",
            backgroundColor: "#EDF2F6",
            fontSize: "15px",
            color: "rgb(5, 5, 5)",
            border: "0",
            outline: "0",
            position: "relative",
            fontFamily: "inherit",
            zIndex: 2000,
          }}
          value={linkUrl}
          onChange={(event: any) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <Box
            style={{
              display: "block",
              width: "calc(100% - 24px)",
              boxSizing: "border-box",
              margin: "8px 12px",
              padding: "8px 12px",
              borderRadius: "15px",
              backgroundColor: "#EDF2F6",
              fontSize: "15px",
              color: "rgb(5, 5, 5)",
              border: 0,
              outline: 0,
              position: "relative",
              fontFamily: "inherit",
              zIndex: 2000,
            }}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
                {linkUrl}
              </Link>
              <IconButton
                onClick={() => setEditMode(true)}
                tabIndex={0}
                onMouseDown={(event) => event.preventDefault()}
                aria-label="edit"
              >
                <EditLocationIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
