import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, Card } from "@mui/material";

export const Title = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const SharedCard = styled(Card)({
  filter: "drop-shadow(0px 0px 5px grey)",
  borderRadius: "15px",
});
