import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const InputContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "78%",
  bottom: "50px",
  background: "#fff",
  [theme.breakpoints.down("sm")]: {
    bottom: "50px",
  },
}));

export const List = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "130px",
  width: "80%",
  [theme.breakpoints.down("sm")]: {
    bottom: "10px",
  },
}));

export const BorderBox = styled(Box)(() => ({
  border: "1px solid grey",
  borderRadius: "5px",
  padding: "10px",
}));

export const MessageBox = styled(Box)(() => ({
  width: "90%",
}));
