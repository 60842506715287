import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const PackageAvatar = styled("img")({
  width: "60%",
  height: "310px",
});

export const Alignment = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "85vh",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "90vh",
  },
}));
