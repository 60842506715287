import React, { FunctionComponent } from "react";
import { Text, Loader } from "shared";
import { Box, Grid } from "@mui/material";
import EmailVerifyImage from "assets/email.png";
import { UserSelectors } from "reducers/user";
import { CardVerification } from "utils/sharedStyled";
import { ImageContainer } from "./style";

type Props = Record<string, never>;

const EmailResetPassword: FunctionComponent<Props> = () => {
  const { loading } = UserSelectors();

  if (loading) {
    return (
      <p>
        {" "}
        <Loader isLoading={loading} />
      </p>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="container-bg">
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <Box
              p={{ xs: 2, sm: 2, md: 6, lg: 0 }}
              mt={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              ml={{ xs: 0, sm: 0, md: 0, lg: 12 }}
              mr={{ xs: 0, sm: 0, md: 0, lg: 12 }}
            >
              <CardVerification>
                <Box p={{ xs: 2, sm: 2, md: 4, lg: 6 }}>
                  <Box display={{ xs: "none", md: "block", lg: "block" }}>
                    <Text
                      fontSize="40px"
                      fontWeight="700"
                      align="center"
                      variant="h2"
                    >
                      Reset Your Password !
                    </Text>
                  </Box>
                  <ImageContainer
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    {" "}
                    <img src={EmailVerifyImage} alt="img" />
                  </ImageContainer>

                  <Box>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={5} lg={5}>
                        <Box>
                          <Text
                            fontSize="18px"
                            fontWeight="600"
                            align="center"
                            variant="subtitle1"
                          >
                            Please check your email to reset your password
                          </Text>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardVerification>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EmailResetPassword;
