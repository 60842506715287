import React from "react";
import { BeatLoader } from "react-spinners";

interface LoaderProps {
  isLoading: boolean;
  height?: any;
}

const Loader: React.FC<LoaderProps> = ({ isLoading, height }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: height ? height : "100vh",
        }}
      >
        {isLoading && (
          <div className="sweet-loading">
            <BeatLoader color={"#123abc"} size={10} />
            <p>Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Loader;
