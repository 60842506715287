import React, { FunctionComponent, useState, useEffect } from "react";
import { DashboardWrapper, Text, Button, Loader } from "shared";
import { Box } from "@mui/material";
import { Colors } from "theme/color";
import useAppDispatch from "hooks/useAppDispatch";
import { getAssessmentLevels, setId } from "reducers/assessment";
import { useNavigate, useLocation } from "react-router-dom";
import { SharedCard, Title } from "./style";

type Props = Record<string, never>;
interface AssessmentLevel {
  pk: number;
  name: string;
  description: string;
}

const StartAssessment: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [levels, setLevels] = useState<AssessmentLevel[]>([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setLoading(true);
    setDisabled(true);
    dispatch(getAssessmentLevels())
      .unwrap()
      .then((data) => {
        setLevels(data.response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  }, [dispatch]);

  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const assessmentId = parts[3];
  const handleStartAssessment = (pk: number) => {
    // Navigate to the URL /assessment/level-qa/${pk}
    const object = {
      id: pk,
      assessmentId: assessmentId,
    };
    dispatch(setId(object));
    navigate(`${pk}`);
  };

  return (
    <DashboardWrapper>
      {loading ? (
        <Box>
          <Loader isLoading={loading} />
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            pl: { xs: 0, sm: 0, md: 8 },
            pr: { xs: 0, sm: 0, md: 8 },
          }}
          mt={5}
        >
          <Text fontSize="36px" fontWeight="700" className="text-mobile">
            START ASSESSMENT
          </Text>

          {levels.map((level) => (
            <Box key={level.pk} mt={5}>
              <SharedCard>
                <Title>
                  <Text
                    color={Colors.text.white}
                    fontWeight="700"
                    fontSize="20px"
                    align="center"
                  >
                    {level.name}
                  </Text>
                </Title>
                <Box pt={12} pb={12}>
                  <Button
                    title={loading ? "Loading..." : "Start Assessment"}
                    width="250px"
                    borderRadius="50px"
                    fontWeight="600"
                    fontSize="18px"
                    onClick={() => handleStartAssessment(level.pk)}
                    disabled={disabled}
                  />
                </Box>
              </SharedCard>
            </Box>
          ))}
        </Box>
      )}
    </DashboardWrapper>
  );
};
export default StartAssessment;
