import React, { useState, useCallback } from "react";
import DataTable, { IDataTableProps } from "react-data-table-component";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  InputAdornment,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  Button,
  Popover,
  Modal,
  SelectChangeEvent,
} from "@mui/material";

import { MdOutlineDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";

import { FiSearch } from "react-icons/fi";
import { Colors } from "theme/color";
import { CustomeButton, Loader, Logo, Input } from "shared";
import { HiDotsVertical } from "react-icons/hi";
import { RxCrossCircled } from "react-icons/rx";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  fetchAssetList,
  updateAsset,
  fetchCategory,
} from "reducers/assessment";
import swal from "sweetalert";
import useAppDispatch from "hooks/useAppDispatch";
import { InputSerch } from "utils/sharedStyled";
const AssesmentSchema = Yup.object().shape({
  assetQuantity: Yup.string()
    .required("Asset Quantity is required")
    .max(50, "Too Long!")
    .matches(/^[0-9]+$/, "Asset Quantity must be a number"),
});

const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  overflowY: "auto",
  maxHeight: "87vh",
  paddingBottom: "15px",
  width: "60%",
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for smaller screens
  },
};

interface Evidence {
  id?: number;
  category?: string;
  asset?: string;
  assetQuantity?: any;
  assetType?: string;
  description?: any;
  comment?: string;
  ssp?: string;
  levels?: any;
  index?: any;
}

interface Props {
  evidence?: Evidence[];
  totalRows?: number;
  paginationRowsPerPageOptions: number[];
  pages?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  paginationPerPages?: number;
  handlePageChange?: (
    // eslint-disable-next-line no-unused-vars
    event: React.ChangeEvent<unknown>,
    // eslint-disable-next-line no-unused-vars
    page: number
  ) => void | undefined;
  // eslint-disable-next-line no-unused-vars
  handleRowsPerPageChange?: (event: any) => void;
  searchTexts?: string;
  handleSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  handleDelete?: () => void;
  selectedObjectIdRef: React.MutableRefObject<number | null>;
  openDeleteDialogs?: boolean;
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
  searchLoading?: boolean;
}

const DataTableComponent: React.FC<Props> = ({
  evidence,
  totalRows,
  paginationRowsPerPageOptions,
  pages,
  paginationPerPages,
  handlePageChange,
  handleRowsPerPageChange,
  handleSearch,
  searchTexts,
  loading,
  selectedObjectIdRef,
  handleDelete,
  searchLoading,
}) => {
  const page = pages ? pages : 0;
  const paginationPerPage = paginationPerPages ? paginationPerPages : 0;

  const searchText = searchTexts ? searchTexts : "";

  const currentPageRows = evidence?.length ? evidence : [];
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const dispatch = useAppDispatch();

  const categoryListData = useSelector(
    (state: RootState) => state.assessment.categoryListData
  );

  const [rowList, setRowList] = useState<any>();
  const [assetCategory, setAssetCategory] = useState("0");
  const [assetType, setAssestType] = useState("0");
  const [id, setId] = useState<number>();

  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
    setAssetCategory("0");
    setAssestType("0");
  };

  // select assessment level handle change
  const handleSelectAssetType = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    setAssestType(selectedValue);
  };

  // select assessment type handle
  const handleSelect = (event: SelectChangeEvent) => {
    setAssetCategory(event.target.value);
  };

  const handleAsset = useCallback(
    async (values: {
      assetQuantity: string;
      assetDescription: string;
      assets: string;
      comment: string;
      controlledBy: string;
      poc: string;
      cuiCategory: string;
      distributionControl: string;
    }) => {
      try {
        if (Number(assetCategory) === 0) {
          swal({
            title: "Asset Inventory",
            text: "Please select Asset Category.",
            icon: "warning",
          });
          return;
        }

        const type =
          Number(assetType) === 1
            ? "people"
            : Number(assetType) === 2
            ? "technology"
            : Number(assetType) === 3
            ? "facilities"
            : "people";
        await dispatch(
          updateAsset({
            id: id,
            category: assetCategory,
            asset: values.assets,
            quantity: values.assetQuantity,
            assetType: type,
            assetDescription: values.assetDescription,
            comment: values.comment,
            controlledBy: values.controlledBy,
            poc: values.poc,
            cuiCategory: values.cuiCategory,
            distributionControl: values.distributionControl,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Asset Inventory",
              text: "Asset Inventory Updated Successfully !",
              icon: "success",
            });
            setOpen(false);
            dispatch(
              fetchAssetList({
                page: page,
                limit: paginationPerPage,
                search: searchText,
              })
            )
              .unwrap()
              .then(() => {})
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Asset Inventory",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Asset Inventory",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    },
    [dispatch, page, paginationPerPage, assetCategory, assetType]
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    objectId: any
  ) => {
    setAnchorEl(event.currentTarget);
    setId(objectId.id);
    setRowList(objectId);
    selectedObjectIdRef.current = objectId.id;

    switch (objectId?.assetType) {
      case "people":
        setAssestType("1");
        break;
      case "technology":
        setAssestType("2");
        break;
      case "facility":
        setAssestType("3");
        break;
      default:
        setAssestType("0");
        break;
    }

    switch (objectId?.category) {
      case "Controlled Unclassified Information (CUI) Assets":
        setAssetCategory("1");
        break;
      case "Security Protection Assets":
        setAssetCategory("2");
        break;
      case "Contractor Risk Managed Assets":
        setAssetCategory("3");
        break;
      case "Specialized Assets":
        setAssetCategory("4");
        break;
      case "Out-of-Scope Assets":
        setAssetCategory("5");
        break;
      default:
        setAssetCategory("0");
        break;
    }
  };

  const handleUpdateClick = () => {
    dispatch(fetchCategory())
      .then(() => {
        setOpen(true);
        setAnchorEl(null);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns: any[] = [
    {
      name: "Asset Category",
      selector: (row: any) => row.category,
      center: true,
      width: "15%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "Asset",
      selector: (row: any) => row.asset,
      center: true,
      width: "15%",
      style: {
        cursor: "default",
        wordBreak: "break-all",
      },
    },
    {
      name: "Asset Quantity",
      selector: (row: any) => row.assetQuantity,
      center: true,
      width: "15%",
      style: {
        cursor: "default",
      },
    },

    {
      name: "Asset type",
      selector: (row: any) => row.assetType,
      center: true,
      width: "15%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "Asset Description",
      selector: (row: any) => row.description,
      center: true,
      width: "15%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "Comment",
      selector: (row: any) => row.comment,
      center: true,
      width: "15%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "Action",
      // Assuming you have a unique identifier for each row (e.g., row.id)
      selector: (row: any) => (
        <div>
          <Button
            aria-describedby={row.id} // Use a unique identifier for each row
            onClick={(event) => {
              handleClick(event, row);
            }}
            sx={{ color: "black" }}
          >
            <HiDotsVertical />
          </Button>
          <Popover
            id={row.id} // Use a unique identifier for each row
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ width: "200px" }}
          >
            <Box
              sx={{
                background: "#fffcf1 !important",
                padding: "10px",
                width: "130px",
              }}
            >
              <Box
                onClick={handleUpdateClick}
                display="flex"
                justifyContent="space-between"
                className="action-hover"
                alignItems="center"
              >
                <Box>Update</Box>
                <Box>
                  <AiTwotoneEdit
                    style={{ fontSize: "20px", marginRight: "-4px" }}
                  />
                </Box>
              </Box>
              <Box
                onClick={handleDelete}
                display="flex"
                justifyContent="space-between"
                className="action-hover"
                alignItems="center"
              >
                <Box>Delete</Box>
                <Box>
                  <MdOutlineDeleteOutline
                    style={{ fontSize: "20px", marginRight: "-4px" }}
                  />
                </Box>
              </Box>
            </Box>
          </Popover>
        </div>
      ),
      center: true,
      width: "10%",
      style: {
        cursor: "default",
      },
    },
  ];

  const tableProps: IDataTableProps<Evidence> = {
    columns,
    data: currentPageRows,
    pointerOnHover: true,
    pagination: true,
    paginationPerPage: paginationPerPage,
    paginationRowsPerPageOptions: paginationRowsPerPageOptions,
    paginationComponent: () => (
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 0px",
        }}
      >
        <Box sx={{ marginTop: "10px", fontSize: "20px !important" }}>
          Showing {(page - 1) * paginationPerPage + 1}-
          {Math.min(page * paginationPerPage)} of {totalRows} entries
        </Box>
        <Pagination
          sx={{
            "& .Mui-selected": {
              backgroundColor: "#FED74F !important",
              color: "#000",
              borderRadius: "5px",
              fontSize: "16px",
            },
          }}
          count={totalRows ? Math.ceil(totalRows / paginationPerPage) : 0}
          page={page}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component={Button}
              sx={{ fontSize: "20px" }}
            />
          )}
        />
      </Box>
    ),
    onChangeRowsPerPage: handleRowsPerPageChange,
    subHeader: true,
    subHeaderComponent: (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex">
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Show
            </Typography>
            <Select
              value={paginationPerPage}
              onChange={handleRowsPerPageChange}
              style={{ marginRight: "20px" }}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent !important", // Hide the root border
                  },
                  "&:hover fieldset, &.Mui-focused fieldset": {
                    borderColor: "transparent !important", // Hide the border on hover and focus
                  },
                },
                "& .MuiSelect-icon": {
                  color: "#FED74F",
                  border: "1px solid #FED74F",
                  borderRadius: "50px",
                },
                "& .MuiSelect-select": {
                  borderColor: "#FED74F !important",
                  border: "2px solid #FED74F !important",
                  borderRadius: "50px !important",
                  padding: "14px 20px",
                  "&:hover, &:focus": {
                    borderColor: "#FED74F !important",
                    border: "2px solid #FED74F !important",
                  },
                },
                width: { xs: "80%", md: "40%" },
              }}
            >
              {paginationRowsPerPageOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: Colors.button.primary_bg,
                    },
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Entries{" "}
            </Typography>
            <Box mt={1.5} ml={4} sx={{ color: "green" }}>
              {searchLoading ? "Please wait.." : ""}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputSerch
            id="input-with-icon-textfield"
            placeholder="Search"
            value={searchText || ""}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    ),
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {loading ? (
            <Loader isLoading={loading} />
          ) : (
            <DataTable
              key={paginationPerPage}
              {...tableProps}
              className="table-style"
              customStyles={customStyles}
              conditionalRowStyles={[
                {
                  when: (row) => row.index % 2 === 0,
                  style: {
                    backgroundColor: "#fffcf1",
                  },
                },
                {
                  when: (row) => row.index % 2 !== 0,
                  style: {
                    backgroundColor: "#fdf4da",
                  },
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent sx={style}>
          <Box display="flex" justifyContent="end" pt={1} pr={1}>
            <RxCrossCircled
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </Box>

          <Box>
            <Logo />
          </Box>

          <Box m={4}>
            <Box>
              <Formik
                initialValues={{
                  assetQuantity: rowList?.assetQuantity,
                  assetDescription: rowList?.description,
                  assets: rowList?.asset,
                  comment: rowList?.comment,
                  controlledBy: rowList?.controlledBy,
                  poc: rowList?.poc,
                  cuiCategory: rowList?.cuiCategory,
                  distributionControl: rowList?.distributionControl,
                }}
                validationSchema={AssesmentSchema}
                onSubmit={handleAsset}
              >
                {({ errors, touched, values, handleChange, handleSubmit }) => (
                  <Box>
                    <Box>
                      <Box p={{ sx: 1, md: 1, lg: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box pt={2}>
                              <Select
                                labelId="demo-select-small"
                                name="assessmentType"
                                id="demo-select-small"
                                value={assetCategory}
                                placeholder="Select"
                                onChange={handleSelect}
                                sx={{
                                  "&.MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent !important", // Hide the root border
                                    },
                                    "&:hover fieldset, &.Mui-focused fieldset":
                                      {
                                        borderColor: "transparent !important", // Hide the border on hover and focus
                                      },
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "#FED74F",
                                    border: "1px solid #FED74F",
                                    borderRadius: "50px",
                                  },
                                  "& .MuiSelect-select": {
                                    borderColor: "#FED74F !important",
                                    border: "2px solid #FED74F !important",
                                    borderRadius: "50px !important",
                                    padding: "20px 20px",
                                    "&:hover, &:focus": {
                                      borderColor: "#FED74F !important",
                                      border: "2px solid #FED74F !important",
                                    },
                                  },
                                  width: { xs: "100%", md: "100%" },
                                }}
                              >
                                <MenuItem
                                  value="0"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Asset category
                                </MenuItem>
                                {categoryListData?.map((category) => (
                                  <MenuItem
                                    key={category.pk}
                                    value={category.pk}
                                  >
                                    {category.category_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Input
                                name="assets"
                                type="text"
                                placeholder="Assets"
                                value={values.assets}
                                onChange={handleChange("assets")}
                              />
                              {errors.assets && touched.assets ? (
                                <div className="error-message">
                                  {errors.assets}
                                </div>
                              ) : null}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Input
                                name="assetQuantity"
                                type="text"
                                placeholder="Asset Quantity"
                                value={values.assetQuantity}
                                onChange={handleChange("assetQuantity")}
                              />
                              {errors.assetQuantity && touched.assetQuantity ? (
                                <div className="error-message">
                                  {errors.assetQuantity}
                                </div>
                              ) : null}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box pt={2}>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={assetType}
                                onChange={handleSelectAssetType}
                                sx={{
                                  "&.MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent !important", // Hide the root border
                                    },
                                    "&:hover fieldset, &.Mui-focused fieldset":
                                      {
                                        borderColor: "transparent !important", // Hide the border on hover and focus
                                      },
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "#FED74F",
                                    border: "1px solid #FED74F",
                                    borderRadius: "50px",
                                  },
                                  "& .MuiSelect-select": {
                                    borderColor: "#FED74F !important",
                                    border: "2px solid #FED74F !important",
                                    borderRadius: "50px !important",
                                    padding: "20px 20px",
                                    "&:hover, &:focus": {
                                      borderColor: "#FED74F !important",
                                      border: "2px solid #FED74F !important",
                                    },
                                  },
                                  width: { xs: "100%", md: "100%" },
                                }}
                              >
                                <MenuItem
                                  value="0"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Asset Type
                                </MenuItem>
                                <MenuItem
                                  value="1"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  People
                                </MenuItem>
                                <MenuItem
                                  value="2"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Technology
                                </MenuItem>
                                <MenuItem
                                  value="3"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Facility
                                </MenuItem>
                              </Select>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Input
                                name="assetDescription"
                                type="text"
                                placeholder="Asset Description"
                                value={values.assetDescription}
                                onChange={handleChange("assetDescription")}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Box>
                                <Input
                                  name="comment"
                                  type="text"
                                  placeholder="Comment (Optional)"
                                  value={values.comment}
                                  onChange={handleChange("comment")}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <>
                                <Box>
                                  <Input
                                    name="controlledBy"
                                    type="text"
                                    placeholder="Controlled By"
                                    value={values.controlledBy}
                                    onChange={handleChange("controlledBy")}
                                  />
                                </Box>
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <>
                                {" "}
                                <Box>
                                  <Input
                                    name="cuiCategory"
                                    type="text"
                                    placeholder="Cui Category"
                                    value={values.cuiCategory}
                                    onChange={handleChange("cuiCategory")}
                                  />
                                </Box>
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <Box>
                                <Input
                                  name="poc"
                                  type="text"
                                  placeholder="Poc"
                                  value={values.poc}
                                  onChange={handleChange("poc")}
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <Box>
                                <Input
                                  name="distributionControl"
                                  type="text"
                                  placeholder="Distribution Control"
                                  value={values.distributionControl}
                                  onChange={handleChange("distributionControl")}
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box pt={3}>
                      <CustomeButton
                        title={"Update"}
                        borderRadius="10px"
                        fontWeight="500"
                        fontSize="18px"
                        align="center"
                        onClick={handleSubmit}
                        width="100px"
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DataTableComponent;

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#FED74F",
      color: "#000",
      border: "none",
      padding: "5px 10px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
    },
  },
  rows: {
    style: {
      backgroundColor: "white", // change body background color
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
      borderBottom: "none !important",
      // add media query for mobile
      "@media (max-width: 767px)": {
        fontSize: "10px !important",
      },
    },
  },
  cells: {
    style: {
      padding: "15px 5px !important",
    },
  },
};
