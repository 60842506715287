import React, { FunctionComponent, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { experimentalStyled as styled } from "@mui/material/styles";
import QRCode from "qrcode";

const QrCodeContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

type Props = {
  align?: "left" | "center" | "right";
  otpauth_url: string | null;
};

const QrCode: FunctionComponent<Props> = ({
  align = "center",
  otpauth_url = null,
}: Props) => {
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  useEffect(() => {
    if (otpauth_url) {
      QRCode.toDataURL(otpauth_url).then(setqrCodeUrl);
    }
  }, [otpauth_url]);

  return (
    <>
      <QrCodeContainer style={{ textAlign: align }} mt={2}>
        <img src={qrcodeUrl} alt="qr" />
      </QrCodeContainer>
    </>
  );
};

export default QrCode;
