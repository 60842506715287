import React, { FunctionComponent, useState, useEffect } from "react";
import {
  AssessmentAccordian,
  Text,
  DashboardWrapper,
  TaskAssessmentTable,
  Loader,
  CustomeButton,
} from "shared";
import useAppDispatch from "hooks/useAppDispatch";
import { getAssessmentQaList, updateHelpTextHtml } from "reducers/assessment";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Box, Modal } from "@mui/material";
import { Colors } from "theme/color";
import { RxCrossCircled } from "react-icons/rx";
import {
  NISTCSFTotalObjectives,
  totalObjectivesNISTCSFQSG,
  assessmentTypeNISTCSFQSG,
  assessmentTypeNISTCSF,
} from "utils/constant";
import { experimentalStyled as styled } from "@mui/material/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const HeaderModal = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
}));

type Props = Record<string, never>;

type QuestionStatus = {
  notMet: boolean;
  met: boolean;
  notInterested: boolean;
};
interface AccordionItem {
  title: string;
  completedQueries: number;
  totalQueries: number;
  securityRequirements: any[];
  userComment: string;
}

const AssessmentPage: FunctionComponent<Props> = () => {
  const assessmentQaList = useSelector(
    (state: RootState) => state.assessment.assessmentsQaList
  );

  const userAnswer = useSelector(
    (state: RootState) => state.assessment.objectives
  );

  const userResponseLevelTwo = useSelector(
    (state: RootState) => state.assessment.userResponse
  );

  // const userName = useSelector((state: RootState) => state.user.userInfo);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const assessmentId = parts[3];
  const [isLoading, setIsLoading] = useState(true);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [page, setPage] = useState(1);
  const [accordions, setAccordions] = useState<AccordionItem[]>([]);

  const currentAssessmentId = assessmentId;

  // descripton tooltip modal
  const [open, setOpen] = React.useState(false);
  const [html, setHtml] = React.useState("");
  const [disableEdit, setDisableEdit] = useState(false);
  const [currentSecurityReqID, setCurrentSecurityReqID] = useState<
    number | undefined
  >();
  const [currentHtmlID, setCurrentHtmlID] = useState<number | undefined>();

  const handleOpen = (data: any, securityRequirementsId: any, htmlID: any) => {
    setOpen(true);
    setHtml(data);
    setCurrentSecurityReqID(securityRequirementsId);
    setCurrentHtmlID(htmlID);
  };

  const handleUpdateContent = () => {
    setDisableEdit(true);
    dispatch(
      updateHelpTextHtml({
        help_text_id: currentHtmlID,
        security_requirement: currentSecurityReqID,
        assessment: assessmentId,
        user_help_text: html,
      })
    )
      .unwrap()
      .then(() => {
        swal({
          title: "Assessment",
          text: "Data updated successfully",
          icon: "success",
        });
        setOpen(false);
        setDisableEdit(false);
      })
      .catch((err: any) => {
        console.log(err);
        setDisableEdit(false);
        swal({
          title: "Assessment",
          text: "Something wen wrong please try again",
          icon: "error",
        });
      });
  };

  const handleCloseModal = () => setOpen(false);

  const pageNumbers = useSelector(
    (state: RootState) =>
      state.assessment?.assessments[currentAssessmentId as any] || 1
  );

  const [totalResponseLevelTwo, setTotalResponseLevelTwo] = useState<any>();
  useEffect(() => {
    setTotalResponseLevelTwo(userResponseLevelTwo);
  }, [userResponseLevelTwo]);

  const { state } = location;

  const viewFlag = state && state.flage;
  // const assessmentName = state && state.name;

  const phaseCardEmail = state && state.email;
  const levelId = state && state.assessmentLevel;
  const assessmentTypeCurrent = state && state.assessmentTypes;

  // levels save and exit function
  const hanldeAssessmentSave = () => {
    navigate("/task-board");
  };

  // level one submit action
  const hanldeSubmitAssessment = () => {
    navigate("/task-board");
  };

  // Function to fetch assessment questions on page reload
  const fetchAssessmentQuestions = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        getAssessmentQaList({
          assessmentId: currentAssessmentId,
          levelId: levelId === null ? 0 : levelId,
          page: pageNumbers ? pageNumbers : page,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.success === false && res?.status === 400) {
            swal({
              title: "Assessment",
              text: "Invalid assessment level_id !",
              icon: "error",
              buttons: {
                confirm: {
                  text: "Go Back",
                  value: true,
                  visible: true,
                  className: "btn-primary",
                  closeModal: true,
                },
                cancel: {
                  text: "Cancel",
                  value: null,
                  visible: true,
                  className: "btn-secondary",
                  closeModal: true,
                },
              },
            }).then((value) => {
              if (value) {
                // Redirect to the desired page
                navigate("/task-board");
                window.location.reload();
              }
            });
          }
        });
      window.scrollTo(0, 0);
      setIsLoading(false);
      setPage(1);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchAssessmentQuestions();
  }, [dispatch, assessmentId, levelId, currentAssessmentId, pageNumbers]);

  useEffect(() => {
    const mappedAccordions = assessmentQaList?.map((item: any) => {
      const securityRequirements = item.security_requirements.map((sr: any) => {
        const objectives = sr.objectives
          .filter(
            (objective: any) =>
              assessmentTypeCurrent !== assessmentTypeNISTCSFQSG ||
              objective.is_nist_csf_2_0_quick_start_objective
          )
          .map((objective: any) => {
            const userAnswerValue = userAnswer[objective.pk];
            let status: QuestionStatus = {
              notMet: false,
              met: false,
              notInterested: false,
            };
            if (userAnswerValue === "not_met") {
              status.notMet = true;
            } else if (userAnswerValue === "met") {
              status.met = true;
            } else if (userAnswerValue === "na") {
              status.notInterested = true;
            }
            const nistExamples = objective.examples?.split("\\n").join("\n");
            const question = {
              id: objective.pk,
              assessmentSecurityRequirementId:
                objective.assessment_security_requirement,
              questionNumber: objective.code,
              title: objective.description,
              pkId: objective.pk,
              status,
              isEvidenceProvided: objective.is_evidence_provided,
              examples: nistExamples,
              objectiveComment: objective.comment,
              is_nist_csf_2_0_quick_start_objective:
                objective.is_nist_csf_2_0_quick_start_objective,
            };
            return question;
          });
        const controlDescription = sr.user_help_text?.html;

        return {
          controlNumber: sr.control_number + ":",
          securityRequirementsId: sr?.pk,
          controlDescription: controlDescription,
          controlDescriptionID: sr.user_help_text?.id,
          controlRequirement: sr.control_requirement,
          objectives: objectives,
          userComment: sr.user_comment,
          userResponse: sr.user_response,
        };
      });

      const accordion = {
        title: item.assessment_family,
        completedQueries: item.total_family_responses,
        totalQueries: item.total_family_objectives,
        securityRequirements: securityRequirements,
        userComment: item.user_comment,
      };
      return accordion;
    });

    setAccordions(mappedAccordions);
  }, [assessmentQaList, assessmentTypeCurrent, userAnswer]);

  const updateCompletedQueries = (index: any, change: any) => {
    setTotalResponseLevelTwo(
      (prevTotalResponseLevelTwo: any) => prevTotalResponseLevelTwo + change
    );
    setAccordions((currentAccordions) => {
      return currentAccordions.map((accordion, idx) => {
        if (idx === index) {
          return {
            ...accordion,
            completedQueries: accordion.completedQueries + change,
          };
        }
        return accordion;
      });
    });
  };

  // this function is maped to get userResponse according to ccurrent assessment
  const completedQueriesArray = accordions.map((item) => {
    return Array.isArray(item.completedQueries)
      ? item.completedQueries
      : [item.completedQueries];
  });

  const userResponse = completedQueriesArray
    .flat()
    .reduce((acc, value) => acc + value, 0);

  // check levelId and userResponse
  useEffect(() => {
    if (levelId === null && assessmentTypeCurrent === assessmentTypeNISTCSF) {
      if (totalResponseLevelTwo === NISTCSFTotalObjectives) {
        setSubmitDisable(false);
      } else {
        setSubmitDisable(true);
      }
    } else if (
      levelId === null &&
      assessmentTypeCurrent === assessmentTypeNISTCSFQSG
    ) {
      if (totalResponseLevelTwo === totalObjectivesNISTCSFQSG) {
        setSubmitDisable(false);
      } else {
        setSubmitDisable(true);
      }
    }
  }, [levelId, userResponse, totalResponseLevelTwo, levelId]);

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Box>
            <Box mt={3} mb={3} display="flex" justifyContent="space-between">
              <Text fontSize="32px" fontWeight="700" className="text-mobile">
                {assessmentTypeCurrent}
              </Text>
            </Box>
            <Box>
              {accordions?.map((item: any, index: any) => (
                // eslint-disable-next-line react/jsx-key
                <AssessmentAccordian
                  key={index}
                  data={item}
                  index={index}
                  accordions={accordions}
                >
                  <TaskAssessmentTable
                    data={item}
                    index={index}
                    accordions={accordions}
                    assessmentId={assessmentId}
                    levelId={levelId}
                    updateCompletedQueries={(change) =>
                      updateCompletedQueries(index, change)
                    }
                    phaseCardEmail={phaseCardEmail}
                    viewFlag={viewFlag}
                    notMet="No"
                    met="Yes"
                    notMerSwitchColor={Colors.button.primary_bg}
                    hideObjectiveTitle={true}
                    showCommentsColumn={true}
                    assessmentTypeCurrent={assessmentTypeCurrent}
                    handleOpen={handleOpen}
                  />
                </AssessmentAccordian>
              ))}
            </Box>
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <CustomeButton
              title="Save and Exit"
              borderRadius="10px"
              fontWeight="500"
              width="220px"
              align="left"
              onClick={hanldeAssessmentSave}
            />

            <CustomeButton
              title="Submit"
              borderRadius="10px"
              fontWeight="500"
              width="100px"
              align="left"
              onClick={hanldeSubmitAssessment}
              disabled={submitDisable}
            />
          </Box>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <ModalContent sx={style}>
          <HeaderModal
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box pl={5}></Box>
            <RxCrossCircled
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </HeaderModal>
          <Box>
            {/* {html} */}
            <CKEditor
              editor={ClassicEditor as any}
              data={html}
              onChange={(event, editor) => {
                const data = editor.getData();
                setHtml(data);
              }}
            />
          </Box>
          <Box mt={4}>
            <CustomeButton
              title="update"
              width="120px"
              onClick={handleUpdateContent}
              disabled={disableEdit}
            />
          </Box>
        </ModalContent>
      </Modal>
    </DashboardWrapper>
  );
};

export default AssessmentPage;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  maxHeight: "90vh",
  overflowY: "auto",
  paddingBottom: "15px",
  width: "80%",
};
