import * as React from "react";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";
import { $wrapNodes } from "@lexical/selection";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";

import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

export default function LexicalEditorBlockOptionsSelect({
  editor,
  blockType,
}: {
  editor: LexicalEditor;
  blockType: string;
}) {
  function formatParagraph() {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  }

  function formatLargeHeading() {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
  }

  function formatSmallHeading() {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
  }

  function formatBulletList() {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }

  function formatNumberedList() {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }

  function formatQuote() {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  }

  function handleChange(event: SelectChangeEvent<string>) {
    const value = event.target.value;
    switch (value) {
      case "paragraph":
        formatParagraph();
        break;
      case "h1":
        formatLargeHeading();
        break;
      case "h2":
        formatSmallHeading();
        break;
      case "ul":
        formatBulletList();
        break;
      case "ol":
        formatNumberedList();
        break;
      case "quote":
        formatQuote();
        break;
    }
  }

  const options = [
    { label: "Normal", value: "paragraph" },
    { label: "Large Heading", value: "h1" },
    { label: "Small Heading", value: "h2" },
    { label: "Bullet List", value: "ul" },
    { label: "Numbered List", value: "ol" },
    { label: "Quote", value: "quote" },
  ];

  return (
    <Select
      placeholder="Select..."
      value={blockType}
      onChange={handleChange}
      className="editor_select"
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}
