import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface ApexChartProps {}

const ApexChart: React.FC<ApexChartProps> = () => {
  const options: ApexOptions = {
    chart: {
      type: "radialBar",
      offsetY: 0,
      dropShadow: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "45%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
        },
        dataLabels: {
          name: {
            fontSize: "16px",
            color: undefined,
            offsetY: 0,
          },
          value: {
            offsetY: -10,
            fontSize: "22px",
            color: undefined,
            formatter: function (val: any) {
              return val + "%";
            },
          },
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#fed74f"],
    },
    stroke: {
      colors: ["#d3d3d3"],
    },
    labels: [""],
  };

  const series = [60];

  return (
    <div id="chart">
      <style>
        {`
          .apexcharts-radialbar-area {
            stroke-dasharray: 4;
            stroke-width: 20;
          }.apexcharts-datalabel-value{
            font-weight: 500 !important;
            line-height: 10px !important;
            font-size:22px !important;
          }
          .apexcharts-radialbar-hollow {
            stroke: #727272; 
            stroke-width: 3px;  
          }
          
        `}
      </style>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={300}
      />
    </div>
  );
};

export default ApexChart;
