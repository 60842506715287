import React, { FunctionComponent, ChangeEventHandler } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Switch, { SwitchProps } from "@mui/material/Switch";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 62,
  height: 27,
  padding: 0,
  [theme.breakpoints.down("sm")]: {
    width: 30,
    height: 16,
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "5ms",
    "&.Mui-checked": {
      transform: "translateX(34px)",
      [theme.breakpoints.down("sm")]: {
        transform: "translateX(14px)",
      },
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
    },
    // Add this section for styles when switch is off
    "&:not(.Mui-checked)": {
      // Your styles here
      "& + .MuiSwitch-track": {
        backgroundColor: "white",
      },
      "& span": {
        color: "gray",
      },
      "&.Mui-disabled": {
        "& + .MuiSwitch-track": {
          backgroundColor: "white",
        },
        "& .MuiSwitch-thumb": {
          color: "gray",
        },
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? "none" : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    [theme.breakpoints.down("sm")]: {
      width: 10,
      height: 10,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 27 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"], {
      duration: 500,
    }),
  },
}));

type Props = {
  color?: string;
  checked?: any;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
};

const Swtich: FunctionComponent<Props> = ({
  color = "red",
  checked = false,
  disabled = false,
  onChange,
}: Props) => {
  return (
    <>
      <Container>
        <IOSSwitch
          sx={{
            border: "1px solid gray",
            borderRadius: "20px",
            "& .MuiSwitch-switchBase": {
              "&.Mui-checked + .MuiSwitch-track": {
                backgroundColor: color,
              },
            },
          }}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </Container>
    </>
  );
};

export default Swtich;
