import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, Avatar, IconButton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import { FaImages } from "react-icons/fa";
import { Switch, SSPCommentInput } from "shared";

import { ObjectiveTooltip } from "utils/sharedStyled";
import { Colors } from "theme/color";
import { BsInfoSquareFill } from "react-icons/bs";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderTop: "1px solid grey  !important",
    borderBottom: "1px solid grey !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 400,
    border: "1px solid grey  !important",
    borderBottom: "1px solid grey !important",
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    border: "1px solid grey  !important",
  },
  "&:nth-of-type(odd)": {
    border: "1px solid grey  !important",
  },
  "&:last-child td, &:last-child th": {
    border: "1px solid grey  !important",
    borderRight: "1px solid grey  !important",
  },
});

const StyledSpan = styled("span")({
  fontSize: "18px",
  fontWeight: 700,
  paddingRight: "10px",
});

type Props = {
  data: any;
  disabled: boolean;
  notMerSwitchColor: string;
  objectTooltip: boolean;
  showSubCategoryComment: boolean;
  placeholderNist: string;
  placeholder: string;
};

const SharedTableBody: React.FC<Props> = ({
  data,
  disabled,
  notMerSwitchColor,
  objectTooltip,
  showSubCategoryComment,
  placeholderNist,
  placeholder,
}) => {
  return (
    <TableBody>
      {data?.objectives?.map((item: any) => (
        <React.Fragment key={item.id}>
          <StyledTableRow>
            {objectTooltip === false ? (
              <StyledTableCell component="th" scope="row">
                <StyledSpan>{item?.questionNumber}</StyledSpan>
                {item?.title}
              </StyledTableCell>
            ) : (
              <StyledTableCell component="th" scope="row">
                <StyledSpan>{item?.questionNumber}</StyledSpan>
                {item?.title}
                <ObjectiveTooltip
                  title={
                    <Box
                      component="div"
                      style={{
                        lineHeight: 1.4,
                        fontSize: "16px",
                        whiteSpace: "pre-line",
                        textAlign: "justify",
                        letterSpacing: 1,
                      }}
                    >
                      <Box
                        component="span"
                        dangerouslySetInnerHTML={{
                          __html: item?.examples,
                        }}
                      />
                    </Box>
                  }
                >
                  <IconButton aria-label="information">
                    <BsInfoSquareFill
                      style={{
                        color: Colors.text.info,
                        fontSize: "16px",
                      }}
                    />
                  </IconButton>
                </ObjectiveTooltip>
              </StyledTableCell>
            )}
            <StyledTableCell align="center">
              <Switch
                color="gray"
                disabled={disabled}
                checked={item.status.notInterested}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              <Switch
                color="green"
                disabled={disabled}
                checked={item.status.met}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              <Switch
                color={notMerSwitchColor}
                disabled={disabled}
                checked={item.status.notMet}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              <Avatar
                sx={{
                  marginLeft: "20px",
                  bgcolor: item.isEvidenceProvided ? "#fed74f" : "",
                }}
              >
                <FaImages color="#000" />
              </Avatar>
            </StyledTableCell>
          </StyledTableRow>
          {showSubCategoryComment && (
            <StyledTableRow>
              <StyledTableCell colSpan={5}>
                <SSPCommentInput
                  placeholder={placeholderNist}
                  value={item.objectiveComment}
                />
              </StyledTableCell>
            </StyledTableRow>
          )}
        </React.Fragment>
      ))}
      {!showSubCategoryComment && (
        <StyledTableRow>
          <TableCell colSpan={5}>
            <SSPCommentInput
              placeholder={placeholder}
              value={data.userComment}
            />
          </TableCell>
        </StyledTableRow>
      )}
    </TableBody>
  );
};

export default SharedTableBody;
