import React, { FunctionComponent, ReactNode } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
} from "@mui/material";
import { FaMinus } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { Colors } from "theme/color";
import {
  GOV,
  IDENTIFY,
  PROTECT,
  DETECT,
  RESPOND,
  RECOVER,
} from "utils/constant";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
}));

const AccordionBox = styled(Accordion)(({ theme }) => ({
  background: "#fff",
  margin: theme.spacing(0),
  color: "#000",
  borderBottom: "1px solid grey",
  borderLeft: "1px solid grey",
  borderRight: "1px solid grey",
}));

const TitleBox = styled(AccordionSummary)(({ theme }) => ({
  margin: theme.spacing(0),
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const AccordionTitle = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  paddingRight: theme.spacing(2),
}));

const AccordionDetail = styled(AccordionDetails)(({ theme }) => ({
  margin: theme.spacing(0),
}));

const AssessmentTitle = styled(Typography)(({ theme }) => ({
  width: "95% !important",
  fontSize: "22px",
  fontWeight: "900",
  [theme.breakpoints.down("sm")]: {
    width: "70% !important",
    fontSize: "16px",
    fontWeight: "600",
  },
}));

type Props = {
  data?: any;
  children?: ReactNode | ReactNode[];
  index?: any;
  expandedAll?: boolean;
  id?: any;
};

const SSPAccordian: FunctionComponent<Props> = ({
  data,
  children,
  index,
  expandedAll,
}: Props) => {
  const [expanded, setExpanded] = React.useState(0);

  const handleChange =
    (index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : -1);
    };

  React.useEffect(() => {
    if (expandedAll) {
      setExpanded(index);
    }
  }, [expandedAll, index]);

  return (
    <>
      <Container>
        <AccordionBox
          key={data.id}
          expanded={expandedAll || expanded === index}
          onChange={handleChange(index)}
        >
          <TitleBox
            expandIcon={
              expandedAll || expanded === index ? (
                <FaMinus
                  color={
                    data.title === GOV
                      ? Colors.text.black
                      : data.title === IDENTIFY
                      ? Colors.text.white
                      : data.title === PROTECT
                      ? Colors.text.white
                      : data.title === DETECT
                      ? Colors.text.black
                      : data.title === RESPOND
                      ? Colors.text.black
                      : data.title === RECOVER
                      ? Colors.text.black
                      : Colors.text.white
                  }
                  style={{ fontWeight: "bold", fontSize: "25px" }}
                />
              ) : (
                <GoPlus
                  color={
                    data.title === GOV
                      ? Colors.text.black
                      : data.title === IDENTIFY
                      ? Colors.text.white
                      : data.title === PROTECT
                      ? Colors.text.white
                      : data.title === DETECT
                      ? Colors.text.black
                      : data.title === RESPOND
                      ? Colors.text.black
                      : data.title === RECOVER
                      ? Colors.text.black
                      : Colors.text.white
                  }
                  style={{ fontWeight: "bold", fontSize: "25px" }}
                />
              )
            }
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            style={{
              backgroundColor:
                data.title === GOV
                  ? Colors.text.govern_bg
                  : data.title === IDENTIFY
                  ? Colors.text.identify_bg
                  : data.title === PROTECT
                  ? Colors.text.protect_bg
                  : data.title === DETECT
                  ? Colors.text.detect_bg
                  : data.title === RESPOND
                  ? Colors.text.respond_bg
                  : data.title === RECOVER
                  ? Colors.text.recover_bg
                  : Colors.text.black,

              color:
                data.title === GOV
                  ? Colors.text.black
                  : data.title === IDENTIFY
                  ? Colors.text.white
                  : data.title === PROTECT
                  ? Colors.text.white
                  : data.title === DETECT
                  ? Colors.text.black
                  : data.title === RESPOND
                  ? Colors.text.black
                  : data.title === RECOVER
                  ? Colors.text.black
                  : Colors.text.white,
            }}
          >
            <AccordionTitle key={index ? index : null}>
              <AssessmentTitle>{data.title}</AssessmentTitle>
            </AccordionTitle>
          </TitleBox>
          <AccordionDetail sx={{ padding: "0px !important" }}>
            {children}
          </AccordionDetail>
        </AccordionBox>
      </Container>
    </>
  );
};

export default SSPAccordian;
