import React, { useState, useCallback } from "react";
import DataTable, { IDataTableProps } from "react-data-table-component";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  InputAdornment,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  Modal,
  ImageList,
  ImageListItem,
  Button,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import swal from "sweetalert";

import { FiSearch } from "react-icons/fi";
import { Colors } from "theme/color";
import { Loader, Text, TextArea, Input, CustomeButton } from "shared";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import {
  addEvidence,
  getEvidenceReport,
  evidenceImage,
  putEvidenceImage,
  deleteEvidence,
  fetchEvidenceList,
} from "reducers/assessment";
import useAppDispatch from "hooks/useAppDispatch";
import { AiFillCloseCircle, AiFillEdit } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import { LightTooltip, InputSerch, evidenceModal } from "utils/sharedStyled";

const AvidanceSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name field is required")
    .min(1, "Too Short!")
    .max(250, "Too Long!"),

  additionalNotes: Yup.string()
    .required("Notes field is required")
    .min(1, "Too Short!")
    .max(250, "Too Long!"),
});

const ImagesListCustome = styled(Box)({
  overflowX: "auto",
  maxHeight: "280px",
});

interface Evidence {
  id?: any;
  assessment?: string;
  evidences?: string;
  relevance?: string;
  source?: string;
  notes?: number;
  evidenceDate?: string;
  ssp?: string;
  levels?: any;
  index?: any;
}

interface Props {
  evidence: Evidence[];
  totalRows: number;
  paginationRowsPerPageOptions: number[];
  pages?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  paginationPerPages?: number;
  handlePageChange?: (
    // eslint-disable-next-line no-unused-vars
    event: React.ChangeEvent<unknown>,
    // eslint-disable-next-line no-unused-vars
    page: number
  ) => void | undefined;
  // eslint-disable-next-line no-unused-vars
  handleRowsPerPageChange?: (event: any) => void;
  search?: string;
  handleSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  handleDelete?: () => void;
  selectedObjectIdRef: React.MutableRefObject<number | null>;
  loading?: boolean;
  searchLoading?: boolean;
}

const DataTableComponent: React.FC<Props> = ({
  evidence,
  totalRows,
  paginationRowsPerPageOptions,
  pages,
  paginationPerPages,
  handlePageChange,
  handleRowsPerPageChange,
  handleSearch,
  search,
  loading,
  searchLoading,
}) => {
  const page = pages ? pages : 0;
  const paginationPerPage = paginationPerPages ? paginationPerPages : 0;

  const searchText = search ? search : null;

  const currentPageRows = evidence?.length ? evidence : [];

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(null));
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [assessmentId, setAssessmentId] = useState<number | null>(null);
  const fileInputRef = React.createRef<HTMLInputElement>();
  const editFileInputRef = React.createRef<HTMLInputElement>();
  const [tabValue, setTabValue] = React.useState("1");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const theme = useTheme();
  const evidenceList = useSelector(
    (state: RootState) => state.assessment.evidenveListData
  );

  const img = evidenceList?.evidence_images;

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const todayDate = dayjs().format("YYYY/MM/DD");
    const date = evidenceList?.evidence_date
      ? evidenceList?.evidence_date
      : todayDate;

    setValue(dayjs(date));
  }, [evidenceList]);

  const handleActionClick = useCallback(
    async (row: any) => {
      const idParts = row.id.split("+");
      // Extract the value after the second '+'
      const lastValue = idParts[2].trim();
      setSelectedItemIndex(row.objectiveIndex);
      setAssessmentId(lastValue);
      // get api for evidence
      try {
        await dispatch(
          getEvidenceReport({
            assessmentId: lastValue,
            objectiveId: row.objectiveIndex,
          })
        )
          .unwrap()
          .then(() => {});
      } catch (error: any) {
        if (error?.data?.status === 500) {
          console.log("Internal Server Error");
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
      setOpen(true);
    },
    [dispatch, assessmentId, selectedItemIndex, evidenceList]
  );

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisabled(true);
    const fileInput = event?.target;
    const file = fileInput.files![0];

    const maxSize = 1000000; // 1MB in bytes
    if (file.size > maxSize) {
      swal({
        title: "Evidence",
        text: "Please upload an image less than 1MB.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }
      return; // Exit the function to prevent further processing
    }

    const maxFileNameLength = 100;
    if (file.name.length > maxFileNameLength) {
      swal({
        title: "Evidence",
        text: "Please upload an image with name of 100 characters or less.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }

      return; // Exit the function to prevent further processing
    }

    // Check if the file type is allowed
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      swal({
        title: "Evidence",
        text: "Please upload an image in .jpg and .png format.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }
      return;
    }

    // api call when new image is uploaded
    if (file) {
      setLoad(true);
      try {
        // post api call for image
        dispatch(
          evidenceImage({
            assessment_evidence: evidenceList?.pk,
            image: file,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              // list api call
              getEvidenceReport({
                assessmentId: assessmentId,
                objectiveId: selectedItemIndex,
              })
            )
              .unwrap()
              .then(() => {
                setLoad(false);
                setIsDisabled(false);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.status === 400) {
          swal({
            title: "Evidence",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    }
  };

  const handleImageChangeOnEdit = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDisabled(true);
    const file = event.target.files![0];

    const maxSize = 1000000; // 1MB in bytes
    if (file.size > maxSize) {
      swal({
        title: "Evidence",
        text: "Uploaded file format must be less than 1MB",
        icon: "error",
      });
      return; // Exit the function to prevent further processing
    }

    const maxFileNameLength = 100;
    if (file.name.length > maxFileNameLength) {
      swal({
        title: "Evidence",
        text: "Please use an file with a file name of 100 characters or less.",
        icon: "error",
      });

      return; // Exit the function to prevent further processing
    }

    // Check if the file type is allowed
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      swal({
        title: "Evidence",
        text: "Please upload an image in .png, .jpeg, or .jpg format.",
        icon: "error",
      });
      return;
    }

    // api call when new image is uploaded
    if (file) {
      setLoad(true);
      try {
        // post api call for image
        dispatch(
          putEvidenceImage({
            evidence_image_id: localStorage.getItem("evidence_image_id"),
            assessment_evidence: evidenceList?.pk,
            image: file,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              // list api call
              getEvidenceReport({
                assessmentId: assessmentId,
                objectiveId: selectedItemIndex,
              })
            )
              .unwrap()
              .then(() => {
                setLoad(false);
                setIsDisabled(false);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.status === 400) {
          swal({
            title: "Evidence",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    }
  };
  // ref button to upload evidence images
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleButtonClickOnEdit = (pk: any, index: any) => {
    console.log("On Edit Image Evidence PK = ", pk, " and Index = " + index);
    localStorage.setItem("evidence_image_id", pk);
    if (editFileInputRef.current) {
      editFileInputRef.current.click();
    }
  };

  // add information of from in evidence
  const handleUpdate = useCallback(
    async (values: { name: string; additionalNotes: string }) => {
      const newSelectedDate = value?.format("YYYY-MM-DD");

      try {
        await dispatch(
          addEvidence({
            pk: evidenceList?.pk,
            assessmentId: assessmentId,
            assessmentObjective: selectedItemIndex,
            name: values.name,
            additionalNotes: values.additionalNotes,
            evidenceDate: newSelectedDate ? newSelectedDate : "",
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              fetchEvidenceList({
                page: page,
                limit: paginationPerPage,
                search: "",
              })
            ).unwrap();
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Evidence",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setTabValue("2");
      }
    },
    [dispatch, evidenceList, assessmentId, selectedItemIndex, value, tabValue]
  );
  // delete images of evidences
  const handleDeleteEvidence = useCallback(
    async (index: any) => {
      try {
        await dispatch(
          deleteEvidence({
            pk: index,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Evidence",
              text: "Evidence Image Deleted Successfully !",
              icon: "success",
            });
            dispatch(
              getEvidenceReport({
                assessmentId: assessmentId,
                objectiveId: selectedItemIndex,
              })
            )
              .unwrap()
              .then(() => {})
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.data?.status === 400) {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    },
    [dispatch, selectedItemIndex]
  );

  //tabs handle change
  const handleTabChange = async (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabValue(newValue);
  };

  const handleFinish = () => {
    setOpen(false);
    swal({
      title: "Evidence",
      text: "Evidence Updated Successfully !",
      icon: "success",
    });
    setTabValue("1");
  };

  const handleClose = () => {
    setOpen(false);
    setTabValue("1");
  };

  const columns: any[] = [
    {
      name: "Assessment",
      selector: (row: any) => row.assessment,
      center: true,
      width: "20%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "Evidence",
      selector: (row: any) => row.evidenceName,
      center: true,
      width: "20%",
      style: {
        cursor: "default",
        wordBreak: "break-all",
      },
    },
    {
      name: "Notes",
      selector: (row: any) => row.notes,
      center: true,
      width: "20%",
      style: {
        cursor: "default",
      },
    },
    {
      name: " Evidence Date",
      selector: (row: any) => row.evidenceDate,
      center: true,
      width: "20%",
      style: {
        cursor: "default",
      },
    },
    {
      name: "Action",
      selector: (row: any) => (
        <Button
          onClick={() => handleActionClick(row)}
          className="user-evidence-button"
          color="success"
          variant="contained"
        >
          Edit
        </Button>
      ),
      center: true,
      width: "20%",
      style: {
        cursor: "default", // Change cursor style to indicate it's clickable
      },
    },
  ];

  const tableProps: IDataTableProps<Evidence> = {
    columns,
    data: currentPageRows,
    pointerOnHover: true,
    pagination: true,
    paginationPerPage: paginationPerPage,
    paginationRowsPerPageOptions: paginationRowsPerPageOptions,
    paginationComponent: () => (
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 0px",
        }}
      >
        <Box sx={{ marginTop: "10px", fontSize: "20px !important" }}>
          Showing {(page - 1) * paginationPerPage + 1}-
          {Math.min(page * paginationPerPage)} of {totalRows} entries
        </Box>
        <Pagination
          sx={{
            "& .Mui-selected": {
              backgroundColor: "#FED74F !important",
              color: "#000",
              borderRadius: "5px",
              fontSize: "16px",
            },
          }}
          count={totalRows ? Math.ceil(totalRows / paginationPerPage) : 0}
          page={page}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component={Button}
              sx={{ fontSize: "20px" }}
            />
          )}
        />
      </Box>
    ),
    onChangeRowsPerPage: handleRowsPerPageChange,
    subHeader: true,
    subHeaderComponent: (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex">
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Show
            </Typography>
            <Select
              value={paginationPerPage}
              onChange={handleRowsPerPageChange}
              style={{ marginRight: "20px" }}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent !important", // Hide the root border
                  },
                  "&:hover fieldset, &.Mui-focused fieldset": {
                    borderColor: "transparent !important", // Hide the border on hover and focus
                  },
                },
                "& .MuiSelect-icon": {
                  color: "#FED74F",
                  border: "1px solid #FED74F",
                  borderRadius: "50px",
                },
                "& .MuiSelect-select": {
                  borderColor: "#FED74F !important",
                  border: "2px solid #FED74F !important",
                  borderRadius: "50px !important",
                  padding: "14px 20px",
                  "&:hover, &:focus": {
                    borderColor: "#FED74F !important",
                    border: "2px solid #FED74F !important",
                  },
                },
                width: { xs: "80%", md: "40%" },
              }}
            >
              {paginationRowsPerPageOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: Colors.button.primary_bg,
                    },
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Entries{" "}
            </Typography>
            <Box mt={1.5} ml={4} sx={{ color: "green" }}>
              {searchLoading ? "Please wait.." : ""}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputSerch
            id="input-with-icon-textfield"
            placeholder="Search"
            value={searchText || ""}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    ),
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {loading ? (
            <Loader isLoading={loading} />
          ) : (
            <DataTable
              key={paginationPerPage}
              {...tableProps}
              keyField="id"
              className="table-style"
              customStyles={customStyles}
              conditionalRowStyles={[
                {
                  when: (row) => row.index % 2 === 0,
                  style: {
                    backgroundColor: "#fffcf1",
                  },
                },
                {
                  when: (row) => row.index % 2 !== 0,
                  style: {
                    backgroundColor: "#fdf4da",
                  },
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
      {/* modal */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={evidenceModal}>
          <Box
            display="flex"
            justifyContent="end"
            sx={{ marginTop: "-20px", marginRight: "-20px" }}
          >
            <AiFillCloseCircle onClick={handleClose} className="custom-icon" />
          </Box>
          <Text fontSize="24px" fontWeight="600" align="center">
            Evidence
          </Text>

          <TabContext value={tabValue}>
            <Box mt={3} display="flex" justifyContent="center" width="100%">
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                sx={{
                  minHeight: "40px !important",
                  borderBottom: "1px solid white !important",
                }}
              >
                <Tab
                  label="Evidence Form"
                  value="1"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#FED74F",
                      color: "#000",
                      minHeight: "40px !important",
                      borderRadius: "5px",
                    },
                    minHeight: "40px !important",
                    color: "#000",
                    border: "1px solid #FED74F",
                    borderRadius: "5px",
                    fontSize: "12px !important",
                    [theme.breakpoints.down("sm")]: {
                      minHeight: "40px !important",
                      color: "#FED74F",
                      border: "1px solid #FED74F",
                      borderRadius: "5px",
                      fontSize: "10px !important",
                      width: "150px",
                    },
                  }}
                />
                <Tab
                  label="Evidence Gallery"
                  value="2"
                  sx={{
                    marginLeft: "20px",
                    "&.Mui-selected": {
                      backgroundColor: "#FED74F",
                      color: "#000",
                      minHeight: "40px !important",
                      borderRadius: "5px",
                    },
                    minHeight: "40px !important",
                    color: "#000",
                    border: "1px solid #FED74F",
                    borderRadius: "5px",
                    fontSize: "12px !important",
                    [theme.breakpoints.down("sm")]: {
                      minHeight: "40px !important",
                      color: "#FED74F",
                      border: "1px solid #FED74F",
                      borderRadius: "5px",
                      fontSize: "10px !important",
                      width: "150px",
                    },
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Formik
                initialValues={{
                  name: evidenceList?.name || "",

                  additionalNotes: evidenceList?.additional_notes || "",
                }}
                validationSchema={AvidanceSchema}
                onSubmit={handleUpdate}
              >
                {({ errors, touched, values, handleChange, handleSubmit }) => (
                  <Box>
                    <Box>
                      <Box p={{ sx: 1, md: 1, lg: 1 }}>
                        <Box>
                          <Box>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Name"
                              value={values.name}
                              onChange={handleChange("name")}
                            />
                            {errors.name && touched.name ? (
                              <div className="error-message">{errors.name}</div>
                            ) : null}
                          </Box>

                          <LightTooltip
                            placement="top"
                            title="While CMMC Proof is designed to meet or exceed the security requirements outlined in NIST SP 800-171, CMMC Proof SHOULD NOT be utilized for storing or handling any information from the US Government, particularly classified data or materials governed by export restrictions (such as ITAR, EAR, or CUI labeled NOFORN or RELTO)."
                          >
                            <Box>
                              <TextArea
                                name="additionalNotes"
                                placeholder="Notes"
                                width="100%"
                                value={values.additionalNotes}
                                onChange={handleChange("additionalNotes")}
                              />
                              {errors.additionalNotes &&
                              touched.additionalNotes ? (
                                <div className="error-message">
                                  {errors.additionalNotes}
                                </div>
                              ) : null}
                            </Box>
                          </LightTooltip>

                          <Box mt={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                                maxDate={dayjs()}
                                format="YYYY/MM/DD"
                                sx={{
                                  borderRadius: "40px",
                                  "&  .MuiOutlinedInput-root": {
                                    borderColor: "#fed74f !important",
                                    border: "2px solid #fed74f !important",
                                    borderRadius: "50px",
                                    padding: "2px 25px",
                                    "&:focus": {
                                      borderColor: "none !important",
                                      boxShadow: "none !important",
                                    },
                                    "&:hover": {
                                      borderColor: "none !important",
                                      boxShadow: "none !important",
                                    },
                                    "& fieldset": {
                                      borderColor: "#fed74f !important",
                                      border: "0px solid #fed74f",
                                      borderRadius: "50px",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#fed74f !important",
                                      border: "0px solid #fed74f",
                                      borderRadius: "50px",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#fed74f !important",
                                      border: "0px solid #fed74f",
                                      borderRadius: "50px",
                                    },
                                  },
                                  width: "100%",
                                }}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      sx={{
                        position: "absolute",
                        bottom: "20px",
                        marginLeft: "-50px",
                      }}
                    >
                      <CustomeButton
                        title={"Next"}
                        borderRadius="50px"
                        fontWeight="500"
                        fontSize="18px"
                        align="center"
                        onClick={handleSubmit}
                        width="130px"
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            </TabPanel>
            <TabPanel value="2">
              <Box>
                <input
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  className="multiple-images-upload-input"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <input
                  type="file"
                  multiple
                  onChange={handleImageChangeOnEdit}
                  className="multiple-images-upload-input"
                  ref={editFileInputRef}
                  style={{ display: "none" }}
                />
                <div className="loading-img">
                  {load ? "Please wait... !" : ""}
                </div>

                <ImagesListCustome>
                  <ImageList cols={3} rowHeight={90}>
                    {img?.map((evidence: any, index: any) => (
                      <ImageListItem
                        key={index}
                        sx={{
                          padding: "10px",
                          border: "1px solid #000",
                          position: "relative",
                          borderRadius: "10px",
                          height: "90px",
                        }}
                      >
                        <div>
                          <img
                            src={evidence.image}
                            alt="images"
                            style={{
                              width: "100%",
                              height: "90px",
                            }}
                          />
                        </div>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            padding: "5px 7px",
                            cursor: "pointer",
                            background: "#FED74F",
                            borderRadius: "50px",
                            height: "20px",
                            margin: "1px",
                          }}
                          onClick={() =>
                            handleButtonClickOnEdit(evidence.pk, index)
                          }
                        >
                          <AiFillEdit />
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "2px",
                            cursor: "pointer",
                            background: "#FED74F",
                            borderRadius: "50px",
                            height: "25px",
                            display: index === 0 ? "none" : "block",
                            margin: "1px",
                          }}
                          onClick={() => handleDeleteEvidence(evidence.pk)}
                        >
                          <CloseIcon />
                        </Box>
                      </ImageListItem>
                    ))}
                    {evidenceList?.pk && !isDisabled ? (
                      <LightTooltip
                        placement="top"
                        title="Upload evidence media"
                      >
                        <ImageListItem
                          key={-23}
                          onClick={handleButtonClick}
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            position: "relative",
                            borderRadius: "10px",
                            height: "90px",
                            opacity: 1,
                            pointerEvents: "auto",
                          }}
                        >
                          <Box pt={3} display="flex" justifyContent="center">
                            <FaPlusCircle className="plus-icon-evidence green" />
                          </Box>
                        </ImageListItem>
                      </LightTooltip>
                    ) : (
                      <LightTooltip
                        placement="top"
                        title={
                          evidenceList?.pk && !isDisabled
                            ? "Please enter form field values to upload evidence media"
                            : "Please wait"
                        }
                        // title="Please enter form field values to upload evidence media"
                      >
                        <ImageListItem
                          key={-23}
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            position: "relative",
                            borderRadius: "10px",
                            height: "90px",
                            opacity: 0.5,
                          }}
                        >
                          <Box pt={3} display="flex" justifyContent="center">
                            <FaPlusCircle className="plus-icon-evidence disabled-color" />
                          </Box>
                        </ImageListItem>
                      </LightTooltip>
                    )}
                  </ImageList>
                </ImagesListCustome>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  marginLeft: "-50px",
                }}
              >
                <CustomeButton
                  title={"Finish"}
                  borderRadius="50px"
                  fontWeight="500"
                  fontSize="18px"
                  align="center"
                  onClick={handleFinish}
                  width="120px"
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
    </>
  );
};

export default DataTableComponent;

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#FED74F",
      color: "#000",
      border: "none",
      padding: "5px 10px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
    },
  },
  rows: {
    style: {
      backgroundColor: "white", // change body background color
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
      borderBottom: "none !important",
      // add media query for mobile
      "@media (max-width: 767px)": {
        fontSize: "10px !important",
      },
    },
  },
  cells: {
    style: {
      padding: "15px 5px !important",
    },
  },
};
