import React, { FunctionComponent } from "react";
import { DashboardWrapper, LinearProgress, Text, Button, Loader } from "shared";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import { getAssessmentReport } from "reducers/assessment";
import { useSelector } from "react-redux";

import ReactSpeedometer from "react-d3-speedometer";
import { CSVLink } from "react-csv";
import { Colors } from "theme/color";
import { Container } from "./style";
import {
  assessmentTypeNISTCSFQSG,
  assessmentTypeNISTCSF,
  FortressPackage,
  // FrontlineDefender,
  icons,
} from "utils/constant";

interface AssessmentReportList {
  assessment_family_percentage: {
    security_requirements_stats: any;
    assessment_family: string;
  }[];
  assessment_type: string;
  company_name: string;
  created_at: string;
  id: number;
  name: string;
  progress: { label: string; value: number }[];
  score: number;
  status: string;
  updated_at: string;
  user: number;
  assessment_level: number;
  target_assessment_level: any;
}

interface RootState {
  user: any;
  assessment: {
    assessmentReportList: AssessmentReportList;
  };
}

type Props = Record<string, never>;

const Dashboard: FunctionComponent<Props> = () => {
  const [isloading, setIsLoading] = React.useState(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname;
  const parts = pathname.split("/");
  const assessmentId = parts[3];
  const assessmentReport = useSelector(
    (state: RootState) => state.assessment.assessmentReportList
  );

  const assessmentScore = assessmentReport?.score;

  const assessmentLevel = assessmentReport?.assessment_level;
  const assessmentTierLevel = assessmentReport?.target_assessment_level;
  const assessmentName = assessmentReport?.name;

  const assessmentData = assessmentReport?.assessment_family_percentage || [];

  // Prepare the data for CSV
  const csvData = [
    // Add header row
    assessmentLevel === null
      ? [
          "Function",
          "Assessment Percentage",
          "Fully Met Count",
          "Total Sub Categories",
          "Total Evidence",
        ]
      : [
          "Assessment Family",
          "Assessment Percentage",
          "Fully Met Count",
          "Total Requirements",
          "Total Evidence",
        ],
    ...assessmentData.map((assessment) => {
      return [
        assessment.assessment_family,
        (assessment.security_requirements_stats.total_objective_met_count /
          assessment.security_requirements_stats.total_objective_count) *
          100,
        assessment.security_requirements_stats.total_objective_met_count,
        assessment.security_requirements_stats.total_objective_count,
        assessment.security_requirements_stats.total_evidence_count,
      ];
    }),
  ];

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getAssessmentReport({ assessmentId }))
      .unwrap()
      .then(() => {})
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, assessmentId]);

  const assessmentTypes = assessmentReport?.assessment_type;

  const handleSSPNavigate = () => {
    navigate(`/ssp/creator/${assessmentId}`, {
      state: { assessmentTypes },
    });
  };

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  return (
    <DashboardWrapper>
      {isloading ? (
        <Loader isLoading={isloading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Text fontSize="36px" fontWeight="700" className="text-mobile">
            Report of Assessments{" "}
          </Text>
          {assessmentLevel === null ? (
            <Box display="flex">
              <Box>
                <Text fontSize="16px" fontWeight="600" className="text-mobile">
                  Assessments Name :
                </Text>
              </Box>
              <Box sx={{ marginTop: "0px" }}>
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  className="text-mobile"
                  padding="0px 10px"
                  color={Colors.text.orange}
                >
                  {assessmentName} & Tier: {assessmentTierLevel}
                </Text>
              </Box>
            </Box>
          ) : (
            <Box display="flex">
              <Box>
                <Text fontSize="16px" fontWeight="600" className="text-mobile">
                  Assessments Name & Level :
                </Text>
              </Box>
              <Box sx={{ marginTop: "0px" }}>
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  className="text-mobile"
                  padding="0px 10px"
                  color={Colors.text.orange}
                >
                  {assessmentName} & {assessmentLevel}
                </Text>
              </Box>
            </Box>
          )}

          {assessmentLevel === 2 && (
            <Box mt={1} sx={{ textDecoration: "underline", color: "green" }}>
              <Text fontSize="24px" fontWeight="600" align="center">
                SPRS SCORE: {assessmentScore}
              </Text>
            </Box>
          )}
          {assessmentLevel === 2 && (
            <Box mt={3}>
              <Grid container justifyContent="center" alignSelf="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  alignContent="center"
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    mt={3}
                  >
                    <ReactSpeedometer
                      minValue={-203}
                      maxValue={110}
                      value={assessmentScore}
                      needleColor="#000"
                      segmentColors={[
                        "#b51610",
                        "#cc2e29",
                        "#de4c47",
                        "#f5736e",
                        "#57d465",
                        "#30c741",
                        "#12b525",
                      ]}
                      segments={6}
                      customSegmentStops={[
                        -203, -150, -100, -50, 0, 40, 80, 110,
                      ]}
                      needleTransitionDuration={3333}
                      needleHeightRatio={0.7}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          {assessmentLevel === 1 && currentPackage === FortressPackage && (
            <Box mt={3}>
              <Grid container justifyContent="center" alignSelf="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  alignContent="center"
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    mt={3}
                  >
                    <ReactSpeedometer
                      minValue={-63}
                      maxValue={63}
                      value={assessmentScore}
                      needleColor="#000"
                      segmentColors={[
                        "#b51610",
                        "#cc2e29",
                        "#de4c47",
                        "#f5736e",
                        "#57d465",
                        "#30c741",
                        "#12b525",
                      ]}
                      segments={6}
                      customSegmentStops={[
                        -63, -45, -30, -15, 0, 15, 30, 45, 63,
                      ]}
                      needleTransitionDuration={3333}
                      needleHeightRatio={0.7}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          {assessmentTypes === assessmentTypeNISTCSF &&
            assessmentLevel === null && (
              <Box mt={3}>
                <Grid container justifyContent="center" alignSelf="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    alignContent="center"
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "center" }}
                      mt={3}
                    >
                      <ReactSpeedometer
                        minValue={0}
                        maxValue={106}
                        value={assessmentScore}
                        needleColor="#000"
                        segmentColors={[
                          "#affab8",
                          "#9ff5a9",
                          "#8bf097",
                          "#84e38f",
                          "#57d465",
                          "#30c741",
                          "#12b525",
                        ]}
                        segments={6}
                        customSegmentStops={[0, 15, 30, 45, 60, 75, 90, 106]}
                        needleTransitionDuration={3333}
                        needleHeightRatio={0.7}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

          {assessmentTypes === assessmentTypeNISTCSFQSG &&
            assessmentLevel === null && (
              <Box mt={3}>
                <Grid container justifyContent="center" alignSelf="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    alignContent="center"
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "center" }}
                      mt={3}
                    >
                      <ReactSpeedometer
                        minValue={0}
                        maxValue={38}
                        value={assessmentScore}
                        needleColor="#000"
                        segmentColors={[
                          "#affab8",
                          "#9ff5a9",
                          "#8bf097",
                          "#84e38f",
                          "#57d465",
                          "#30c741",
                          "#12b525",
                        ]}
                        segments={6}
                        customSegmentStops={[0, 5, 10, 15, 20, 25, 30, 38]}
                        needleTransitionDuration={3333}
                        needleHeightRatio={0.7}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

          <Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2}>
            <Box>
              <CSVLink
                data={csvData}
                filename={"assessment_data.csv"}
                style={{ textDecoration: "none" }}
              >
                <Button
                  title="Export Dashboard"
                  borderRadius="50px"
                  fontWeight="500"
                  width="250px"
                  align="left"
                />
              </CSVLink>
            </Box>
            <Box sx={{ width: "250px" }}>
              <Button
                title="SSP Creator"
                borderRadius="50px"
                fontWeight="500"
                width="200px"
                align="right"
                onClick={handleSSPNavigate}
              />
            </Box>
          </Box>

          <Box mt={4} mb={4}>
            <Container className="dashboard-page">
              <Box p={{ xs: 1, sm: 1, md: 2, lg: 4 }}>
                <Grid container spacing={2}>
                  {assessmentReport?.assessment_family_percentage?.map(
                    (assessment: any, index: any) => (
                      <Grid key={index} item xs={12} sm={12} md={4}>
                        <LinearProgress
                          assessmentName={
                            assessmentLevel == null
                              ? assessment.assessment_family
                                  .split(":")[0]
                                  .trim()
                              : assessment.assessment_family
                          }
                          nameOfCategory={
                            assessmentLevel == null
                              ? assessment.assessment_family
                                  .split("(")[0]
                                  .trim()
                              : assessment.assessment_family
                          }
                          assessmentIcons={
                            icons[
                              assessmentLevel == null
                                ? assessment.assessment_family
                                    .split(":")[0]
                                    .trim()
                                : assessment.assessment_family
                            ]
                          }
                          value={
                            assessmentLevel == null
                              ? (assessment?.security_requirements_stats
                                  ?.total_objective_met_count /
                                  assessment?.security_requirements_stats
                                    ?.total_objective_count) *
                                100
                              : (assessment.security_requirements_stats
                                  .fully_met_count /
                                  assessment.security_requirements_stats
                                    .total_security_requirements) *
                                100
                          }
                          completedQuestion={
                            assessment.security_requirements_stats
                              .fully_met_count
                          }
                          totalQuestion={
                            assessment.security_requirements_stats
                              .total_security_requirements
                          }
                          totalEvidenceCount={
                            (assessment.security_requirements_stats
                              .total_evidence_count /
                              assessment.security_requirements_stats
                                .total_objective_count) *
                            100
                          }
                          assessmentLevleAndType={assessmentLevel}
                          totalNistSubcategories={
                            assessment.security_requirements_stats
                              ?.total_objective_count
                          }
                          assessmentId={assessmentId}
                          assessmentLevel={assessmentLevel}
                          totalMetCountNist={
                            assessment?.security_requirements_stats
                              ?.total_objective_met_count
                          }
                          assessmetType={assessmentTypes}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default Dashboard;
