import React, { FunctionComponent, useCallback, useState } from "react";
import { Text, CustomeButton, Logo, Input } from "shared";
// import { FcRemoveImage } from "react-icons/fc";
import { Colors } from "theme/color";
import { Box, Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import Image from "assets/two-factor.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import swal from "sweetalert";
import { ValidateOtp } from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { ContentContainer, SharedCard } from "./style";
import { RootState } from "store";
import { useSelector } from "react-redux";

const ResetSchema = Yup.object().shape({
  validOtp: Yup.string().required("Valid authentication code is required"),
});

type Props = Record<string, never>;

const MultiFactorAuthentication: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const userEmail = userInfo?.email;
  const handleValidate = useCallback(
    async (values: { validOtp: string }) => {
      setIsLoading(true);
      try {
        // attempt a login, and if successful get the user profile
        const result = await dispatch(
          ValidateOtp({
            validOtp: values.validOtp,
          })
        ).unwrap();

        // const checkEmailVerification = await dispatch(
        //   userLoginEmailVerification({
        //     email: userEmail,
        //   })
        // ).unwrap();
        setIsLoading(false);

        if (result.is_invited_user === true) {
          swal({
            title: "Two Factor Authentication",
            text: "Successfully Verified Two Factor Authentication",
            icon: "success",
          });
          navigate("/task-board");
        } else if (result.is_invited_user === false) {
          navigate("/dashboard");
        } else if (result?.user_subscription.length === 0) {
          swal({
            title: "Two Factor Authentication",
            text: "Successfully Verified Two Factor Authentication",
            icon: "success",
          });
          navigate("/plan");
        } else {
          swal({
            title: "Two Factor Authentication",
            text: "Successfully Verified Two Factor Authentication",
            icon: "success",
          });
          navigate("/dashboard");
        }
      } catch (error: any) {
        setIsLoading(false);
        if (error?.data.status === 400) {
          swal({
            title: "Two Factor Authentication",
            text: "Invalid Two Factor Authentication Value!",
            icon: "error",
          });
        } else {
          swal({
            title: "Two Factor Authentication",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    },
    [dispatch]
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} alignItems="center">
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            display={{ xs: "none", md: "block" }}
            alignItems="center"
          >
            <Box>
              <Box pl={10} pr={10} pt={4}>
                <Box>
                  {" "}
                  <img
                    src={Image}
                    alt="new-password-img"
                    className="two-factor-img"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={6}>
            <ContentContainer
              pl={{ xs: 2, sm: 2, md: 6, lg: 8 }}
              pr={{ xs: 2, sm: 2, md: 6, lg: 8 }}
            >
              <SharedCard>
                <Box
                  pl={{ xs: 2, sm: 2, md: 6, lg: 10 }}
                  pr={{ xs: 2, sm: 2, md: 6, lg: 10 }}
                >
                  <Logo />
                  <Box mt={4} display={{ xs: "none", lg: "block" }}>
                    <Text
                      color={Colors.text.black}
                      fontSize="14"
                      variant="h6"
                      align="center"
                      fontWeight="700"
                    >
                      Two-factor authentication
                    </Text>
                  </Box>
                  {/* <Box mt={0}>
                    <Text
                      color={Colors.text.identify_bg}
                      fontSize="12"
                      align="center"
                      variant="subtitle2"
                      fontWeight="400"
                    >
                      {userEmail}
                    </Text>
                  </Box> */}
                  <Box pt={4}>
                    <Formik
                      initialValues={{
                        validOtp: "",
                      }}
                      validationSchema={ResetSchema}
                      onSubmit={handleValidate}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Box>
                          <Input
                            name="validOtp"
                            type="text"
                            label="Authentication Code"
                            value={values.validOtp}
                            onChange={handleChange("validOtp")}
                          />
                          {errors.validOtp && touched.validOtp ? (
                            <div className="error-message">
                              {errors.validOtp}
                            </div>
                          ) : null}

                          <Box pt={5}>
                            <CustomeButton
                              title="Verify"
                              align="center"
                              width="50%"
                              borderRadius="50px"
                              onClick={handleSubmit}
                              disabled={isLoading}
                            />
                          </Box>
                        </Box>
                      )}
                    </Formik>

                    <Box mt={3}>
                      <Stack direction="row" spacing={2}>
                        <Box mt={1}>
                          <PhoneIphoneIcon />
                        </Box>
                        <Box>
                          <Text
                            color={Colors.text.black}
                            fontSize="14px"
                            variant="subtitle1"
                            align="left"
                          >
                            Open the two-factor authentication app on your
                            device to view your authentication code of{" "}
                            <span
                              style={{
                                color: Colors.text.light_red,
                                fontWeight: 600,
                              }}
                            >
                              {userEmail}
                            </span>{" "}
                            and verify your identity.
                          </Text>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </SharedCard>
            </ContentContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MultiFactorAuthentication;
