import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));
