import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Text, CustomeButton, Input, QrCode, Loader } from "shared";
import { Colors } from "theme/color";
import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import Image from "assets/two-factor.png";
import swal from "sweetalert";
import { twoFactorOtp, verifyOtp } from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { ContentContainer, SharedCard } from "./style";
import { RootState } from "store";
import { useSelector } from "react-redux";

const ResetSchema = Yup.object().shape({
  validOtp: Yup.string().required("Valid authentication code is required"),
});

type Props = Record<string, never>;

const MultiFactorAuthentication: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state: RootState) => state.user.otpauthUrl);

  useEffect(() => {
    setIsLoading(true);
    dispatch(twoFactorOtp())
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [dispatch]);

  //   // verify otp code of two factor api
  const handleVerify = useCallback(
    async (values: { validOtp: string }) => {
      try {
        // attempt a login, and if successful get the user profile
        await dispatch(
          verifyOtp({
            twoFactorOtp: values.validOtp,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Two Factor Authentication",
              text: "Successfully verified two factor authentication",
              icon: "success",
            });
            navigate("/dashboard");
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Two Factor Authentication",
            text: "Invalid Two Factor Authentication Value!",
            icon: "error",
          });
        } else {
          swal({
            title: "Two Factor Authentication",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    },
    [dispatch]
  );

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} alignItems="center">
            <Grid
              item
              xs={12}
              md={5}
              lg={6}
              display={{ xs: "none", md: "block" }}
              alignItems="center"
            >
              <Box>
                <Box pl={10} pr={10} pt={4}>
                  <Box>
                    {" "}
                    <img
                      src={Image}
                      alt="new-password-img"
                      className="two-factor-img"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={6}>
              <ContentContainer
                pl={{ xs: 2, sm: 2, md: 6, lg: 8 }}
                pr={{ xs: 2, sm: 2, md: 6, lg: 8 }}
              >
                <SharedCard>
                  <Box
                    pl={{ xs: 2, sm: 2, md: 4, lg: 5 }}
                    pr={{ xs: 2, sm: 2, md: 4, lg: 5 }}
                  >
                    <Box mt={4} display={{ xs: "none", lg: "block" }}>
                      <Text
                        color={Colors.text.black}
                        fontSize="14"
                        variant="h6"
                        align="center"
                        fontWeight="700"
                      >
                        Two-factor authentication
                      </Text>
                      <Text fontSize="16px" fontWeight="400">
                        Connect an authenticator app (e.g. Google Authenticator,
                        Microsoft Authenticator) to generate a verification
                        code. Use the code to verify your identity.
                      </Text>
                      <ul>
                        <li>
                          Download and install an authenticator app on your
                          mobile device.
                        </li>
                        <li>Use the app to scan this QR code.</li>
                      </ul>
                      <Box mt={4}>
                        <QrCode otpauth_url={token} />
                      </Box>
                    </Box>
                    <Box pt={1}>
                      <Formik
                        initialValues={{
                          validOtp: "",
                        }}
                        validationSchema={ResetSchema}
                        onSubmit={handleVerify}
                      >
                        {({
                          errors,
                          touched,
                          values,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <Box>
                            <Input
                              name="validOtp"
                              type="text"
                              placeholder="Enter authentication Code"
                              value={values.validOtp}
                              onChange={handleChange("validOtp")}
                            />
                            {errors.validOtp && touched.validOtp ? (
                              <div className="error-message">
                                {errors.validOtp}
                              </div>
                            ) : null}

                            <Box pt={3} mb={5}>
                              <CustomeButton
                                title="Verify"
                                align="center"
                                width="50%"
                                borderRadius="50px"
                                onClick={handleSubmit}
                                disabled={isLoading}
                              />
                            </Box>
                          </Box>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </SharedCard>
              </ContentContainer>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default MultiFactorAuthentication;
