//button Colors
const BUTTON_PRIMARY_BACKGROUND = "#FED74F";
const BUTTON_BORDER_GRAY_03 = "#BDBDBD";
const BUTTON_GRAY = "#777777";

//Text colors
const TEXT_WHITE = "#FFFFFF";
const TEXT_BLACK = "#000000";
const TEXT_BLUE = "#4258FF";
const TEXT_GREY = "#727272";
const TEXT_NAVY = "#003D96";
const TEXT_SKY = "#2392F7";
const TEXT_ORANGE = "#FDB62F";
const TEXT_GREEN = "#0FD341";
const TEXT_SKY_1 = "#66A4FF";
const TEXT_THEME = "#FED74F";
const TEXT_LINK = "blue";
const TEXT_NAVY_ORANG = "#FDF4DA";
const TEXT_LIGHT_GREY = "#ACACAC";
const PLAN_BG_1 = "#060606";
const GOVERN = "#F9F49D";
const IDENTIFY = "#4CB2E0";
const PROTECT = "#918CEA";
const DETECT = "#FAB746";
const RESPOND = "#E67676";
const RECOVER = "#7DF49F";
const TOCTEXT = "rgb(3, 3, 41)";
const TOCLI = "#5b97a4";
const REDISH = "#af5079";
const INFO = "#6da2d1";

export const Colors = {
  button: {
    primary_bg: BUTTON_PRIMARY_BACKGROUND,
    border_gary_03: BUTTON_BORDER_GRAY_03,
    gray: BUTTON_GRAY,
  },
  text: {
    white: TEXT_WHITE,
    black: TEXT_BLACK,
    blue: TEXT_BLUE,

    grey: TEXT_GREY,

    navy: TEXT_NAVY,
    sky: TEXT_SKY,
    orange: TEXT_ORANGE,
    green: TEXT_GREEN,
    sky_1: TEXT_SKY_1,
    theme: TEXT_THEME,
    link: TEXT_LINK,
    navy_orange: TEXT_NAVY_ORANG,
    light_grey: TEXT_LIGHT_GREY,
    plan_bg_1: PLAN_BG_1,
    identify_bg: IDENTIFY,
    protect_bg: PROTECT,
    detect_bg: DETECT,
    respond_bg: RESPOND,
    recover_bg: RECOVER,
    govern_bg: GOVERN,
    toc: TOCTEXT,
    toc_li: TOCLI,
    light_red: REDISH,
    info: INFO,
  },
};

import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FF1F00",
    },
    secondary: {
      main: "#4B980E",
    },
  },
});
