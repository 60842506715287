import { resetStore } from "store";
const AUTH_TOKEN_KEY = "authToken";

export const setAuthToken = async (token: string) => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const getAuthToken = async (): Promise<string | null> => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return token ? token : null;
};

export const AUTHORIZATION_HEADER_NAME = "Authorization";

export const getAuthorizationHeaderValue = async () => {
  const authToken = await getAuthToken();
  return `Token ${authToken}`;
};

export const resetAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const logoutAction = async () => {
  resetStore();
};
