import React, { FunctionComponent, useEffect, useState } from "react";
import { Loader } from "shared";
import {
  verifyEmailAction,
  userOtpStatus,
  userLoginEmailVerification,
} from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";

type Props = Record<string, never>;

const EmailValidate: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const verificationCode = parts[1];
  const verificationEmail = parts[2];

  const emailString = `${verificationCode}/${verificationEmail}`;

  useEffect(() => {
    const verifyEmail = async () => {
      setLoading(true);

      try {
        if (emailString) {
          const result = await dispatch(
            verifyEmailAction({ email: emailString })
          ).unwrap();

          if (result) {
            const otpStatus = await dispatch(userOtpStatus()).unwrap();
            swal({
              title: "Account Verify",
              text: "Your email is verified successfully!",
              icon: "success",
            });

            const checkEmailVerification = await dispatch(
              userLoginEmailVerification({
                email: result?.response?.email,
              })
            ).unwrap();

            if (otpStatus.is_otp_enabled === false) {
              navigate("/multi-factor-enable");
            } else if (otpStatus.is_otp_enabled) {
              navigate("/multi-factor-authentication");
            } else if (checkEmailVerification.isVerified) {
              navigate("/dashboard");
            } else {
              navigate("/login");
            }
          }
        }
      } catch (err) {
        console.log(err);
        swal({
          title: "Account Verify",
          text: "Something went wrong. Please try again.",
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [dispatch, emailString, navigate]);

  return (
    <>
      {loading ? (
        <p>
          {" "}
          <Loader isLoading={loading} />
        </p>
      ) : null}
    </>
  );
};

export default EmailValidate;
