import React, { FunctionComponent } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { FaCommentDots } from "react-icons/fa";
import { Colors } from "theme/color";

const Input = styled("div")(({ theme }) => ({
  padding: "2px 2px",
  border: "none !important",
  borderBottom: "none !important",
  minHeight: "18px",
  width: "100%",
  fontSize: "18px",
  [theme.breakpoints.down("md")]: {
    padding: "20px 6px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 9px",
    width: "100%",
  },
}));

type Props = {
  placeholder?: string;
  value?: string;
};

const SSPCommentInput: FunctionComponent<Props> = ({
  placeholder = "",
  value,
}: Props) => {
  return (
    <Box
      sx={{ width: "100%", overflowX: "hidden" }}
      display="flex"
      alignItems="center"
    >
      <Box mr={3}>
        <FaCommentDots
          style={{ fontSize: "30px", color: Colors.text.orange }}
        />
      </Box>
      <Input>{value ? value : placeholder}</Input>
    </Box>
  );
};

export default SSPCommentInput;
