import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, InputLabel } from "@mui/material";

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  padding: theme.spacing(2),
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
}));

export const FlexBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export const BoxSm = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
  },
}));

export const Lable = styled(InputLabel)`
  color: #000;
  font-weight: bold;
  margin-left: 25px;
  margin-top: 10px;
`;

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  overflowY: "auto",
  maxHeight: "87vh",
  paddingBottom: "15px",
};
