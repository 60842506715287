import React from "react";
import { FaUniversalAccess } from "react-icons/fa";
import {
  MdAccountBalanceWallet,
  MdOutlineAssessment,
  MdOutlineSecurity,
} from "react-icons/md";
import {
  GiAwareness,
  GiFireSpellCast,
  GiSecurityGate,
  GiSatelliteCommunication,
  GiSolarSystem,
} from "react-icons/gi";
import { SiGoogletagmanager } from "react-icons/si";
import { HiIdentification } from "react-icons/hi";
import { GrVmMaintenance, GrShieldSecurity } from "react-icons/gr";
import { CgMediaPodcast } from "react-icons/cg";
import CyberSecurity from "assets/cyber-security.png";
import Identification from "assets/identify.png";
import Respond from "assets/auto-reply.png";
import Detect from "assets/detection.png";
import Recover from "assets/recover.png";
import Protect from "assets/shield.png";

export const phaseMapping: Record<string, number> = {
  Reconnaissance: 1,
  "Intelligence Gathering": 2,
  "Fortress Fortification": 3,
  "Tactical Communication": 4,
  "War Games": 5,
  "Battle Ready": 6,
};

export const contentText =
  "\\A To earn this badge complete \\A the following steps";

export const contentTextCompleted = "\\A Already earn this badge";

//   date
export const formatDate = (date: any) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate().toString().padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

type Icons = {
  [key: string]: React.ReactNode;
};
export const icons: Icons = {
  "Access Control": React.createElement(FaUniversalAccess),
  "Audit and Accountability": React.createElement(MdAccountBalanceWallet),
  "Awareness and Training": React.createElement(GiAwareness),
  "Configuration Management": React.createElement(SiGoogletagmanager),
  "Identification and Authentication": React.createElement(HiIdentification),
  "Incident Response": React.createElement(GiFireSpellCast),
  Maintenance: React.createElement(GrVmMaintenance),
  "Media Protection": React.createElement(CgMediaPodcast),
  "Personnel Security": React.createElement(GrShieldSecurity),
  "Physical Protection": React.createElement(GiSecurityGate),
  "Risk Assessment": React.createElement(MdOutlineAssessment),
  "Sercurity Assessment": React.createElement(MdOutlineSecurity),
  "System and Communications Protection": React.createElement(
    GiSatelliteCommunication
  ),
  "System and Information Integrity": React.createElement(GiSolarSystem),
  "DETECT (DE)": React.createElement("img", {
    src: Detect,
    alt: "Custom Icon",
    width: 35,
    height: 35,
  }),
  "GOVERN (GV)": React.createElement("img", {
    src: CyberSecurity,
    alt: "Custom Icon",
    width: 38,
    height: 38,
  }),
  "IDENTIFY (ID)": React.createElement("img", {
    src: Identification,
    alt: "Custom Icon",
    width: 35,
    height: 35,
  }),
  "RESPOND (RS)": React.createElement("img", {
    src: Respond,
    alt: "Custom Icon",
    width: 38,
    height: 38,
  }),
  "RECOVER (RC)": React.createElement("img", {
    src: Recover,
    alt: "Custom Icon",
    width: 38,
    height: 38,
  }),
  "PROTECT (PR)": React.createElement("img", {
    src: Protect,
    alt: "Custom Icon",
    width: 35,
    height: 35,
  }),
};
export const assessmentNistSp = "NIST SP 800-171";
export const assessmentTypeNISTCSF = "NIST CSF 2.0";
export const assessmentTypeNISTCSFQSG = "NIST CSF Quick Start Guide";
export const NISTCSFTotalObjectives = 106;
export const levelOneTotalObjective = 59;
export const levelTwoPaginationCount = 14;
export const NISTCSFPaginationCount = 6;
export const totalObjectivesLevelTwo = 261;
export const totalObjectivesNISTCSFQSG = 38;

export const TIMEOUT_DURATION = 3600000; // 60 minutes in milliseconds

export const GOV =
  "GOVERN (GV): The organization's cybersecurity risk management strategy, expectations, and policy are established, communicated, and monitored";

export const IDENTIFY =
  "IDENTIFY (ID) : The organization's current cybersecurity risks are understood";

export const PROTECT =
  "PROTECT (PR): Safeguards to manage the organization's cybersecurity risks are used";

export const DETECT =
  "DETECT (DE): Possible cybersecurity attacks and compromises are found and analyzed";

export const RESPOND =
  "RESPOND (RS): Actions regarding a detected cybersecurity incident are taken";

export const RECOVER =
  "RECOVER (RC): Assets and operations affected by a cybersecurity incident are restored";

const familyNames = [
  "Access Control",
  "Awareness and Training",
  "Audit and Accountability",
  "Configuration Management",
  "Identification and Authentication",
  "Incident Response",
  "Maintenance",
  "Media Protection",
  "Personnel Security",
  "Physical Protection",
  "Risk Assessment",
  "Sercurity Assessment",
  "System and Communications Protection",
  "System and Information Integrity",
];

export const familyNameToPageNumber: { [key: string]: number } =
  familyNames.reduce(
    (acc: { [key: string]: number }, name: string, index: number) => {
      acc[name] = index + 1; // Assuming page numbers start at 1
      return acc;
    },
    {}
  );

export const FortressPackage = "Fortress";
export const Bunker = "Bunker";
export const FrontlineDefender = "Frontline Defender";
