import React, { FunctionComponent } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  CircularProgressProps,
} from "@mui/material";

function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    color: string;
    status: string;
    padding: any;
  }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        sx={{
          color: props.value < 0 ? "red" : props.color,
          background: "#D9D9D9",
          borderRadius: "200px",
        }}
        variant="determinate"
        {...props}
        style={{ width: "190px", height: "190px" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "190px",
          height: "190px",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="black"
          style={{
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "center",
            backgroundColor: "white",
            padding: props.padding,
            borderRadius: "200px",
          }}
        >
          <Typography sx={{ textAlign: "center" }}>
            {" "}
            {`${Math.round(props.value)}%`}{" "}
          </Typography>
          {props?.status}
        </Typography>
      </Box>
    </Box>
  );
}

type Props = {
  value?: number;
  mt?: string;
  mb?: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  status?: string;
  padding?: any;
};

const CircularProgres: FunctionComponent<Props> = ({
  value = 90,
  mt = "20px",
  mb = "10px",
  color = "error",
  status = "",
  padding = "0 0",
}: Props) => {
  return (
    <>
      <Box mt={mt} mb={mb}>
        <CircularProgressWithLabel
          value={value}
          color={color}
          status={status}
          padding={padding}
        />
      </Box>
    </>
  );
};
export default CircularProgres;
