import React, { FunctionComponent, ReactNode } from "react";
import {
  Box,
  Modal,
  IconButton,
  FormControlLabel,
  Divider,
  Backdrop,
  TextField,
  Autocomplete,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Text, CustomeButton, CollaborationUserList } from "shared";
import { Colors } from "theme/color";
import { RxCrossCircled } from "react-icons/rx";
import { FcCollaboration } from "react-icons/fc";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import { RootState } from "store";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { LightTooltip } from "utils/sharedStyled";

const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
}));

const ModalBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  overflowY: "auto",
  minHeight: "75vh",
  maxHeight: "75vh",
  marginBottom: "10px",
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  p: 0,
  minHeight: "90vh",
  maxHeight: "90vh",
  paddingBottom: "15px",
  boxShadow: "none !important",
};
const CustomBackdrop = styled(Backdrop)(() => ({
  backgroundColor: "rgba(0, 0, 0, 0.1) !important", // Customize the backdrop color and transparency
}));

type Props = {
  width?: string;
  title?: string;
  values?: any;
  selectedEmails?: any;
  viewChecked?: any;
  writeChecked?: any;
  handleViewChange?: () => void;
  handleWriteChange?: () => void;
  children?: ReactNode | ReactNode[];
  handleClose?: () => void;
  handleChangeSelect?: // eslint-disable-next-line no-unused-vars
  (e: any, value: any) => void;
  open?: any;
  handleOpen?: () => void;
  personName?: any;
  handleInputChange?: // eslint-disable-next-line no-unused-vars
  (e: any) => void;
  handleSubmit?: any;
  loaderButton?: any;
  permissionUsersList?: any;
  listLoader?: any;
  handleChangeSelectBufferPhase?: // eslint-disable-next-line no-unused-vars
  (e: any, value: any) => void;
  phaseName?: any;
  showPhase?: boolean;
  bufferPhasesName?: any;
  handleClickToggle?: // eslint-disable-next-line no-unused-vars
  (e: any, value: any) => void;
  userPermissions?: any;
  handleDeletePermission?: // eslint-disable-next-line no-unused-vars
  (email: any) => void;
  loader?: any;
  handleViewResouce?: () => void;
  viewResource?: boolean;
  multipleUserPermissions?: any;
  multiPhaseFlag?: boolean;
  buttonTitle?: any;
  iconOnly?: boolean;
};

const Collaboration: FunctionComponent<Props> = ({
  width = "100%",
  title,
  viewChecked,
  writeChecked,
  handleWriteChange,
  handleViewChange,
  children,
  handleOpen,
  handleClose,
  handleChangeSelect,
  open,
  personName,
  handleSubmit,
  loaderButton,
  listLoader,
  handleChangeSelectBufferPhase,
  phaseName,
  showPhase = false,
  bufferPhasesName,
  userPermissions,
  handleClickToggle,
  handleDeletePermission,
  handleInputChange,
  loader,
  handleViewResouce,
  viewResource = true,
  multipleUserPermissions,
  multiPhaseFlag,
  buttonTitle,
  iconOnly = false,
}: Props) => {
  const inviteUserList = useSelector(
    (state: RootState) => state.collaboration.inviteAcceptUserList
  );

  const inviteUser = inviteUserList?.map((item: any) => {
    const accpetInviteUserList = {
      id: item.pk,
      userName: item.email_address,
    };
    return accpetInviteUserList;
  });

  const userNames = inviteUser?.map((item: any) => item.userName);

  return (
    <Box>
      <Box
        style={{
          width: width,
        }}
      >
        <LightTooltip title="Click to assign permission" arrow placement="top">
          {buttonTitle && iconOnly === false ? (
            <Button
              aria-label="collaboration"
              onClick={handleOpen}
              sx={{
                background: Colors.text.navy_orange,
                textTransform: "capitalize",
                color: Colors.text.black,
              }}
              variant="contained"
              endIcon={<FcCollaboration />}
            >
              {buttonTitle}
            </Button>
          ) : iconOnly ? (
            <Box
              onClick={handleOpen}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
              sx={{ padding: "4px 0px" }}
            >
              <Box sx={{ fontSize: "16px" }}>{buttonTitle}</Box>
              <Box ml={2}>
                <FcCollaboration />
              </Box>
            </Box>
          ) : (
            <IconButton
              aria-label="collaboration"
              onClick={handleOpen}
              sx={{
                background: Colors.text.navy_orange,
              }}
            >
              <FcCollaboration />
            </IconButton>
          )}
        </LightTooltip>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={CustomBackdrop}
      >
        <ModalContent sx={style}>
          <Header
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text fontSize="15px" fontWeight="600" color={Colors.text.black}>
              share {title}
            </Text>
            <RxCrossCircled
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </Header>
          <Divider />

          <ModalBody>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={0}
              mt={2}
              ml={2}
              mr={2}
            >
              <Text color={Colors.text.green}>
                Select users to share feature
              </Text>
              {viewResource && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleViewResouce}
                >
                  View Resource
                </Button>
              )}
            </Box>

            <Box mb={0} ml={2} mr={2}>
              <Box pt={1} pb={2}>
                {children}
              </Box>
              {/* buffer phases list show only for bulk api on battle ready modal */}
              {showPhase && (
                <Box mb={1}>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    multiple
                    options={bufferPhasesName}
                    value={phaseName}
                    onChange={handleChangeSelectBufferPhase}
                    getOptionLabel={(option) => option}
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Buffer Zone Phases"
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        {...props}
                        key={option}
                        value={option}
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                        {loader ? (
                          "loading..."
                        ) : (
                          <>
                            {option}
                            {selected ? <CheckIcon color="info" /> : null}
                          </>
                        )}
                      </MenuItem>
                    )}
                  />
                </Box>
              )}
              {/* select user with accept invite */}
              <Box>
                <Autocomplete
                  sx={{ width: "100%" }}
                  multiple
                  options={userNames}
                  value={personName}
                  onChange={handleChangeSelect}
                  getOptionLabel={(option) => option}
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Users To Invite"
                      onChange={handleInputChange}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem
                      {...props}
                      key={option}
                      value={option}
                      sx={{ justifyContent: "space-between" }}
                    >
                      {loader ? (
                        "loading..."
                      ) : (
                        <>
                          {option}
                          {selected ? <CheckIcon color="info" /> : null}
                        </>
                      )}
                    </MenuItem>
                  )}
                />
              </Box>
              {/* checkbox of view */}
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: Colors.button.primary_bg,
                      }}
                      checked={viewChecked}
                      onChange={handleViewChange}
                    />
                  }
                  label="Viewer"
                />
              </Box>
              {/* checkbox of editor */}
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: Colors.button.primary_bg,
                      }}
                      checked={writeChecked}
                      onChange={handleWriteChange}
                    />
                  }
                  label="Editor"
                />
              </Box>
            </Box>
            {/* user list with permission list */}
            <CollaborationUserList
              listLoader={listLoader}
              userPermissions={userPermissions}
              handleClickToggle={handleClickToggle}
              handleDeletePermission={handleDeletePermission}
              multipleUserPermissions={multipleUserPermissions}
              multiPhaseFlag={multiPhaseFlag}
            />
          </ModalBody>

          <CustomeButton
            title="Save"
            width="120px"
            fontWeight="500"
            onClick={handleSubmit}
            disabled={loaderButton}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Collaboration;
