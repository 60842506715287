import React, { FunctionComponent } from "react";
import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  LinearProgressProps,
} from "@mui/material";
import { customTheme } from "theme/color";
import { ThemeProvider } from "@mui/material/styles";
import { Text } from "shared";

type Props = {
  minValue?: number;
  maxValue?: number;
  value?: number;
  mt?: string;
  mb?: string;
};

const SprsProgres: FunctionComponent<Props> = ({
  minValue = -203,
  maxValue = 110,
  value = 0,
  mt = "20px",
  mb = "10px",
}: Props) => {
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: -23,
            left: `${props.value}%`,
            transform: "translateX(-50%)",
          }}
        >
          <Typography variant="body2">{value}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Box mt={mt} mb={mb}>
        <LinearProgressWithLabel
          color={value < 0 ? "primary" : "secondary"}
          variant="determinate"
          value={((value + 203) / 313) * 100}
          style={{
            backgroundColor: "#D9D9D9",
            height: 7,
          }}
        />
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Text>{minValue}</Text>
          <Text>{maxValue}</Text>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};
export default SprsProgres;
