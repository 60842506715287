import React, {
  FunctionComponent,
  useState,
  useMemo,
  useCallback,
} from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Avatar,
  Modal,
  ImageList,
  ImageListItem,
  Tab,
  IconButton,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { LightTooltip } from "utils/sharedStyled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Switch,
  CommentInput,
  Input,
  Button,
  TextArea,
  Text,
  Loader,
} from "shared";
import axios from "axios";
import {
  getAuthorizationHeaderValue,
  AUTHORIZATION_HEADER_NAME,
} from "utils/auth";
import { COMMON } from "utils/common";
import swal from "sweetalert";
import {
  addEvidence,
  getEvidenceReport,
  evidenceImage,
  putEvidenceImage,
  deleteEvidence,
  userQaResponse,
  undoQaResponse,
  addCommentNistCsfSubCategory,
  addComment,
} from "reducers/assessment";
import useAppDispatch from "hooks/useAppDispatch";
import { Formik } from "formik";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaImages, FaCommentMedical } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { AiFillCloseCircle, AiFillEdit } from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  evidenceModal,
  commentModal,
  ObjectiveTooltip,
} from "utils/sharedStyled";
import { Colors } from "theme/color";
import { BsInfoSquareFill } from "react-icons/bs";
import { assessmentTypeNISTCSFQSG } from "utils/constant";

const AvidanceSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name field is required")
    .min(1, "Too Short!")
    .max(250, "Too Long!"),
  additionalNotes: Yup.string()
    .required("Notes field is required")
    .min(1, "Too Short!")
    .max(250, "Too Long!"),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderTop: "1px solid grey",
    borderBottom: "1px solid grey",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 400,
    border: "1px solid grey",
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    border: "1px solid grey",
  },
  "&:nth-of-type(odd)": {
    border: "1px solid grey",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: "1px solid grey",
    borderRight: "1px solid grey",
  },
});

const StyledSpan = styled("span")({
  fontSize: "15px",
  fontWeight: "bold",
  paddingRight: "10px",
});

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
}));

const ImagesListCustome = styled(Box)({
  overflowX: "auto",
  maxHeight: "280px",
});

type Props = {
  assessmentObjective?: string;
  notInterested?: any;
  met?: any;
  notMet?: any;
  data: any;
  disabled?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  assesmtId?: any;
  levelId?: any;
  updateCompletedQueries: // eslint-disable-next-line no-unused-vars
  (change: number) => void;
  viewFlag?: boolean;
  phaseCardEmail?: any;
  notMerSwitchColor?: any;
  objectiveWeitgh?: any;
  weightFlag?: any;
  hideObjectiveTitle?: any;
  showCommentsColumn?: any;
  comentColumnTitle?: any;
  assessmentTypeCurrent?: any;
};

const AssessmentTable: FunctionComponent<Props> = ({
  assessmentObjective = "Assessment Objectives  ",
  notInterested = "N/A",
  met = "MET",
  notMet = "NOT MET",
  data: data,
  assesmtId,
  updateCompletedQueries,
  viewFlag,
  phaseCardEmail,
  notMerSwitchColor = "red",
  weightFlag = false,
  hideObjectiveTitle = false,
  showCommentsColumn = false,
  comentColumnTitle = "Comment",
  assessmentTypeCurrent,
}: Props) => {
  if (!data) {
    return <div>No data found</div>;
  }

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const navigate = useNavigate();

  const [naSwitches, setNaSwitches] = useState(
    data.objectives?.map((objective: any) => objective.status.notInterested)
  );
  const [metSwitches, setMetSwitches] = useState(
    data.objectives?.map((objective: any) => objective.status.met)
  );
  const [notMetSwitches, setNotMetSwitches] = useState(
    data.objectives?.map((objective: any) => objective.status.notMet)
  );

  const [isEvidence, setIsEvidence] = useState(
    data.objectives?.map((objective: any) => objective.isEvidenceProvided)
  );
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState(data?.userComment);

  const tableId = useMemo(() => {
    return data?.objectives?.length > 0
      ? data?.objectives[0].assessmentSecurityRequirementId
      : -1;
  }, []);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(getTodayDate()));
  const [mediaFlag, setMediaFlage] = useState(false);
  const [objectiveNumber, setObjectiveNumber] = useState<any>("");
  const [tabValue, setTabValue] = React.useState("1");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [modalOpenComment, setModalOpenComment] = useState(false);
  const [commentValue, setCommentValue] = useState("");

  const [currentCommentObjectId, setCurrentCommentObjectId] = useState<any>();
  const [currentCommentObjectValue, setCurrentCommentObjectValue] =
    useState<any>();
  const [submitFlag, setSubmitFlag] = useState(false);
  const [changeCommentValue, setChangeCommentValue] = useState("");
  const [commentUpdate, setCommentUpdate] = useState("");
  const [commentLoader, setCommentLoader] = useState(false);
  const [previousComment, setPreviousComment] = useState("");

  const assesmentId = assesmtId;
  // Update localStorendComent based on the objectId
  const updatedComment = useSelector(
    (state: RootState) =>
      state?.assessment?.comments[assesmentId]?.[currentCommentObjectId]
  );

  const handleComment = (objectId: any, index: any, comment: any) => {
    if (userInfo?.email !== phaseCardEmail) {
      swal({
        title: "Unauthorized Access",
        text: "Sorry, You can't make this change!",
        icon: "error",
      });
      return;
    }
    const getStatusObject = () => {
      if (naSwitches[index]) {
        return "na";
      } else if (metSwitches[index]) {
        return "met";
      } else if (notMetSwitches[index]) {
        return "not_met";
      } else {
        return "null"; // Handle the case when none of the switches are true
      }
    };
    const status = getStatusObject();

    if (status === "null") {
      setSubmitFlag(true);
    } else {
      setSubmitFlag(false);
    }
    setPreviousComment(comment);
    setCommentValue(comment);
    setCurrentCommentObjectValue(status);
    setCurrentCommentObjectId(objectId);
    setModalOpenComment(true);
  };

  const indexUpdatedComment = updatedComment ? updatedComment : previousComment;

  React.useEffect(() => {
    setCommentLoader(true);
    setCommentValue(indexUpdatedComment);
    setChangeCommentValue(commentValue);
    setCommentUpdate(changeCommentValue);
    const timeout = setTimeout(() => {
      setCommentLoader(false);
    }, 1500);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeout);
  }, [updatedComment, commentValue, changeCommentValue, indexUpdatedComment]);

  const addCommentNistObjective = () => {
    if (!commentUpdate || !commentUpdate.trim()) {
      swal({
        title: "Comment",
        text: "Please add comment!",
        icon: "warning",
      });
      return; // Exit the function if commentValue is empty
    }
    dispatch(
      addCommentNistCsfSubCategory({
        answer: currentCommentObjectValue,
        assessment: assesmtId,
        assessment_objective: currentCommentObjectId,
        comment: commentUpdate,
        task: false,
      })
    )
      .unwrap()
      .then((res) => {
        const assessmentId = assesmentId;
        const objectiveId = res.response.assessment_objective;
        const comment = res.response.comment;

        dispatch(addComment({ assessmentId, objectiveId, comment }));
        setModalOpenComment(false);
        setCommentValue("");
        setChangeCommentValue("");
        setCommentUpdate("");
        swal({
          title: "Comment",
          text: "Successfully save comment",
          icon: "success",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCloseModalComments = () => {
    setModalOpenComment(false);
    setCommentValue("");
    setChangeCommentValue("");
    setCommentUpdate("");
  };

  const theme = useTheme();

  // comment api call on blur event
  async function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;

    setInputValue(value);
    try {
      const authToken = await getAuthorizationHeaderValue();
      axios.post(
        `${COMMON.apiBaseUrl}assessment/store-assessment-comment`,
        {
          comment: value,
          assessment: assesmtId,
          assessment_security_requirement: tableId,
        },
        {
          headers: {
            [AUTHORIZATION_HEADER_NAME]: authToken,
          },
        }
      );
    } catch (error) {
      swal({
        title: "CMMS",
        text: "Something went wrong",
        icon: "error",
      });
    }
  }

  // handle switches change
  const handleMetSwitchChange =
    (index: any, switchType: any) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (userInfo?.email !== phaseCardEmail) {
        swal({
          title: "Unauthorized Access",
          text: "Sorry, You can't make this change!",
          icon: "error",
        });
        return;
      }

      const objectiveId = data.objectives[index].id;
      if (switchType === "na") {
        const newSwitches = [...naSwitches];
        const newMetSwitches = [...metSwitches];
        const newNotMetSwitches = [...notMetSwitches];
        newSwitches[index] = event.target.checked;
        newMetSwitches[index] = false;
        newNotMetSwitches[index] = false;
        setNaSwitches(newSwitches);
        setMetSwitches(newMetSwitches);
        setNotMetSwitches(newNotMetSwitches);
      } else if (switchType === "met") {
        const newSwitches = [...metSwitches];
        const newNaSwitches = [...naSwitches];
        const newNotMetSwitches = [...notMetSwitches];
        newSwitches[index] = event.target.checked;
        newNaSwitches[index] = false;
        newNotMetSwitches[index] = false;
        setMetSwitches(newSwitches);
        setNaSwitches(newNaSwitches);
        setNotMetSwitches(newNotMetSwitches);
      } else {
        const newSwitches = [...notMetSwitches];
        const newNaSwitches = [...naSwitches];
        const newMetSwitches = [...metSwitches];
        newSwitches[index] = event.target.checked;
        newNaSwitches[index] = false;
        newMetSwitches[index] = false;
        setNotMetSwitches(newSwitches);
        setNaSwitches(newNaSwitches);
        setMetSwitches(newMetSwitches);
      }
      let value;

      if (switchType === "na") {
        value = event.target.checked ? "na" : "na";
      } else if (switchType === "met") {
        value = event.target.checked ? "met" : "met";
      } else {
        value = event.target.checked ? "not_met" : "not_met";
      }

      const naSwitchValue = naSwitches[index];
      const metSwitchValue = metSwitches[index];
      const notMetSwitchValue = notMetSwitches[index];

      const change = event.target.checked
        ? naSwitchValue || metSwitchValue || notMetSwitchValue
          ? 0
          : 1
        : -1;

      updateCompletedQueries(change);

      try {
        if (event.target.checked) {
          await dispatch(
            userQaResponse({
              answer: value,
              assessment: assesmtId,
              assessment_objective: objectiveId,
              task: true,
            })
          )
            .unwrap()
            .then((res) => {
              if (!res.success) {
                // Handle the error message when success is false

                swal({
                  title: "Unauthorized Access",
                  text: "You cannot perform action you have only view access!",
                  icon: "error",
                });
                // Uncheck the current index based on the switch type
                if (switchType === "na") {
                  const newSwitches = [...naSwitches];
                  newSwitches[index] = false;
                  setNaSwitches(newSwitches);
                } else if (switchType === "met") {
                  const newSwitches = [...metSwitches];
                  newSwitches[index] = false;
                  setMetSwitches(newSwitches);
                } else {
                  const newSwitches = [...notMetSwitches];
                  newSwitches[index] = false;
                  setNotMetSwitches(newSwitches);
                }
                const change = -1;

                updateCompletedQueries(change);
              } else {
                // Handle success if needed
              }
            })
            .catch((err: any) => {
              console.log(err);
              swal({
                title: "Error",
                text: "Something went wrong please try again!",
                icon: "error",
              });
            });
        } else {
          await dispatch(
            undoQaResponse({
              answer: value,
              assessment: assesmtId,
              assessment_objective: objectiveId,
              task: true,
            })
          )
            .unwrap()
            .then((res) => {
              if (!res.success) {
                // Handle the error message when success is false

                swal({
                  title: "Unauthorized Access",
                  text: "You cannot perform action you have only view access!",
                  icon: "error",
                });
                // Uncheck the current index based on the switch type
                if (switchType === "na") {
                  const newSwitches = [...naSwitches];
                  newSwitches[index] = true;
                  setNaSwitches(newSwitches);
                } else if (switchType === "met") {
                  const newSwitches = [...metSwitches];
                  newSwitches[index] = true;
                  setMetSwitches(newSwitches);
                } else {
                  const newSwitches = [...notMetSwitches];
                  newSwitches[index] = true;
                  setNotMetSwitches(newSwitches);
                }
                const change = 1;

                updateCompletedQueries(change);
              } else {
                // Handle success if needed
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        }
      } catch (error) {
        console.error("Error sending API request:", error);
        swal({
          title: "CMMS",
          text: "Something went wrong",
          icon: "error",
        });
      }
    };

  const evidenceList = useSelector(
    (state: RootState) => state.assessment.evidenveListData
  );

  const img = evidenceList?.evidence_images;

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  React.useEffect(() => {
    if (img?.length > 0) {
      setMediaFlage(true);
    } else {
      setMediaFlage(false);
    }
  }, [img]);

  const [loading, setLoading] = useState(false);

  const fileInputRef = React.createRef<HTMLInputElement>();

  const editFileInputRef = React.createRef<HTMLInputElement>();

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue && newValue.isAfter(dayjs(), "day")) {
      return;
    }
    setValue(newValue);
  };

  React.useEffect(() => {
    const todayDate = dayjs().format("YYYY/MM/DD");
    const date = evidenceList?.evidence_date
      ? evidenceList?.evidence_date
      : todayDate;
    setValue(dayjs(date));
  }, [evidenceList]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItemIndex(null);
    setTabValue("1");
  };

  const handleAvatarClick = useCallback(
    async (index: any, questionNumber: any) => {
      if (userInfo?.email !== phaseCardEmail) {
        swal({
          title: "Unauthorized Access",
          text: "Sorry, You can't make this change!",
          icon: "error",
        });
        return;
      }
      setSelectedItemIndex(index);
      setObjectiveNumber(questionNumber);
      // get api for evidence

      try {
        await dispatch(
          getEvidenceReport({
            assessmentId: assesmtId,
            objectiveId: index,
          })
        ).unwrap();
      } catch (error: any) {
        if (error?.data?.status === 500) {
          console.log("Internal Server Error");
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
      setModalOpen(true);
    },
    [dispatch, assesmtId, selectedItemIndex, evidenceList]
  );

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisabled(true);
    const fileInput = event?.target;
    const file = fileInput.files![0];

    const maxSize = 1000000; // 1MB in bytes
    if (file.size > maxSize) {
      swal({
        title: "Evidence",
        text: "Please upload an image less than 1MB.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }
      return;
    }

    const maxFileNameLength = 100;
    if (file.name.length > maxFileNameLength) {
      swal({
        title: "Evidence",
        text: "Please upload an image with name of 100 characters or less.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }

      return;
    }

    // Check if the file type is allowed
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      swal({
        title: "Evidence",
        text: "Please upload an image in .jpg and .png format.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }
      return;
    }

    // api call when new image is uploaded
    if (file) {
      setLoading(true);
      try {
        // post api call for image
        dispatch(
          evidenceImage({
            assessment_evidence: evidenceList?.pk,
            image: file,
          })
        )
          .unwrap()
          .then(() => {
            if (fileInput instanceof HTMLInputElement) {
              fileInput.value = ""; // Use an empty string instead of null
            }
            dispatch(
              // list api call
              getEvidenceReport({
                assessmentId: assesmtId,
                objectiveId: selectedItemIndex,
              })
            )
              .unwrap()
              .then(() => {
                setLoading(false);
                setIsDisabled(false);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.status === 400) {
          swal({
            title: "Evidence",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    }
  };

  const handleImageChangeOnEdit = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDisabled(true);
    const fileInput = event?.target;
    const file = fileInput.files![0];

    const maxSize = 1000000; // 1MB in bytes
    if (file.size > maxSize) {
      swal({
        title: "Evidence",
        text: "Uploaded file format must be less than 1MB",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }
      return;
    }

    const maxFileNameLength = 100;
    if (file.name.length > maxFileNameLength) {
      swal({
        title: "Evidence",
        text: "Please use an image with a file name of 100 characters or less.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }

      return;
    }

    // Check if the file type is allowed
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      swal({
        title: "Evidence",
        text: "Please upload an image in .png, .jpeg, or .jpg format.",
        icon: "error",
      });
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = ""; // Use an empty string instead of null
      }
      return;
    }

    // api call when new image is uploaded
    if (file) {
      setLoading(true);
      try {
        // post api call for image
        dispatch(
          putEvidenceImage({
            evidence_image_id: localStorage.getItem("evidence_image_id"),
            assessment_evidence: evidenceList?.pk,
            image: file,
          })
        )
          .unwrap()
          .then(() => {
            if (fileInput instanceof HTMLInputElement) {
              fileInput.value = ""; // Use an empty string instead of null
            }
            dispatch(
              // list api call
              getEvidenceReport({
                assessmentId: assesmtId,
                objectiveId: selectedItemIndex,
              })
            )
              .unwrap()
              .then(() => {
                setLoading(false);
                setIsDisabled(false);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.status === 400) {
          swal({
            title: "Evidence",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    }
  };
  // ref button to upload evidence images
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleButtonClickOnEdit = (pk: any, index: any) => {
    console.log("On Edit Image Evidence PK = ", pk, " and Index = " + index);
    localStorage.setItem("evidence_image_id", pk);
    if (editFileInputRef.current) {
      editFileInputRef.current.click();
    }
  };

  // add information of from in evidence
  const handleUpdate = useCallback(
    async (values: { name: string; additionalNotes: string }) => {
      const newSelectedDate = value?.format("YYYY-MM-DD");

      try {
        await dispatch(
          addEvidence({
            pk: evidenceList?.pk,
            assessmentId: assesmtId,
            assessmentObjective: selectedItemIndex,
            name: values.name,
            additionalNotes: values.additionalNotes,
            evidenceDate: newSelectedDate ? newSelectedDate : "",
          })
        )
          .unwrap()
          .then(async () => {
            dispatch(
              // list api call
              getEvidenceReport({
                assessmentId: assesmtId,
                objectiveId: selectedItemIndex,
              })
            ).unwrap();
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Evidence",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setTabValue("2");
      }
    },
    [
      dispatch,
      evidenceList,
      assesmtId,
      selectedItemIndex,
      value,
      data,
      tabValue,
    ]
  );

  const handleFinish = useCallback(async () => {
    const indexSlected = selectedItemIndex ? selectedItemIndex : 0;
    const index = data.objectives.findIndex(
      (objective: any) => objective.pkId === indexSlected
    );
    // const naSwitchValue = naSwitches[index];
    const metSwitchValue = metSwitches[index];
    // const notMetSwitchValue = notMetSwitches[index];
    const updatedEvidence = [...isEvidence];
    updatedEvidence[index] = true;
    setIsEvidence(updatedEvidence);

    try {
      // Send first API post request when switch is checked

      if (!metSwitchValue) {
        const updatedMetSwitches = [...metSwitches];
        const updatedNotMetSwitches = [...notMetSwitches];
        const updatedNaSwitches = [...naSwitches];
        updatedMetSwitches[index] = true;
        updatedNaSwitches[index] = false;
        updatedNotMetSwitches[index] = false;
        // Set the updated array as the new state
        setNaSwitches(updatedNaSwitches);
        setNotMetSwitches(updatedNotMetSwitches);
        setMetSwitches(updatedMetSwitches);
        const naSwitchValue = naSwitches[index];
        const metSwitchValue = metSwitches[index];
        const notMetSwitchValue = notMetSwitches[index];

        const change = updatedMetSwitches
          ? naSwitchValue || metSwitchValue || notMetSwitchValue
            ? 0
            : 1
          : -1;
        updateCompletedQueries(change);
        // Call the API
        await dispatch(
          userQaResponse({
            answer: "met",
            assessment: assesmtId,
            assessment_objective: indexSlected,
            task: true,
          })
        )
          .unwrap()
          .then((res) => {
            if (!res.success) {
              // Handle the error message when success is false
              console.log("heree");
              swal({
                title: "Unauthorized Access",
                text: "You cannot perform action you have only view access!",
                icon: "error",
              });
              // Uncheck the current index based on the switch type

              const newSwitches = [...metSwitches];
              newSwitches[index] = false;
              setMetSwitches(newSwitches);
            } else {
              // Handle success if needed
              updateCompletedQueries(change);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    } catch (err) {
      // Handle errors for both API calls
      console.log(err);
    } finally {
      // Display success message
      swal({
        title: "Evidence",
        text: "Evidence Added Successfully !",
        icon: "success",
      });
      setModalOpen(false);
      setTabValue("1");
    }
  }, [dispatch, evidenceList, assesmtId, selectedItemIndex, data, tabValue]);
  // delete images of evidences
  const handleDeleteEvidence = useCallback(
    async (index: any) => {
      try {
        await dispatch(
          deleteEvidence({
            pk: index,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Evidence",
              text: "Evidence Image Deleted Successfully !",
              icon: "success",
            });
            dispatch(
              getEvidenceReport({
                assessmentId: assesmtId,
                objectiveId: selectedItemIndex,
              })
            )
              .unwrap()
              .then(() => {})
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.data?.status === 400) {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        } else {
          swal({
            title: "Evidence",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    },
    [dispatch, selectedItemIndex]
  );

  //tabs handle change
  const handleTabChange = async (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabValue(newValue);
  };

  const routePlan = () => {
    navigate("/plan");
  };

  return (
    <>
      <Container>
        <TableContainer sx={{ width: "100%" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="objective" style={{ width: "60%" }}>
                  {hideObjectiveTitle ? null : assessmentObjective}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "10%" }}>
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <NotInterestedIcon />
                    </Box>
                    <Box className="headerBold">{notInterested}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className="headerBold"
                  style={{ width: "10%" }}
                >
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <CheckCircleIcon />
                    </Box>
                    <Box className="headerBold">{met}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className="headerBold"
                  style={{ width: "10%" }}
                >
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <CancelIcon />
                    </Box>
                    <Box className="headerBold">{notMet}</Box>
                  </Stack>
                </StyledTableCell>

                {showCommentsColumn === true && (
                  <StyledTableCell
                    align="center"
                    className="headerBold"
                    style={{ width: "9%" }}
                  >
                    <Stack direction="column">
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        {" "}
                        <FaCommentMedical size={20} />
                      </Box>
                      <Box className="headerBoldWeight" pt={0.4}>
                        {comentColumnTitle}
                      </Box>
                    </Stack>
                  </StyledTableCell>
                )}

                <StyledTableCell
                  align="center"
                  className="headerBold notMet"
                  style={{ width: "10%" }}
                >
                  <Stack direction="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {" "}
                      <VscWorkspaceTrusted size={20} />
                    </Box>
                    <Box className="bold">Evidence</Box>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.objectives
                ?.filter((objective: any) => {
                  if (assessmentTypeCurrent === assessmentTypeNISTCSFQSG) {
                    return objective.is_nist_csf_2_0_quick_start_objective;
                  }
                  return true;
                })
                ?.map((objective: any, index: any) => {
                  return (
                    <StyledTableRow key={objective?.id}>
                      {weightFlag === true ? (
                        <StyledTableCell component="th" scope="row">
                          <React.Fragment>
                            <StyledSpan>{objective?.questionNumber}</StyledSpan>
                            {objective?.title}
                          </React.Fragment>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell component="th" scope="row">
                          <React.Fragment>
                            <StyledSpan>{objective?.questionNumber}</StyledSpan>
                            {objective?.title}
                          </React.Fragment>
                          <ObjectiveTooltip
                            title={
                              <div
                                style={{
                                  lineHeight: 1.4,
                                  fontSize: "16px",
                                  whiteSpace: "pre-line",
                                  textAlign: "justify",
                                  letterSpacing: 1,
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: objective?.examples,
                                  }}
                                />
                              </div>
                            }
                          >
                            <IconButton aria-label="information">
                              <BsInfoSquareFill
                                style={{
                                  color: Colors.text.info,
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>
                          </ObjectiveTooltip>
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="center">
                        <Switch
                          color="gray"
                          disabled={viewFlag}
                          checked={naSwitches[index]}
                          onChange={handleMetSwitchChange(index, "na")}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Switch
                          color="green"
                          disabled={viewFlag}
                          checked={metSwitches[index]}
                          onChange={handleMetSwitchChange(index, "met")}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Switch
                          color={notMerSwitchColor}
                          disabled={viewFlag}
                          checked={notMetSwitches[index]}
                          onChange={handleMetSwitchChange(index, "not_met")}
                        />
                      </StyledTableCell>
                      {showCommentsColumn === true && (
                        <StyledTableCell align="center">
                          <Box>
                            <Avatar
                              sx={{
                                marginLeft: "18px",
                              }}
                              onClick={() =>
                                handleComment(
                                  objective?.id,
                                  index,
                                  objective?.objectiveComment
                                )
                              }
                            >
                              <FaCommentMedical style={{ color: "#000" }} />
                            </Avatar>
                          </Box>
                        </StyledTableCell>
                      )}

                      {currentPackage === "Frontline Defender" ? (
                        <StyledTableCell align="center">
                          <LightTooltip
                            arrow
                            placement="top"
                            title="Subscribe Bunker $499 Per Year To Unlock Evidence Vault"
                          >
                            <Avatar
                              sx={{
                                marginLeft: "20px",
                                bgcolor:
                                  isEvidence[index] === true ? "#fed74f" : "",
                              }}
                              onClick={routePlan}
                            >
                              <FaImages color="#000" />
                            </Avatar>
                          </LightTooltip>
                        </StyledTableCell>
                      ) : (
                        <>
                          {!viewFlag ? (
                            <StyledTableCell align="center">
                              <Avatar
                                sx={{
                                  marginLeft: "20px",
                                  bgcolor:
                                    isEvidence[index] === true ? "#fed74f" : "",
                                }}
                                onClick={() =>
                                  handleAvatarClick(
                                    objective.pkId,
                                    objective.questionNumber
                                  )
                                }
                              >
                                <FaImages color="#000" />
                              </Avatar>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="center">
                              <Avatar
                                sx={{
                                  marginLeft: "20px",
                                  bgcolor:
                                    isEvidence[index] === true ? "#fed74f" : "",
                                }}
                              >
                                <FaImages color="#000" />
                              </Avatar>
                            </StyledTableCell>
                          )}
                        </>
                      )}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            {showCommentsColumn === false && (
              <tfoot>
                <StyledTableRow>
                  <TableCell colSpan={6}>
                    {" "}
                    <CommentInput
                      readOnly={viewFlag}
                      value={inputValue}
                      onBlur={handleBlur}
                      onChange={(event) => setInputValue(event.target.value)}
                      placeholder="Description of how objective is met or why not applicable:"
                    />
                  </TableCell>
                </StyledTableRow>
              </tfoot>
            )}
          </Table>
        </TableContainer>
      </Container>

      {/* modal comments */}

      <Modal
        open={modalOpenComment}
        onClose={handleCloseModalComments}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={commentModal}>
          <Box
            display="flex"
            justifyContent="end"
            sx={{ marginTop: "-20px", marginRight: "-20px" }}
          >
            <AiFillCloseCircle
              onClick={handleCloseModalComments}
              className="custom-icon"
            />
          </Box>
          <Text fontSize="24px" fontWeight="600" align="center">
            Comments
          </Text>
          <Box mt={4}>
            {commentLoader ? (
              <Box>
                <Loader isLoading={commentLoader} height="15vh" />
              </Box>
            ) : (
              <TextArea
                value={commentUpdate}
                onChange={(event) => setCommentUpdate(event.target.value)}
                placeholder="Add Comment"
              />
            )}
          </Box>
          <Box mt={4}>
            {submitFlag ? (
              <LightTooltip
                arrow
                placement="top"
                title="Please answer the question before adding comment"
              >
                <Box>
                  <Button
                    title={"Save"}
                    borderRadius="50px"
                    fontWeight="500"
                    fontSize="18px"
                    align="center"
                    width="130px"
                    disabled={submitFlag}
                  />
                </Box>
              </LightTooltip>
            ) : (
              <Button
                title={"Save"}
                borderRadius="50px"
                fontWeight="500"
                fontSize="18px"
                align="center"
                width="130px"
                onClick={addCommentNistObjective}
              />
            )}
          </Box>
        </Box>
      </Modal>

      {/* modal */}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={evidenceModal}>
          <Box
            display="flex"
            justifyContent="end"
            sx={{ marginTop: "-20px", marginRight: "-20px" }}
          >
            <AiFillCloseCircle
              onClick={handleCloseModal}
              className="custom-icon"
            />
          </Box>
          <Text fontSize="24px" fontWeight="600" align="center">
            Evidence
          </Text>

          <TabContext value={tabValue}>
            <Box display="flex" justifyContent="center" width="100%" pt={3}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                sx={{
                  minHeight: "40px !important",
                  borderBottom: "1px solid white !important",
                }}
              >
                <Tab
                  label="Evidence Form"
                  value="1"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#FED74F",
                      color: "#000",
                      minHeight: "40px !important",
                      borderRadius: "5px",
                    },
                    minHeight: "40px !important",
                    color: "#000",
                    border: "1px solid #FED74F",
                    borderRadius: "5px",
                    fontSize: "12px !important",
                    [theme.breakpoints.down("sm")]: {
                      minHeight: "40px !important",
                      color: "#FED74F",
                      border: "1px solid #FED74F",
                      borderRadius: "5px",
                      fontSize: "10px !important",
                      width: "150px",
                    },
                  }}
                />
                <Tab
                  label="Evidence Gallery"
                  value="2"
                  sx={{
                    marginLeft: "20px",
                    "&.Mui-selected": {
                      backgroundColor: "#FED74F",
                      color: "#000",
                      minHeight: "40px !important",
                      borderRadius: "5px",
                    },
                    minHeight: "40px !important",
                    color: "#000",
                    border: "1px solid #FED74F",
                    borderRadius: "5px",
                    fontSize: "12px !important",
                    [theme.breakpoints.down("sm")]: {
                      minHeight: "40px !important",
                      color: "#FED74F",
                      border: "1px solid #FED74F",
                      borderRadius: "5px",
                      fontSize: "10px !important",
                      width: "150px",
                    },
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Formik
                initialValues={{
                  name:
                    evidenceList?.name ||
                    `${objectiveNumber} __ ${getTodayDate()}`,
                  additionalNotes: evidenceList?.additional_notes || "",
                }}
                validationSchema={AvidanceSchema}
                onSubmit={handleUpdate}
              >
                {({ errors, touched, values, handleChange, handleSubmit }) => (
                  <Box>
                    <Box>
                      <Box p={{ sx: 1, md: 1, lg: 1 }}>
                        <Box>
                          <Box>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Name"
                              value={values.name}
                              onChange={handleChange("name")}
                            />
                            {errors.name && touched.name ? (
                              <div className="error-message">{errors.name}</div>
                            ) : null}
                          </Box>

                          <LightTooltip
                            arrow
                            placement="top"
                            title="While CMMC Proof is designed to meet or exceed the security requirements outlined in NIST SP 800-171, CMMC Proof SHOULD NOT be utilized for storing or handling any information from the US Government, particularly classified data or materials governed by export restrictions (such as ITAR, EAR, or CUI labeled NOFORN or RELTO)."
                          >
                            <Box>
                              <TextArea
                                name="additionalNotes"
                                placeholder="Notes"
                                width="100%"
                                value={values.additionalNotes}
                                onChange={handleChange("additionalNotes")}
                              />
                              {errors.additionalNotes &&
                              touched.additionalNotes ? (
                                <div className="error-message">
                                  {errors.additionalNotes}
                                </div>
                              ) : null}
                            </Box>
                          </LightTooltip>

                          <Box mt={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={value}
                                onChange={handleDateChange}
                                maxDate={dayjs()}
                                format="YYYY-MM-DD"
                                sx={{
                                  borderRadius: "40px",
                                  "&  .MuiOutlinedInput-root": {
                                    borderColor: "#fed74f !important",
                                    border: "2px solid #fed74f !important",
                                    borderRadius: "50px",
                                    padding: "2px 25px",
                                    "&:focus": {
                                      borderColor: "none !important",
                                      boxShadow: "none !important",
                                    },
                                    "&:hover": {
                                      borderColor: "none !important",
                                      boxShadow: "none !important",
                                    },
                                    "& fieldset": {
                                      borderColor: "#fed74f !important",
                                      border: "0px solid #fed74f",
                                      borderRadius: "50px",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#fed74f !important",
                                      border: "0px solid #fed74f",
                                      borderRadius: "50px",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#fed74f !important",
                                      border: "0px solid #fed74f",
                                      borderRadius: "50px",
                                    },
                                  },
                                  width: "100%",
                                }}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      sx={{
                        position: "absolute",
                        bottom: "20px",
                        marginLeft: "-50px",
                      }}
                    >
                      <Button
                        title={"Next"}
                        borderRadius="50px"
                        fontWeight="500"
                        fontSize="18px"
                        align="center"
                        onClick={handleSubmit}
                        width="130px"
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            </TabPanel>
            <TabPanel value="2">
              <Box>
                <input
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  className="multiple-images-upload-input"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <input
                  type="file"
                  multiple
                  onChange={handleImageChangeOnEdit}
                  className="multiple-images-upload-input"
                  ref={editFileInputRef}
                  style={{ display: "none" }}
                />
                <div className="loading-img">
                  {loading ? "Please wait... !" : ""}
                </div>

                <ImagesListCustome>
                  <ImageList cols={3} rowHeight={90}>
                    {img?.map((evidence: any, index: any) => (
                      <ImageListItem
                        key={index}
                        sx={{
                          padding: "10px",
                          border: "1px solid #000",
                          position: "relative",
                          borderRadius: "10px",
                          height: "90px",
                        }}
                      >
                        <div>
                          <img
                            src={evidence.image}
                            alt="images"
                            style={{
                              width: "100%",
                              height: "90px",
                            }}
                          />
                        </div>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            padding: "5px 7px",
                            cursor: "pointer",
                            background: "#FED74F",
                            borderRadius: "50px",
                            height: "20px",
                            margin: "1px",
                          }}
                          onClick={() =>
                            handleButtonClickOnEdit(evidence.pk, index)
                          }
                        >
                          <AiFillEdit />
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "2px",
                            cursor: "pointer",
                            background: "#FED74F",
                            borderRadius: "50px",
                            height: "25px",
                            display: index === 0 ? "none" : "block",
                            margin: "1px",
                          }}
                          onClick={() => handleDeleteEvidence(evidence.pk)}
                        >
                          <CloseIcon />
                        </Box>
                      </ImageListItem>
                    ))}
                    {evidenceList?.pk && !isDisabled ? (
                      <LightTooltip
                        arrow
                        placement="top"
                        title="Upload evidence media"
                      >
                        <ImageListItem
                          key={-23}
                          onClick={handleButtonClick}
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            position: "relative",
                            borderRadius: "10px",
                            height: "90px",
                            opacity: 1,
                            pointerEvents: "auto",
                          }}
                        >
                          <Box pt={3} display="flex" justifyContent="center">
                            <FaPlusCircle className="plus-icon-evidence green" />
                          </Box>
                        </ImageListItem>
                      </LightTooltip>
                    ) : (
                      <LightTooltip
                        arrow
                        placement="top"
                        title={
                          !isDisabled
                            ? "Please enter form field values to upload evidence media"
                            : "Please wait"
                        }
                      >
                        <ImageListItem
                          key={-23}
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            position: "relative",
                            borderRadius: "10px",
                            height: "90px",
                            opacity: 0.5,
                          }}
                        >
                          <Box pt={3} display="flex" justifyContent="center">
                            <FaPlusCircle className="plus-icon-evidence disabled-color" />
                          </Box>
                        </ImageListItem>
                      </LightTooltip>
                    )}
                  </ImageList>
                </ImagesListCustome>
              </Box>

              <Box
                pt={2}
                display="flex"
                justifyContent="center"
                width="100%"
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  marginLeft: "-50px",
                }}
              >
                <Button
                  title={"Finish"}
                  borderRadius="50px"
                  fontWeight="500"
                  fontSize="18px"
                  align="center"
                  onClick={handleFinish}
                  width="120px"
                  disabled={mediaFlag ? false : true}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
    </>
  );
};

export default AssessmentTable;
