import React, { FunctionComponent } from "react";
import { DashboardWrapper, Text, Button } from "shared";
import { Box, Grid } from "@mui/material";
import ThnakuImg from "assets/thanku.png";
import { useNavigate } from "react-router-dom";
import { PackageAvatar, Alignment } from "./style";

type Props = Record<string, never>;

const Thanku: FunctionComponent<Props> = () => {
  const navigate = useNavigate();

  const handleProfile = () => {
    navigate("/profile");
  };

  return (
    <DashboardWrapper>
      <Grid container spacing={2} alignContent="center" justifyContent="center">
        <Grid item lg={6} md={8} sm={12} xs={12}>
          <Alignment>
            <Box display="flex" justifyContent="center" width="100%">
              <PackageAvatar src={ThnakuImg} alt="thankuimg" />
            </Box>
            <Box>
              <Text fontSize="36px" fontWeight="600" align="center">
                Payment Successful!
              </Text>
            </Box>
            <Box>
              <Text fontSize="20px" fontWeight="400" align="center">
                Your payment has been successfully processed. Thank you for
                choosing our service.
              </Text>
            </Box>
            <Box mt={3}>
              <Button
                title={"Explore More Features"}
                align="center"
                width="250px"
                fontSize="16px"
                fontWeight="500"
                onClick={handleProfile}
              />
            </Box>
          </Alignment>
        </Grid>
      </Grid>
    </DashboardWrapper>
  );
};

export default Thanku;
