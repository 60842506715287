import React from "react";
import DataTable, { IDataTableProps } from "react-data-table-component";

import {
  InputAdornment,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";

import { FiSearch } from "react-icons/fi";
import { Colors } from "theme/color";
import { Loader } from "shared";

import { RootState } from "store";
import { useSelector } from "react-redux";
import { InputSerch } from "utils/sharedStyled";
interface Users {
  id?: any;
  assessment?: string;
  evidences?: string;
  relevance?: string;
  source?: string;
  notes?: number;
  evidenceDate?: string;
  ssp?: string;
  levels?: any;
  index?: any;
}

interface Props {
  user?: Users[];
  totalRows?: number;
  paginationRowsPerPageOptions: number[];
  pages?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  paginationPerPages?: number;
  handlePageChange?: (
    // eslint-disable-next-line no-unused-vars
    event: React.ChangeEvent<unknown>,
    // eslint-disable-next-line no-unused-vars
    page: number
  ) => void | undefined;
  // eslint-disable-next-line no-unused-vars
  handleRowsPerPageChange?: (event: any) => void;
  search?: string;
  handleSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  handleDelete?: () => void;
  selectedObjectIdRef: React.MutableRefObject<number | null>;
  loading?: boolean;
  searchLoading?: boolean;
  handleResendInvite: // eslint-disable-next-line no-unused-vars
  (activeRow: any) => void;
  handleDeleteInvite: // eslint-disable-next-line no-unused-vars
  (activeRow: any) => void;
  deleteInvite?: any;
  clickedResend?: any;
  handleDeleteUser?: // eslint-disable-next-line no-unused-vars
  (activeRow: any) => void;
}

const DataTableComponent: React.FC<Props> = ({
  user,
  totalRows,
  paginationRowsPerPageOptions,
  pages,
  paginationPerPages,
  handlePageChange,
  handleRowsPerPageChange,
  handleSearch,
  search,
  loading,
  searchLoading,
  handleResendInvite,
  handleDeleteInvite,
  deleteInvite,
  clickedResend,
  handleDeleteUser,
}) => {
  const page = pages ? pages : 0;
  const paginationPerPage = paginationPerPages ? paginationPerPages : 0;

  const searchText = search ? search : null;

  const currentPageRows = user?.length ? user : [];

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0]?.plan?.name : "Null";

  const columns: any[] = [
    {
      name: "Email",
      selector: (row: any) => row.email,
      center: false,
      width: "50%",
      style: {
        cursor: "default",
        marginLeft: "15px",
        textTransform: "lowercase",
      },
    },

    {
      name: "Accepted",
      selector: (row: any) =>
        row.accepted ? (
          <button className="user-accept-button-yes">Yes</button>
        ) : (
          <button className="user-accept-button-no">No</button>
        ),
      center: true,
      width: "25%",
      style: {
        cursor: "default",
        wordBreak: "break-all",
      },
    },
    {
      name: "Action",
      selector: (row: any) =>
        row.accepted ? (
          <Box>
            <Button
              className="user-delete-button"
              color="error"
              variant="contained"
              onClick={() => {
                if (handleDeleteUser) {
                  handleDeleteUser(row);
                }
              }}
            >
              Delete
            </Button>
          </Box>
        ) : (
          <>
            <Button
              disabled={
                currentPackage === "Null" || clickedResend === row?.pk
                  ? true
                  : false
              }
              onClick={() => handleResendInvite(row)}
              className="user-delete-button"
              color="success"
              variant="contained"
            >
              Resend Invite
            </Button>
            <Button
              className="user-delete-button"
              onClick={() => handleDeleteInvite(row)}
              color="error"
              variant="contained"
              disabled={
                currentPackage === "Null" || deleteInvite === row?.pk
                  ? true
                  : false
              }
            >
              Delete
            </Button>
          </>
        ),
      right: true,
      width: "20%",
      style: {
        cursor: "default", // Change cursor style to indicate it's clickable
      },
    },
  ];

  const tableProps: IDataTableProps<Users> = {
    columns,
    data: currentPageRows,
    pointerOnHover: true,
    pagination: true,
    paginationPerPage: paginationPerPage,
    paginationRowsPerPageOptions: paginationRowsPerPageOptions,
    paginationComponent: () => (
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 0px",
        }}
      >
        <Box sx={{ marginTop: "10px", fontSize: "20px !important" }}>
          Showing {(page - 1) * paginationPerPage + 1}-
          {Math.min(page * paginationPerPage)} of {totalRows} entries
        </Box>
        <Pagination
          sx={{
            "& .Mui-selected": {
              backgroundColor: "#FED74F !important",
              color: "#000",
              borderRadius: "5px",
              fontSize: "16px",
            },
          }}
          count={totalRows ? Math.ceil(totalRows / paginationPerPage) : 0}
          page={page}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component={Button}
              sx={{ fontSize: "20px" }}
            />
          )}
        />
      </Box>
    ),
    onChangeRowsPerPage: handleRowsPerPageChange,
    subHeader: true,
    subHeaderComponent: (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex">
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Show
            </Typography>
            <Select
              value={paginationPerPage}
              onChange={handleRowsPerPageChange}
              style={{ marginRight: "20px" }}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent !important", // Hide the root border
                  },
                  "&:hover fieldset, &.Mui-focused fieldset": {
                    borderColor: "transparent !important", // Hide the border on hover and focus
                  },
                },
                "& .MuiSelect-icon": {
                  color: "#FED74F",
                  border: "1px solid #FED74F",
                  borderRadius: "50px",
                },
                "& .MuiSelect-select": {
                  borderColor: "#FED74F !important",
                  border: "2px solid #FED74F !important",
                  borderRadius: "50px !important",
                  padding: "14px 20px",
                  "&:hover, &:focus": {
                    borderColor: "#FED74F !important",
                    border: "2px solid #FED74F !important",
                  },
                },
                width: { xs: "80%", md: "40%" },
              }}
            >
              {paginationRowsPerPageOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: Colors.button.primary_bg,
                    },
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
            <Typography
              component="p"
              sx={{ marginRight: "10px", fontSize: "16px", marginTop: "15px" }}
            >
              Entries{" "}
            </Typography>
            <Box mt={1.5} ml={4} sx={{ color: "green" }}>
              {searchLoading ? "Please wait.." : ""}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputSerch
            id="input-with-icon-textfield"
            placeholder="Search"
            value={searchText || ""}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    ),
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {loading ? (
            <Loader isLoading={loading} />
          ) : (
            <DataTable
              key={paginationPerPage}
              {...tableProps}
              keyField="id"
              className="table-style"
              customStyles={customStyles}
              conditionalRowStyles={[
                {
                  when: (row) => row.index % 2 === 0,
                  style: {
                    backgroundColor: "#fffcf1",
                  },
                },
                {
                  when: (row) => row.index % 2 !== 0,
                  style: {
                    backgroundColor: "#fdf4da",
                  },
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DataTableComponent;

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#FED74F",
      color: "#000",
      border: "none",
      padding: "5px 10px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
    },
  },
  rows: {
    style: {
      backgroundColor: "white", // change body background color
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "0.2px",
      textTransform: "capitalize !important",
      borderBottom: "none !important",
      // add media query for mobile
      "@media (max-width: 767px)": {
        fontSize: "10px !important",
      },
    },
  },
  cells: {
    style: {
      padding: "15px 5px !important",
    },
  },
};
