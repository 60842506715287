import React, { FunctionComponent } from "react";
import { Button } from "shared";
import { Box, Typography } from "@mui/material";
import { Colors } from "theme/color";
import { DarkTooltip } from "utils/sharedStyled";

type Props = {
  disabled?: boolean;
  handleAgree?: any;
};

const Toc: FunctionComponent<Props> = ({ disabled, handleAgree }) => {
  return (
    <>
      <Box style={{ padding: "20px 50px" }}>
        <Box mt={0}>
          Please read these Terms of Service, including the terms of the Privacy
          Policy, which is incorporated into this document by reference
          (together, the{" "}
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="600"
            color={Colors.text.toc_li}
          >
            {" "}
            “Terms”{" "}
          </Typography>{" "}
          or{" "}
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="600"
            color={Colors.text.toc_li}
          >
            {" "}
            “Terms of Service”
          </Typography>{" "}
          ) carefully before using the CMMC Proof website, application, and/or
          platform (the{" "}
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="600"
            color={Colors.text.toc_li}
          >
            {" "}
            “Platform”
          </Typography>{" "}
          ).
        </Box>
        <Box mt={2}>
          These Terms are a contract entered into between You ({" "}
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="600"
            color={Colors.text.toc_li}
          >
            {" "}
            “you,” “your,” “yours”
          </Typography>{" "}
          ) and Aspire Cyber, LLC, a Texas company ({" "}
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="600"
            color={Colors.text.toc_li}
          >
            {" "}
            “we,” “us,” “our,” “Aspire Cyber”
          </Typography>{" "}
          ). These Terms govern your use of and access to the Platform,
          including any related content, functionality, and services offered on
          or through it or them (the{" "}
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="600"
            color={Colors.text.toc_li}
          >
            {" "}
            “Services”
          </Typography>{" "}
          ). These Terms apply to all visitors, users, end-users, and others who
          wish to access or use the Platform and any other associated website,
          application, or service.
        </Box>
        <Box mt={4} color={Colors.text.light_red}>
          For any questions about these Terms of Service, please contact us at
          <Typography
            component="span"
            fontSize="16px"
            fontWeight="600"
            color={Colors.text.toc_li}
          >
            {" "}
            support@aspirecyber.com.
          </Typography>{" "}
        </Box>
        <Box pl={4} pr={4} mt={4}>
          <Box className="Toc_heading">Acceptance of Terms</Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Acceptance.
              </Typography>{" "}
              BY USING OR ACCESSING THE PLATFORM OR SERVICES, OR BY CLICKING
              “SIGN UP” AFTER INPUTTING THE REQUIRED INFORMATION, YOU ACCEPT AND
              AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF SERVICE. IF YOU DO
              NOT AGREE TO THE TERMS OR CONDITIONS CONTAINED IN THIS AGREEMENT
              OR ANY PORTION OF THE TERMS OF SERVICE YOU MUST NOT ACCESS OR USE
              THE PLATFORM OR SERVICES.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Acceptance on Behalf of a Legal Entity.
              </Typography>{" "}
              IF YOU AGREE TO THESE TERMS OF SERVICE ON BEHALF OF AN ENTITY OR
              AGENCY, OR IN CONNECTION WITH PROVIDING OR RECEIVING SERVICES ON
              BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT YOU
              HAVE THE AUTHORITY TO BIND THAT ENTITY OR AGENCY TO THE TERMS OF
              THIS AGREEMENT AND THE ENTIRETY OF THE TERMS OF SERVICE AND
              FURTHER UNDERSTAND AND AGREE THAT YOU ARE BINDING BOTH YOU AND
              THAT ENTITY OR AGENCY TO THIS AGREEMENT AND THE ENTIRETY OF THE
              TERMS OF SERVICE. IN THAT EVENT, “YOU,” “YOUR,” AND “YOURS” WILL
              REFER AND APPLY TO YOU AS AND INDIVIDUAL AND THAT ENTITY OR
              AGENCY.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Persons who Cannot Accept.
              </Typography>{" "}
              You cannot accept these Terms if: <br /> (i) you are not lawfully
              entitled to use the Platform or any part, feature, or
              functionality under any applicable laws in the country, state,
              province, or other location in which you are physically present at
              the time of access or otherwise resident of or <br /> (ii) you are
              under the age of 18. It is your responsibility to ensure that you
              are legally allowed to enter into these Terms and that you do not
              violate any applicable laws.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Modification.
              </Typography>{" "}
              We, or our third-party affiliates, may make improvements and/or
              changes to the capabilities, features, prices, and availability of
              the Platform at any time without notice. You agree that we are not
              and shall not be liable to you or to any third party for any
              modification, suspension, or discontinuance of the Platform, the
              Services or any portion or functionality thereof. The preceding
              two sentences do not apply to the rights and obligations of
              Sections 12 and 13, and if a change or modification of any such
              sections is made, you will be notified through the Platform and/or
              via an email address or other contact method you have provided to
              us. Your continued use of the Platform after any such change
              constitutes acceptance of the new, additional, or modified Terms.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} mt={4}>
          <Box className="Toc_heading">Registration and Accounts.</Box>
          <ul>
            <li className="toc_li">
              Fill in the account creation form on the Platform by providing the
              requested information. Prior to clicking “Sign Up,” you will be
              required to provide and confirm certain personal information,
              including your legal name, an entity name (called a “Company Name”
              in the form, though it may not be a company but some other legal
              entity) and email address. By clicking “Sign Up,” your Account is
              created (an “Account”). You are only permitted to have one Account
              and if your Account is terminated by us for a breach of these
              Terms, you may not be permitted to create another Account.
            </li>
            <li className="toc_li">
              You acknowledge that your Account is personal to you, or your
              authorized users, if any, and you are obliged not to provide any
              other person with access to the Platform or Services or portions
              of it using your email address, password, or other security
              information.
            </li>
            <li className="toc_li">
              You are responsible for maintaining the confidentiality of your
              data and for monitoring and, if necessary, restricting access to
              your devices and those of your authorized users, if any. Any email
              address, password, or any other information chosen by you or
              provided to you as a part of our security procedures, must be
              treated as confidential, and you must not disclose it to any other
              person or entity. You must exercise caution when accessing your
              Account from a public or shared device so that others are not able
              to view or record your password or other Account information.
            </li>
            <li className="toc_li">
              You will use any and all commercially reasonable efforts to
              prevent unauthorized use of Account assigned to you and will
              promptly notify us, in writing, if you suspect that an Account is
              compromised or misused or if any user ID, password or other access
              credentials are lost, stolen, compromised, or misused.
            </li>
            <li className="toc_li">
              You understand, acknowledge, and agree that to the extent
              permitted by applicable law, we shall not be responsible or
              liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by, or in connection with the unauthorized
              use of your Account.
            </li>
            <li className="toc_li">
              You understand, acknowledge, and agree that any Account
              information must be accurate, current, truthful, and complete, and
              you will keep your Account information accurate, current,
              truthful, and complete. We may identify you and send notices,
              statements, and other information by e-mail or through your
              Account.
            </li>
            <li className="toc_li">
              You understand, acknowledge, and agree that we have the right, but
              not the obligation, to monitor your Account for any reason. You
              further understand, acknowledge, and agree that we may:
            </li>
          </ul>
        </Box>
        <ul>
          <li className="toc_li">
            remove anything that is, in our sole discretion, unacceptable,
            inappropriate, or not in compliance with these Terms;{" "}
          </li>
          <li className="toc_li">
            disclose data to law enforcement agencies or authorities who may
            investigate reports of misuse or abuse of the Account, the Platform,
            and/or Services
          </li>
          <li className="toc_li">
            suspend or terminate access to the Account, at any time and without
            prior notice.
          </li>
        </ul>

        <Box pl={4} pr={4} mt={4}>
          <Box className="Toc_heading">Permitted Use and Conduct.</Box>
          <ul>
            <li className="toc_li">
              You may use the Services only within the intended purpose and
              permitted use. You acknowledge that the purpose of the Account is
              to provide you with access to the Services, including tools to
              track, access, and manage information and compliance data,
              interact with the Platform, or other content created, uploaded,
              shared, published or distributed through the Platform or Services,
              either directly or indirectly (“User Content”). Any use for other
              purposes or particular misuse of the Services or Platform is not
              permitted.
            </li>
            <li className="toc_li">
              Under no circumstances are you permitted to engage in Improper
              Conduct. Improper Conduct includes, but is not limited to.
            </li>
          </ul>
        </Box>
        <ul>
          <li className="toc_li">
            Falsifying personal information or impersonating any natural or
            legal person, including payment information, required to use the
            Services.
          </li>
          <li className="toc_li">
            to spam, phish, pharm, pretext, spider, crawl, or scrape.
          </li>
          <li className="toc_li">
            colluding with any other person or engaging in any type of false
            valuation or falsification or fabrication of compliance information.
          </li>
          <li className="toc_li">
            offer for sale or license any item or service.
          </li>
          <li className="toc_li">
            to perform or participate in any unlawful acts or to engage in acts
            that are unrelated to the purpose of the Platform or Services.
          </li>
          <li className="toc_li">
            tampering with the administration of the Services or Platform or
            trying to in any way tamper with the computer programs associated
            with the Services or Platform.
          </li>
          <li className="toc_li">
            obtaining other users’ information and spamming other users.
          </li>
          <li className="toc_li">
            abusing the Services or Platform in any way.
          </li>
          <li className="toc_li">
            otherwise violating these Terms of Service.
          </li>
        </ul>
        <Box pl={4} pr={4}>
          <ul>
            <li className="toc_li">
              Failure to observe the limits of purpose and permitted use of your
              Account, the Platform, and the Services is deemed a material
              breach of these Terms. We shall be entitled to – without prejudice
              to any other rights and in our sole and absolute discretion –
              terminate your Account, the Account held by your authorized users,
              if any, and/or both. You may not use or access the Platform or
              Services if we have terminated your Account or otherwise elect not
              to provide you access to the Platform or Services.
            </li>
          </ul>
        </Box>
        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">Payments and Subscriptions. </Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Price Changes.
              </Typography>{" "}
              All prices, discounts, and promotions posted on the Platform are
              subject to change without notice. The price charged for Services
              selected by you will be the price advertised on the Platform at
              the time the order is placed, subject to the terms of any
              promotions or discounts, your geographical location or residence,
              and chosen payment method. You will be charged the price
              advertised at the time you complete the payment process. If we
              increase our prices, that increase will only apply to purchases
              made after the date the increase comes into effect. The prices
              posted on the Platform may not include applicable discounts or
              taxes until the payment information is fully completed by you. We
              strive to display accurate price information, however, we may, on
              occasion, make inadvertent typographical errors, inaccuracies or
              omissions related to pricing and availability. We reserve the
              right to correct any errors, inaccuracies, or omissions at any
              time and to cancel any orders arising from such occurrences. You
              must initiate payment for the charges related to the specific
              Services you select or other products or services provided through
              the Platform when submitting the order. If you do not complete
              this payment to our satisfaction, we will not provide you with the
              Services you requested. Using the Platform or accessing the
              Services via the Internet may lead to incurring costs that you
              will have to pay to your service provider.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Payment Methods.
              </Typography>{" "}
              You can use any available and the most convenient payment method
              currently available through the Platform for all purchases.
              However, Aspire Cyber does not guarantee the availability of any
              payment method at any moment. Aspire Cyber may add, remove or
              suspend any payment method temporarily or permanently by its own
              discretion. You understand that you are purchasing the Services
              from Aspire Cyber via a payment service provider (“PSP”) and
              subject to the terms of that PSP. Unless otherwise required by
              law, you are obligated to contact Aspire Cyber support service for
              any issues related to payment transactions before contacting the
              PSP or financial institution. Upon completing the purchase, you
              are representing and warranting that: <br /> (i) the payment
              information you supply to us is true, correct and complete,
              <br /> (ii) you are duly authorized to use the payment method you
              provide,
              <br />
              (iii) charges incurred by you will be honored by the issuer of
              your payment method, and <br /> (iv) you will pay charges incurred
              by you at the posted prices, including all applicable taxes, if
              any, regardless of the amount quoted on the Platform or through
              the Services at the time of your order.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Taxes.
              </Typography>{" "}
              Any payments you make through the Platform and for the Services
              may be subject to applicable state, federal, or other tax. You are
              solely responsible for payment of any applicable tax, whether
              local, state, federal, international, or other. Aspire Cyber is
              not liable for any failure of any user to pay taxes.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Subscriptions.
              </Typography>{" "}
              If you have an Account and authorize us to make recurring payments
              for certain Services (a “Subscription”), we will automatically
              renew your Subscription from the beginning of the applicable
              billing period. You irrevocably authorize and instruct us to make
              the required monthly payments to Aspire Cyber on your behalf.
              Automatic renewal occurs on the first day after the expiration
              date. You understand that you are purchasing the Subscription via
              a PSP.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">
            Cancellations, Returns, and Refunds.{" "}
          </Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                14-Day Cancellation.
              </Typography>{" "}
              You have the right to cancel a purchase of Services for a refund
              within a period of 14 days from purchase. Once you have initiated
              the process to refund your purchase, you will no longer have the
              right to access to any Services for which you have initiated a
              cancellation.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                All Sales Final.
              </Typography>{" "}
              Unless otherwise required by applicable law, we have no obligation
              to provide a refund, exchange, return, or a credit. Due to the
              nature of Platform as a digital product, no refunds, exchanges,
              return, or a credit are granted without clear, justified and
              legitimate reasons. We will assess any refund request for fees
              payable in advance on its merits and in our sole and absolute
              discretion. Except as otherwise set out in these Terms, all sales
              are final, non-refundable, and as-is, with no warranties of any
              kind, including warranties of quality and fitness for a particular
              purpose.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Refund and Cancellation Requests.
              </Typography>{" "}
              To request a refund, exchange, return, or a credit, please contact
              us at support@aspirecyber.com. Absolutely no refunds, exchanges,
              returns, or credits will be granted solely on the basis of non-use
              or a change in the availability of certain Services. To cancel a
              Subscription, please contact us at support@aspirecyber.com. Any
              such cancellation will be granted immediately upon receipt;
              however, you will be permitted to access remaining Services or
              Subscription, in our sole and absolute discretion and subject to
              availability of any such Services or Subscription.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">
            User Content and Intellectual Property
          </Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Your Responsibilities.
              </Typography>{" "}
              You are responsible for any User Content that you or your
              authorized users post on or through the Platform, including its
              legality, reliability, and appropriateness. By posting User
              Content on or through the Platform, you represent and warrant that
              the User Content:
            </li>
            <li className="toc_li pl-2">
              is owned and created by you or you have the legal right to use it
              and the ability to grant us the rights and license as provided in
              these Terms
            </li>
            <li className="toc_li pl-2">
              does not violate the privacy rights, publicity rights, copyrights,
              contract rights or any other rights of any person or entity
            </li>
            <li className="toc_li pl-2">
              does not contain a link or promote any affiliate program,
              multi-level marketing scheme, sites repurposing existing stories
              or off-topic content
            </li>
            <li className="toc_li pl-2">
              does not scrape, access, monitor, index, frame, link, or copy any
              content or information on the Services by accessing the Services
              in an automated way, using any robot, spider, scraper, web
              crawler, or using any method of access other than manually
              accessing the publicly-available portions of the Services through
              a browser or accessing the Services through any approved mobile
              application, application programming interface, or client
              application
            </li>
            <li className="toc_li pl-2">
              does not contain software viruses or any other computer code,
              files or programs designed or functioning to interrupt, destroy,
              or limit the functionality of any computer software or hardware or
              telecommunications equipment
            </li>
            <li className="toc_li pl-2">
              does not falsely or incorrectly claim that you are, or any other
              person is, a technology, compliance, legal, government, or
              financial professional, expert, or agent, or falsely state or
              otherwise misrepresent your or another person’s affiliation with a
              government, government agency or entity, law enforcement, law
              firm, technology institution, financial institution, or
              educational facility
            </li>
            <li className="toc_li pl-2">
              is not and does contain unlawful, harmful, threatening, abusive,
              fraudulent, harassing, insulting, defamatory, vulgar, obscene,
              libelous, invasive of anothers privacy, hateful or racist, that
              glorifies violence, is pornographic, unethical or otherwise
              prohibited, objectionable, or infringing on the rights of any
              third party.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Your Rights.
              </Typography>{" "}
              Except for those rights granted herein and any other rights
              necessary or prudent to provide the Services and enforce or comply
              with these Terms, you retain any and all of your rights to any
              User Content you submit, post or display on or through the
              Platform or Services and you are responsible for protecting those
              rights. We take no responsibility and assume no liability for any
              User Content you or any third-party post or otherwise make
              available on or through the Platform or Services. We reserve the
              right to terminate the account of anyone found to be infringing on
              a copyright or otherwise violating these Terms.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Your License to Us.
              </Typography>{" "}
              You grant us a non-exclusive, worldwide, perpetual, irrevocable,
              royalty-free, sub-licensable and license to use, modify, publicly
              perform, publicly display, reproduce, re-format and distribute any
              such User Content on and through the Services and Platform. You
              agree that this license includes the right for us to make your
              User Content available to other users, including your authorized
              users and the administrators of the Platform, who may also use
              your User Content subject to these Terms.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Intellectual Property Displayed.
              </Typography>{" "}
              Any other information, images, data or content found on or through
              this Platform (“Data”) is the property of Aspire Cyber, used and
              displayed by Aspire Cyber in accordance with 17 U.S. Code § 107,
              or used and displayed with permission given to Aspire Cyber,
              including all patent rights, copyright, mask work rights, moral
              rights, rights of publicity, trademark, trade dress and service
              mark rights, goodwill, trade secret rights and other intellectual
              property rights as may now exist or hereafter come into existence
              (“Intellectual Property”). You may not distribute, modify,
              transmit, reuse, download, repost, copy, or use said Data or
              Intellectual Property, whether in whole or in part, for commercial
              purposes or for personal gain, without express advance written
              permission from us. You specifically agree not to access (or
              attempt to access) the Platform, Services, User Content, Data or
              Intellectual Property through any automated means (including the
              use of any script, web crawler, robot, spider, or scraper), and
              that you will not forge or manipulate identifiers in order to
              disguise the origin of any access (or attempted access) to the
              Platform, Services, User Content, Data or Intellectual Property.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Intellectual Property Ownership.
              </Typography>{" "}
              The Platform, Services, Data, and other Intellectual Property
              objects displayed, distributed, or otherwise made available via
              the Platform and Services, is the property of Aspire Cyber, and
              its successors, assigns, licensors, or suppliers. Unless
              specifically provided in these Terms or if you have agreed
              otherwise in writing with us, nothing in these Terms gives you a
              right to use the Platform, Services, Data, User Content or
              Intellectual Property, and nothing in these Terms transfers or
              assigns any ownership rights.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Feedback.
              </Typography>{" "}
              We may provide means for you to give suggestions or feedback, or
              we may require information from you to provide troubleshooting or
              other Services. We are under no obligation to use suggestion,
              feedback, or information, but may, in our sole discretion, without
              any credit or notification to you. You hereby specifically
              disclaim any rights, whether through intellectual property, moral
              rights, or any other right in law or equity for any User Content
              you submit to us for the purpose of functionality suggestions,
              feedback, or customer service, including any complaints and
              regardless of the method you provide it to us and expressly permit
              us to utilize it as we deem necessary or desirable for any purpose
              whatsoever.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">
            Links to and Integration with Other Websites and Platforms.
          </Box>
          <ul>
            <li className="toc_li">
              Use of certain links or integrated features on the Platform will
              direct you to third party feeds, software, websites or
              applications (collectively, “Third-Party Platforms”). Such
              Third-Party Platforms are not under the control of Aspire Cyber,
              and we are not responsible for the content or services of any such
              Third-Party Platforms or any link contained in such Third
              Party-Platform. Links to Third-Party Platforms included on or
              through the Platform are provided for your convenience, and the
              inclusion of such links does not imply a recommendation or
              endorsement by us of any such Third-Party Platform or the products
              or services or information offered therein. If you decide to
              access any Third-Party Platform information, you do so entirely at
              your own risk. In no event shall we be responsible or liable,
              directly or indirectly, for any damage or loss caused or alleged
              to be caused by or in connection with use of or reliance on any
              such content, goods or services available on or through any such
              Third-Party Platform. We strongly advise you to read the Terms of
              Service and Privacy Policies (and/or any other applicable
              document, contract, or agreement) of any Third-Party Platform or
              any other website or services that you visit.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">
            Location, Push Notifications, and Other Technologies.
          </Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Location Verification.
              </Typography>{" "}
              To determine your eligibility to use the Services, we may
              determine your location using one or more reference points, such
              as GPS, IP address, beacons and/or software within your personal
              computer, mobile device, consumer electronics device, video game
              device or console, or any other equipment. If you have set your
              device(s) to disable GPS, Bluetooth or other location determining
              software, use connection methods or systems that mask your
              location, such a virtual private network, or do not authorize the
              Services to access your location data, the Services may not be
              able to determine your location and you will not be able to access
              the Services. We reserves the right to suspend and/or terminate
              your account if you prevent the Services from accurately
              determining your location. For more information about how the
              Services collects, uses and retains your information, please read
              the Privacy Policy.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Other Technologies.
              </Typography>{" "}
              We may include other integrated technologies or services in the
              Platform and Services, as we deem necessary. Any such additional
              technology will be subject to the restrictions in our Privacy
              Policy, which may be updated to reflect any such addition.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">Term and Termination.</Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Termination.
              </Typography>{" "}
              We may terminate these Terms, your Account or the Account of your
              authorized users, if any, or any use of or access to the Platform
              or Services at any time for any reason, with or without cause. We
              specifically reserve the right to terminate these Terms if you
              violate any of the terms outlined herein, including, but not
              limited to, violating the intellectual property rights of Aspire
              Cyber or a third party, failing to comply with applicable laws or
              other legal obligations, and/or publishing or distributing
              illegal, incorrect, or immoral material. If you have registered
              for an Account, you may also terminate these Terms at any time by
              contacting us at support@aspirecyber.com and requesting
              termination.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Effect of Termination.
              </Typography>{" "}
              Upon the termination of these Terms, any provisions that would be
              expected to survive termination by their nature shall remain in
              full force and effect, including, but not limited to
              Indemnification, Limitation of Liability, Dispute Resolution, and
              Disclaimers of Warranties. Upon termination, your right to use the
              Platform and Services is automatically revoked, and your Account
              (and the Accounts of your authorized users, if any) will be
              closed. As a result of Account closure and your termination of
              these Terms, YOU MUST NOT ACCESS THE PLATFORM OR SERVICES.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Effect on User Content.
              </Typography>{" "}
              Except as otherwise required by law, if your Account is closed for
              any reason, you will no longer have access to User Content you
              keep on or through the Platform and/or Services and any closure of
              your Account may involve deletion of any User Content stored in
              your Account, for which we have no liability whatsoever. We, in
              our sole discretion and as permitted or required by law, may
              retain some or all of your Account information and/or User
              Content, including any license thereto.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Risk of Loss.
              </Typography>{" "}
              NOTWITHSTANDING ANYTHING TO THE CONTRARY, YOU ACKNOWLEDGE AND
              AGREE THAT ANY OR ALL OF OUR SERVICES MAY BE TERMINATED IN WHOLE
              OR IN PART AT OUR SOLE DISCRETION WITHOUT NOTICE TO YOU. YOU
              ASSUME ANY AND ALL RISK OF LOSS ASSOCIATED WITH THE TERMINATION OF
              OUR SERVICES.
            </li>
          </ul>
        </Box>
        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">Disclaimer of Warranties.</Box>
          <ul>
            <li className="toc_li">
              Your access to and use of the Platform and Services is at your
              sole risk, and you expressly understand and agree that access to
              and use of the Platform and Services is at your sole risk. The
              Platform (including the Services, Data, and any User Content) is
              provided to you on an `AS IS`` and `AS AVAILABLE` basis, without
              warranties of any kind, either express or implied, including,
              without limitation, any warranties contained elsewhere in these
              Terms of Service and any implied warranties of merchantability,
              fitness for a particular purpose, or non-infringement. You
              acknowledge that Aspire Cyber has no control over, and no duty to
              take any action regarding: which users gain access to or use the
              Service; what effects the User Content and/or Data may have on
              you; how you may interpret or use the User Content and/or Data; or
              what actions you may take as a result of having been exposed to
              the User Content and/or Data. You release Aspire Cyber from all
              liability for you having acquired or not acquired User Content,
              Data, or other information on or through the Platform or Services.
              The Platform or Services may contain, or direct you to other
              websites containing information that some people may find
              offensive or inappropriate, or may be incorrect. We make no
              representations concerning any User Content or Data contained in
              or accessed through the Platform or Services, and Aspire Cyber
              will not be responsible or liable for the accuracy, compliance,
              legality, truthfulness, correctness, or decency of material
              contained in or accessed through the Platform or Services.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">Indemnification.</Box>
          <ul>
            <li className="toc_li">
              In addition to any other indemnifications stated in these Terms,
              you agree to indemnify, defend, and hold harmless Aspire Cyber and
              its affiliates, vendors, licensors and their respective owners,
              directors, officers, employees, agents, subsidiaries,
              representatives, successors and assigns from and against all
              claims, demands, liabilities, suits, actions, judgments, awards,
              damages, losses, costs and expenses, including attorneys fees,
              arising out of, related to, or resulting from
            </li>
            <li className="toc_li pl-2">
              any action taken using your Account, user IDs, passwords or other
              access credentials,
            </li>
            <li className="toc_li pl-2">
              your use or non-use of the Platform or Services.
            </li>
            <li className="toc_li pl-2">
              your reliance or non-reliance on any User Content, Data or other
              information on the Platform.
            </li>
            <li className="toc_li pl-2">
              the User Content or Data made available through your Account.
            </li>
            <li className="toc_li pl-2">
              your or noncompliance with or breach of these Terms.
            </li>
            <li className="toc_li pl-2">
              your age, physical location at the time of access to or use of the
              Platform or Services.
            </li>
            <li className="toc_li pl-2">
              your relationship to or association with an entity named when you
              created your Account.
            </li>
            <li className="toc_li pl-2">
              your receipt, ownership, use, or misuse of any User Content, Data,
              or other information.
            </li>
            <li className="toc_li pl-2">
              your use of third-party services, including products, links,
              advertisements, or tools.
            </li>
            <li className="toc_li pl-2">
              your violations of any third-party rights, including any
              third-party intellectual property rights or rights of privacy.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">Limitation of Liability.</Box>
          <ul>
            <li className="toc_li">
              In no event shall Aspire Cyber, LLC, nor its directors, employees,
              partners, agents, suppliers, or affiliates, be liable for any
              indirect, incidental, special, consequential or punitive damages,
              including without limitation, injuries or any other damage that
              might occur to your person, loss of profits, loss of data,
              compliance requirements, legal obligations, or other intangible
              losses, resulting from <br /> (i) your access to or use of or
              inability to access or use the Platform or Services, whether in
              whole or part; <br /> (ii) any conduct or content of any third
              party on the Platform;
              <br /> (iii) any User Content, Data, or other information obtained
              from the Platform, including any services offered or provided by
              users, including the Services; and <br /> (iv) unauthorized
              access, use or alteration of your transmissions or content, User
              Content, and Data, whether based on warranty, contract, tort
              (including negligence) or any other legal theory, whether or not
              we have been informed of the possibility of such damage, and even
              if a remedy set forth herein is found to have failed of its
              essential purpose.
            </li>
            <li className="toc_li">
              IN NO EVENT SHALL ASPIRE CYBER, LLC, ITS EMPLOYEES, SUBSIDIARIES,
              PARENTS, AGENTS, PARTNERS, THIRD-PARTY CONTENT PROVIDERS, VENDORS,
              SUCCESSORS, ASSIGNS OR THEIR RESPECTIVE DIRECTORS, OFFICERS, AND
              MEMBERS, BE LIABLE TO YOU, YOUR AUTHORIZED USERS OR ANY OTHER
              PERSON FOR ANY LOSS OR DAMAGES WHATSOEVER ARISING FROM :
            </li>
            <li className="toc_li pl-2">
              THE USE OF OR INABILITY TO USE THE PLATFORM, SERVICES, AND
              ACCOUNTS,
            </li>
            <li className="toc_li pl-2">
              ANY INFORMATION, USER CONTENT, INTELLECTUAL PROPERTY, MATERIALS,
              OR THE SERVICES OTHERWISE MADE AVAILABLE TO YOU THROUGH US,
            </li>
            <li className="toc_li pl-2">
              CHANGES IN APPLICABLE LAWS AFFECTING YOU OR OTHER PERSONS,
              INCLUDING BUT NOT LIMITED TO ANY INDIRECT, SPECIAL, CONSEQUENTIAL,
              INCIDENTAL, PUNITIVE, OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED
              TO EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, LOSS OF PROFITS,
              PERSONAL INJURY OR DEATH, PROPERTY DAMAGE, REPUTATIONAL HARM, OR
              LOSS OF INFORMATION OR DATA.
            </li>
            <li className="toc_li pl-2">
              WE EXPRESSLY DISCLAIM ANY AND ALL LIABILITY OF ANY KIND FOR ANY
              AUTHORIZED, UNAUTHORIZED, OR UNINTENDED ACCESS TO OR USE OF YOUR
              CONFIDENTIAL INFORMATION, USER CONTENT, OR INTELLECTUAL PROPERTY.
              BY EITHER YOU OR YOUR AUTHORIZED USERS UTILIZING OUR PLATFORM,
              SERVICES, OR ACCOUNTS, YOU ACKNOWLEDGE AND AGREE TO OUR DISCLAIMER
              OF ANY SUCH LIABILITY. IF YOU DO NOT AGREE, YOU SHOULD NOT ACCESS
              OR OTHERWISE USE THEM.
            </li>
            <li className="toc_li">
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </li>
            <li className="toc_li">
              THE ABOVE LIMITATIONS SHALL SURVIVE THESE TERMS OF SERVICE AND
              INURE TO THE BENEFIT OF ASPIRE CYBER, ITS AFFILIATES, AND
              RESPECTIVE OWNERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
              REPRESENTATIVES, SUCCESSORS AND ASSIGNS.
            </li>
            <li className="toc_li">
              Nothing in these Terms shall be construed to be financial,
              investment, tax, or legal advice by Aspire Cyber. These Terms of
              Service are not intended to, and do not, create or impose any tax
              or legal obligation on Aspire Cyber, its employees, agents,
              affiliates, assigns, subcontractors, licensors, suppliers, or any
              other persons or entities.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">Dispute Resolution. </Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Initial Dispute Resolution.
              </Typography>{" "}
              You can email us at support@aspirecyber.com to address any
              concerns you may have regarding the Platform or Services. We
              attempt to resolve concerns quickly to our user’s satisfaction.
              The parties shall in good faith use their best efforts to settle
              any dispute, claim, question, or disagreement prior to and as a
              condition of initiating any legal proceeding.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Governing Law.
              </Typography>{" "}
              These Terms are governed by the State of Texas, without regard to
              its conflict of laws rules or principles. The parties hereby
              exclude the United Nations Convention on Contracts for the
              International Sale of Goods and the United Nations Convention on
              the Limitation Period in the International Sale of Goods, as
              amended.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Time to File Dispute.
              </Typography>{" "}
              Any claim or cause of action you or may have arising out of,
              relating to, or resulting from these Terms, the Platform,
              Services, or Account must be commenced within one year after the
              cause of action accrues. Otherwise, such claim or cause of action
              is waived and permanently barred.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Class Action Waiver.
              </Typography>{" "}
              Any arbitration shall be conducted in your individual capacity
              only and not as a class action or other representative action, and
              you expressly waive your right to file a class action or seek
              relief on a class basis. YOU AGREE THAT YOU MAY ONLY BRING CLAIMS
              AGAINST ASPIRE CYBER ONLY IN YOUR INDIVIDUAL CAPACITY, AND NOT AS
              A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING. If any court or arbitrator determines
              that the class action waiver set forth in this paragraph is void
              or unenforceable for any reason or that an arbitration can proceed
              on a class basis, then the arbitration provision set forth above
              shall be deemed null and void in its entirety and the parties
              shall be deemed to have not agreed to arbitrate disputes.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Binding Arbitration in the United States.
              </Typography>{" "}
              All questions or disputes regarding the interpretation,
              performance, or enforceability of these Terms, or the rights and
              remedies of the parties, shall be resolved by binding arbitration
              before a single arbitrator, with arbitration administered by the
              American Arbitration Association (`AAA`) under its Commercial
              Arbitration Rules in effect on the date the proceeding is
              initiated. The arbitrator shall apply the substantive laws of the
              State of Texas, United States without giving effect to any
              conflict of laws rules or principles. The arbitration hearing and
              all related proceedings shall be conducted in Delaware and in the
              English language. The arbitrator’s decision shall be final and
              non-appealable. Judgment on the award or decision rendered by the
              arbitrator may be entered in any court of competent jurisdiction.
              The arbitrator shall have the authority to settle any controversy,
              claim or dispute by finding that a party should be enjoined from
              certain actions or be compelled to undertake certain actions, and
              in such event a court of competent jurisdiction may enter an order
              enjoining and/or compelling such actions as found by the
              arbitrator. Except to the extent required by applicable law, the
              parties agree to keep confidential the nature of the controversy,
              claims, and dispute submitted to arbitration, all submissions made
              by the parties in connection with any arbitration proceeding or
              hearing, and the content of the arbitration proceedings and
              hearings. This section does not limit either party’s right to
              provisional or ancillary remedies from a court of competent
              jurisdiction before, during, or after arbitration, and the
              exercise of any such remedy does not waive either party’s right to
              arbitration. Judgment on an arbitration award may be entered by
              any court with competent jurisdiction. Any requirement in these
              Terms to pay or reimburse court costs or attorneys’ fees includes,
              without limitation, a requirement to reimburse costs and fees
              related to such arbitration. These Terms are subject to the
              operation of the 1958 United Nations Convention on the Recognition
              and Enforcement of Foreign Arbitral Awards
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Temporary Restraining Order.
              </Typography>{" "}
              Notwithstanding anything in this Section 13 to the contrary, the
              parties expressly agree that any court of competent jurisdiction
              may enter a temporary restraining order, an order enjoining breach
              of these Terms pending a final award or further decision by the
              arbitrator.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Jury Trial Waiver.
              </Typography>{" "}
              EACH PARTY HEREBY WAIVES ITS RIGHT TO A TRIAL BY JURY FOR DISPUTES
              ARISING OUT OF OR RELATED TO THESE TERMS OF USE, INCLUDING WITHOUT
              LIMITATION COUNTERCLAIMS REGARDING SUCH DISPUTES, CLAIMS RELATED
              TO THE PARTIES’ NEGOTIATIONS AND INDUCEMENTS TO ENTER INTO THIS
              LICENSE, AND OTHER CHALLENGES TO THE VALIDITY OR ENFORCEABILITY OF
              THIS LICENSE. THE WAIVER IN THE PRECEDING SENTENCE APPLIES
              REGARDLESS OF THE TYPE OF DISPUTE, WHETHER PROCEEDING UNDER CLAIMS
              OF CONTRACT OR TORT (INCLUDING NEGLIGENCE) OR ANY OTHER THEORY.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Waiver of Terms.
              </Typography>{" "}
              Any waiver made by us regarding these Terms shall be effective
              only if agreed or declared in writing. If we fail at any time to
              enforce any right, power or remedy reserved to us under these
              Terms, such failure shall not be treated as a waiver of our right
              to exercise the same or any other right, power or remedy at any
              time. The rights and remedies herein provided are cumulative and
              not exclusive of any other rights and remedies provided by law.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Right to Opt-Out.
              </Typography>{" "}
              You have the right to opt-out and not be bound by the arbitration
              and class action waiver provisions set forth above by sending
              written notice of your decision to opt-out to
              support@aspirecyber.com. The notice must be sent within 30 days of
              from the first use of the Services, otherwise you shall be bound
              to arbitrate disputes in accordance with the terms of those
              paragraphs. If you opt-out of these arbitration provisions, we
              also will not be bound by them.
            </li>
          </ul>
        </Box>

        <Box pl={4} pr={4} pt={4}>
          <Box className="Toc_heading">Miscellaneous. </Box>
          <ul>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Affiliate Disclosure.
              </Typography>{" "}
              We may have an affiliate relationship with third parties and
              affiliates to whose products and services we link or promote.
              Because of this relationship, we may earn a commission on products
              or services purchased by you from a third-party affiliate. We are
              not responsible for, and you agree not to hold us liable for, any
              products or services purchased by you from a third-party
              affiliate, nor are we responsible or liable for any loss or damage
              caused as a result of your use of any third-party product or
              service linked to from our Platform or in our Services.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Assignment.
              </Typography>{" "}
              You understand, acknowledge, and agree that we have the right to
              assign or transfer these Terms and its rights and obligations
              hereunder to any third party. You understand, acknowledge, and
              agree that you shall not assign or transfer your rights or
              subcontract or delegate the performance of any of your obligations
              under these Terms without our prior written consent, which may or
              may not be given, withheld, or delayed at our sole and exclusive
              discretion.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Customer Service.
              </Typography>{" "}
              Should you have any questions, comments or concerns regarding
              information or services provided or offered by us, our customer
              service may be contacted at any time by email at
              support@aspirecyber.com.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Entire Agreement.
              </Typography>{" "}
              These Terms of Service, together with the Privacy Policy and any
              other terms, constitute the sole and entire agreement between you
              and Aspire Cyber with respect to the Platform and Services
              described herein, and supersedes all prior and contemporaneous
              understandings, agreements, representations and warranties, both
              written and oral, with respect to the same. No modification or
              amendment will be binding upon us unless in a written instrument
              signed by our duly authorized representative or made available to
              you by us.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Force Majeure.
              </Typography>{" "}
              We shall not be bound to meet any obligation if prevented from
              doing so as a consequence of acts of god or force majeure,
              including but not limited to measures taken or imposed by any
              government or public authority or in case of any other event
              beyond the control of us, including but not limited to natural
              disasters (such as storm, hurricane, fire, flood, earthquake),
              war, civil unrest, terrorist activities, states of emergency,
              government sanctions, embargos, nationalizations, strikes and
              breakdowns of public utilities (such as of electricity or
              telecommunication services). We shall use all reasonable efforts
              to notify you of the circumstances causing the delay and to resume
              performance as soon as possible, both without undue delay.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Notices.
              </Typography>{" "}
              Except as explicitly stated otherwise, any notices directed to
              Aspire Cyber shall be given by email to support@aspirecyber.com.
              Any notices directed to you shall be directed to the e-mail
              address you provide to us, either during the Sign-Up process or
              when your e-mail address changes through your Account profile.
              Notice to you shall be deemed given twenty-four (24) hours after
              any such e-mail is sent unless the sending party is notified that
              the e-mail address is invalid. Alternatively, we may give you
              notice by certified mail, postage prepaid and return receipt
              requested, to any address provided to us in your Account profile.
              In such case, notice shall be deemed given three (3) calendar days
              after the date of mailing.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Severability.
              </Typography>{" "}
              If any part of these Terms is determined to be invalid or
              unenforceable by a court or tribunal of competent jurisdiction,
              then the invalid or unenforceable provision will be deemed
              superseded by a valid, enforceable provision that most closely
              matches the intent of the original provision and the remainder of
              these Terms will continue in effect.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Third-Party Beneficiaries.
              </Typography>{" "}
              You understand and agree that, except as otherwise provided for in
              these Terms, there shall be no third-party beneficiaries to the
              Terms.
            </li>
            <li className="toc_li">
              <Typography component="span" className="toc_li_heading">
                {" "}
                Titles and Headings.
              </Typography>{" "}
              Titles and headings of sections of these Terms are for convenience
              only and shall not affect the construction of any provision of
              this Terms.
            </li>
          </ul>
        </Box>
        <Box display="flex" justifyContent="center">
          <DarkTooltip
            arrow
            placement="top"
            title="Scroll to bottom to agree terms and condition"
          >
            <Box className="toc_agree_btn" width="200px">
              <Button
                title="I Accept Terms "
                align="center"
                disabled={disabled}
                background={Colors.text.green}
                borderRadius="50px"
                color={Colors.text.white}
                onClick={handleAgree}
              />
            </Box>
          </DarkTooltip>
        </Box>
      </Box>
    </>
  );
};

export default Toc;
