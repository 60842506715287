import SignIn from "./signIn";
import SignUp from "./signUp";
import ForgetPassword from "./forgetPassword";
import ResetPassword from "./resetPassword";
import SupportContact from "./supportContact";
import EmailVerification from "./emailVerification";
import MultiFactorAuthentication from "./multiFactorAuthentication";
import AssessmentQuestion from "./assessmentQuestion";
import Profile from "./profile";
import Dashboard from "./report";
import Home from "./home";
import StartAssessment from "./startAssessment";
import SSPCreator from "./sspCreator";
import NotFound from "./notFound";
import Unautherized from "./unAutherized";
import EmailValidate from "./emailValidate";
import EmailResetPassword from "./emailResetPassword";
import CertificatePage from "./certificate";
import Assessment from "./assessment";
import Evidence from "./evidence";
import Openapi from "./openapi";

import BufferZone from "./bufferZone";
import Reconnaissance from "./reconnaissance";
import Intelligence from "./intelligence";
import Fortress from "./fortress";
import Tactical from "./tactical";
import Wargames from "./warGames";
import BattleReady from "./battleReady";
import Inventory from "./inventory";
import Poam from "./poam";

import Billing from "./billing";
import ThankuPage from "./thanku";
import InviteUser from "./inviteUser";
import InviteUserSignUp from "./inviteUserSignUp";
import TrelloCard from "./trelloPage";
import BufferPhaseTask from "./bufferPhaseTask";
import AssessmentTask from "./assessmentTask";
import AssessmentNistCsfQuestion from "./assessmentNistCsfQuestion";
import AssessmentTaskNist from "./assessmentTaskNist";
import MFAEnablePage from "./MFAEnable";
import AssessmentNistCsf from "./assessmentNistCsf";
import AssessmentLevelTwo from "./assessmentLevelTwo";
import TermAndCondition from "./termAndCondition";
export {
  SignIn,
  SignUp,
  ResetPassword,
  ForgetPassword,
  SupportContact,
  EmailVerification,
  MultiFactorAuthentication,
  AssessmentQuestion,
  Profile,
  Dashboard,
  Home,
  StartAssessment,
  SSPCreator,
  NotFound,
  Unautherized,
  EmailValidate,
  EmailResetPassword,
  CertificatePage,
  Assessment,
  Evidence,
  Openapi,
  BufferZone,
  Reconnaissance,
  Intelligence,
  Fortress,
  Tactical,
  Wargames,
  BattleReady,
  Inventory,
  Poam,
  Billing,
  ThankuPage,
  InviteUser,
  InviteUserSignUp,
  TrelloCard,
  BufferPhaseTask,
  AssessmentTask,
  AssessmentNistCsfQuestion,
  AssessmentTaskNist,
  MFAEnablePage,
  AssessmentNistCsf,
  AssessmentLevelTwo,
  TermAndCondition,
};
