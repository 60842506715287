import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Box, Divider, Icon } from "@mui/material";
import { LexicalEditorToolbarButton } from "../LexicalToolBar/LexicalEditorToolbarButton";
import { LexicalEditorToolbarToggleButton } from "../LexicalToolBar/LexicalEditorToolbarToggleButton";
import LexicalEditorBlockOptionsSelect from "../LexicalToolBar/LexicalEditorBlockOptionsSelect";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $isListNode, ListNode } from "@lexical/list";
import { createPortal } from "react-dom";
import { $isHeadingNode } from "@lexical/rich-text";
import { LexicalEditorFloatingLinkEditor } from "../LexicalToolBar/LexicalEditorFloatingLinkEditor";
import { $selectAll } from "@lexical/selection";
import {
  AlignLeft,
  AlignMiddle,
  AlignRight,
  AlignJustify,
  Undo,
  Redo,
  Bold,
  Italic,
  LinkAlt,
  Strikethrough,
  Underline,
  Reset,
} from "@styled-icons/boxicons-regular";
import {
  LowPriority,
  getSelectedNode,
} from "../LexicalToolBar/LexicalEditorUtils";

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "h1",
  "h2",
  "ul",
  "ol",
]);

export default function LexicalEditorToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  const clearFormatting = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $selectAll(selection);
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0);
            node.setStyle("");
            $getNearestBlockElementAncestorOrThrow(node).setFormat("");
          }
          if ($isDecoratorBlockNode(node)) {
            node.setFormat("");
          }
        });
      }
    });
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        // eslint-disable-next-line no-unused-vars
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <>
      <Box
        ref={toolbarRef}
        display="flex"
        alignItems="center"
        sx={{ border: "1px solid grey" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "fit-content",
            borderRadius: 1,

            "& hr": {
              mx: 1,
            },
            p: 1,
          }}
        >
          <LexicalEditorToolbarButton
            disabled={!canUndo}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
            label="Undo"
            styles={{
              backgroundColor: "#EDF2F6",
              border: "1px solid #000",
              padding: "3px 6px 6px 6px",
              borderRadius: "4px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
          >
            <Icon>
              <Undo />
            </Icon>
          </LexicalEditorToolbarButton>
          <LexicalEditorToolbarButton
            disabled={!canRedo}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
            }}
            label="Redo"
            styles={{
              backgroundColor: "#EDF2F6",
              border: "1px solid #000",
              padding: "3px 6px 6px 6px",
              borderRadius: "4px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
          >
            <Icon>
              <Redo />
            </Icon>
          </LexicalEditorToolbarButton>
          <Divider />
          <Box>
            {supportedBlockTypes.has(blockType) && (
              <LexicalEditorBlockOptionsSelect
                editor={editor}
                blockType={blockType}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex">
              <LexicalEditorToolbarToggleButton
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}
                label="Bold"
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                selected={isBold}
              >
                <Icon>
                  <Bold />
                </Icon>
              </LexicalEditorToolbarToggleButton>
              <LexicalEditorToolbarToggleButton
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
                label="Italic"
                selected={isItalic}
              >
                <Icon>
                  <Italic />
                </Icon>
              </LexicalEditorToolbarToggleButton>
              <LexicalEditorToolbarToggleButton
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
                label="Underline"
                selected={isUnderline}
              >
                <Icon>
                  <Underline />
                </Icon>
              </LexicalEditorToolbarToggleButton>
              <LexicalEditorToolbarToggleButton
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                label="Strikethrough"
                selected={isStrikethrough}
              >
                <Icon>
                  <Strikethrough />
                </Icon>
              </LexicalEditorToolbarToggleButton>
              <LexicalEditorToolbarToggleButton
                disabled={false}
                onClick={() => {
                  clearFormatting();
                }}
                label="Clear Formatting"
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <Icon>
                  <Reset style={{ marginTop: "-4px" }} />
                </Icon>
              </LexicalEditorToolbarToggleButton>
              <LexicalEditorToolbarToggleButton
                disabled={false}
                onClick={insertLink}
                label="Link"
                selected={isLink}
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <Icon>
                  <LinkAlt />
                </Icon>
              </LexicalEditorToolbarToggleButton>
              {isLink &&
                createPortal(
                  <LexicalEditorFloatingLinkEditor editor={editor} />,
                  document.body
                )}
            </Box>
            <Box display="flex">
              <LexicalEditorToolbarButton
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                }}
                label="Left Align"
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <Icon>
                  <AlignLeft />
                </Icon>
              </LexicalEditorToolbarButton>
              <LexicalEditorToolbarButton
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                }}
                label="Center Align"
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <Icon>
                  <AlignMiddle />
                </Icon>
              </LexicalEditorToolbarButton>
              <LexicalEditorToolbarButton
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                }}
                label="Right Align"
              >
                <Icon>
                  <AlignRight />
                </Icon>
              </LexicalEditorToolbarButton>
              <LexicalEditorToolbarButton
                styles={{
                  backgroundColor: "#EDF2F6",
                  border: "1px solid #000",
                  padding: "3px 6px 6px 6px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                disabled={false}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                }}
                label="Justify Align"
              >
                <Icon>
                  <AlignJustify />
                </Icon>
              </LexicalEditorToolbarButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
