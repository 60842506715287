import React, { FunctionComponent, useRef } from "react";
import { CertificateTemplate, DashboardWrapper, Button } from "shared";
import { Grid, Box } from "@mui/material";
import { FiDownload } from "react-icons/fi";
import { PDFDocument } from "pdf-lib";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useLocation } from "react-router-dom";
import { formatDate } from "utils/constant";

type Props = Record<string, never>;

const Certificate: FunctionComponent<Props> = () => {
  const userName = useSelector((state: RootState) => state.user.userInfo);
  const location = useLocation();

  const certificateRef = useRef<HTMLDivElement | null>(null);
  const downloadCertificatePDF = async () => {
    if (certificateRef.current) {
      // Capture the content of the CertificateTemplate component using html2canvas
      const certificateImage = await html2canvas(certificateRef.current);
      const certificateImageData = certificateImage.toDataURL("image/png");

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([8.5 * 72, 11 * 72]); // Set the PDF page size

      // Embed the captured image into the PDF
      const pdfImage = await pdfDoc.embedPng(certificateImageData);
      const scaleFactor = page.getWidth() / certificateImage.width;

      // Draw the image on the PDF page
      const { height } = page.getSize();
      page.drawImage(pdfImage, {
        x: 0,
        y: 0,
        width: certificateImage.width * scaleFactor,
        height: height,
      });

      // Save the PDF to a Blob
      const pdfBytes = await pdfDoc.save();

      // Create a Blob containing the PDF data and create a download link
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "certificate.pdf";
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const today = new Date();
  const formattedDate = formatDate(today);

  const { state } = location;

  const assessmentName = state && state;

  return (
    <DashboardWrapper>
      <Box mt={4}>
        <Button
          title="Download Pdf"
          borderRadius="50px"
          fontWeight="500"
          width="230px"
          align="center"
          Icon={<FiDownload />}
          onClick={downloadCertificatePDF}
        />
      </Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <div ref={certificateRef}>
            <CertificateTemplate
              name={userName?.name}
              assessmentName={assessmentName}
              date={formattedDate}
            />
          </div>
        </Grid>
      </Grid>
    </DashboardWrapper>
  );
};

export default Certificate;
