import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import React from "react";

export function LexicalEditorToolbarToggleButton({
  children,
  onClick,
  disabled,
  label,
  styles,
}: {
  children: React.ReactElement;
  onClick?: IconButtonProps["onClick"];
  disabled: boolean;
  label: string;
  selected?: boolean;
  styles?: any;
}) {
  return (
    <Tooltip title={label}>
      <span>
        <IconButton
          disabled={disabled}
          size="small"
          color="inherit"
          aria-label="menu"
          onClick={disabled ? undefined : onClick}
          style={{
            pointerEvents: disabled ? "none" : "auto",
            opacity: disabled ? 0.6 : 1,
            ...styles,
          }}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
}
