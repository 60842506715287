import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ImageContainer = styled(Box)(({ theme }) => ({
  background: "#fff",
  height: "320px",
  [theme.breakpoints.down("md")]: {
    height: "250px",
  },
  [theme.breakpoints.down("sm")]: {
    boxShadow: "3px 3px 4px rgba(112, 102, 102, 0.25)",
    background: "#FAFAFA",
    borderRadius: "200px !important",
    marginBottom: theme.spacing(5),
  },
}));
