import React, { FunctionComponent, ReactNode } from "react";
import { Box, Card } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";

const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh",
  },
}));
const SharedCard = styled(Card)({
  filter: "drop-shadow(0px 0px 5px grey)",
  borderRadius: "15px",
});

type Props = {
  padding?: string;
  margin?: string;
  width?: string;
  children?: ReactNode | ReactNode[];
};

const CardComponent: FunctionComponent<Props> = ({
  padding = "0px",
  margin = "0px",
  width = "100%",
  children,
}: Props) => {
  return (
    <>
      <Container style={{ margin: margin }}>
        <SharedCard
          style={{
            width: width,
            padding: padding,
          }}
        >
          {children}
        </SharedCard>
      </Container>
    </>
  );
};

export default CardComponent;
