import React, { FunctionComponent } from "react";
import { Box, Divider } from "@mui/material";

import { Text } from "shared";
import { Colors } from "theme/color";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { MdArrowDropDown } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { MdRemoveModerator } from "react-icons/md";

type Props = {
  listLoader?: any;
  handleClickToggle?: // eslint-disable-next-line no-unused-vars
  (e: any, value: any) => void;
  userPermissions?: any;
  handleDeletePermission?: // eslint-disable-next-line no-unused-vars
  (email: any) => void;

  multipleUserPermissions?: any;
  multiPhaseFlag?: boolean;
};

const Collaboration: FunctionComponent<Props> = ({
  listLoader,
  userPermissions,
  handleClickToggle,
  handleDeletePermission,
  multipleUserPermissions,
  multiPhaseFlag,
}: Props) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<
    Record<string, HTMLButtonElement | null>
  >({});

  const handleClick = (
    email: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setPopoverAnchorEl((prev) => ({ ...prev, [email]: event.currentTarget }));
  };

  const handleClosePopover = (email: string) => {
    setPopoverAnchorEl((prev) => ({ ...prev, [email]: null }));
  };

  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <Box>
      {/* phases user list with assign permission */}
      <Box pb={1}>
        <Text
          fontSize="15px"
          fontWeight="600"
          color={Colors.text.orange}
          align="center"
        >
          People with access
        </Text>
      </Box>
      {listLoader ? (
        <Box textAlign="center" mt={2} mb={3}>
          Loading...
        </Box>
      ) : (
        <Box mb={2}>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
              marginBottom: "8px",
            }}
          >
            {userPermissions?.length === 0 ? (
              <Box textAlign="center">No user exist with access</Box>
            ) : (
              userPermissions?.map((email: any) => {
                return (
                  <ListItem
                    key={email?.accepter_id}
                    secondaryAction={
                      <Box>
                        <div>
                          <Button
                            aria-describedby={id}
                            variant="text"
                            onClick={(event) =>
                              handleClick(email?.user_email, event)
                            }
                            className="popover-button-text"
                            endIcon={<MdArrowDropDown />}
                            sx={{ width: "100px" }}
                          >
                            {email?.can_view ? "Viewer" : "Editor"}
                          </Button>
                          <Popover
                            id={id}
                            open={Boolean(popoverAnchorEl[email?.user_email])}
                            anchorEl={popoverAnchorEl[email?.user_email]}
                            onClose={() =>
                              handleClosePopover(email?.user_email)
                            }
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            sx={{ width: "200px" }}
                          >
                            <Box
                              p={2}
                              sx={{
                                background: "#fff !important",
                                padding: "10px",
                                width: "100px",
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Button
                                  variant="text"
                                  className="popover-button-text"
                                  onClick={() => {
                                    handleClickToggle?.(
                                      email?.user_email,
                                      "Viewer"
                                    );
                                    handleClosePopover(email?.user_email);
                                  }}
                                  sx={{ width: "80px" }}
                                >
                                  Viewer
                                </Button>
                                <Box>
                                  {email.can_view && (
                                    <TiTick
                                      style={{
                                        color: Colors.text.green,
                                        fontSize: "20px",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Button
                                  variant="text"
                                  className="popover-button-text"
                                  onClick={() => {
                                    handleClickToggle?.(
                                      email?.user_email,
                                      "Editor"
                                    );
                                    handleClosePopover(email?.user_email);
                                  }}
                                  sx={{ width: "80px" }}
                                >
                                  Editor
                                </Button>
                                <Box>
                                  {email.can_write && (
                                    <TiTick
                                      style={{
                                        color: Colors.text.green,
                                        fontSize: "20px",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Divider />
                              <Box mt={1}>
                                <Button
                                  variant="contained"
                                  color="error"
                                  className="popover-button-text"
                                  sx={{
                                    width: "100px",
                                    color: `${Colors.text.white} !important`,
                                  }}
                                  endIcon={
                                    <MdRemoveModerator
                                      style={{
                                        fontSize: "14px",
                                        color: Colors.text.white,
                                      }}
                                    />
                                  }
                                  onClick={() => {
                                    handleDeletePermission?.(email);
                                    handleClosePopover(email?.user_email); // Close the popover after deleting
                                  }}
                                >
                                  Remove
                                </Button>
                              </Box>
                            </Box>
                          </Popover>
                        </div>
                      </Box>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            bgcolor: deepPurple[500],
                            height: "24px",
                            width: "24px",
                            textTransform: "uppercase",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                          }}
                        >
                          {email?.user_email?.substring(0, 2)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={email.user_email}
                        sx={{ marginLeft: "-20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
            )}
          </List>
        </Box>
      )}

      {/* multiple user list of bulk api for view not change permission here */}

      {multiPhaseFlag && (
        <Box mb={2}>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
              marginBottom: "8px",
            }}
          >
            {multipleUserPermissions?.length === 0 ? (
              <Box textAlign="center">No user exist with access</Box>
            ) : (
              multipleUserPermissions?.map((email: any) => {
                return (
                  <ListItem
                    key={email?.accepter_id}
                    secondaryAction={
                      <Box>
                        <div>
                          <Button
                            aria-describedby={id}
                            variant="text"
                            onClick={(event) =>
                              handleClick(email?.user_email, event)
                            }
                            className="popover-button-text"
                            endIcon={<MdArrowDropDown />}
                            sx={{ width: "100px" }}
                          >
                            {email?.can_view ? "Viewer" : "Editor"}
                          </Button>
                        </div>
                      </Box>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            bgcolor: deepPurple[500],
                            height: "24px",
                            width: "24px",
                            textTransform: "uppercase",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                          }}
                        >
                          {email?.user_email?.substring(0, 2)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={email.user_email}
                        sx={{ marginLeft: "-20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Collaboration;
