import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const FlexBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",
  },
}));

export const styles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  overflowY: "auto",
  maxHeight: "87vh",
  paddingBottom: "15px",
  width: "30%",
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for smaller screens
  },
};
