import React, { FunctionComponent, useCallback, useState } from "react";
import { Text, Button, Card, Logo, Input, TextArea } from "shared";
import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import useAppDispatch from "hooks/useAppDispatch";
import { userSupport } from "reducers/user";
import swal from "sweetalert";
import { Container } from "./style";
import ReCAPTCHA from "react-google-recaptcha";

const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  fname: Yup.string()
    .matches(
      /^[A-Za-z ]+$/,
      "Only alphabetic characters and spaces are allowed"
    )
    .required("First Name is required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  lname: Yup.string()
    .matches(
      /^[A-Za-z ]+$/,
      "Only alphabetic characters and spaces are allowed"
    )
    .required("Last Name is required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  massage: Yup.string()
    .matches(
      /^[A-Za-z ]+$/,
      "Only alphabetic characters and spaces are allowed"
    )
    .required("Message is required")
    .min(2, "Too Short!"),
});

type Props = Record<string, never>;

const SupportContact: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const siteKey = process.env.REACT_APP_SITE_KEY!;
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const handleSupport = useCallback(
    async (
      values: {
        email: string;
        fname: string;
        lname: string;
        massage: string;
      },
      { resetForm }: any
    ) => {
      try {
        if (
          !values.email ||
          !values.fname.trim() || // Check if fname has any non-whitespace characters
          !values.lname.trim() || // Check if lname has any non-whitespace characters
          !values.massage.trim()
        ) {
          swal({
            title: "Support",
            text: "Enter valid details in all fields!",
            icon: "warning",
          });
          return; // Exit function if any field is empty
        }
        const captchaValue = recaptchaRef.current?.getValue();
        if (!captchaValue) {
          swal({
            title: "Register",
            text: "Please verify the reCAPTCHA!",
            icon: "warning",
          });
          return; // Exit function if any field is empty
        }

        setIsLoading(true);
        setIsDisabled(true);
        // attempt a login, and if successful get the user profile
        await dispatch(
          userSupport({
            email: values.email,
            fname: values.fname,
            lname: values.lname,
            massage: values.massage,
          })
        )
          .unwrap()
          .then(() => {
            resetForm();
          });
        // go to the main page
        swal({
          title: "Support",
          text: "Successfully submitted your data!",
          icon: "success",
        });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Support",
            text: "Bad Request!",
            icon: "error",
          });
        } else if (error?.data.status === 401) {
          swal({
            title: "Support",
            text: "Email Already Exist!",
            icon: "error",
          });
        } else {
          swal({
            title: "Register",
            text: "Oops, something went wrong. Please try again later!",
            icon: "error",
          });
        }
      } finally {
        setIsLoading(false);
        setIsDisabled(false);
      }
    },
    [dispatch]
  );

  return (
    <>
      <Container sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            display={{ xs: "none", md: "block" }}
            alignItems="center"
          >
            <Box>
              <Box pl={10} pr={4} pt={0}>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    display={{ xs: "none", md: "block" }}
                    alignItems="center"
                  >
                    <Box>
                      <Text
                        fontSize="30px"
                        fontWeight="700"
                        align="left"
                        variant="h5"
                      >
                        Fill out the form and someone will reach out to you.
                      </Text>
                    </Box>
                    <Box mt={4}>
                      <Text fontSize="20px" fontWeight="400" align="justify">
                        We know how important cybersecurity is, so we will do
                        our best to contact you as soon as we can.
                      </Text>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              pl={{ xs: 2, sm: 2, md: 6, lg: 8 }}
              pr={{ xs: 2, sm: 2, md: 6, lg: 8 }}
              pb={1}
              pt={1}
            >
              <Card>
                <Box p={{ xs: 2, sm: 2, md: 6, lg: 6 }}>
                  <Logo />
                  <Box pt={4}>
                    <Formik
                      initialValues={{
                        email: "",
                        fname: "",
                        lname: "",
                        massage: "",
                      }}
                      validationSchema={SignInSchema}
                      // onSubmit={handleSupport}
                      onSubmit={(values, { resetForm }) =>
                        handleSupport(values, { resetForm })
                      }
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Box>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange("email")}
                          />
                          {errors.email && touched.email ? (
                            <div className="error-message">{errors.email}</div>
                          ) : null}
                          <Input
                            name="fname"
                            type="text"
                            placeholder="Enter First Name"
                            value={values.fname}
                            onChange={handleChange("fname")}
                          />
                          {errors.fname && touched.fname ? (
                            <div className="error-message">{errors.fname}</div>
                          ) : null}

                          <Input
                            name="lname"
                            type="text"
                            placeholder="Enter Last Name"
                            value={values.lname}
                            onChange={handleChange("lname")}
                          />
                          {errors.lname && touched.lname ? (
                            <div className="error-message">{errors.lname}</div>
                          ) : null}
                          <TextArea
                            name="massage"
                            placeholder="Anything  Else You ‘d  Like To Add"
                            width="100%"
                            value={values.massage}
                            onChange={handleChange("massage")}
                          />
                          {errors.massage && touched.massage ? (
                            <div className="error-message">
                              {errors.massage}
                            </div>
                          ) : null}
                          <Box
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} />
                          </Box>
                          <Box pt={3}>
                            <Button
                              title={isLoading ? "Loadin..." : "Submit"}
                              align="center"
                              width="50%"
                              onClick={handleSubmit}
                              disabled={isDisabled}
                            />
                          </Box>
                        </Box>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SupportContact;
