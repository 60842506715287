import { Box, Button } from "@mui/material";
import React, { ChangeEventHandler } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";

const Image = styled("img")(({ theme }) => ({
  width: "220px",
  height: "220px",
  border: "5px solid #FED74F",
  borderRadius: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "150px",
    height: "150px",
    border: "5px solid #FED74F",
    borderRadius: "50%",
  },
}));

type Props = {
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  file?: string;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Cropper: React.FC<Props> = ({ file, onChange }) => {
  return (
    <>
      <main className="container">
        <Box>
          <div className="photo">
            <input
              type="file"
              onChange={onChange}
              id="upload"
              accept="image/*"
              style={{ display: "none" }}
            />
            <label htmlFor="upload">
              <Button
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <Image
                  id="avatar"
                  src={
                    file ||
                    "https://www.signivis.com/img/custom/avatars/member-avatar-01.png"
                  }
                />
              </Button>
            </label>
            <label htmlFor="avatar" />
          </div>
        </Box>
      </main>
    </>
  );
};

export default Cropper;
