import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import React from "react";

export function LexicalEditorToolbarButton({
  children,
  onClick,
  disabled,
  label,
  styles,
}: {
  children: React.ReactElement;
  onClick?: IconButtonProps["onClick"];
  disabled: boolean;
  label: string;
  styles?: any;
}) {
  return (
    <Tooltip title={label}>
      <IconButton
        disabled={disabled}
        size="small"
        color="inherit"
        aria-label="menu"
        onClick={disabled ? undefined : onClick}
        style={{
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? 0.6 : 1,
          ...styles,
        }}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
}
