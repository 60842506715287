import React, { useEffect, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate, useLocation } from "react-router-dom";

interface PrivateRouteProps {
  children: ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const isVerified = useSelector((state: RootState) => state.user.isVerified);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isVerified) {
      navigate("/signin", { replace: true, state: { from: location } });
    }
  }, [isVerified, navigate, location]);

  return isVerified ? <>{children}</> : null;
}

export default PrivateRoute;
