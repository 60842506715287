import React, { FunctionComponent, ReactNode } from "react";
import { Box } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Text } from "shared";
import { MdAccessTimeFilled } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Card = styled(Box)({
  backgroundColor: "#fff",
  borderRadius: "10px",
  filter: "drop-shadow(0px 0px 4px grey)",
  padding: "20px",
});

const Progress = styled(Box)({
  height: "26px",
  width: "100%",
  backgroundColor: "#e9ecef",
  borderRadius: "20px",
  overflow: "hidden",
});

const ProgressBar = styled(Box)({
  height: "100%",
  backgroundColor: "#28a745",
  float: "left",
});

const ProgressValue = styled(Box)({
  textAlign: "end",
  padding: "0px 10px",
});

type Props = {
  value?: number;
  assessmentName?: string;
  assessmentIcons?: ReactNode;
  totalQuestion?: number;
  completedQuestion?: number;
  mt?: string;
  mb?: string;
  totalEvidenceCount?: number;
  evidenceIcons?: ReactNode;
  assessmentLevleAndType?: any;
  totalNistSubcategories?: any;
  assessmentId?: any;
  assessmentLevel?: any;
  nameOfCategory?: any;
  totalMetCountNist?: any;
  assessmetType?: any;
};

const LinearProgress: FunctionComponent<Props> = ({
  value = 60,
  assessmentName = " Access Control",
  assessmentIcons = <MdAccessTimeFilled />,
  totalQuestion = 22,
  completedQuestion = 2,
  mt = "0px",
  mb = "15px",
  // totalEvidenceCount = 0,
  // evidenceIcons = <SiTrustedshops />,
  assessmentLevleAndType,
  totalNistSubcategories,
  assessmentId,
  assessmentLevel,
  nameOfCategory,
  totalMetCountNist,
  assessmetType,
}: Props) => {
  // const evidenceValue = isNaN(totalEvidenceCount) ? 0 : totalEvidenceCount;
  const navigate = useNavigate();

  const handleClick = () => {
    const accordionTitle = assessmentName;
    navigate(`/start/assessment/${assessmentId}/level/${assessmentLevel}`, {
      state: { accordionTitle },
    });
  };

  const handleClickNist = () => {
    const accordionTitle = assessmentName;
    const levelID = assessmentLevleAndType;
    const assessmentTypes = assessmetType;
    navigate(`/start/assessment/${assessmentId}`, {
      state: { accordionTitle, levelID, assessmentTypes },
    });
  };

  return (
    <>
      <Card>
        <Box style={{ minHeight: "100px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "35px",
                width: "35px !imporant",
                height: "35px !imporant",
              }}
            >
              {" "}
              {assessmentIcons}
            </Box>
          </Box>
          <Box
            onClick={
              assessmentLevleAndType === null ? handleClickNist : handleClick
            }
            sx={{ cursor: "pointer" }}
            mt={2}
          >
            <Text fontSize="16px" fontWeight="600" align="center">
              {assessmentName}
            </Text>
          </Box>
        </Box>
        <Progress mt={mt} mb={mb}>
          <ProgressBar role="progressbar" style={{ width: value + "%" }}>
            <ProgressValue>{Math.round(value)}%</ProgressValue>
          </ProgressBar>
        </Progress>
        <Box>
          <Text
            fontSize="16px"
            fontWeight="700"
            align="center"
            textTransform="capitalize"
          >
            {assessmentLevleAndType === null ? (
              <Box display="flex" justifyContent="center">
                <Box className="text_captilize" pr={1}>
                  {nameOfCategory}{" "}
                </Box>
                {totalMetCountNist} out of {totalNistSubcategories}
              </Box>
            ) : (
              <>
                {completedQuestion} out of {totalQuestion}
              </>
            )}
          </Text>
        </Box>
      </Card>
    </>
  );
};
export default LinearProgress;
