export const COMMON = {
  get apiBaseUrl() {
    return (
      process.env.REACT_APP_API_BASE_URL || "https://backend.cmmcproof.com/"
    );
  },
  dateStr(date: Date) {
    return date.toISOString();
  },
};
