import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { PURGE } from "redux-persist";

import * as api from "services/api";
import { RootState } from "store";

export interface AuthState {
  loading: boolean;
  inviteUserList: any[];
  inviteAcceptUserList: any[];
  permissionUser: any[];
  taskBoard: any;
  objectChangeHistoryList: any[];
}

const initialState: AuthState = {
  loading: false,
  inviteUserList: [],
  inviteAcceptUserList: [],
  permissionUser: [],
  taskBoard: null,
  objectChangeHistoryList: [],
};

// invite users to accept invite
export const inviteUser = createAsyncThunk(
  "invite/user",
  async (
    options: {
      email_addresses: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.inviteUsers(options.email_addresses);
      return response; // Return the entire API response
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// invite user list
export const getUserInvite = createAsyncThunk(
  "get/user/invite",
  async (
    options: {
      emailString: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getUserInvite(options.emailString);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// invite user to accept invite api
export const acceptInvite = createAsyncThunk(
  "accept/invite",
  async (
    options: {
      userString: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.acceptInvite(options.userString);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// table list api
export const fetchInviteUsers = createAsyncThunk(
  "user/invite/list",
  async (
    { page, limit, search }: { page: number; limit: number; search: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.fetchInviteUsers(page, limit, search);
      const assessmentsListData = response.list;
      const count = response.count;
      return { response, assessmentsListData, count };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// resend invite
export const resendUserInvite = createAsyncThunk(
  "resend/users/invite",
  async ({ pk }: { pk: number }, { rejectWithValue }) => {
    try {
      const response = await api.resendUserInvite(pk);
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// delete invite
export const delInviteUser = createAsyncThunk(
  "del/users/invite",
  async ({ pk }: { pk: number }, { rejectWithValue }) => {
    try {
      const response = await api.delInviteUser(pk);
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// reject invite
export const rejectInvite = createAsyncThunk(
  "reject/invite",
  async (
    options: {
      userString: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.rejectInvite(options.userString);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// fetch user invite with accepted
export const fetchAcceptedInvitedUsers = createAsyncThunk(
  "user/invite/accepted",
  async (
    { page, limit, search }: { page: number; limit: number; search: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.fetchAcceptedInvitedUsers(page, limit, search);
      const assessmentsListData = response.list;
      const count = response.count;
      return { response, assessmentsListData, count };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// assign permisisn to user
export const assignPermission = createAsyncThunk(
  "user/assign/permission",
  async (
    options: {
      invited_user_ids: any[];
      object_id: any[];
      content_type: string;
      read_permission: boolean;
      write_permission: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.assignPermission(
        options.invited_user_ids,
        options.object_id,
        options.content_type,
        options.read_permission,
        options.write_permission
      );
      // Return the token along with the response
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// fetch permisison assign to user with phase
export const fetchPermissionUserList = createAsyncThunk(
  "user/list/permission",
  async (
    { id, content_type }: { id: number | undefined; content_type: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.fetchPermissionUserList(id, content_type);

      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// delete permisison assign to user according to phase
export const deleteUserPermission = createAsyncThunk(
  "user/permission/delete",
  async (
    {
      userId,
      content_type,
      object_id,
    }: { userId: any; content_type: any; object_id: any[] },
    { rejectWithValue }
  ) => {
    try {
      return await api.deletePermission(userId, content_type, object_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
// change permisison assign to user according to phase
export const changeUserPermission = createAsyncThunk(
  "chnage/user/permission",
  async (
    {
      invited_user_id,
      object_id,
      perm_codename,
      read_permission,
      write_permission,
    }: {
      invited_user_id: any[];
      object_id: any[];
      perm_codename: string;
      read_permission: boolean;
      write_permission: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.changePermission(
        invited_user_id,
        object_id,
        perm_codename,
        read_permission,
        write_permission
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// list api for trello board for admin user and invited users
export const getListTaskBoard = createAsyncThunk(
  "task/board/list",
  async (_, { rejectWithValue }) => {
    try {
      return await api.getListTaskBoard();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// delete user all invite
export const deleteUserAllInvites = createAsyncThunk(
  "del/users/all/invite",
  async ({ pk }: { pk: number }, { rejectWithValue }) => {
    try {
      const response = await api.deleteAllUserInvite(pk);
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// aip for getting current phase data with id
export const objectChangeHistory = createAsyncThunk(
  "object/change/history",
  async (
    {
      content_type,
      id,
      ownerPk,
    }: { content_type: string; id: number; ownerPk: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.objectChangeHistory(content_type, id, ownerPk);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const collaborationSlice = createSlice({
  name: "collaboration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInviteUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInviteUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.inviteUserList = action.payload.response.list;
    });
    builder.addCase(fetchInviteUsers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAcceptedInvitedUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAcceptedInvitedUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.inviteAcceptUserList = action.payload.response.list;
    });
    builder.addCase(fetchAcceptedInvitedUsers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchPermissionUserList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPermissionUserList.fulfilled, (state, action) => {
      state.loading = false;
      state.permissionUser = action.payload.response;
    });
    builder.addCase(fetchPermissionUserList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getListTaskBoard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getListTaskBoard.fulfilled, (state, action) => {
      state.loading = false;
      state.taskBoard = action.payload;
    });
    builder.addCase(getListTaskBoard.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(objectChangeHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(objectChangeHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.objectChangeHistoryList = action.payload;
    });
    builder.addCase(objectChangeHistory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteUserAllInvites.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserAllInvites.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUserAllInvites.rejected, (state) => {
      state.loading = false;
    });

    // when purging reset back to the initial state
    builder.addCase(PURGE, () => initialState);
  },
});

export default collaborationSlice.reducer;

interface UserSelectorsType {
  loading: boolean;
}

export const UserSelectors = (): UserSelectorsType => {
  const loading = useSelector((state: RootState) => state.user.loading);
  return {
    loading,
  };
};
