import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { PURGE } from "redux-persist";

import * as api from "services/api";
import { RootState } from "store";
import { logoutAction } from "utils/auth";
import { UserInfo, UserProfile } from "types/user";

export interface AuthState {
  loading: boolean;
  userInfo: UserInfo | null;
  userProfile: UserProfile | null;
  authToken: string | null;
  email: string | null;
  isVerified: boolean | null;
  otpauthUrl: string | null;
  otpEnabled: boolean;
  userPackage: any[];
  isInvitedUser: boolean;
  logoutTimer: any;
}

const initialState: AuthState = {
  loading: false,
  userInfo: null,
  userProfile: null,
  authToken: null,
  email: null,
  isVerified: false,
  otpauthUrl: null,
  otpEnabled: false,
  userPackage: [],
  isInvitedUser: false,
  logoutTimer: null,
};

export const userSignUp = createAsyncThunk(
  "user/signup",
  async (
    options: {
      name: string;
      email: string;
      password: string;
      companyName: string;
      toc_agree: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.signUp(
        options.name,
        options.email,
        options.password,
        options.companyName,
        options.toc_agree
      );

      const authToken = response.auth_token;
      // Remove the auth_token property from the response
      // delete response.auth_token;
      // Return the token along with the response
      return { response, authToken };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userLogin = createAsyncThunk(
  "user/login",
  async (options: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await api.login(options.email, options.password);

      const authToken = response.auth_token;
      const email = response.email;
      const isVerified = response.is_email_verified;
      const otpEnabled = response.otp_enabled;

      return { response, authToken, email, isVerified, otpEnabled };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userLoginEmailVerification = createAsyncThunk(
  "user/login/email/verification",
  async (options: { email: any }, { rejectWithValue }) => {
    try {
      const response = await api.loginVerifyEmail(options.email);

      const isVerified = response.is_email_verified;

      return { response, isVerified };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userOtpStatus = createAsyncThunk(
  "user/login/email/otp/status",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.mfaOtpStatus();

      const is_otp_enabled = response.is_otp_enabled;

      return { response, is_otp_enabled };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      await api.logout();
      return;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resendVerify = createAsyncThunk(
  "user/email/resend-verify",
  async (_, { rejectWithValue }) => {
    try {
      return await api.resendVerify();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userSupport = createAsyncThunk(
  "user/support",
  async (
    options: {
      email: string;
      fname: string;
      lname: string;
      massage: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.supportConact(
        options.email,
        options.fname,
        options.lname,
        options.massage
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyEmailAction = createAsyncThunk(
  "user/email/verify",
  async (
    options: {
      email: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.verifyEmail(options.email);

      // const isVerified = response.is_email_verified;
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userForgetPassword = createAsyncThunk(
  "user/request/password-change",
  async (options: { email: string }, { rejectWithValue }) => {
    try {
      return await api.forgetPassword(options.email);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userResetPassword = createAsyncThunk(
  "user/change/password",
  async (
    options: { memberToken: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.resetPassword(options.memberToken, options.password);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const twoFactorOtp = createAsyncThunk(
  "user/otp/generate",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.generateOtp();
      const otpauthUrl = response.otpauth_url;

      return { response, otpauthUrl };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "user/otp/verify",
  async (
    options: {
      twoFactorOtp: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.verifyOtp(options.twoFactorOtp);
      const otpEnabled = response.otp_verified;
      return { response, otpEnabled };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ValidateOtp = createAsyncThunk(
  "user/otp/validate",
  async (
    options: {
      validOtp: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.validateOtp(options.validOtp);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const disabledOtp = createAsyncThunk(
  "user/otp/disable",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.disabledOtp();
      const otpEnabled = response.otp_enabled;
      return { response, otpEnabled };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/update",
  async (
    options: {
      name: string;
      jobTitle: string;
      password: string;
      company_name: string;
      image?: File;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.updateProfilePage(
        options.name,
        options.jobTitle,
        options.password,
        options.company_name,
        options.image
      );

      const authToken = response.auth_token;
      const email = response.email;

      return { response, authToken, email };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userPackages = createAsyncThunk(
  "package/user",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.userPackage();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// invite user sign up for accepting invite
export const userSignUpInviteUser = createAsyncThunk(
  "user/signup/invite",
  async (
    options: {
      name: string;
      email: string;
      password: string;
      companyName: string;
      inviteString: string;
      toc_agree: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.signUpInviteUser(
        options.name,
        options.email,
        options.password,
        options.companyName,
        options.inviteString,
        options.toc_agree
      );

      // Return the token along with the response
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// subcribe front line defender package

export const subscribeFrontlineDefender = createAsyncThunk(
  "add/frontline-defender",
  async (
    options: {
      plan_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.subscribeFrontlineDefender(options.plan_id);

      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogoutTimer(state, action) {
      state.logoutTimer = action.payload;
    },
    clearLogoutTimer(state) {
      state.logoutTimer = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.userInfo = action.payload.response ?? null;
      state.loading = false;
      state.authToken = action.payload.response?.auth_token || "ABC";
      state.email = action.payload.response?.email;
      // state.otpEnabled = action.payload.response?.otp_enabled;
      state.userPackage = action.payload.response?.user_subscription;
      state.isInvitedUser = action.payload.response?.is_invited_user;
    });

    builder.addCase(userLogin.rejected, (state) => {
      state.loading = false;
      state.authToken = null; // set authToken to null in case of login failure
    });

    builder.addCase(userLoginEmailVerification.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userLoginEmailVerification.fulfilled, (state, action) => {
      state.loading = false;

      state.isVerified = action.payload.response?.is_email_verified;
    });

    builder.addCase(userLoginEmailVerification.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(userOtpStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userOtpStatus.fulfilled, (state, action) => {
      state.loading = false;

      state.otpEnabled = action.payload.response?.is_otp_enabled;
    });

    builder.addCase(userOtpStatus.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(userSignUp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userSignUp.fulfilled, (state, action) => {
      state.userInfo = action.payload.response ?? null;
      state.loading = false;
      state.email = action.payload.response?.email;
      state.authToken = action.payload.response?.auth_token || "ABC";
    });
    builder.addCase(userSignUp.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(twoFactorOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(twoFactorOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.otpauthUrl = action.payload.response?.otpauth_url;
    });
    builder.addCase(twoFactorOtp.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(disabledOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disabledOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.otpEnabled = action.payload.response?.otp_enabled;
    });
    builder.addCase(disabledOtp.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.otpEnabled = action.payload.response?.otp_verified;
    });
    builder.addCase(verifyOtp.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(userLogout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userLogout.fulfilled, (state) => {
      state.userInfo = null;
      state.userProfile = null;
      state.loading = false;
      state.authToken = null;
      state.isVerified = false;
      state.userPackage = [];
      state.otpEnabled = false;
    });
    builder.addCase(userLogout.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(userSupport.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(userSupport.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userSupport.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(resendVerify.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resendVerify.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resendVerify.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.userInfo = action.payload.response ?? null;
      state.loading = false;
      state.authToken = action.payload.response?.auth_token || "ABC";
      state.email = action.payload.response?.email;
      // state.otpEnabled = action.payload.response?.otp_enabled;
      state.userPackage = action.payload.response?.user_subscription;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(ValidateOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ValidateOtp.fulfilled, (state, action) => {
      state.userInfo = action.payload ?? null;
      state.loading = false;
      state.authToken = action.payload?.auth_token || "ABC";
      state.email = action.payload?.email;
      state.userPackage = action.payload?.user_subscription;
    });
    builder.addCase(ValidateOtp.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(verifyEmailAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyEmailAction.fulfilled, (state) => {
      state.loading = false;
      // state.authToken = action.payload.response?.data?.auth_token || "ABC";
    });
    builder.addCase(verifyEmailAction.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(userPackages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userPackages.fulfilled, (state, action) => {
      state.loading = false;
      state.userPackage = action.payload;
    });
    builder.addCase(userPackages.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(userSignUpInviteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userSignUpInviteUser.fulfilled, (state, action) => {
      state.userInfo = action.payload.response ?? null;
      state.loading = false;
      state.email = action.payload.response?.email;
    });
    builder.addCase(userSignUpInviteUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(subscribeFrontlineDefender.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(subscribeFrontlineDefender.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(subscribeFrontlineDefender.rejected, (state) => {
      state.loading = false;
    });

    // when purging reset back to the initial state
    builder.addCase(PURGE, () => initialState);

    // matcher which matches any rejected (with value) action to catch
    // unauthorized errors and trigger a logout
    builder.addMatcher(isRejectedWithValue(), (state, action: any) => {
      // if a user is logged in and the action payload has a status
      // which is 401 (unauthorized), trigger a logout
      if (
        action.payload &&
        action.payload.status &&
        action.payload.status === 401 &&
        state.authToken !== null
      ) {
        setImmediate(logoutAction);
      }
    });
  },
});
export const { setLogoutTimer, clearLogoutTimer } = userSlice.actions;

export default userSlice.reducer;

interface UserSelectorsType {
  loading: boolean;
}

export const UserSelectors = (): UserSelectorsType => {
  const loading = useSelector((state: RootState) => state.user.loading);
  return {
    loading,
  };
};
