import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { Text, DashboardWrapper, EvidenceTable, Loader } from "shared";

import useAppDispatch from "hooks/useAppDispatch";
import { fetchEvidenceList } from "reducers/assessment";
import { RootState } from "store";

import { Box } from "@mui/material";
import swal from "sweetalert";
import { useSelector } from "react-redux";

type Props = Record<string, never>;

const Evidence: FunctionComponent<Props> = () => {
  const paginationRowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const selectedObjectIdRef = useRef<number | null>(null);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchLoading === false) {
      const newSearchText = event.target.value;
      setSearchText(newSearchText);
    }
  };

  const evidenceList = useSelector(
    (state: RootState) => state.assessment.evidenceList
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setTableLoader(true);
    setPage(page);
    dispatch(
      fetchEvidenceList({
        page: page,
        limit: paginationPerPage,
        search: searchText,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setCount(data.count);
          setTableLoader(false);
        }, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowsPerPageChange = (event: any) => {
    setTableLoader(true);
    const value = event?.target?.value;
    if (value) {
      setPaginationPerPage(parseInt(value));
      setPage(1);
    }
  };

  useEffect(() => {
    setSearchLoading(true);
    setPage(1);
    dispatch(
      fetchEvidenceList({
        page: 1,
        limit: paginationPerPage,
        search: searchText,
      })
    )
      .unwrap()
      .then((data) => {
        setCount(data.count);
        setLoading(false);
        setTableLoader(false);
        setSearchLoading(false);
      })
      .catch((err) => {
        setSearchLoading(false);
        console.log(err);
        swal({
          title: "Evidence",
          text: "Something went wrong",
          icon: "error",
        });
      });
  }, [dispatch, paginationPerPage, searchText]);

  const evidenceData = evidenceList?.map((item: any, index) => {
    const evidence = {
      index: index + 1,
      pk: item.pk,
      id: `${item.pk} + id + ${item.assessment_info.id}`,
      assessment: item.assessment_info.name,
      evidenceName: item.name,
      relevance: item.relevance,
      source: item.source_information,
      notes: item.additional_notes,
      evidenceDate: item.evidence_date,
      objectiveIndex: item.assessment_objective,
    };
    return evidence;
  });

  return (
    <DashboardWrapper>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Box>
            <Box mt={3} mb={2}>
              <Text fontSize="32px" fontWeight="700" className="text-mobile">
                Evidence
              </Text>
            </Box>
          </Box>
          {tableLoader ? (
            <Loader isLoading={tableLoader} />
          ) : (
            <EvidenceTable
              evidence={evidenceData}
              totalRows={count}
              paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              pages={page}
              paginationPerPages={paginationPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              setPage={setPage}
              handleSearch={handleInputChange}
              search={searchText}
              selectedObjectIdRef={selectedObjectIdRef}
              loading={tableLoader}
              searchLoading={searchLoading}
            />
          )}
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default Evidence;
