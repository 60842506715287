import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { DashboardWrapper, Progress, Text, Button, Loader } from "shared";
import { Colors } from "theme/color";

import { Box, Grid, Select, SelectChangeEvent, MenuItem } from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";

import { fetchAssessmentStats } from "reducers/assessment";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { HeaderTitle, Header, NotFound } from "./style";
import {
  FrontlineDefender,
  Bunker,
  FortressPackage,
  assessmentTypeNISTCSFQSG,
} from "utils/constant";

type Props = Record<string, never>;

const Home: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();

  const [status, setStatus] = React.useState("0");
  const [completed, setCompleted] = useState(false);
  const [completedItems, setCompletedItems] = useState(1);
  const [inprogressItems, setInprogressItems] = useState(1);
  const [inprogress, setInprogress] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [completedList, setCompletedList] = useState<any>([]);
  const [newCompletedList, setNewCompletedList] = useState<any>([]);
  const [inprogressList, setInprogressList] = useState<any>([]);
  const [newInprogressList, setNewInprogressList] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [searchFlag, setSearchFlag] = useState(false);
  const dispatch = useAppDispatch();

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  const fetchData = useCallback(() => {
    dispatch(
      fetchAssessmentStats({
        inprogressPage: completedItems,
        completedPage: inprogressItems,
        perPage: 5,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setCompletedList(data.completed);
          setInprogressList(data.inprogress);
          setCompleted(data.response.is_last_completed_page);
          setInprogress(data.response.is_last_inprogress_page);
          setLoading(false);
        }, 0);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSeeMoreClick = () => {
    setLoading(true);
    const updatedCompletedItems = completedItems + 1;

    dispatch(
      fetchAssessmentStats({
        inprogressPage: inprogressItems,
        completedPage: updatedCompletedItems,
        perPage: 5,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setCompletedItems(updatedCompletedItems);
          setNewCompletedList(data.completed);
          setCompleted(data.response.is_last_completed_page);
          setLoading(false);
        }, 0);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleSeeMoreClickInprogress = () => {
    setLoading(true);
    const updatedInprogressItems = inprogressItems + 1;

    dispatch(
      fetchAssessmentStats({
        inprogressPage: updatedInprogressItems,
        completedPage: completedItems,
        perPage: 5,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setInprogressItems(updatedInprogressItems);
          setNewInprogressList(data.inprogress);
          setInprogress(data.response.is_last_inprogress_page);
          setLoading(false);
        }, 0);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (completedList?.length > 0 && newCompletedList?.length > 0) {
      setCompletedList((prevList: any) => prevList.concat(newCompletedList));
      setNewCompletedList([]);
    }
  }, [newCompletedList]);

  useEffect(() => {
    if (inprogressList?.length > 0 && newInprogressList?.length > 0) {
      setInprogressList((prevList: any) => prevList.concat(newInprogressList));
      setNewInprogressList([]);
    }
  }, [newInprogressList]);

  const InprogressAssessmentList = inprogressList;

  const CompletedAssessmentList = completedList;

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const clearInputValue = () => {
    setSearchFlag(false);
    setSearchValue("");
    setStatus("0");
  };

  const handleSearch = () => {
    setSearchFlag(true);
    // Perform the search logic here
    const selectStatusall = [
      ...InprogressAssessmentList.filter(
        (assessment: any) =>
          assessment.name
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          assessment.status
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      ),
      ...CompletedAssessmentList.filter(
        (assessment: any) =>
          assessment.name
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          assessment.status
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      ),
    ];
    const inprogress = [
      ...InprogressAssessmentList.filter(
        (assessment: any) =>
          assessment.name
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          assessment.status
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      ),
    ];
    const completed = [
      ...CompletedAssessmentList.filter(
        (assessment: any) =>
          assessment.name
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          assessment.status
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      ),
    ];

    let updatedResults = [];

    switch (status) {
      case "0":
        updatedResults = selectStatusall;
        break;
      case "10":
        updatedResults = completed;
        break;
      case "20":
        updatedResults = inprogress;
        break;
      // Add more cases if needed

      default:
        updatedResults = selectStatusall;
        break;
    }

    setSearchResults(updatedResults);
  };

  return (
    <DashboardWrapper>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Box sx={{ flexGrow: 1, ml: { xs: 2, sm: 2, md: 0 } }} mt={4}>
          <Box>
            <Text fontSize="36px" fontWeight="700" className="text-mobile">
              Home
            </Text>
          </Box>
          <Header>
            <Box mt={3}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  sx={{ borderRight: "1px solid white" }}
                >
                  <Box sx={{ p: { sx: 0, md: 2 } }}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Box pt={1}>
                          {" "}
                          <Text
                            fontWeight="700"
                            fontSize="16px"
                            color={Colors.text.white}
                            className="text-color"
                          >
                            Assessment Name
                          </Text>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={7} lg={7}>
                        <Box pl={0} pr={2}>
                          <input
                            placeholder="search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            className="assessment-name"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ borderRight: "1px solid white" }}
                >
                  <Box p={2}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Box pt={1}>
                          {" "}
                          <Text
                            fontWeight="700"
                            fontSize="16px"
                            color={Colors.text.white}
                            className="text-color"
                          >
                            Status
                          </Text>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={10} lg={10}>
                        <Box
                          pr={2}
                          sx={{
                            pl: { xs: 0, md: 2 },
                          }}
                        >
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={status}
                            placeholder="Select Status"
                            onChange={handleChange}
                            sx={{
                              "&.MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "transparent !important",
                                  height: "20px  !important",
                                  // Hide the root border
                                },
                                "&:hover fieldset, &.Mui-focused fieldset": {
                                  borderColor: "transparent !important", // Hide the border on hover and focus
                                },
                              },
                              "& .MuiSelect-icon": {
                                color: "#FED74F",
                                border: "1px solid #FED74F",
                                borderRadius: "50px",
                              },
                              "& .MuiSelect-select": {
                                borderColor: "#FED74F !important",
                                border: "2px solid #FED74F !important",
                                borderRadius: "15px !important",
                                background: "#fff !important",
                                padding: "10px 14px !important",
                                "&:hover, &:focus": {
                                  borderColor: "#FED74F !important",
                                  border: "2px solid #FED74F !important",
                                },
                              },
                              width: { xs: "100%", md: "100%" },
                            }}
                          >
                            <MenuItem
                              value="0"
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: Colors.button.primary_bg,
                                },
                              }}
                            >
                              Select
                            </MenuItem>
                            <MenuItem
                              value="10"
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: Colors.button.primary_bg,
                                },
                              }}
                            >
                              Completed Assessments
                            </MenuItem>
                            <MenuItem
                              value="20"
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: Colors.button.primary_bg,
                                },
                              }}
                            >
                              In Progress Assessments
                            </MenuItem>
                          </Select>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      mt: { xs: 2, md: 0 },
                    }}
                    p={2}
                  >
                    <Button
                      title="Search"
                      width="120px"
                      fontWeight="500"
                      onClick={handleSearch}
                      disabled={searchValue?.length > 0 ? false : true}
                    />
                    <Button
                      title="Reset"
                      fontWeight="500"
                      width="120px"
                      onClick={clearInputValue}
                      disabled={searchValue?.length > 0 ? false : true}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Header>
          <Box mt={3} mb={3}>
            <Grid
              container
              justifyContent="center"
              alignSelf="center"
              spacing={4}
            >
              <Grid item xs={12} sm={12} md={12}>
                {searchFlag ? (
                  <>
                    <HeaderTitle>
                      <Text
                        fontWeight="700"
                        fontSize="30px"
                        color={Colors.text.white}
                      >
                        Search Results{" "}
                      </Text>
                    </HeaderTitle>
                    <Box mt={2}>
                      {searchResults?.length > 0 ? (
                        searchResults?.map((assessment: any, index: any) => {
                          let progressValue = 0; // Initialize the progressValue variable

                          if (
                            assessment?.assessment_level === "empty" &&
                            assessment?.progress?.length >= 2
                          ) {
                            progressValue =
                              ((assessment.progress[0].answered_objectives +
                                assessment.progress[1].answered_objectives) /
                                (assessment.progress[0].total_objectives +
                                  assessment.progress[1].total_objectives)) *
                              100;
                          } else if (assessment?.progress?.length > 0) {
                            if (assessment.assessment_level === 2) {
                              progressValue =
                                ((assessment.progress[1]?.answered_objectives +
                                  assessment.progress[0]?.answered_objectives) /
                                  assessment.progress[1]?.total_objectives) *
                                100;
                            } else {
                              progressValue =
                                (assessment.progress[0]?.answered_objectives /
                                  assessment.progress[0]?.total_objectives) *
                                100;
                            }
                          }
                          return (
                            <Box key={index}>
                              {currentPackage === FrontlineDefender &&
                                assessment?.assessment_level === null &&
                                assessment?.assessment_type ===
                                  assessmentTypeNISTCSFQSG && (
                                  <Progress
                                    value={progressValue}
                                    status={assessment.status}
                                    align="right"
                                    url={{
                                      pathname:
                                        assessment.status === "completed"
                                          ? `/assessment/report/${assessment.id}`
                                          : assessment?.assessment_level ===
                                            null
                                          ? `/start/assessment/${assessment?.id}`
                                          : `/start/assessment/${assessment?.id}/level/${assessment?.assessment_level}`,
                                      state: {
                                        name: assessment.name,
                                        levelID: assessment?.assessment_level,
                                        assessmentTypes:
                                          assessment?.assessment_type,
                                      },
                                    }}
                                    assessmentLevleProgress={
                                      assessment?.assessment_level === null
                                        ? assessment?.target_assessment_level
                                        : assessment?.assessment_level
                                    }
                                    assessmentTypeProgress={
                                      assessment?.assessment_type
                                    }
                                  >
                                    {assessment.name}
                                  </Progress>
                                )}

                              {currentPackage === Bunker &&
                                assessment.assessment_level === 1 && (
                                  <Progress
                                    value={progressValue}
                                    status={assessment.status}
                                    align="right"
                                    url={{
                                      pathname: `/start/assessment/${assessment?.id}/level/${assessment?.assessment_level}`,
                                      state: {
                                        name: assessment.name,
                                      },
                                    }}
                                    assessmentLevleProgress={
                                      assessment?.assessment_level === null
                                        ? assessment?.target_assessment_level
                                        : assessment?.assessment_level
                                    }
                                    assessmentTypeProgress={
                                      assessment?.assessment_type
                                    }
                                  >
                                    {assessment.name}
                                  </Progress>
                                )}
                              {currentPackage === Bunker &&
                                assessment.assessment_level === null &&
                                assessment?.assessment_type ===
                                  assessmentTypeNISTCSFQSG && (
                                  <Progress
                                    value={progressValue}
                                    status={assessment.status}
                                    align="right"
                                    url={{
                                      pathname: `/start/assessment/${assessment?.id}`,
                                      state: {
                                        name: assessment.name,
                                        levelID: assessment?.assessment_level,
                                        assessmentTypes:
                                          assessment?.assessment_type,
                                      },
                                    }}
                                    assessmentLevleProgress={
                                      assessment?.assessment_level === null
                                        ? assessment?.target_assessment_level
                                        : assessment?.assessment_level
                                    }
                                    assessmentTypeProgress={
                                      assessment?.assessment_type
                                    }
                                  >
                                    {assessment.name}
                                  </Progress>
                                )}

                              {currentPackage === FortressPackage &&
                                assessment?.assessment_level !== "empty" && (
                                  <Progress
                                    value={progressValue}
                                    status={assessment.status}
                                    align="right"
                                    url={{
                                      pathname:
                                        assessment.status === "completed"
                                          ? `/assessment/report/${assessment.id}`
                                          : assessment?.assessment_level ===
                                            null
                                          ? `/start/assessment/${assessment?.id}`
                                          : `/start/assessment/${assessment?.id}/level/${assessment?.assessment_level}`,
                                      state: {
                                        name: assessment.name,
                                        levelID: assessment?.assessment_level,
                                        assessmentTypes:
                                          assessment?.assessment_type,
                                      },
                                    }}
                                    assessmentLevleProgress={
                                      assessment?.assessment_level === null
                                        ? assessment?.target_assessment_level
                                        : assessment?.assessment_level
                                    }
                                    assessmentTypeProgress={
                                      assessment?.assessment_type
                                    }
                                  >
                                    {assessment.name}
                                  </Progress>
                                )}
                            </Box>
                          );
                        })
                      ) : (
                        <NotFound>
                          <Text
                            variant="h3"
                            align="center"
                            fontWeight="700"
                            color={Colors.text.blue}
                          >
                            No records found
                          </Text>
                        </NotFound>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    {CompletedAssessmentList?.length > 0 ||
                    InprogressAssessmentList?.length > 0 ? (
                      <>
                        <Grid
                          container
                          justifyContent="center"
                          alignSelf="center"
                          spacing={8}
                        >
                          <Grid item xs={12} sm={12} md={6}>
                            {CompletedAssessmentList?.length > 0 ? (
                              <>
                                <Box>
                                  {(status === "0" || status === "10") && (
                                    <Box>
                                      <HeaderTitle>
                                        <Text
                                          fontWeight="700"
                                          fontSize="30px"
                                          color={Colors.text.white}
                                        >
                                          Completed Assessments{" "}
                                        </Text>
                                      </HeaderTitle>

                                      <Box mt={2}>
                                        {CompletedAssessmentList?.map(
                                          (assessment: any, index: any) => {
                                            let progressValue = 0; // Initialize the progressValue variable

                                            if (
                                              assessment?.assessment_level ===
                                                null &&
                                              assessment?.progress?.length >= 2
                                            ) {
                                              progressValue =
                                                ((assessment.progress[0]
                                                  .answered_objectives +
                                                  assessment.progress[1]
                                                    .answered_objectives) /
                                                  (assessment.progress[0]
                                                    .total_objectives +
                                                    assessment.progress[1]
                                                      .total_objectives)) *
                                                100;
                                            } else if (
                                              assessment?.progress?.length > 0
                                            ) {
                                              progressValue =
                                                (assessment.progress[0]
                                                  .answered_objectives /
                                                  assessment.progress[0]
                                                    .total_objectives) *
                                                100;
                                            }

                                            return (
                                              <Box key={index}>
                                                {currentPackage !== "Null" &&
                                                  currentPackage !==
                                                    FortressPackage &&
                                                  assessment.assessment_level ===
                                                    null &&
                                                  assessment?.assessment_type ===
                                                    assessmentTypeNISTCSFQSG && (
                                                    <Progress
                                                      value={progressValue}
                                                      status={assessment.status}
                                                      align="right"
                                                      url={{
                                                        pathname: `/assessment/report/${assessment.id}`,
                                                        state: {
                                                          name: assessment.name,
                                                        },
                                                      }}
                                                      assessmentLevleProgress={
                                                        assessment?.assessment_level ===
                                                        null
                                                          ? assessment?.target_assessment_level
                                                          : assessment?.assessment_level
                                                      }
                                                      assessmentTypeProgress={
                                                        assessment?.assessment_type
                                                      }
                                                    >
                                                      {assessment.name}
                                                    </Progress>
                                                  )}

                                                {currentPackage === Bunker &&
                                                  assessment.assessment_level ===
                                                    1 && (
                                                    <Progress
                                                      value={progressValue}
                                                      status={assessment.status}
                                                      align="right"
                                                      url={{
                                                        pathname: `/assessment/report/${assessment.id}`,
                                                        state: {
                                                          name: assessment.name,
                                                        },
                                                      }}
                                                      assessmentLevleProgress={
                                                        assessment?.assessment_level ===
                                                        null
                                                          ? assessment?.target_assessment_level
                                                          : assessment?.assessment_level
                                                      }
                                                      assessmentTypeProgress={
                                                        assessment?.assessment_type
                                                      }
                                                    >
                                                      {assessment.name}
                                                    </Progress>
                                                  )}

                                                {currentPackage ===
                                                  FortressPackage && (
                                                  <Progress
                                                    value={progressValue}
                                                    status={assessment.status}
                                                    align="right"
                                                    url={{
                                                      pathname: `/assessment/report/${assessment.id}`,
                                                      state: {
                                                        name: assessment.name,
                                                      },
                                                    }}
                                                    assessmentLevleProgress={
                                                      assessment?.assessment_level ===
                                                      null
                                                        ? assessment?.target_assessment_level
                                                        : assessment?.assessment_level
                                                    }
                                                    assessmentTypeProgress={
                                                      assessment?.assessment_type
                                                    }
                                                  >
                                                    {assessment.name}
                                                  </Progress>
                                                )}
                                              </Box>
                                            );
                                          }
                                        )}
                                        {completed === true ? null : (
                                          <Box
                                            display="flex"
                                            justifyContent="center"
                                          >
                                            <button
                                              className="see-more-button"
                                              onClick={handleSeeMoreClick}
                                            >
                                              See More
                                            </button>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            {InprogressAssessmentList?.length > 0 ? (
                              <Box>
                                {(status === "0" || status === "20") && (
                                  <Box mt={status === "0" ? 0 : 0}>
                                    <HeaderTitle>
                                      <Text
                                        fontWeight="700"
                                        fontSize="30px"
                                        color={Colors.text.white}
                                      >
                                        In Progress
                                      </Text>
                                    </HeaderTitle>

                                    <Box mt={2}>
                                      {InprogressAssessmentList?.map(
                                        (assessment: any, index: any) => {
                                          let progressValue = 0; // Initialize the progressValue variable

                                          if (
                                            assessment?.progress?.length > 0
                                          ) {
                                            if (
                                              assessment.assessment_level === 2
                                            ) {
                                              progressValue =
                                                ((assessment.progress[1]
                                                  ?.answered_objectives +
                                                  assessment.progress[0]
                                                    ?.answered_objectives) /
                                                  assessment.progress[1]
                                                    ?.total_objectives) *
                                                100;
                                            } else {
                                              progressValue =
                                                (assessment.progress[0]
                                                  ?.answered_objectives /
                                                  assessment.progress[0]
                                                    ?.total_objectives) *
                                                100;
                                            }
                                          }

                                          return (
                                            <Box key={index}>
                                              {currentPackage !== "Null" &&
                                                currentPackage !==
                                                  FortressPackage &&
                                                assessment.assessment_level ===
                                                  null &&
                                                assessment?.assessment_type ===
                                                  assessmentTypeNISTCSFQSG && (
                                                  <Progress
                                                    value={progressValue}
                                                    status={assessment.status}
                                                    align="right"
                                                    url={{
                                                      pathname: `/start/assessment/${assessment?.id}`,
                                                      state: {
                                                        name: assessment.name,
                                                        levelID:
                                                          assessment?.assessment_level,
                                                        assessmentTypes:
                                                          assessment?.assessment_type,
                                                      },
                                                    }}
                                                    assessmentLevleProgress={
                                                      assessment?.assessment_level ===
                                                      null
                                                        ? assessment?.target_assessment_level
                                                        : assessment?.assessment_level
                                                    }
                                                    assessmentTypeProgress={
                                                      assessment?.assessment_type
                                                    }
                                                  >
                                                    {assessment.name}
                                                  </Progress>
                                                )}

                                              {currentPackage === Bunker &&
                                                assessment.assessment_level ===
                                                  1 && (
                                                  <Progress
                                                    value={progressValue}
                                                    status={assessment.status}
                                                    align="right"
                                                    url={{
                                                      pathname: `/start/assessment/${assessment?.id}/level/${assessment?.assessment_level}`,
                                                      state: {
                                                        name: assessment.name,
                                                      },
                                                    }}
                                                    assessmentLevleProgress={
                                                      assessment?.assessment_level ===
                                                      null
                                                        ? assessment?.target_assessment_level
                                                        : assessment?.assessment_level
                                                    }
                                                    assessmentTypeProgress={
                                                      assessment?.assessment_type
                                                    }
                                                  >
                                                    {assessment.name}
                                                  </Progress>
                                                )}

                                              {currentPackage ===
                                                FortressPackage &&
                                                assessment.assessment_level !==
                                                  "empty" &&
                                                (assessment.assessment_level ===
                                                null ? (
                                                  <Progress
                                                    value={progressValue}
                                                    status={assessment.status}
                                                    align="right"
                                                    url={{
                                                      pathname: `/start/assessment/${assessment?.id}`,
                                                      state: {
                                                        name: assessment.name,
                                                        levelID:
                                                          assessment?.assessment_level,
                                                        assessmentTypes:
                                                          assessment?.assessment_type,
                                                      },
                                                    }}
                                                    assessmentLevleProgress={
                                                      assessment?.assessment_level ===
                                                      null
                                                        ? assessment?.target_assessment_level
                                                        : assessment?.assessment_level
                                                    }
                                                    assessmentTypeProgress={
                                                      assessment?.assessment_type
                                                    }
                                                  >
                                                    {assessment.name}
                                                  </Progress>
                                                ) : (
                                                  <Progress
                                                    value={progressValue}
                                                    status={assessment.status}
                                                    align="right"
                                                    url={{
                                                      pathname: `/start/assessment/${assessment?.id}/level/${assessment?.assessment_level}`,
                                                      state: {
                                                        name: assessment.name,
                                                      },
                                                    }}
                                                    assessmentLevleProgress={
                                                      assessment?.assessment_level ===
                                                      null
                                                        ? assessment?.target_assessment_level
                                                        : assessment?.assessment_level
                                                    }
                                                    assessmentTypeProgress={
                                                      assessment?.assessment_type
                                                    }
                                                  >
                                                    {assessment.name}
                                                  </Progress>
                                                ))}
                                            </Box>
                                          );
                                        }
                                      )}

                                      {!inprogress && (
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <button
                                            onClick={
                                              handleSeeMoreClickInprogress
                                            }
                                            className="see-more-button"
                                          >
                                            See More
                                          </button>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            ) : null}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Box className="create-assessment-box">
                        <Box display="flex" justifyContent="center">
                          <Text fontSize="20px" fontWeight="700">
                            No Record Found
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default Home;
