import React, { FunctionComponent, ReactNode } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Typography, TypographyProps } from "@mui/material";

const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  letterSpacing: "0.01em",
  fontFamily: "Poppins !important",
  padding: theme.spacing(0),
}));

type Props = {
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  textDecoration?: string;
  padding?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2";
  align?: "left" | "center" | "right" | "justify";
  children?: ReactNode | ReactNode[];
  className?: string;
  textTransform?: "capitalize" | "lowercase" | "uppercase" | "none";
  wordBreak?: "normal" | "break-all" | "keep-all" | "break-word";
};

const TitleComponent: FunctionComponent<Props> = ({
  fontSize = "14px",
  fontWeight = "400",
  color = "black",
  textDecoration = "none",
  padding = "0px",
  align = "left",
  variant = "h6",
  children,
  className,
  textTransform = "none",
  wordBreak = "normal",
}: Props) => {
  return (
    <>
      <StyledTypography
        className={className}
        variant={variant}
        align={align}
        style={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: color,
          textDecoration: textDecoration,
          padding: padding,
          textTransform: textTransform,
          wordBreak: wordBreak,
        }}
      >
        {children}
      </StyledTypography>
    </>
  );
};

export default TitleComponent;
