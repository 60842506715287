import React, { FunctionComponent, ReactNode } from "react";
import { Box } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Text } from "shared";
import { MdAccessTimeFilled } from "react-icons/md";

const Progress = styled(Box)({
  height: "10px",
  width: "100%",
  backgroundColor: "#e9ecef",
  borderRadius: "20px",
  overflow: "hidden",
});

const ProgressBar = styled(Box)({
  height: "100%",
  float: "left",
});

type Props = {
  value?: number;
  assessmentName?: string;
  assessmentIcons?: ReactNode;
  totalQuestion?: any;
  completedQuestion?: any;
  mt?: string;
  mb?: string;
  totalEvidenceCount?: number;
  evidenceIcons?: ReactNode;
  color?: any;
  align?: any;
  buffer?: boolean;
  poamTitle?: string;
  poam?: boolean;
};

const BufferProgress: FunctionComponent<Props> = ({
  value = 60,
  assessmentName = " Access Control",
  assessmentIcons = <MdAccessTimeFilled />,
  totalQuestion = 0,
  completedQuestion = 0,
  mt = "0px",
  mb = "15px",
  color = "#003D96",
  align = "right",
  buffer = false,
  poam = false,
  poamTitle,
}: Props) => {
  return (
    <>
      <>
        {buffer ? (
          <Text fontSize="16px" fontWeight="500" align={align}>
            {completedQuestion} / {totalQuestion}
          </Text>
        ) : (
          <Box display="flex" justifyContent="space-between">
            {poam ? (
              <Box
                sx={{
                  fontSize: "14px",
                  marginTop: "30px",
                }}
              >
                {" "}
                {poamTitle}
              </Box>
            ) : (
              <Box
                sx={{
                  fontSize: "35px",
                }}
              >
                {" "}
                {assessmentIcons}
              </Box>
            )}

            <Box mt={4}>
              <Text fontSize="16px" fontWeight="500" align={align}>
                {completedQuestion} / {totalQuestion}
              </Text>
            </Box>
          </Box>
        )}

        <Progress mt={mt} mb={mb}>
          <ProgressBar
            role="progressbar"
            style={{ width: value + "%", backgroundColor: color }}
          ></ProgressBar>
        </Progress>
        <Text fontSize="24px" fontWeight="500" align="left">
          {assessmentName}
        </Text>
      </>
    </>
  );
};
export default BufferProgress;
