import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  SSPAccordian,
  Text,
  DashboardWrapper,
  SspChildAccordion,
  Loader,
  Button,
  BufferProgress,
  Input,
  TextArea,
  Logo,
  Collaboration,
} from "shared";
import { FiDownload } from "react-icons/fi";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import useAppDispatch from "hooks/useAppDispatch";
import {
  getPoam,
  getEvidenceImagesReport,
  addPoem,
  getPoamList,
  putPoam,
} from "reducers/assessment";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Zoom,
  Grid,
  Modal,
  Select,
  SelectChangeEvent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { Colors } from "theme/color";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import { GrSystem } from "react-icons/gr";
import { Formik } from "formik";
import * as Yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BiPlusCircle } from "react-icons/bi";
import { AiFillMinusCircle } from "react-icons/ai";
import { fetchAcceptedInvitedUsers } from "reducers/collaburation";
import { LargeModal, LightTooltip } from "utils/sharedStyled";
import { ModalContent, FlexBox, styles } from "./style";
import { assessmentTypeNISTCSFQSG, FrontlineDefender } from "utils/constant";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AssesmentSchema = Yup.object().shape({
  poamId: Yup.string().required("Poam Id is required").max(50, "Too Long!"),
  weaknessName: Yup.string()
    .required("Weakness Name is required")
    .max(50, "Too Long!"),
  pointContact: Yup.string()
    .email("Please enter valid email")
    .required("Email is required"),
});

interface QuestionStatus {
  notMet: boolean;
  met: boolean;
  notInterested: boolean;
}

type Props = Record<string, never>;

const Poam: FunctionComponent<Props> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [allExpanded, setAllExpanded] = React.useState(false);
  const [severity, setSeverity] = useState("0");
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(null));
  const [actualDate, setActualDate] = useState<Dayjs | null>(dayjs(null));
  const [checked, setChecked] = useState<number[]>([]);
  const [milestonesList, setMilestonesList] = useState<
    { milestone: string; is_completed: boolean }[]
  >([]);
  const [milestonesListShare, setMilestonesListShare] = useState<
    { milestone: string; is_completed: boolean }[]
  >([]);
  const [checkedShare, setCheckedShare] = useState<number[]>([]);
  const [milestones, setMilestones] = useState("");
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [poamPopupList, setPoamPopupList] = useState<any>();
  const [poamList, setPoamList] = useState<any>([]);
  const [totalMilestone, setTotalMilestone] = useState<any>();
  const [completedMilestone, setCompletedMilestone] = useState<any>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [milestoneIndexToRemove, setMilestoneIndexToRemove] = useState(null);
  const [assessmentLevel, setAssessmentLevel] = useState();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const assessmentId = parts[2];
  const navigate = useNavigate();

  const { state } = location;

  const CompeleteDate = state && state.completedDate;
  const assessmentTypeCurrent = state && state.assessmentTypes;

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  const handleSelectSeverity = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    setSeverity(selectedValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(getPoamList({ assessmentId }))
      .unwrap()
      .then((response) => {
        setPoamPopupList(response);
        setMilestonesList(response?.milestones);
      })
      .catch((err: any) => {
        console.log(err);
      });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getPoam({ assessmentId }))
      .unwrap()
      .then((response) => {
        setPoamList(response?.grouped_security_requirements);
        setCompletedMilestone(
          response?.milestone_prgress?.completed_milestones
        );
        setTotalMilestone(response?.milestone_prgress?.total_milestones);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }, [dispatch, assessmentId]);

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getEvidenceImagesReport({ assessmentId }))
      .unwrap()
      .then(() => {})
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }, [dispatch, assessmentId]);

  const accordion = React.useMemo(() => {
    return poamList?.map((item: any) => {
      const securityRequirements = item.security_requirements.map((sr: any) => {
        setAssessmentLevel(sr.assessment_level);
        const objectives = sr.objectives
          .filter(
            (objective: any) =>
              assessmentTypeCurrent !== assessmentTypeNISTCSFQSG ||
              objective.is_nist_csf_2_0_quick_start_objective
          )
          .map((objective: any) => {
            let status: QuestionStatus = {
              notMet: false,
              met: false,
              notInterested: false,
            };
            if (objective.user_answer === "not_met") {
              status.notMet = true;
            } else if (objective.user_answer === "met") {
              status.met = true;
            } else if (objective.user_answer === "na") {
              status.notInterested = true;
            }
            const nistExamples = objective.examples?.split("\\n").join("\n");
            const question = {
              id: objective.pk,
              assessmentSecurityRequirementId:
                objective.assessment_security_requirement,
              questionNumber: objective.code,
              title: objective.description,
              status,
              isEvidenceProvided: objective.is_evidence_provided,
              examples: nistExamples,
              objectiveComment: objective.comment,
              is_nist_csf_2_0_quick_start_objective:
                objective.is_nist_csf_2_0_quick_start_objective,
            };
            return question;
          });
        const controlDescription =
          sr.assessment_level === null
            ? sr?.user_help_text?.html
            : sr.control_explanation?.split("\\n").join("\n");
        return {
          controlNumber:
            assessmentLevel === null
              ? sr.control_number + ":"
              : sr.control_number,
          controlDescription: controlDescription,
          controlRequirement: sr.control_requirement,
          objectives: objectives,
          userComment: sr.user_comment,
          userResponse: sr.user_response,
        };
      });

      const accordion = {
        title: item.assessment_family,
        completedQueries: item.total_family_responses,
        totalQueries: item.total_family_objectives,
        securityRequirements: securityRequirements,
        userComment: item.user_comment + "12",
      };
      return accordion;
    });
  }, [poamList]);

  // excell sheet download
  const formatDataForExcel = () => {
    if (poamPopupList.length < 0) {
      // Show alert and return early if poamPopupList is null
      swal({
        title: "POAM",
        text: "Please Add Milestone To Export In Excel !",
        icon: "warning",
      });
      return;
    }

    const headers = [
      "POAM ID",
      "Creation Date",
      "Weakness Name",
      "Weakness Description",
      "Severity Level (Low, Moderate, High)",
      "Point of Contact",
      "Scheduled Completion Date",
      "Milestones",
      "Source of Weakness",
      "Actual Completion Date",
      "Comments",
    ];

    const formattedData = [
      headers,
      [
        poamPopupList?.poam_id,
        poamPopupList?.created_at,
        poamPopupList?.weakness_name,
        poamPopupList?.weakness_description,
        poamPopupList?.severity,
        poamPopupList?.point_of_contact_email,
        poamPopupList?.scheduled_completion_date,
        poamPopupList?.total_milestones
          ? poamPopupList?.total_milestones.toString()
          : "", // Check if total_milestones is defined
        poamPopupList?.source_of_weakness,
        poamPopupList?.actual_completion_date,
        poamPopupList?.comments,
      ],
    ];

    return formattedData;
  };

  const addWidthAndBackgroundColor = (ws: any) => {
    // Define column widths
    const columnConfigs = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 40 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    // Define background color for header row
    const headerBackgroundColor = { bgColor: { rgb: "000000FF" } }; // Blue background color

    // Apply column widths
    columnConfigs.forEach((config, columnIndex) => {
      ws["!cols"] = ws["!cols"] || [];
      ws["!cols"][columnIndex] = config;
    });

    // Apply background color to the header row
    const headerRow = 1; // Assuming the header row is the first row
    for (
      let columnIndex = 0;
      columnIndex < columnConfigs.length;
      columnIndex++
    ) {
      const cellAddress = XLSX.utils.encode_col(columnIndex) + (headerRow + 1); // Add 1 because Excel uses 1-based indexing
      ws[cellAddress] = {
        ...ws[cellAddress],
        s: { fill: headerBackgroundColor }, // Set the 'fill' property for the cell
      };
    }
  };

  const exportToExcel = () => {
    const data = formatDataForExcel();
    if (!data) {
      return;
    }
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Add column width and background color
    addWidthAndBackgroundColor(ws);

    XLSX.writeFile(wb, "Poam.xlsx");
  };

  const downloadPDF = async () => {
    setAllExpanded(true); // set all expanded before generating PDF
    setLoading(true);
    setIsDisabled(true);
    const input = document.getElementById("pdf-content");

    if (input) {
      setTimeout(() => {
        // add a delay to ensure all content is expanded
        html2canvas(input, { scale: 0.7 }).then(async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = await PDFDocument.create();
          const img = await pdf.embedPng(imgData);
          const { width, height } = img.scale(1);

          // add some padding to the page
          const padding = 50;
          const pageWidth = width + padding * 2;
          const pageHeight = height + padding * 2;
          const page = pdf.addPage([pageWidth, pageHeight]);
          page.drawImage(img, { x: padding, y: padding, width, height });

          const pdfBytes = await pdf.save();
          const compressedPdfBytes = await (
            await PDFDocument.load(pdfBytes)
          ).save({
            useObjectStreams: false,
          });
          const blob = new Blob([compressedPdfBytes], {
            type: "application/pdf",
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "download.pdf";
          document.body.appendChild(a);
          a.click();
          setLoading(false);
          setIsDisabled(false);
          setAllExpanded(false);
        });
      }, 1000); // adjust the delay time as needed
    } else {
      alert("Element with ID 'pdf-content' not found in DOM");
    }
  };

  const [getPoamBoolean, setGetPoamBoolean] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
    setGetPoamBoolean(true);
    dispatch(getPoamList({ assessmentId }))
      .unwrap()
      .then((response) => {
        setPoamPopupList(response);
        setMilestonesList(response?.milestones);
        setMilestonesListShare(response?.milestones);
        setGetPoamBoolean(false);
      })
      .catch((err: any) => {
        console.log(err);
        setButtonIsDisabled(false);
        setIsLoading(false);
        setGetPoamBoolean(false);
        setOpenModal(true);
      });
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    switch (poamPopupList?.severity) {
      case "Low":
        setSeverity("1");
        break;
      case "Moderate":
        setSeverity("2");
        break;
      case "High":
        setSeverity("3");
        break;
      default:
        setSeverity("0");
        break;
    }
  }, [poamPopupList]);
  const handleStartDateChange = (newValue: Dayjs | null) => {
    if (newValue && newValue.isAfter(dayjs(), "day")) {
      return;
    }
    setStartDate(newValue);
  };

  const handleActualDateChange = (newValue: Dayjs | null) => {
    if (newValue && newValue.isAfter(dayjs(), "day")) {
      return;
    }
    setActualDate(newValue);
  };

  useEffect(() => {
    const todayDate = dayjs().format("YYYY-MM-DD");

    const startdate = poamPopupList?.scheduled_completion_date
      ? poamPopupList?.scheduled_completion_date
      : todayDate;

    const actualDate = poamPopupList?.actual_completion_date
      ? poamPopupList?.actual_completion_date
      : todayDate;

    setStartDate(dayjs(startdate));
    setActualDate(dayjs(actualDate));
  }, [poamPopupList]);

  const handleToggle = (index: number) => () => {
    const currentIndex = checked.indexOf(index);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(index);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const updatedMilestones = milestonesList?.map((item, i) => ({
      ...item,
      is_completed: i === index ? !item.is_completed : item.is_completed,
    }));

    setChecked(newChecked);
    setMilestonesList(updatedMilestones);
  };

  const handleToggleShare = (index: number) => () => {
    const currentIndex = checkedShare.indexOf(index);
    const newChecked = [...checkedShare];
    if (currentIndex === -1) {
      newChecked.push(index);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const updatedMilestones = milestonesListShare?.map((item, i) => ({
      ...item,
      is_completed: i === index ? !item.is_completed : item.is_completed,
    }));

    setCheckedShare(newChecked);
    setMilestonesListShare(updatedMilestones);
  };

  const handleChangeMilestone = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMilestones(e.target.value);
  };

  const addMilestone = () => {
    if (milestones.trim() !== "") {
      const is_completed = checked.includes(milestonesList?.length);

      if (Array.isArray(milestonesList)) {
        setMilestonesList([
          ...milestonesList,
          { milestone: milestones, is_completed },
        ]);
      } else {
        setMilestonesList([{ milestone: milestones, is_completed }]);
      }

      setMilestones("");
    }
  };

  const handleOpenConfirmation = (index: any) => {
    setMilestoneIndexToRemove(() => index);
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setMilestoneIndexToRemove(null);
    setOpenConfirmation(false);
  };

  const removeMilestone = () => {
    if (milestoneIndexToRemove !== null) {
      const updatedMilestones = milestonesList.filter(
        (_, i) => i !== milestoneIndexToRemove
      );
      setMilestonesList(updatedMilestones);
      handleCloseConfirmation();
    }
  };

  // add
  const handleAdd = useCallback(
    async (values: {
      weaknessDescription: string;
      weaknessName: string;
      poamId: string;
      pointContact: string;
      sourceWeakness: string;
      comment: string;
    }) => {
      try {
        const newStartDate = startDate?.format("YYYY-MM-DD");
        const newActualDate = actualDate?.format("YYYY-MM-DD");
        if (Number(severity) === 0) {
          swal({
            title: "POAM",
            text: "Please select severity type !",
            icon: "warning",
          });
          return;
        }

        if (milestonesList === undefined) {
          swal({
            title: "POAM",
            text: "Please add milestone",
            icon: "warning",
          });
          return;
        }

        const type =
          Number(severity) === 1
            ? "Low"
            : Number(severity) === 2
            ? "Moderate"
            : Number(severity) === 3
            ? "High"
            : "High";
        await dispatch(
          addPoem({
            poamId: values.poamId,
            weaknessName: values.weaknessName,
            severity: type,
            assessment: assessmentId,
            point_of_contact_email: values.pointContact,
            scheduled_completion_date: newStartDate,
            comments: values.comment,
            source_of_weakness: values.sourceWeakness,
            weakness_description: values.weaknessDescription,
            actual_completion_date: newActualDate,
            milestones: milestonesList,
          })
        )
          .unwrap()
          .then((response) => {
            setMilestonesListShare(response?.milestones);
            setCompletedMilestone(response?.completed_milestones);
            setTotalMilestone(response?.total_milestones);
            setOpenModal(false);
            swal({
              title: "POAM",
              text: "POAM Added Successfully !",
              icon: "success",
            });
          });
      } catch (error: any) {
        console.error("Error:", error);
        if (error.status === 400) {
          swal({
            title: "POAM",
            text: "Poam with this assessment already exists",
            icon: "error",
          });
        } else {
          swal({
            title: "POAM",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    },
    [dispatch, severity, milestonesList, completedMilestone, totalMilestone]
  );

  //update

  const handleUpdate = useCallback(
    async (values: {
      weaknessDescription: string;
      weaknessName: string;
      poamId: string;
      pointContact: string;
      sourceWeakness: string;
      comment: string;
    }) => {
      try {
        const newStartDate = startDate?.format("YYYY-MM-DD");
        const newActualDate = actualDate?.format("YYYY-MM-DD");
        if (Number(severity) === 0) {
          swal({
            title: "POAM",
            text: "Please select severity type !",
            icon: "warning",
          });
          return;
        }

        if (milestonesList?.length === 0) {
          swal({
            title: "POAM",
            text: "Please add milestone",
            icon: "warning",
          });
          return;
        }
        const type =
          Number(severity) === 1
            ? "Low"
            : Number(severity) === 2
            ? "Moderate"
            : Number(severity) === 3
            ? "High"
            : "High";
        await dispatch(
          putPoam({
            pk: poamPopupList?.id,
            poamId: values.poamId,
            weaknessName: values.weaknessName,
            severity: type,
            assessment: assessmentId,
            point_of_contact_email: values.pointContact,
            scheduled_completion_date: newStartDate,
            comments: values.comment,
            source_of_weakness: values.sourceWeakness,
            weakness_description: values.weaknessDescription,
            actual_completion_date: newActualDate,
            milestones: milestonesList,
          })
        )
          .unwrap()
          .then((response) => {
            setMilestonesListShare(response?.milestones);
            setCompletedMilestone(response?.completed_milestones);
            setTotalMilestone(response?.total_milestones);
            setOpenModal(false);
            swal({
              title: "POAM",
              text: "POAM Updated Successfully !",
              icon: "success",
            });
          });
      } catch (error: any) {
        console.error("Error:", error);
        if (error.status === 400) {
          swal({
            title: "POAM",
            text: "Poam with this assessment already exists",
            icon: "error",
          });
        } else {
          swal({
            title: "POAM",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      }
    },
    [dispatch, severity, milestonesList, completedMilestone, totalMilestone]
  );

  // collaboration feature

  const [viewChecked, setViewChecked] = useState(false);
  const [writeChecked, setWriteChecked] = useState(false);
  const [openModalColaboration, setOpenModalColaboration] = useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleModalOpen = () => {
    setOpenModalColaboration(true);
  };

  const handleViewChange = () => {
    setViewChecked(true);
    setWriteChecked(false);
  };

  const handleWriteChange = () => {
    setWriteChecked(true);
    setViewChecked(false);
  };

  const handleCloseModalColaboration = () => {
    setOpenModalColaboration(false);
    setPersonName([]);
  };

  const handleAutocompleteChange = (event: any, name: any) => {
    // Update the state with selected names
    setPersonName(name);
  };

  const [userSearch, setUserSearch] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchLoading === false) {
      const newSearchText = event.target.value;
      setUserSearch(newSearchText);
    }
  };

  React.useEffect(() => {
    setSearchLoading(true);
    dispatch(
      fetchAcceptedInvitedUsers({
        page: 1,
        limit: 15,
        search: userSearch,
      })
    )
      .unwrap()
      .then(() => {
        setLoader(false);
        setSearchLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setSearchLoading(false);
        setLoader(false);
      });
  }, [dispatch, userSearch]);

  const handlePlan = () => {
    navigate("/plan");
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Box>
            <Box mt={3}>
              <Text fontSize="32px" fontWeight="700" className="text-mobile">
                POAM
              </Text>
            </Box>
            <FlexBox mb={1}>
              <Box display="flex">
                <Box>
                  <Text fontSize="20px" fontWeight="500">
                    Assessment Completed Date :
                  </Text>
                </Box>
                <Box ml={2} mt={0.4}>
                  <Text fontSize="16px" fontWeight="600">
                    {CompeleteDate}
                  </Text>
                </Box>
              </Box>
              <Box mr={1} display="flex">
                <Box mr={4} mt={1} display="none">
                  <Collaboration
                    title="POAM"
                    viewChecked={viewChecked}
                    writeChecked={writeChecked}
                    handleWriteChange={handleWriteChange}
                    handleViewChange={handleViewChange}
                    personName={personName}
                    open={openModalColaboration}
                    handleOpen={handleModalOpen}
                    handleClose={handleCloseModalColaboration}
                    handleChangeSelect={handleAutocompleteChange}
                    handleInputChange={handleInputChange}
                    loader={loader}
                  >
                    {milestonesListShare?.length > 0 && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                            border: `2px solid ${Colors.button.primary_bg}`,
                            borderRadius: "10px",
                          }}
                        >
                          <List sx={{ maxHeight: "230px", overflowY: "auto" }}>
                            {milestonesListShare?.map((value, index) => {
                              const labelId = `checkbox-list-label-${index}`;

                              return (
                                <ListItem key={index} disablePadding>
                                  <ListItemButton
                                    role={undefined}
                                    onClick={handleToggleShare(index)}
                                    dense
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        style={{
                                          color: Colors.button.primary_bg,
                                        }}
                                        checked={value.is_completed}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        padding: "5px 5px",
                                        borderRadius: "50px",
                                        marginLeft: "-20px",
                                        marginRight: "0px",
                                      }}
                                      id={labelId}
                                      primary={`${value?.milestone}`}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Box>
                      </Grid>
                    )}
                  </Collaboration>
                </Box>
                {currentPackage === FrontlineDefender ? (
                  <LightTooltip
                    title="Subscribe Bunker $499 Per Year to Unlock Poam"
                    placement="top"
                    arrow
                  >
                    <Box>
                      {" "}
                      <Button
                        title={"POAM"}
                        borderRadius="50px"
                        width="120px"
                        fontWeight="600"
                        fontSize="22"
                        Icon={<GrSystem className="gr-icon" />}
                        onClick={handlePlan}
                        disabled={buttonIsDisabled}
                      />
                    </Box>
                  </LightTooltip>
                ) : (
                  <Box>
                    {" "}
                    <Button
                      title={"POAM"}
                      borderRadius="50px"
                      width="120px"
                      fontWeight="600"
                      fontSize="22"
                      Icon={<GrSystem className="gr-icon" />}
                      onClick={handleOpenModal}
                      disabled={buttonIsDisabled}
                    />
                  </Box>
                )}
              </Box>
            </FlexBox>

            <FlexBox mb={2}>
              <Box className="linear-progress">
                <BufferProgress
                  assessmentName=""
                  assessmentIcons=""
                  value={(completedMilestone / totalMilestone) * 100}
                  completedQuestion={completedMilestone}
                  totalQuestion={totalMilestone}
                  color="#FED74F"
                  align="left"
                  poamTitle="Achieved Milestones"
                  poam={true}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "end",
                }}
                className="paom-right-button"
              >
                <Tooltip
                  title="Click to download CSV or Pdf"
                  placement="bottom"
                  arrow
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Button
                      title={loading ? "Loading..." : "POAM Export"}
                      borderRadius="50px"
                      width="200px"
                      fontWeight="600"
                      fontSize="22"
                      Icon={<FiDownload />}
                      disabled={isDisabled}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={downloadPDF}
                  sx={{
                    backgroundColor: Colors.button.primary_bg,
                    "&:hover": {
                      backgroundColor: Colors.button.border_gary_03,
                      color: Colors.text.white,
                    },
                  }}
                >
                  <ListItemIcon>
                    <FiDownload />{" "}
                  </ListItemIcon>
                  {loading ? "Loading..." : "Export PDF"}
                </MenuItem>
                <MenuItem
                  sx={{
                    backgroundColor: Colors.text.blue,
                    color: Colors.text.white,
                    marginTop: "6px",
                    "&:hover": {
                      backgroundColor: Colors.button.border_gary_03,
                      color: Colors.text.white,
                    },
                  }}
                  onClick={exportToExcel}
                >
                  <ListItemIcon>
                    <FiDownload />{" "}
                  </ListItemIcon>
                  Export Excel
                </MenuItem>
              </Menu>
            </FlexBox>
            <div id="pdf-content">
              {accordion?.map((item: any, index: any) => (
                // eslint-disable-next-line react/jsx-key
                <SSPAccordian
                  key={index}
                  data={item}
                  index={index}
                  expandedAll={allExpanded}
                >
                  {assessmentLevel === null ? (
                    <SspChildAccordion
                      data={item}
                      index={index}
                      accordions={accordion}
                      assessmentId={assessmentId}
                      notMet="No"
                      met="Yes"
                      notMerSwitchColor={Colors.button.primary_bg}
                      objectTooltip={true}
                      showSubCategoryComment={true}
                      hideControlRequirement={true}
                      assessmentTypeCurrent={assessmentTypeCurrent}
                    />
                  ) : (
                    <SspChildAccordion
                      data={item}
                      index={index}
                      accordions={accordion}
                      assessmentId={assessmentId}
                    />
                  )}
                </SSPAccordian>
              ))}
            </div>
          </Box>
        </Box>
      )}
      <Modal
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent sx={styles}>
          <Box display="flex" justifyContent="end" pt={1} pr={1}>
            <AiFillCloseCircle
              onClick={handleCloseConfirmation}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </Box>
          <Box>
            <Text fontSize="32px" fontWeight="700" align="center">
              Remove milestone
            </Text>
          </Box>
          <Box>
            <Text fontSize="16px" fontWeight="500" align="center">
              Are you sure you want to remove this milestone?
            </Text>
          </Box>
          <Box mt={4} display="flex" justifyContent="center">
            <Box display="flex">
              <Button
                title={"Yes"}
                borderRadius="10px"
                fontWeight="500"
                fontSize="18px"
                align="center"
                width="100px"
                onClick={removeMilestone}
              />
              <Button
                title={"Cancle"}
                borderRadius="10px"
                fontWeight="500"
                fontSize="18px"
                align="center"
                width="100px"
                onClick={handleCloseConfirmation}
                background="#ACACAC"
                margin="0 0 0 20px"
              />
            </Box>
          </Box>
        </ModalContent>
      </Modal>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent sx={LargeModal}>
          <Box display="flex" justifyContent="end" pt={1} pr={1}>
            <AiFillCloseCircle
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </Box>

          <Box>
            <Logo />
          </Box>
          {getPoamBoolean ? (
            <Box>
              <Loader isLoading={getPoamBoolean} />
            </Box>
          ) : (
            <Box>
              <Box m={4}>
                <Box>
                  <Formik
                    initialValues={{
                      weaknessDescription:
                        poamPopupList?.weakness_description || "",
                      weaknessName: poamPopupList?.weakness_name || "",
                      poamId: poamPopupList?.poam_id || "",
                      pointContact: poamPopupList?.point_of_contact_email || "",
                      sourceWeakness: poamPopupList?.source_of_weakness || "",
                      comment: poamPopupList?.comments || "",
                    }}
                    validationSchema={AssesmentSchema}
                    onSubmit={poamPopupList?.id ? handleUpdate : handleAdd}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Box>
                        <Box>
                          <Box p={{ sx: 1, md: 1, lg: 1 }}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                  <Input
                                    name="poamId"
                                    type="text"
                                    placeholder="POAM ID"
                                    value={values.poamId}
                                    onChange={handleChange("poamId")}
                                  />
                                  {errors.poamId && touched.poamId ? (
                                    <div className="error-message">
                                      {errors.poamId}
                                    </div>
                                  ) : null}
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                  <Input
                                    name="pointContact"
                                    type="text"
                                    placeholder="Point Of Contact"
                                    value={values.pointContact}
                                    onChange={handleChange("pointContact")}
                                  />
                                  {errors.pointContact &&
                                  touched.pointContact ? (
                                    <div className="error-message">
                                      {errors.pointContact}
                                    </div>
                                  ) : null}
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box pt={2}>
                                  <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={severity}
                                    onChange={handleSelectSeverity}
                                    sx={{
                                      "&.MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "transparent !important", // Hide the root border
                                        },
                                        "&:hover fieldset, &.Mui-focused fieldset":
                                          {
                                            borderColor:
                                              "transparent !important", // Hide the border on hover and focus
                                          },
                                      },
                                      "& .MuiSelect-icon": {
                                        color: "#FED74F",
                                        border: "1px solid #FED74F",
                                        borderRadius: "50px",
                                      },
                                      "& .MuiSelect-select": {
                                        borderColor: "#FED74F !important",
                                        border: "2px solid #FED74F !important",
                                        borderRadius: "50px !important",
                                        padding: "20px 20px",
                                        "&:hover, &:focus": {
                                          borderColor: "#FED74F !important",
                                          border:
                                            "2px solid #FED74F !important",
                                        },
                                      },
                                      width: { xs: "100%", md: "100%" },
                                    }}
                                  >
                                    <MenuItem
                                      value="0"
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor:
                                            Colors.button.primary_bg,
                                        },
                                      }}
                                    >
                                      Severity
                                    </MenuItem>
                                    <MenuItem
                                      value="1"
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor:
                                            Colors.button.primary_bg,
                                        },
                                      }}
                                    >
                                      Low
                                    </MenuItem>
                                    <MenuItem
                                      value="2"
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor:
                                            Colors.button.primary_bg,
                                        },
                                      }}
                                    >
                                      Medium
                                    </MenuItem>
                                    <MenuItem
                                      value="3"
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor:
                                            Colors.button.primary_bg,
                                        },
                                      }}
                                    >
                                      High
                                    </MenuItem>
                                  </Select>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                  <Input
                                    name="weaknessName"
                                    type="text"
                                    placeholder="Weakness Name"
                                    value={values.weaknessName}
                                    onChange={handleChange("weaknessName")}
                                  />
                                </Box>
                                {errors.weaknessName && touched.weaknessName ? (
                                  <div className="error-message">
                                    {errors.weaknessName}
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                  <Box>
                                    <TextArea
                                      name="weaknessDescription"
                                      type="text"
                                      placeholder="Weakness Description"
                                      value={values.weaknessDescription}
                                      onChange={handleChange(
                                        "weaknessDescription"
                                      )}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                  <Box>
                                    <Input
                                      name="sourceWeakness"
                                      type="text"
                                      placeholder="Source Weakness"
                                      value={values.sourceWeakness}
                                      onChange={handleChange("sourceWeakness")}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                  <TextArea
                                    name="comment"
                                    type="text"
                                    placeholder="Comment"
                                    value={values.comment}
                                    onChange={handleChange("comment")}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box mt={2}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      maxDate={dayjs()}
                                      format="YYYY-MM-DD"
                                      sx={{
                                        borderRadius: "40px",
                                        "&  .MuiOutlinedInput-root": {
                                          borderColor: "#fed74f !important",
                                          border:
                                            "2px solid #fed74f !important",
                                          borderRadius: "50px",
                                          padding: "2px 25px",
                                          "&:focus": {
                                            borderColor: "none !important",
                                            boxShadow: "none !important",
                                          },
                                          "&:hover": {
                                            borderColor: "none !important",
                                            boxShadow: "none !important",
                                          },
                                          "& fieldset": {
                                            borderColor: "#fed74f !important",
                                            border: "0px solid #fed74f",
                                            borderRadius: "50px",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: "#fed74f !important",
                                            border: "0px solid #fed74f",
                                            borderRadius: "50px",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "#fed74f !important",
                                            border: "0px solid #fed74f",
                                            borderRadius: "50px",
                                          },
                                        },
                                        width: "100%",
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box mt={2}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      value={actualDate}
                                      onChange={handleActualDateChange}
                                      maxDate={dayjs()}
                                      format="YYYY-MM-DD"
                                      sx={{
                                        borderRadius: "40px",
                                        "&  .MuiOutlinedInput-root": {
                                          borderColor: "#fed74f !important",
                                          border:
                                            "2px solid #fed74f !important",
                                          borderRadius: "50px",
                                          padding: "2px 25px",
                                          "&:focus": {
                                            borderColor: "none !important",
                                            boxShadow: "none !important",
                                          },
                                          "&:hover": {
                                            borderColor: "none !important",
                                            boxShadow: "none !important",
                                          },
                                          "& fieldset": {
                                            borderColor: "#fed74f !important",
                                            border: "0px solid #fed74f",
                                            borderRadius: "50px",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: "#fed74f !important",
                                            border: "0px solid #fed74f",
                                            borderRadius: "50px",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "#fed74f !important",
                                            border: "0px solid #fed74f",
                                            borderRadius: "50px",
                                          },
                                        },
                                        width: "100%",
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={10} sm={10} md={11} lg={11}>
                                <Box>
                                  <TextArea
                                    name="milestones"
                                    type="text"
                                    placeholder="List of milestones"
                                    value={milestones}
                                    onChange={handleChangeMilestone}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sm={2}
                                md={1}
                                lg={1}
                                alignItems="center"
                              >
                                <Box
                                  sx={{
                                    background: Colors.button.primary_bg,
                                    width: "50px",
                                    height: "50px",
                                    color: Colors.text.white,
                                    borderRadius: "50px",
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    pt={1.5}
                                  >
                                    <BiPlusCircle
                                      className="plus-icon-poam"
                                      onClick={addMilestone}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              {milestonesList?.length > 0 && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <List
                                    sx={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      bgcolor: "background.paper",
                                      border: `2px solid ${Colors.button.primary_bg}`,
                                      borderRadius: "10px",
                                    }}
                                  >
                                    {milestonesList?.map((value, index) => {
                                      const labelId = `checkbox-list-label-${index}`;

                                      return (
                                        <ListItem
                                          key={index}
                                          secondaryAction={
                                            <IconButton
                                              edge="end"
                                              aria-label="comments"
                                            >
                                              <Box
                                                sx={{
                                                  background:
                                                    Colors.text.navy_orange,
                                                  width: "35px",
                                                  height: "35px",
                                                  color:
                                                    Colors.button.primary_bg,
                                                  borderRadius: "50px",
                                                }}
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                  pt={1}
                                                >
                                                  <AiFillMinusCircle
                                                    className="minus-icon-poam"
                                                    onClick={() =>
                                                      handleOpenConfirmation(
                                                        index
                                                      )
                                                    }
                                                  />
                                                </Box>
                                              </Box>
                                            </IconButton>
                                          }
                                          disablePadding
                                        >
                                          <ListItemButton
                                            role={undefined}
                                            onClick={handleToggle(index)}
                                            dense
                                          >
                                            <ListItemIcon>
                                              <Checkbox
                                                edge="start"
                                                style={{
                                                  color:
                                                    Colors.button.primary_bg,
                                                }}
                                                checked={value.is_completed}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                  "aria-labelledby": labelId,
                                                }}
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              sx={{
                                                padding: "10px 20px",
                                                borderRadius: "50px",
                                                marginLeft: "-20px",
                                                marginRight: "20px",
                                              }}
                                              id={labelId}
                                              primary={`${value?.milestone}`}
                                            />
                                          </ListItemButton>
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Box>
                        <Box pt={3}>
                          <Button
                            title={"Save"}
                            borderRadius="10px"
                            fontWeight="500"
                            fontSize="18px"
                            align="center"
                            onClick={handleSubmit}
                            width="100px"
                          />
                        </Box>
                      </Box>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          )}
        </ModalContent>
      </Modal>
    </DashboardWrapper>
  );
};

export default Poam;
