import React, { FunctionComponent, ReactNode } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Button, Box } from "@mui/material";

const Btn = styled(Button)(({ disabled }) => ({
  textTransform: "none",
  border: 0,
  height: 48,
  padding: "10px 30px",
  cursor: "pointer",
  "&:disabled": {
    backgroundColor: "#DCDCDC !important",
    color: "#9E9E9E !important",
  },
  "&:hover": {
    backgroundColor: disabled ? "#DCDCDC !important" : "#FED74F", // Orange color on hover
  },
}));

type Props = {
  title?: string;
  width?: string;
  margin?: string;
  direction?: "row" | "column";
  align?: "left" | "center" | "right";
  fontSize?: string;
  borderRadius?: string;
  onClick?: () => void;
  disabled?: boolean;
  Icon?: ReactNode;
  fontWeight?: string;
  className?: string;
  background?: any;
  color?: any;
};

const ButtonComponent: FunctionComponent<Props> = ({
  title,
  width = "200px",
  margin = "0px",
  direction = "row",
  align = "center",
  fontSize = "17px",
  borderRadius = "10px",
  disabled = false,
  onClick,
  Icon,
  fontWeight = "600",
  className,
  background = "#FED74F",
  color = "#000",
}: Props) => {
  return (
    <>
      <Box display="flex" flexDirection={direction} justifyContent={align}>
        <Btn
          disabled={disabled}
          variant="contained"
          className={className}
          style={{
            width: width,
            margin: margin,
            fontSize: fontSize,
            borderRadius: borderRadius,
            fontWeight: fontWeight,
            backgroundColor: disabled ? "#DCDCDC !important" : background,
            color: disabled ? "#9E9E9E !important" : color,
          }}
          onClick={onClick}
          startIcon={Icon}
        >
          {title}
        </Btn>
      </Box>
    </>
  );
};

export default ButtonComponent;
