import React, { FunctionComponent, useEffect, useState } from "react";
import { DashboardWrapper, Text, Button, Loader } from "shared";
import { Grid, Box, Link } from "@mui/material";
import Logo from "assets/CMMCProofLogo.png";
import { Colors } from "theme/color";
import CheckOne from "assets/check.png";
import CheckTwo from "assets/check-2.png";
import Standard from "assets/standard.png";
import Premium from "assets/premium.png";
import Basic from "assets/basic.png";
import { getPackageList } from "reducers/assessment";
import { userPackages, subscribeFrontlineDefender } from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { LogoBox, CustomAvatar, PackageType, CustomTick, Card } from "./style";
import { FortressPackage, Bunker, FrontlineDefender } from "utils/constant";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

type Props = Record<string, never>;

const Billing: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const packageList = useSelector(
    (state: RootState) => state.assessment.packageList
  );

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPackageList())
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [dispatch]);

  const handleSubscribeFronLinePackage = () => {
    setIsLoading(true);
    dispatch(
      subscribeFrontlineDefender({
        plan_id: 1,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(userPackages())
          .unwrap()
          .then(() => {
            setIsLoading(false);
          })
          .catch((err: any) => {
            console.log(err.message);
            setIsLoading(false);
          });

        navigate("/dashboard");
        swal({
          title: "Subscription",
          text: `Congratulation you have subsribe Frontline Defender successfully !`,
          icon: "success",
        });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        swal({
          title: "Subscription",
          text: `Something went wrong. Please try again!`,
          icon: "error",
        });
      });
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box mt={5}>
          <Grid container spacing={2}>
            {packageList?.map((item, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                <Card
                  backgroundColor={
                    item.name === FortressPackage
                      ? Colors.text.black
                      : Colors.text.white
                  }
                  pt={3}
                >
                  <Card
                    backgroundColor={
                      item.name === FortressPackage
                        ? Colors.button.primary_bg
                        : Colors.text.plan_bg_1
                    }
                    p={3}
                  >
                    <Box display="flex">
                      {item.name === FrontlineDefender && (
                        <PackageType src={Basic} alt="Basic" />
                      )}
                      {item.name === Bunker && (
                        <PackageType src={Standard} alt="Standard" />
                      )}
                      {item.name === FortressPackage && (
                        <PackageType src={Premium} alt="Premium" />
                      )}

                      {currentPackage === item.name && (
                        <Box
                          sx={{
                            background:
                              item.name === FortressPackage
                                ? Colors.text.black
                                : Colors.button.primary_bg,
                            width: "fit-content",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            marginLeft: "10px",
                            fontSize: "14px",
                            color:
                              item.name === FortressPackage
                                ? Colors.text.white
                                : Colors.text.black,
                          }}
                        >
                          Activated
                        </Box>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <LogoBox>
                        <CustomAvatar alt="Remy Sharp" src={Logo} />
                      </LogoBox>
                    </Box>
                    <Text
                      fontSize="20px"
                      fontWeight="600"
                      align="center"
                      color={
                        item.name === FortressPackage
                          ? Colors.text.black
                          : Colors.text.theme
                      }
                    >
                      {item.name !== FortressPackage && item.name}
                    </Text>
                    <Box mt={2}>
                      <Text
                        fontSize="18px"
                        fontWeight="500"
                        align="center"
                        color={
                          item.name === FortressPackage
                            ? Colors.text.black
                            : Colors.text.theme
                        }
                      >
                        {item.package_sub_name}
                      </Text>
                    </Box>
                    <Box mt={3} mb={3}>
                      <Text
                        fontSize="24px"
                        fontWeight="600"
                        align="center"
                        color={
                          item.name === FortressPackage
                            ? Colors.text.black
                            : Colors.text.white
                        }
                      >
                        {item.price_text}
                      </Text>
                      <Text
                        fontSize="14px"
                        fontWeight="300"
                        align="center"
                        color={
                          item.name === FortressPackage
                            ? Colors.text.black
                            : Colors.text.light_grey
                        }
                      >
                        {item.description}
                      </Text>
                    </Box>
                    <Box pt={item.name === FortressPackage ? 1.5 : 0}>
                      {item.features.map((feature: any, index: any) => (
                        <Box
                          display="flex"
                          pt={item.name === FortressPackage ? 1 : 3}
                          key={index}
                        >
                          <Box>
                            {item.name === FortressPackage ? (
                              <CustomTick src={CheckTwo} />
                            ) : (
                              <CustomTick src={CheckOne} />
                            )}
                          </Box>
                          <Box ml={3}>
                            <Text
                              fontSize="18px"
                              fontWeight="400"
                              color={
                                item.name === FortressPackage
                                  ? Colors.text.black
                                  : Colors.text.white
                              }
                            >
                              {feature.description}
                            </Text>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    {item.name === FrontlineDefender ? (
                      <Box mt={14}>
                        <Button
                          title={
                            currentPackage === item.name
                              ? "Activated"
                              : item.name === FrontlineDefender
                              ? "Unlock Access"
                              : "Start Free 7-Day Trial"
                          }
                          borderRadius="10px"
                          fontWeight="500"
                          fontSize="18px"
                          align="center"
                          width="80%"
                          background={
                            item.name === FortressPackage
                              ? "#000"
                              : Colors.button.primary_bg
                          }
                          color={
                            item.name === FortressPackage
                              ? Colors.text.white
                              : Colors.text.black
                          }
                          onClick={handleSubscribeFronLinePackage}
                        />
                      </Box>
                    ) : (
                      <Box mt={4}>
                        <Link
                          href={`${item?.payment_link}?prefilled_email=${
                            userInfo?.email || ""
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            title={
                              currentPackage === item.name
                                ? "Activated"
                                : item.name === FrontlineDefender
                                ? "Unlock Access"
                                : "Start Free 7-Day Trial"
                            }
                            borderRadius="10px"
                            fontWeight="500"
                            fontSize="18px"
                            align="center"
                            width="80%"
                            background={
                              item.name === FortressPackage
                                ? "#000"
                                : Colors.button.primary_bg
                            }
                            color={
                              item.name === FortressPackage
                                ? Colors.text.white
                                : Colors.text.black
                            }
                          />
                        </Link>
                      </Box>
                    )}

                    {/* <Box mt={item.name === FrontlineDefender ? 14 : 4}>
                      <Link
                        href={`${item?.payment_link}?prefilled_email=${
                          userInfo?.email || ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          title={
                            currentPackage === item.name
                              ? "Activated"
                              : item.name === FrontlineDefender
                              ? "Unlock Access"
                              : "Start Free 7-Day Trial"
                          }
                          borderRadius="10px"
                          fontWeight="500"
                          fontSize="18px"
                          align="center"
                          width="80%"
                          background={
                            item.name === FortressPackage
                              ? "#000"
                              : Colors.button.primary_bg
                          }
                          color={
                            item.name === FortressPackage
                              ? Colors.text.white
                              : Colors.text.black
                          }
                        />
                      </Link>
                    </Box> */}
                  </Card>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default Billing;
