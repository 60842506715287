import React, { FunctionComponent, useEffect, useState } from "react";
import { DashboardWrapper, Text, Loader } from "shared";
import { Box, Grid, Avatar, Divider } from "@mui/material";
import { Colors } from "theme/color";
import { deepPurple } from "@mui/material/colors";
import useAppDispatch from "hooks/useAppDispatch";
import { getListTaskBoard } from "reducers/collaburation";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import { COMMON } from "utils/common";
import { BsFileEarmarkText } from "react-icons/bs";
import { TbStack } from "react-icons/tb";

import { TaskCard } from "./style";

type Props = Record<string, never>;

const Trello: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  // api call of list of assign task with respect to login user
  useEffect(() => {
    setIsLoading(true);
    dispatch(getListTaskBoard())
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [dispatch]);

  const taskList = useSelector(
    (state: RootState) => state.collaboration.taskBoard
  );

  const navigate = useNavigate();

  // list of assign task to invite users
  const toDo = taskList?.todo;
  // inprogress list of task
  const inProgress = taskList?.in_progress;
  // completed list of task
  const done = taskList?.done;

  const falseStatusCountToDoList = toDo?.items?.filter(
    (item: any) => !item.status
  ).length;

  const falseStatusCountInProgressList = inProgress?.items?.filter(
    (item: any) => !item.status
  ).length;

  const falseStatusCountDoneList = done?.items?.filter(
    (item: any) => !item.status
  ).length;

  const filteredCarduserNameTodo = toDo?.items?.map(
    (item: any) => item.user.name
  );

  const filteredCarduserNameInprogress = inProgress?.items?.map(
    (item: any) => item.user.name
  );

  const filteredCarduserNameDone = done?.items?.map(
    (item: any) => item.user.name
  );

  // handle function to navigate current phase
  const handleNavigate = (
    appContent: any,
    objectId: any,
    task: any,
    email: any,
    ownerId: number,
    assessmentLevel: number,
    name: any,
    assessmentTypes: any
  ) => {
    if (appContent === "buffer_zone") {
      navigate(`/task/${appContent}/${objectId}`, {
        state: { task, email, ownerId },
      });
    } else if (assessmentLevel === null) {
      navigate(`/task/${appContent}/${objectId}`, {
        state: { task, email, ownerId, assessmentLevel, name, assessmentTypes },
      });
    } else {
      navigate(`/task/${appContent}/${objectId}/${assessmentLevel}`, {
        state: { task, email, ownerId, assessmentLevel, name },
      });
    }
  };

  const baseURL = COMMON.apiBaseUrl;

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box p={3}>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  background: Colors.button.primary_bg,
                  borderRadius: "5px",
                  padding: "10px 20px",
                }}
              >
                <Text
                  fontSize="15px"
                  fontWeight="600"
                  color={Colors.text.black}
                >
                  To Do
                </Text>
                <Box
                  sx={{
                    border: `1px solid ${Colors.text.black}`,
                    borderRadius: "5px",
                    padding: "1px 7px",
                    textAlign: "center",
                  }}
                >
                  {filteredCarduserNameTodo?.includes(userInfo?.name)
                    ? toDo?.count - falseStatusCountToDoList
                    : toDo?.count}
                </Box>
              </Box>
              <Box
                mt={2}
                p={2}
                sx={{
                  background: Colors.text.navy_orange,
                  borderRadius: "5px",
                }}
              >
                {/* show invite user */}
                {toDo?.items?.map(
                  (item: any, index: any) =>
                    item?.user?.name === userInfo?.name &&
                    item.status === true && (
                      <TaskCard
                        mb={2}
                        key={index}
                        onClick={() =>
                          handleNavigate(
                            item?.app_content_type,
                            item?.type === "buffer_zone"
                              ? item?.object_id
                              : item?.task?.id,

                            item?.task.phase_name,
                            item?.user?.email,
                            item?.owner_user?.pk,
                            item?.task.assessment_level,
                            item?.task.name,
                            item?.task.assessment_type
                          )
                        }
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ maxWidth: "60%" }}
                          >
                            <Box>
                              {item?.user?.users_profile === null ? (
                                <Avatar
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {item?.user?.username?.substring(0, 2)}
                                </Avatar>
                              ) : (
                                <Avatar
                                  alt="user"
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                  src={`${baseURL}${item?.user?.users_profile?.image}`}
                                />
                              )}
                            </Box>
                            <Box ml={1}>
                              {" "}
                              <Text
                                fontSize="12px"
                                fontWeight="600"
                                color={Colors.text.navy}
                                textTransform="capitalize"
                                wordBreak="break-all"
                              >
                                {item?.user?.username}
                              </Text>
                            </Box>
                          </Box>
                          <Box width="40%">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                              align="right"
                            >
                              Activity Date
                            </Text>
                            <Text
                              fontSize="11px"
                              fontWeight="400"
                              color={Colors.text.navy}
                              align="right"
                            >
                              {item?.recent_activity_date
                                ? new Date(
                                    item.recent_activity_date
                                  ).toLocaleString()
                                : ""}
                            </Text>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box mt={1}>
                            <Text
                              fontSize="14px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? item?.task.phase_name
                                : item?.task.name}{" "}
                            </Text>
                          </Box>
                          <Box>
                            <Box display="flex" justifyContent="center">
                              <Text
                                fontSize="12px"
                                fontWeight="400"
                                color={Colors.text.black}
                              >
                                {item?.type === "buffer_zone" ? (
                                  <TbStack
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="24px"
                                  />
                                ) : (
                                  <BsFileEarmarkText
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="18px"
                                  />
                                )}{" "}
                              </Text>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? "Buffer Zone"
                                : "Assessment"}{" "}
                            </Text>
                          </Box>
                        </Box>
                      </TaskCard>
                    )
                )}
                {toDo?.items?.map(
                  (item: any, index: any) =>
                    item?.user?.name !== userInfo?.name && (
                      <TaskCard
                        mb={2}
                        key={index}
                        onClick={() =>
                          handleNavigate(
                            item?.app_content_type,
                            item?.type === "buffer_zone"
                              ? item?.object_id
                              : item?.task?.id,

                            item?.task.phase_name,
                            item?.user?.email,
                            item?.owner_user?.pk,
                            item?.task.assessment_level,
                            item?.task.name,
                            item?.task.assessment_type
                          )
                        }
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ maxWidth: "60%" }}
                          >
                            <Box>
                              {item?.user?.users_profile === null ? (
                                <Avatar
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {item?.user?.username?.substring(0, 2)}
                                </Avatar>
                              ) : (
                                <Avatar
                                  alt="user"
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                  src={`${baseURL}${item?.user?.users_profile?.image}`}
                                />
                              )}
                            </Box>
                            <Box ml={1}>
                              {" "}
                              <Text
                                fontSize="12px"
                                fontWeight="600"
                                color={Colors.text.navy}
                                textTransform="capitalize"
                                wordBreak="break-all"
                              >
                                {item?.user?.username}
                              </Text>
                            </Box>
                          </Box>
                          <Box width="40%">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                              align="right"
                            >
                              Activity Date
                            </Text>
                            <Text
                              fontSize="11px"
                              fontWeight="400"
                              color={Colors.text.navy}
                              align="right"
                            >
                              {item?.recent_activity_date
                                ? new Date(
                                    item.recent_activity_date
                                  ).toLocaleString()
                                : ""}
                            </Text>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box mt={1}>
                            <Text
                              fontSize="14px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? item?.task.phase_name
                                : item?.task.name}{" "}
                            </Text>
                          </Box>
                          <Box>
                            <Box display="flex" justifyContent="center">
                              <Text
                                fontSize="12px"
                                fontWeight="400"
                                color={Colors.text.black}
                              >
                                {item?.type === "buffer_zone" ? (
                                  <TbStack
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="24px"
                                  />
                                ) : (
                                  <BsFileEarmarkText
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="18px"
                                  />
                                )}{" "}
                              </Text>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? "Buffer Zone"
                                : "Assessment"}{" "}
                            </Text>
                          </Box>
                        </Box>
                      </TaskCard>
                    )
                )}
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  background: Colors.button.primary_bg,
                  borderRadius: "5px",
                  padding: "10px 20px",
                }}
              >
                <Text
                  fontSize="15px"
                  fontWeight="600"
                  color={Colors.text.black}
                >
                  In Progress
                </Text>
                <Box
                  sx={{
                    border: `1px solid ${Colors.text.black}`,
                    borderRadius: "5px",
                    padding: "1px 7px",

                    textAlign: "center",
                  }}
                >
                  {filteredCarduserNameInprogress?.includes(userInfo?.name)
                    ? inProgress?.count - falseStatusCountInProgressList
                    : inProgress?.count}
                </Box>
              </Box>
              <Box
                mt={2}
                p={2}
                sx={{
                  background: Colors.text.navy_orange,
                  borderRadius: "5px",
                }}
              >
                {/* for invite user */}
                {inProgress?.items?.map(
                  (item: any, index: any) =>
                    item?.user?.name === userInfo?.name &&
                    item.status === true && (
                      <TaskCard
                        mb={2}
                        key={index}
                        onClick={() =>
                          handleNavigate(
                            item?.app_content_type,
                            item?.type === "buffer_zone"
                              ? item?.object_id
                              : item?.task?.id,
                            item?.task.phase_name,
                            item?.user?.email,
                            item?.owner_user?.pk,
                            item?.task.assessment_level,
                            item?.task.name,
                            item?.task.assessment_type
                          )
                        }
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ maxWidth: "60%" }}
                          >
                            <Box>
                              {item?.user?.users_profile === null ? (
                                <Avatar
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {item?.user?.username?.substring(0, 2)}
                                </Avatar>
                              ) : (
                                <Avatar
                                  alt="user"
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                  src={`${baseURL}${item?.user?.users_profile?.image}`}
                                />
                              )}
                            </Box>
                            <Box ml={1}>
                              {" "}
                              <Text
                                fontSize="12px"
                                fontWeight="600"
                                color={Colors.text.navy}
                                textTransform="capitalize"
                                wordBreak="break-all"
                              >
                                {item?.user?.username}
                              </Text>
                            </Box>
                          </Box>
                          <Box width="40%">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                              align="right"
                            >
                              Activity Date
                            </Text>
                            <Text
                              fontSize="11px"
                              fontWeight="400"
                              color={Colors.text.navy}
                              align="right"
                            >
                              {item?.recent_activity_date
                                ? new Date(
                                    item.recent_activity_date
                                  ).toLocaleString()
                                : ""}
                            </Text>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box mt={1}>
                            <Text
                              fontSize="14px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? item?.task.phase_name
                                : item?.task.name}{" "}
                            </Text>
                          </Box>
                          <Box>
                            <Box display="flex" justifyContent="center">
                              <Text
                                fontSize="12px"
                                fontWeight="400"
                                color={Colors.text.black}
                              >
                                {item?.type === "buffer_zone" ? (
                                  <TbStack
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="24px"
                                  />
                                ) : (
                                  <BsFileEarmarkText
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="18px"
                                  />
                                )}{" "}
                              </Text>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? "Buffer Zone"
                                : "Assessment"}{" "}
                            </Text>
                          </Box>
                        </Box>
                      </TaskCard>
                    )
                )}
                {/* for admin user */}
                {inProgress?.items?.map(
                  (item: any, index: any) =>
                    item?.user?.name !== userInfo?.name && (
                      <TaskCard
                        mb={2}
                        key={index}
                        onClick={() =>
                          handleNavigate(
                            item?.app_content_type,
                            item?.type === "buffer_zone"
                              ? item?.object_id
                              : item?.task?.id,
                            item?.task.phase_name,
                            item?.user?.email,
                            item?.owner_user?.pk,
                            item?.task.assessment_level,
                            item?.task.name,
                            item?.task.assessment_type
                          )
                        }
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ maxWidth: "60%" }}
                          >
                            <Box>
                              {item?.user?.users_profile === null ? (
                                <Avatar
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {item?.user?.username?.substring(0, 2)}
                                </Avatar>
                              ) : (
                                <Avatar
                                  alt="user"
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                  src={`${baseURL}${item?.user?.users_profile?.image}`}
                                />
                              )}
                            </Box>
                            <Box ml={1}>
                              {" "}
                              <Text
                                fontSize="12px"
                                fontWeight="600"
                                color={Colors.text.navy}
                                textTransform="capitalize"
                                wordBreak="break-all"
                              >
                                {item?.user?.username}
                              </Text>
                            </Box>
                          </Box>
                          <Box width="40%">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                              align="right"
                            >
                              Activity Date
                            </Text>
                            <Text
                              fontSize="11px"
                              fontWeight="400"
                              color={Colors.text.navy}
                              align="right"
                            >
                              {item?.recent_activity_date
                                ? new Date(
                                    item.recent_activity_date
                                  ).toLocaleString()
                                : ""}
                            </Text>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box mt={1}>
                            <Text
                              fontSize="14px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? item?.task.phase_name
                                : item?.task.name}{" "}
                            </Text>
                          </Box>
                          <Box>
                            <Box display="flex" justifyContent="center">
                              <Text
                                fontSize="12px"
                                fontWeight="400"
                                color={Colors.text.black}
                              >
                                {item?.type === "buffer_zone" ? (
                                  <TbStack
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="24px"
                                  />
                                ) : (
                                  <BsFileEarmarkText
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="18px"
                                  />
                                )}{" "}
                              </Text>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? "Buffer Zone"
                                : "Assessment"}{" "}
                            </Text>
                          </Box>
                        </Box>
                      </TaskCard>
                    )
                )}
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  background: Colors.button.primary_bg,
                  borderRadius: "5px",
                  padding: "10px 20px",
                }}
              >
                <Text
                  fontSize="15px"
                  fontWeight="600"
                  color={Colors.text.black}
                >
                  Done
                </Text>
                <Box
                  sx={{
                    border: `1px solid ${Colors.text.black}`,
                    borderRadius: "5px",
                    padding: "1px 7px",

                    textAlign: "center",
                  }}
                >
                  {filteredCarduserNameDone?.includes(userInfo?.name)
                    ? done?.count - falseStatusCountDoneList
                    : done?.count}
                </Box>
              </Box>
              <Box
                mt={2}
                p={2}
                sx={{
                  background: Colors.text.navy_orange,
                  borderRadius: "5px",
                }}
              >
                {/* invite user card */}
                {done?.items?.map(
                  (item: any, index: any) =>
                    item?.user?.name === userInfo?.name &&
                    item.status === true && (
                      <TaskCard
                        mb={2}
                        key={index}
                        onClick={() =>
                          handleNavigate(
                            item?.app_content_type,
                            item?.type === "buffer_zone"
                              ? item?.object_id
                              : item?.task?.id,
                            item?.task.phase_name,
                            item?.user?.email,
                            item?.owner_user?.pk,
                            item?.task.assessment_level,
                            item?.task.name,
                            item?.task.assessment_type
                          )
                        }
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ maxWidth: "60%" }}
                          >
                            <Box>
                              {item?.user?.users_profile === null ? (
                                <Avatar
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {item?.user?.username?.substring(0, 2)}
                                </Avatar>
                              ) : (
                                <Avatar
                                  alt="user"
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                  src={`${baseURL}${item?.user?.users_profile?.image}`}
                                />
                              )}
                            </Box>
                            <Box ml={1}>
                              {" "}
                              <Text
                                fontSize="12px"
                                fontWeight="600"
                                color={Colors.text.navy}
                                textTransform="capitalize"
                                wordBreak="break-all"
                              >
                                {item?.user?.username}
                              </Text>
                            </Box>
                          </Box>
                          <Box width="40%">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                              align="right"
                            >
                              Activity Date
                            </Text>
                            <Text
                              fontSize="11px"
                              fontWeight="400"
                              color={Colors.text.navy}
                              align="right"
                            >
                              {item?.recent_activity_date
                                ? new Date(
                                    item.recent_activity_date
                                  ).toLocaleString()
                                : ""}
                            </Text>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box mt={1}>
                            <Text
                              fontSize="14px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? item?.task.phase_name
                                : item?.task.name}{" "}
                            </Text>
                          </Box>
                          <Box>
                            <Box display="flex" justifyContent="center">
                              <Text
                                fontSize="12px"
                                fontWeight="400"
                                color={Colors.text.black}
                              >
                                {item?.type === "buffer_zone" ? (
                                  <TbStack
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="24px"
                                  />
                                ) : (
                                  <BsFileEarmarkText
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="18px"
                                  />
                                )}{" "}
                              </Text>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? "Buffer Zone"
                                : "Assessment"}{" "}
                            </Text>
                          </Box>
                        </Box>
                      </TaskCard>
                    )
                )}
                {/* admin user card */}
                {done?.items?.map(
                  (item: any, index: any) =>
                    item?.user?.name !== userInfo?.name && (
                      <TaskCard
                        mb={2}
                        key={index}
                        onClick={() =>
                          handleNavigate(
                            item?.app_content_type,
                            item?.type === "buffer_zone"
                              ? item?.object_id
                              : item?.task?.id,
                            item?.task.phase_name,
                            item?.user?.email,
                            item?.owner_user?.pk,
                            item?.task.assessment_level,
                            item?.task.name,
                            item?.task.assessment_type
                          )
                        }
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ maxWidth: "60%" }}
                          >
                            <Box>
                              {item?.user?.users_profile === null ? (
                                <Avatar
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {item?.user?.username?.substring(0, 2)}
                                </Avatar>
                              ) : (
                                <Avatar
                                  alt="user"
                                  sx={{
                                    bgcolor: deepPurple[500],
                                    height: "26px",
                                    width: "26px",
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                  src={`${baseURL}${item?.user?.users_profile?.image}`}
                                />
                              )}
                            </Box>
                            <Box ml={1}>
                              {" "}
                              <Text
                                fontSize="12px"
                                fontWeight="600"
                                color={Colors.text.navy}
                                textTransform="capitalize"
                                wordBreak="break-all"
                              >
                                {item?.user?.username}
                              </Text>
                            </Box>
                          </Box>
                          <Box width="40%">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                              align="right"
                            >
                              Activity Date
                            </Text>
                            <Text
                              fontSize="11px"
                              fontWeight="400"
                              color={Colors.text.navy}
                              align="right"
                            >
                              {item?.recent_activity_date
                                ? new Date(
                                    item.recent_activity_date
                                  ).toLocaleString()
                                : ""}
                            </Text>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box mt={1}>
                            <Text
                              fontSize="14px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? item?.task.phase_name
                                : item?.task.name}{" "}
                            </Text>
                          </Box>
                          <Box>
                            <Box display="flex" justifyContent="center">
                              <Text
                                fontSize="12px"
                                fontWeight="400"
                                color={Colors.text.black}
                              >
                                {item?.type === "buffer_zone" ? (
                                  <TbStack
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="24px"
                                  />
                                ) : (
                                  <BsFileEarmarkText
                                    color="#000"
                                    fontWeight="700"
                                    fontSize="18px"
                                  />
                                )}{" "}
                              </Text>
                            </Box>
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              color={Colors.text.black}
                            >
                              {item?.type === "buffer_zone"
                                ? "Buffer Zone"
                                : "Assessment"}{" "}
                            </Text>
                          </Box>
                        </Box>
                      </TaskCard>
                    )
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default Trello;
