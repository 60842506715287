import React, { FunctionComponent, ChangeEventHandler, ReactNode } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { TextField, InputLabel } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const Input = styled(TextField)`
  background: white;
  & .MuiInputBase-root {
    padding: 5px 25px; /* add padding inside the input */
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #fed74f !important;
    border: 2px solid #fed74f;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #fed74f !important;
      border: 2px solid #fed74f;
      border-radius: 50px;
    }
    &:hover fieldset {
      border-color: #fed74f !important;
      border-radius: 50px;
      border: 2px solid #fed74f;
    }
    &.Mui-focused fieldset {
      border-color: #fed74f !important;
      border-radius: 50px;
      border: 2px solid #fed74f;
    }
  }
`;

const Label = styled(InputLabel)`
  color: #000;
  font-weight: bold;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

type Props = {
  placeholder?: string;
  width?: string;
  type?: string;
  name?: string;
  label?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value?: string;
  id?: string;
  readonly?: boolean;
  handleShow?: () => void;
  Icon?: ReactNode;

  IconBackground?: string;
  IconsColor?: string;
  onKeyPress?: any;

  onBlur?: any;
};

const InputComponent: FunctionComponent<Props> = ({
  placeholder,
  width = "100%",
  type = "text",
  name = "name",
  label,
  onChange,
  value,
  id,
  readonly = false,
  handleShow,
  Icon,

  IconBackground,
  IconsColor,
  onKeyPress,

  onBlur,
}: Props) => {
  return (
    <>
      <Label>{label}</Label>
      <Input
        id={id}
        onChange={onChange}
        value={value}
        type={type}
        name={name}
        placeholder={placeholder}
        variant="outlined"
        onBlur={onBlur}
        style={{ width: width }}
        onKeyPress={onKeyPress}
        disabled={readonly}
        InputProps={{
          readOnly: readonly,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShow}
                edge="end"
                tabIndex={-1}
                style={{ background: IconBackground, color: IconsColor }}
              >
                {Icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default InputComponent;
