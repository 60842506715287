import React, { FunctionComponent, ReactNode, useRef } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import { FaMinus } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { Colors } from "theme/color";
import {
  GOV,
  IDENTIFY,
  PROTECT,
  DETECT,
  RESPOND,
  RECOVER,
} from "utils/constant";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(0),
}));

const AccordionBox = styled(Accordion)(({ theme }) => ({
  background: "#fff",
  margin: theme.spacing(0),
  color: "#000",
  borderBottom: "1px solid grey",
  borderLeft: "1px solid grey",
  borderRight: "1px solid grey",
}));

const TitleBox = styled(AccordionSummary)(({ theme }) => ({
  margin: theme.spacing(0),
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const AccordionTitle = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  paddingRight: theme.spacing(0),
}));

const AccordionDetail = styled(AccordionDetails)(({ theme }) => ({
  margin: theme.spacing(0),
}));

const AssessmentTitle = styled(Box)(({ theme }) => ({
  width: "100% !important",
  fontSize: "22px",
  fontWeight: "900",
  [theme.breakpoints.down("sm")]: {
    width: "70% !important",
    fontSize: "16px",
    fontWeight: "600",
  },
}));

const ExpandIcon = styled("span")({
  fontSize: "22px",
  fontWeight: "700",
  cursor: "pointer",
  paddingLeft: "10px",
});

const IconContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
}));

type Props = {
  accordions?: any;
  data?: any;
  index?: any;
  children?: ReactNode | ReactNode[];
  handleInputChange?: // eslint-disable-next-line no-unused-vars
  (e: any) => void;
  loader?: any;
  scrollToTitle?: any;
  levelID?: any;
};

const AssessmentAccordian: FunctionComponent<Props> = ({
  data: item,
  index: index,
  children,
  scrollToTitle,
  levelID,
}: Props) => {
  const [expanded, setExpanded] = React.useState(index);
  const accordionRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (index: any) => {
    setExpanded((prevExpanded: any) => (prevExpanded === index ? null : index));
  };

  React.useEffect(() => {
    let titleToCompare = item.title;
    if (levelID === null) {
      titleToCompare = item.title.split(":")[0].trim();
    }

    if (
      scrollToTitle &&
      scrollToTitle === titleToCompare &&
      accordionRef.current
    ) {
      // Calculate the offset to stop 100px from the top
      const topOffset = accordionRef.current.getBoundingClientRect().top - 100;

      // Scroll to the element with the offset
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });

      setExpanded(index);
    }
  }, [scrollToTitle, item.title, levelID, index]);

  return (
    <>
      <Container>
        <AccordionBox key={item.id} expanded={expanded === index}>
          <TitleBox
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            ref={accordionRef}
            style={{
              backgroundColor:
                item.title === GOV
                  ? Colors.text.govern_bg
                  : item.title === IDENTIFY
                  ? Colors.text.identify_bg
                  : item.title === PROTECT
                  ? Colors.text.protect_bg
                  : item.title === DETECT
                  ? Colors.text.detect_bg
                  : item.title === RESPOND
                  ? Colors.text.respond_bg
                  : item.title === RECOVER
                  ? Colors.text.recover_bg
                  : Colors.text.black,

              color:
                item.title === GOV
                  ? Colors.text.black
                  : item.title === IDENTIFY
                  ? Colors.text.white
                  : item.title === PROTECT
                  ? Colors.text.white
                  : item.title === DETECT
                  ? Colors.text.black
                  : item.title === RESPOND
                  ? Colors.text.black
                  : item.title === RECOVER
                  ? Colors.text.black
                  : Colors.text.white,
            }}
          >
            <AccordionTitle>
              <AssessmentTitle>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box component="span" width="80%" display="flex">
                    {item.title}
                  </Box>

                  <Box
                    component="span"
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      marginTop: "-10px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                    width="10%"
                  >
                    ({item.completedQueries ? item.completedQueries : 0} of{" "}
                    {item.totalQueries ? item.totalQueries : 0})
                  </Box>
                  <IconContainer width="3%">
                    <Box
                      component="span"
                      display="flex"
                      justifyContent="end"
                      width="100%"
                    >
                      <ExpandIcon
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent accordion from expanding/collapsing
                          handleChange(index);
                        }}
                      >
                        {expanded === index ? (
                          <FaMinus
                            color={
                              item.title === GOV
                                ? Colors.text.black
                                : item.title === IDENTIFY
                                ? Colors.text.white
                                : item.title === PROTECT
                                ? Colors.text.white
                                : item.title === DETECT
                                ? Colors.text.black
                                : item.title === RESPOND
                                ? Colors.text.black
                                : item.title === RECOVER
                                ? Colors.text.black
                                : Colors.text.white
                            }
                            style={{ fontWeight: "bold", fontSize: "25px" }}
                          />
                        ) : (
                          <GoPlus
                            color={
                              item.title === GOV
                                ? Colors.text.black
                                : item.title === IDENTIFY
                                ? Colors.text.white
                                : item.title === PROTECT
                                ? Colors.text.white
                                : item.title === DETECT
                                ? Colors.text.black
                                : item.title === RESPOND
                                ? Colors.text.black
                                : item.title === RECOVER
                                ? Colors.text.black
                                : Colors.text.white
                            }
                            style={{ fontWeight: "bold", fontSize: "25px" }}
                          />
                        )}
                      </ExpandIcon>
                    </Box>
                  </IconContainer>
                </Box>
              </AssessmentTitle>
            </AccordionTitle>
          </TitleBox>
          <AccordionDetail sx={{ padding: "0px !important" }}>
            {children}
          </AccordionDetail>
        </AccordionBox>
      </Container>
    </>
  );
};

export default AssessmentAccordian;
