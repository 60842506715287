import React, { FunctionComponent, ReactNode } from "react";
import { Box } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Text } from "shared";
import { useNavigate } from "react-router-dom";
import { Colors } from "theme/color";

const Card = styled(Box)({
  backgroundColor: "#fff",
  borderRadius: "10px",
  filter: "drop-shadow(0px 0px 4px grey)",
  padding: "20px",
  marginBottom: "20px",
});

const Progres = styled(Box)({
  height: "26px",
  width: "100%",
  backgroundColor: "#e9ecef",
  borderRadius: "20px",
  overflow: "hidden",
});

const ProgressBar = styled(Box)({
  height: "100%",
  backgroundColor: "#28a745",
  float: "left",
});

const ProgressValue = styled(Box)({
  textAlign: "end",
  padding: "0px 10px",
});

type Props = {
  value?: number;
  children?: ReactNode | ReactNode[];
  status?: string;
  mt?: string;
  mb?: string;
  align?: "left" | "center" | "right" | "justify";
  url?: any;
  assessmentLevleProgress?: any;
  assessmentTypeProgress?: any;
};

const Progress: FunctionComponent<Props> = ({
  value = 60,
  children,
  status = "inprogress",
  mt = "0px",
  mb = "15px",
  align = "center",
  url,
  assessmentLevleProgress,
  assessmentTypeProgress,
}: Props) => {
  const navigate = useNavigate();

  const goToReoprt = () => {
    if (status === "completed") {
      navigate(url.pathname, { state: url.state });
    } else if (status === "inprogress") {
      navigate(url.pathname, { state: url.state });
    }
  };

  return (
    <>
      <Box onClick={goToReoprt} sx={{ cursor: "pointer" }}>
        <Card>
          <Box style={{ minHeight: "60px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontSize="20px" fontWeight="700" textTransform="capitalize">
                {children}
              </Text>
              <Box>
                <Text
                  fontSize="16px"
                  fontWeight="500"
                  align="right"
                  color={Colors.text.sky_1}
                >
                  Type: {assessmentTypeProgress}{" "}
                </Text>
                {assessmentTypeProgress === "NIST CSF 2.0" ? (
                  <Text
                    fontSize="16px"
                    fontWeight="500"
                    align="right"
                    color={Colors.text.orange}
                  >
                    Tier:
                    {assessmentLevleProgress}
                  </Text>
                ) : (
                  <Text
                    fontSize="16px"
                    fontWeight="500"
                    align="right"
                    color={Colors.text.orange}
                  >
                    Level:
                    {assessmentLevleProgress}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
          <Progres mt={mt} mb={mb}>
            <ProgressBar role="progressbar" style={{ width: value + "%" }}>
              <ProgressValue>{Math.round(value)}%</ProgressValue>
            </ProgressBar>
          </Progres>

          <Text fontSize="14px" fontWeight="600" align={align}>
            {status === "completed" ? "Completed" : "In Progress"}
          </Text>
        </Card>
      </Box>
    </>
  );
};
export default Progress;
