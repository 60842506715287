import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Colors } from "theme/color";

export const Card = styled(Box)(({ theme }) => ({
  position: "relative", // add position relative to the Card
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100vh",
  "&::before": {
    // use ::before pseudo-element to add a bubble
    content: '""',
    position: "absolute",
    top: "-60px",
    left: "-80px",
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    backgroundColor: Colors.button.primary_bg,
    opacity: 0.2,
  },
  "&::after": {
    // use ::after pseudo-element to add another bubble
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    backgroundColor: Colors.button.primary_bg,
    opacity: 0.2,
  },
}));

export const Bubbles = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: -1,
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-60px",
    left: "-80px",
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    backgroundColor: Colors.button.primary_bg,
    opacity: 0.2,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-60px",
    right: "-80px",
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    backgroundColor: Colors.button.primary_bg,
    opacity: 0.2,
  },
}));
