import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, Card } from "@mui/material";

export const ContentContainer = styled(Box)({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const SharedCard = styled(Card)({
  filter: "drop-shadow(0px 0px 5px grey)",
  borderRadius: "15px",
  minHeight: "95vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});
